import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';
import InputMask from 'react-input-mask';

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomTable from '../Shared/CustomJs/CustomTable';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { isCpfValid } from './../Shared/Utils/validations';
import { formatarDataComHora, renameFileWithTimestamp } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/Inspetor`;

const Inspetor = () => {

    const listOptions = [
        { value: "nome", label: "Inspetor" },
        { value: "cpf", label: "CPF" },
        { value: "rg", label: "RG" },
        { value: "caminhoAssinatura", label: "Assinatura" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
    ];

    const excludeColumns = ["idInspetor", "ativo", "assinatura"]; // Colunas que você quer excluir da tabela

    const renderTableHeaders = () => {
        const emptyColumns = [<th key="empty1"></th>, <th key="empty2"></th>];

        const optionColumns = listOptions.map((option, index) => (
            <th key={index}>{option.label}</th>
        ));

        return [...emptyColumns, ...optionColumns];
    };

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [listInspetorData, setListInspetorData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    const [idInspetor, setIdInspetor] = useState(null);

    //Add
    const [newInspetor, setNewInspetor] = useState('');
    const [newCpf, setNewCpf] = useState('');
    const [newRg, setNewRg] = useState('');
    const [newAssinatura, setNewAssinatura] = useState('');

    //Edit    
    const [editInspetor, setEditInspetor] = useState(null);
    const [editCpf, setEditCpf] = useState(null);
    const [editRg, setEditRg] = useState(null);

    const [modalMessage, setModalMessage] = useState('');

    const fileInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("nome");

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitialData();
        }
    }, []);


    const fetchInitialData = async () => {
        try {
            setLoading(true);
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-inspetores`);
            const formattedData = formatarDataComHora(response.data);
            setListInspetorData(formattedData);
            setConstListData(formattedData);
            handleNoRecordsFoundAndLoadingFalse(response?.status);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            handleLoadingFalse();
        }
    };

    const handleSave = async (nome, cpf, rg) => {

        if (
            !rg?.trim() ||
            !cpf?.trim() ||
            !nome?.trim()
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!isCpfValid(cpf)) {
            setModalMessage('Erro. CPF inválido');
            return;
        }
        cpf = cpf.replace(/\D/g, '')
        rg = rg.replace(/\D/g, '')

        const itemExistsCpf = listInspetorData.some((item) => item.cpf && item.cpf.replace(/\D/g, '') === cpf.replace(/\D/g, ''));
        const itemExistsRg = listInspetorData.some((item) => item.rg && item.rg.replace(/\D/g, '') === rg.replace(/\D/g, ''));

        if (itemExistsCpf) {
            setModalMessage(`Registro com CPF: ${cpf} já existe`);
        }
        else if (itemExistsRg) {
            setModalMessage(`Registro com RG: ${rg} já existe`);
        }
        else {
            setLoading(true);
            try {

                const originalFile = fileInputRef.current.files[0];
                const newFile = renameFileWithTimestamp(originalFile);

                const formData = new FormData();
                formData.append("Nome", nome);
                formData.append("Cpf", cpf);
                formData.append("Rg", rg);
                formData.append("Assinatura", newFile);

                const response = await axios.post(`${baseApi}/add-inspetor`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response?.status === 200) {
                    setModalMessage(`${response?.data?.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setEditInspetor(null);
                        setNewInspetor('');
                        setEditCpf(null);
                        setNewCpf('');
                        setEditRg(null);
                        setNewRg('');
                        fetchInitialData();
                    }

                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao adicionar o inspetor:', response?.data?.message);
                    setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao adicionar o inspetor:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
                //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleUpdate = async (nome, cpf, rg) => {

        if (
            !rg?.trim() ||
            !cpf?.trim() ||
            !nome?.trim()
        ) {
            setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!isCpfValid(cpf)) {
            setModalMessage('Erro. CPF inválido');
            return;
        }
        cpf = cpf.replace(/\D/g, '');
        rg = rg.replace(/\D/g, '');

        const itemExistsCpf = listInspetorData.some((item) => item.idInspetor !== idInspetor && item.cpf && item.cpf.replace(/\D/g, '') === cpf.replace(/\D/g, ''));
        const itemExistsRg = listInspetorData.some((item) => item.idInspetor !== idInspetor && item.rg && item.rg.replace(/\D/g, '') === rg.replace(/\D/g, ''));

        if (itemExistsCpf) {
            setModalMessage(`Registro com CPF: ${cpf} já existe`);
        }
        else if (itemExistsRg) {
            setModalMessage(`Registro com RG: ${rg} já existe`);
        }
        else {
            setLoading(true);
            try {

                const originalFile = fileInputRef.current.files[0];
                const newFile = renameFileWithTimestamp(originalFile);

                const formData = new FormData();
                formData.append("Nome", nome);
                formData.append("Cpf", cpf);
                formData.append("Rg", rg);
                formData.append("Assinatura", newFile);

                const response = await axios.put(`${baseApi}/update-inspetor/${idInspetor}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response?.status === 200) {

                    setModalMessage(`${response?.data?.message}`);
                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setEditInspetor(null);
                        setNewInspetor('');
                        setEditCpf(null);
                        setNewCpf('');
                        setEditRg(null);
                        setNewRg('');
                        setShowModal(false);
                        Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                        setTimeout(() => {
                            fetchInitialData();
                        }, 50);
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao editar o inspetor:', response?.data?.message);
                    setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao editar o inspetor:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleDelete = async (item) => {
        setIdInspetor(item.idInspetor);
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const response = await axios.delete(`${baseApi}/delete-inspetor/${item.idInspetor}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData();
                        });
                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleInspetorChange = (event) => {
        isUpdate
            ? setEditInspetor(event.target.value.toUpperCase())
            : setNewInspetor(event.target.value.toUpperCase());
    };

    const handleCpfChange = (event) => {
        isUpdate
            ? setEditCpf(event.target.value.toUpperCase())
            : setNewCpf(event.target.value.toUpperCase());
    };

    const handleRgChange = (event) => {
        isUpdate
            ? setEditRg(event.target.value.toUpperCase())
            : setNewRg(event.target.value.toUpperCase());
    };

    const handleFileChange = (event) => {
        setNewAssinatura(event.target.value.toLowerCase());
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleLoadingFalse = () => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const handleNoRecordsFoundAndLoadingFalse = (status) => {
        if (status === 200) {
            handleLoadingFalse();
        }
    };


    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    const openModalAdd = () => {
        setModalMessage('');
        setNewInspetor('');
        setNewCpf('');
        setNewRg('');
        setEditInspetor(null);
        setEditCpf(null);
        setEditRg(null);
        setIsUpdate(false);
        setShowModal(true);
    };
    const openModalEdit = (item) => {
        setModalMessage('');
        setIdInspetor(item.idInspetor);
        setEditInspetor(item.nome);
        setEditCpf(item.cpf);
        setEditRg(item.rg);
        setNewInspetor(null);
        setNewCpf(null);
        setNewRg(null);
        setIsUpdate(true);
        setShowModal(true);
    };
    const closeModal = () => {
        setModalMessage('');
        setNewInspetor('');
        setNewCpf('');
        setNewRg('');
        setEditInspetor(null);
        setEditCpf(null);
        setEditRg(null);
        setIdInspetor(null);
        setIsUpdate(false);
        setShowModal(false);
    };

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0 && loading === false) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>Inspetor</h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>Inspetor</h4></Card.Header>
                            <Card.Body>

                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    openModal={openModalAdd}
                                    fetchInitialData={fetchInitialData}
                                />
                                <CustomTable
                                    renderTableHeaders={renderTableHeaders}
                                    data={paginatedData}
                                    handleEdit={openModalEdit}
                                    handleDelete={handleDelete}
                                    excludeColumns={excludeColumns}
                                    api={`${baseApi}/download-assinatura`}
                                    name={'inspetor'}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>

            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'Inspetor - Editar' : 'Inspetor - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(editInspetor, editCpf, editRg)
                    : () => handleSave(newInspetor, newCpf, newRg)}
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
            >
                <Form.Group controlId="inspetor">
                    <Form.Label className="bold-label">*Nome:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o nome do inspetor"
                        autoFocus
                        value={isUpdate
                            ? editInspetor
                            : newInspetor}
                        onChange={handleInspetorChange}
                    />
                </Form.Group>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="cpf">
                        <Form.Label className="bold-label mt-3">*CPF:</Form.Label>
                        <InputMask
                            mask="999.999.999-99"
                            value={isUpdate
                                ? editCpf
                                : newCpf}
                            onChange={handleCpfChange}
                        >
                            {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="Digite o CPF do inspetor" />}
                        </InputMask>
                    </Form.Group>
                    <Form.Group as={Col} controlId="rg">
                        <Form.Label className="bold-label mt-3">*RG:</Form.Label>
                        <InputMask
                            mask="99.999.999-9"
                            value={isUpdate
                                ? editRg
                                : newRg}
                            onChange={handleRgChange}
                        >
                            {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="Digite o RG do inspetor" />}
                        </InputMask>
                    </Form.Group>
                </Row>

                <Form.Group controlId="assinatura">
                    <Form.Label className="bold-label mt-3">*Assinatura do Técnico:</Form.Label>
                    <Form.Control
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </Form.Group>


                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>
        </Container>
    )
};

export default Inspetor;
