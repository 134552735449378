import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';

//Compartilhados
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { formatarData } from './../Shared/Utils/helper';
import TableBoletos from '../Shared/CustomJs/TableBoletos';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/Boletos`;
const baseApiGetPdf = `${baseApiUrl}/api/Files/download-pdf`;

const Boletos = () => {

    const listOptions = [
        { value: "vencimento", label: "Vencimento" },
        { value: "codigoBarras", label: "Código de Barras" },
    ];

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 100; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [listBoletoData, setListBoletoData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    const [selectedOption, setSelectedOption] = useState("vencimento");

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitial();
        }
    }, []);

    const fetchInitial = async () => {
        setLoading(true);
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-boleto/`);
            const formattedData = formatarData(response.data);
            setListBoletoData(formattedData);
            setConstListData(formattedData);
        } catch (error) {
        }
        setLoading(false);
    };

    const handleUpdateBoletoStatus = async (nome, novoStatus) => {
        try {
            const response = await axios.post(`${baseApi}/update-status/`, {
                Status: novoStatus,
                Nome: nome
            });

            const updatedData = constListData.map(item => {
                if (item.nome === nome) {
                    return { ...item, status: novoStatus };
                }
                return item;
            });
            setConstListData(updatedData);

            Swal.fire('Atualizado!', `Esse registro foi atualizado com sucesso`, 'success');

        } catch (error) {
            console.error("Erro ao atualizar o status:", error);
        }
    };

    const handleDownloadPdf = async (filename) => {
        try {

            const response = await axios.get(`${baseApiGetPdf}/${filename}.pdf`, {
                responseType: 'blob', // Mudança para 'blob' aqui
            });

            if (response?.data) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const urlPdf = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = urlPdf;
                link.download = `${filename}.pdf`; // Força o download com o nome do arquivo
                link.target = '_blank'; // Tenta abrir em uma nova aba
                link.click();

                // Libera a URL do objeto Blob após o download.
                URL.revokeObjectURL(urlPdf);
            }
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCheckTypeUse = () => {

        const storedUser = JSON.parse(localStorage.getItem("user"));

        if (storedUser && storedUser.roles && storedUser.roles.includes('Master')) {
            return true;
        }
        return false;
    }

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );  

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;


    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Col>
                                    <h4 style={{ color: 'red' }}>Boletos</h4>
                                </Col>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row>
                <Col>
                    <Form>
                        <Row className='mt-3'>
                            <Col>
                                <h4 style={{ color: 'red' }}>Boletos - Disponível 10 dias antes da data do vencimento </h4>
                            </Col>
                        </Row>
                        <SearchBar
                            listOptions={listOptions}
                            handleChangeSearchTerm={handleChangeSearchTerm}
                            handleSelectChange={handleSelectChange}
                            handleSearchClick={handleSearchClick}
                            fetchInitialData={fetchInitial}
                        />

                    </Form>

                    <TableBoletos
                        data={paginatedData}
                        handleDownloadPdf={handleDownloadPdf}
                        api={`${baseApi}/download-qrcode/`}
                        updateBoletoStatus={handleUpdateBoletoStatus}
                        handleCheckTypeUse={handleCheckTypeUse}
                    />

                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Col>
            </Row>

            <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />

        </Container>
    )
};

export default Boletos;
