import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './menu.css';
import { useAuth } from '../Context/Auth';

import axios from '../Config/axiosConfig';
import { getTypeSystem } from './../Shared/Utils/helper';

const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApiSession = `${baseApiUrl}/api/Session`;

const Menu = () => {

  const { currentUser, logout, checkUserPermission } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${baseApiUrl}/api/Account/logout`, {
        userName: currentUser.userName,
        System: getTypeSystem()
      });

      await logout();
      navigate('/login');
    } catch {
      // handle error
    }
  };

  if (!currentUser) {
    return null;
  }

  const canSeeMenu = (permission) => {
    return !currentUser || checkUserPermission(permission);
  }

  const arquivoMenuVisible = canSeeMenu('OrdemServico') || canSeeMenu('Laudos') || canSeeMenu('AreaCliente');
  const cadastrosMenuVisible = canSeeMenu('Acessorios') || canSeeMenu('Inspetor') || canSeeMenu('ResponsavelLaudo') || canSeeMenu('ResponsavelTecnico') || canSeeMenu('Usuarios') || canSeeMenu('Clientes') || canSeeMenu('DocumentosComplementares') || canSeeMenu('MetodologiaInspecao');
  const gerenciarMenuVisible = canSeeMenu('Boletos');

  return (
    <Navbar className="custom-navbar" expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-navbar-toggle" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {arquivoMenuVisible && (
              <NavDropdown title="Arquivo" id="nav-dropdown-arquivo" className="custom-nav-dropdown">
                {canSeeMenu('OrdemServico') && (<NavDropdown.Item as={Link} to="/ordemservico">O.S.</NavDropdown.Item>)}
                {canSeeMenu('Laudos') && (<NavDropdown.Item as={Link} to="/os-laudos">O.S. Laudos</NavDropdown.Item>)}
                {/* {canSeeMenu('AreaCliente') && (<NavDropdown.Item as={Link} to="/areacliente">Área do Cliente</NavDropdown.Item>)} */}

                {canSeeMenu('AreaCliente') && !currentUser?.isClient && (<NavDropdown.Item as={Link} to="/areacliente">Laudos Cliente</NavDropdown.Item>)}
                {canSeeMenu('AreaCliente') && currentUser?.isClient && (<NavDropdown.Item as={Link} to="/areacliente">Laudos</NavDropdown.Item>)}               
                {canSeeMenu('DashboardCliente') && currentUser?.isClient && (
                  <NavDropdown.Item as={Link} to="/dashboardcliente">Dashboard</NavDropdown.Item>
                )}

              </NavDropdown>
            )}
            {cadastrosMenuVisible && (
              <NavDropdown title="Cadastros" id="nav-dropdown-cadastros" className="custom-nav-dropdown">
                {canSeeMenu('Acessorios') && (<NavDropdown.Item as={Link} to="/acessorios">Acessórios</NavDropdown.Item>)}
                {canSeeMenu('Inspetor') && (<NavDropdown.Item as={Link} to="/inspetor">Resp. pela Inspeção</NavDropdown.Item>)}
                {canSeeMenu('ResponsavelLaudo') && (<NavDropdown.Item as={Link} to="/responsavellaudo">Resp. pelo Laudo</NavDropdown.Item>)}
                <NavDropdown.Divider />
                {canSeeMenu('ResponsavelTecnico') && (<NavDropdown.Item as={Link} to="/responsaveltecnico">Responsável Técnico</NavDropdown.Item>)}
                {canSeeMenu('Usuarios') && (<NavDropdown.Item as={Link} to="/usuarios">Usuários</NavDropdown.Item>)}
                {canSeeMenu('Clientes') && (<NavDropdown.Item as={Link} to="/clientes">Clientes</NavDropdown.Item>)}
                <NavDropdown.Divider />
                {canSeeMenu('MetodologiaInspecao') && (<NavDropdown.Item as={Link} to="/metodologiaInspecao">Metod. da Inspeção</NavDropdown.Item>)}                
                {canSeeMenu('DocumentosComplementares') && (<NavDropdown.Item as={Link} to="/documentoscomplementares">Documentos Complementares</NavDropdown.Item>)}
              </NavDropdown>
            )}
            {gerenciarMenuVisible && (
              <NavDropdown title="Gerenciar" id="nav-dropdown-gerenciar" className="custom-nav-dropdown">
                {canSeeMenu('Boletos') && (<NavDropdown.Item as={Link} to="/boletos">Boletos</NavDropdown.Item>)}
              </NavDropdown>
            )}
            {canSeeMenu('Dashboard') && (
              <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
            )}
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link>
              <i className="fa fa-user" aria-hidden="true"></i> Seja bem-vindo {currentUser ? currentUser.userName : ''}
            </Nav.Link>
            <Nav.Link onClick={handleLogout}>
              <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
