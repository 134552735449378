import React from 'react';
import { Table, Card } from 'react-bootstrap';

const DashboardClienteTipoAcessorioSetorTable = ({ totalsByAcessorio }) => {

    // Calcula a soma total das quantidades
    const totalQuantity = totalsByAcessorio.reduce((acc, item) => acc + item.total, 0);

    // Ordena os acessórios pela quantidade total de forma decrescente
    const sortedTotalsByAcessorio = [...totalsByAcessorio].sort((a, b) => b.total - a.total);

    return (
        <>
            <Card className="mb-4 mt-4">
                <Card.Body>
                    <Card.Title>
                        <h2 className="table-title">Gráficos por Tipo de Acessório Inspecionado</h2>
                    </Card.Title>
                    <div className="table-responsive">
                        <Table bordered hover responsive>
                            <thead>
                                <tr style={{ backgroundColor: '#ffd966', textAlign: 'center' }}>
                                    <th>Acessórios</th>
                                    <th>Quantidade</th>
                                    <th>Porcentagem</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedTotalsByAcessorio.map((item, index) => (
                                    <tr key={index} style={{ textAlign: 'center' }}>
                                        <td style={{ textAlign: 'left', fontWeight: 'bold' }}>{item.acessorio}</td>
                                        <td>{item.total}</td>
                                        <td>{item.porcentagem}%</td>
                                    </tr>
                                ))}
                                <tr style={{ backgroundColor: '#ffd966', fontWeight: 'bold', textAlign: 'center' }}>
                                    <td>Total</td>
                                    <td>{totalQuantity}</td>
                                    <td>100.00%</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default DashboardClienteTipoAcessorioSetorTable;
