import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomTable from '../Shared/CustomJs/CustomTable';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/Acessorios`;

const Acessorios = () => {

    const listOptions = [
        { value: "acessorio", label: "Acessório" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
    ];

    const excludeColumns = ["idAcessorios", "ativo"]; // Colunas que você quer excluir da tabela

    const renderTableHeaders = () => {
        const emptyColumns = [<th key="empty1"></th>, <th key="empty2"></th>];

        const optionColumns = listOptions.map((option, index) => (
            <th key={index}>{option.label}</th>
        ));

        return [...emptyColumns, ...optionColumns];
    };

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [listAcessoriosData, setListAcessoriosData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    //Add
    const [newAcessorio, setNewAcessorio] = useState('');

    //Edit  
    const [editAcessorio, setEditAcessorio] = useState(null);

    const [idAcessorios, setIdAcessorios] = useState(null);

    const [modalMessage, setModalMessage] = useState('');
    const itemInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("acessorio");

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            fetchInitialData();
        }
    }, []);

    const fetchInitialData = async () => {

        try {
            setLoading(true);
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-acessorios`);
            const formattedData = formatarDataComHora(response.data);
            setListAcessoriosData(formattedData);
            setConstListData(formattedData);
            handleNoRecordsFoundAndLoadingFalse(response?.status);
        } catch (error) {
            handleLoadingFalse();
        }
    };

    const handleSave = async (acessorio) => {
        if (
            !acessorio?.trim()
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        const itemExists = listAcessoriosData.some((item) => item.acessorio && item.acessorio.toLowerCase() === acessorio.toLowerCase());
        if (itemExists) {
            setModalMessage(`Registro ${acessorio} já existe`);
        } else {
            setLoading(true);
            try {
                const newItem = {
                    Acessorio: acessorio
                };

                const response = await axios.post(`${baseApi}/add-acessorio`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response?.data?.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setNewAcessorio('');
                        setEditAcessorio(null);
                        fetchInitialData();
                    }

                } else {
                    handleLoadingFalse();
                    console.error('Erro ao adicionar o acessório:', response?.data?.message);
                    setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                console.error('Erro ao adicionar o acessório:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
                //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
            }
            handleLoadingFalse();
        }
    };

    const handleUpdate = async (acessorio) => {

        if (
            !acessorio?.trim()
        ) {
            setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        const itemExists = listAcessoriosData.some((item) => item.acessorio && item.acessorio.toLowerCase() === acessorio.toLowerCase());
        if (itemExists) {
            setModalMessage(`Registro ${acessorio} já existe`);
        } else {
            setLoading(true);

            try {

                const newItem = {
                    Acessorio: acessorio
                };

                const response = await axios.put(`${baseApi}/update-acessorio/${idAcessorios}`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response.data.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setEditAcessorio(null);
                        setNewAcessorio('');
                        setShowModal(false);
                        Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                        setTimeout(() => {
                            fetchInitialData();
                        }, 50);
                    }
                    handleLoadingFalse();
                } else {
                    handleLoadingFalse();
                    console.error('Erro ao editar o acessório:', response?.data?.message);
                    setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                handleLoadingFalse();
                console.error('Erro ao editar o acessório:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
            }
            handleLoadingFalse();
        }
    };

    const handleDelete = async (item) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${baseApi}/delete-acessorio/${item.idAcessorios}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData();
                        });

                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleAcessorioChange = (event) => {
        isUpdate
            ? setEditAcessorio(event.target.value.toUpperCase())
            : setNewAcessorio(event.target.value.toUpperCase());
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleLoadingFalse = () => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const handleNoRecordsFoundAndLoadingFalse = (status) => {
        if (status === 200) {
            handleLoadingFalse();
        }
    };

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    const openModalAdd = () => {
        setModalMessage('');
        setNewAcessorio('');
        setIdAcessorios(null);
        setEditAcessorio(null);
        setIsUpdate(false);
        setShowModal(true);
    };
    const openModalEdit = (item) => {
        setModalMessage('');
        setEditAcessorio(item.acessorio);
        setIdAcessorios(item.idAcessorios);
        setNewAcessorio('');
        setIsUpdate(true);
        setShowModal(true);
    };
    const closeModal = () => {
        setModalMessage('');
        setNewAcessorio('');
        setEditAcessorio(null);
        setIdAcessorios(null);
        setIsUpdate(false);
        setShowModal(false);
    };

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0 && loading === false) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>Acessórios</h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>Acessórios</h4></Card.Header>
                            <Card.Body>
                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    openModal={openModalAdd}
                                    fetchInitialData={fetchInitialData}
                                />

                                <CustomTable
                                    renderTableHeaders={renderTableHeaders}
                                    data={paginatedData}
                                    handleEdit={openModalEdit}
                                    handleDelete={handleDelete}
                                    excludeColumns={excludeColumns}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />

                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>


            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'Acessórios - Editar' : 'Acessórios - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(editAcessorio)
                    : () => handleSave(newAcessorio)}
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
            >
                <Form.Group controlId="acessorio">
                    <Form.Label className="bold-label">*Acessório:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o nome do acessório"
                        autoFocus
                        value={isUpdate
                            ? editAcessorio
                            : newAcessorio}
                        onChange={handleAcessorioChange}
                    />
                </Form.Group>

                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>
        </Container>
    )
};

export default Acessorios;
