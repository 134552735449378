import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';
import InputMask from 'react-input-mask';

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomTable from '../Shared/CustomJs/CustomTable';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { isCnpjValid, isValidNumberOfCharacters } from './../Shared/Utils/validations';
import { formatarDataComHora, getTypeSystem } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/Clientes`;

const Clientes = () => {

    const listOptions = [

        { value: "cliente", label: "Cliente" },
        { value: "cnpj", label: "CNPJ" },
        { value: "telefone", label: "Telefone" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" }
    ];

    const excludeColumns = ["idCliente", "ativo", "caminhoLogoTipoCliente", "comCopia", "lembreteEmail", "nome", "senha", "destinatarios", "system"]; // Colunas que você quer excluir da tabela

    const renderTableHeaders = () => {
        const emptyColumns = [<th key="empty1"></th>, <th key="empty2"></th>];

        const optionColumns = listOptions.map((option, index) => (
            <th key={index}>{option.label}</th>
        ));

        return [...emptyColumns, ...optionColumns];
    };

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 100; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [listClienteData, setListClienteData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    const [idCliente, setIdCliente] = useState(null);

    //Add
    const [newCliente, setNewCliente] = useState('');
    const [newCnpj, setNewCnpj] = useState('');
    const [newTelefone, setNewTelefone] = useState('');
    const [newNome, setNewNome] = useState('');
    const [newSenha, setNewSenha] = useState('');
    const [newLembreteEmail, setNewLembreteEmail] = useState(false);
    const [newDestinatarios, setNewDestinatarios] = useState('');
    const [newComCopia, setNewComCopia] = useState('');

    //Edit
    const [editCliente, setEditCliente] = useState(null);
    const [editCnpj, setEditCnpj] = useState('');
    const [editTelefone, setEditTelefone] = useState('');
    const [editNome, setEditNome] = useState('');
    const [editSenha, setEditSenha] = useState('');
    const [editLembreteEmail, setEditLembreteEmail] = useState(false);
    const [editDestinatarios, setEditDestinatarios] = useState('');
    const [editComCopia, setEditComCopia] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const [modalMessage, setModalMessage] = useState('');
    const itemInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("cliente");

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitialData();
        }
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-clientes`);
            const formattedData = formatarDataComHora(response.data);
            setListClienteData(formattedData);
            setConstListData(formattedData);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
        setLoading(false);
    };

    const handleSave = async (cliente, cnpj, telefone, nome, senha, lembreteEmail, destinatarios, comcopia) => {

        if (
            !cliente?.trim() ||
            !cnpj?.trim() ||
            !nome?.trim() ||
            !senha?.trim() ||
            lembreteEmail === null
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!isValidNumberOfCharacters(nome, 5)) {
            setModalMessage('Erro ao adicionar o registro: O nome deve ter 5 ou mais caracteres');
            return;
        }

        if (!isValidNumberOfCharacters(senha, 3)) {
            setModalMessage('Erro ao adicionar o registro: A senha deve ter 3 ou mais caracteres');
            return;
        }

        if (!isCnpjValid(cnpj)) {
            setModalMessage('Erro. CNPJ inválido');
            return;
        }

        const itemExistsCliente = listClienteData.some((item) => item.cliente && item.cliente.toLowerCase() === cliente.toLowerCase());
        const itemExistsNome = listClienteData.some((item) => item.nome && item.nome.toLowerCase() === nome.toLowerCase());

        if (itemExistsCliente) {
            setModalMessage(`Razão Social ${cliente} já existe`);
        }
        else if (itemExistsNome) {
            setModalMessage(`Registro com o nome ${nome} já existe`);
        }
        else {
            setLoading(true);
            try {

                const newItem = {
                    Cliente: cliente,
                    Cnpj: cnpj,
                    Telefone: telefone,
                    Nome: nome,
                    Senha: senha,
                    LembreteEmail: lembreteEmail,
                    Destinatarios: destinatarios,
                    ComCopia: comcopia,
                    System: getTypeSystem()
                };

                const response = await axios.post(`${baseApi}/add-cliente`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response?.data?.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setNewCliente('');
                        setNewCnpj('');
                        setNewComCopia('');
                        setNewDestinatarios('');
                        setNewLembreteEmail(false);
                        setNewNome('');
                        setNewSenha('');
                        setNewTelefone('');

                        setEditCliente(null);
                        setEditCnpj('');
                        setEditComCopia('');
                        setEditDestinatarios('');
                        setEditLembreteEmail(false);
                        setEditNome('');
                        setEditSenha('');
                        setEditTelefone('');

                        setIsUpdate(false);
                        fetchInitialData();
                    }

                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao adicionar o acessório:', response?.data?.message);
                    setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao adicionar o acessório:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
                //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleUpdate = async (cliente, cnpj, telefone, nome, senha, lembreteEmail, destinatarios, comcopia) => {

        if (
            !cliente?.trim() ||
            !cnpj?.trim() ||
            !nome?.trim() ||
            !senha?.trim() ||
            lembreteEmail === null
        ) {
            setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!isValidNumberOfCharacters(nome, 5)) {
            setModalMessage('Erro ao editar o registro: O nome deve ter 5 ou mais caracteres');
            return;
        }

        if (!isValidNumberOfCharacters(senha, 3)) {
            setModalMessage('Erro ao editar o registro: A senha deve ter 3 ou mais caracteres');
            return;
        }

        if (!isCnpjValid(cnpj)) {
            setModalMessage('Erro. CNPJ inválido');
            return;
        }

        const itemExistsCliente = listClienteData.some((item) => item.idCliente !== idCliente && item.cliente && item.cliente.toLowerCase() === cliente.toLowerCase());
        const itemExistsNome = listClienteData.some((item) => item.idCliente !== idCliente && item.nome && item.nome.toLowerCase() === nome.toLowerCase());

        if (itemExistsCliente) {
            setModalMessage(`Razão Social ${cliente} já existe`);
        }
        else if (itemExistsNome) {
            setModalMessage(`Registro com o nome ${nome} já existe`);
        } else {
            setLoading(true);

            try {

                const newItem = {
                    Cliente: cliente,
                    Cnpj: cnpj,
                    Telefone: telefone,
                    Nome: nome,
                    Senha: senha,
                    LembreteEmail: lembreteEmail,
                    Destinatarios: destinatarios,
                    ComCopia: comcopia
                };

                const response = await axios.put(`${baseApi}/update-cliente/${idCliente}`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response.data.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setNewCliente('');
                        setNewCnpj('');
                        setNewComCopia('');
                        setNewDestinatarios('');
                        setNewLembreteEmail(false);
                        setNewNome('');
                        setNewSenha('');
                        setNewTelefone('');

                        setEditCliente(null);
                        setEditCnpj('');
                        setEditComCopia('');
                        setEditDestinatarios('');
                        setEditLembreteEmail(false);
                        setEditNome('');
                        setEditSenha('');
                        setEditTelefone('');
                        setShowModal(false);
                        Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                        setTimeout(() => {
                            fetchInitialData();
                        }, 50);
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao editar o acessório:', response?.data?.message);
                    setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao editar o acessório:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);

        }
    };

    const handleDelete = async (item) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${baseApi}/delete-cliente/${item.idCliente}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData();
                        });

                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleClienteChange = (event) => {
        isUpdate
            ? setEditCliente(event.target.value.toUpperCase())
            : setNewCliente(event.target.value.toUpperCase());
    };

    const handleCnpjChange = (event) => {
        isUpdate
            ? setEditCnpj(event.target.value.toUpperCase())
            : setNewCnpj(event.target.value.toUpperCase());
    };

    const handleTelefoneChange = (event) => {
        isUpdate
            ? setEditTelefone(event.target.value.toUpperCase())
            : setNewTelefone(event.target.value.toUpperCase());
    };

    const handleLembreteEmailChange = (event) => {
        if (isUpdate) {
            setEditLembreteEmail(event.target.checked);
        } else {
            setNewLembreteEmail(event.target.checked);
        }
    };

    const handleDestinatariosChange = (event) => {
        isUpdate
            ? setEditDestinatarios(event.target.value.toUpperCase())
            : setNewDestinatarios(event.target.value.toUpperCase());
    };

    const handleComCopiaChange = (event) => {
        isUpdate
            ? setEditComCopia(event.target.value.toUpperCase())
            : setNewComCopia(event.target.value.toUpperCase());
    };

    const handleNomeUsuarioClienteChange = (event) => {
        isUpdate
            ? setEditNome(event.target.value.toUpperCase())
            : setNewNome(event.target.value.toUpperCase());
    };

    const handleSenhaChange = (event) => {
        isUpdate
            ? setEditSenha(event.target.value.toUpperCase())
            : setNewSenha(event.target.value.toUpperCase());
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredClientesData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredClientesData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredClientesData.length / itemsPerPage);
    const totalRecords = filteredClientesData.length;

    const openModalAdd = () => {
        setModalMessage('');
        setIdCliente(null);
        setNewCliente('');
        setNewCnpj('');
        setNewComCopia('');
        setNewDestinatarios('');
        setNewLembreteEmail(false);
        setNewNome('');
        setNewSenha('');
        setNewTelefone('');

        setEditCliente(null);
        setEditCnpj('');
        setEditComCopia('');
        setEditDestinatarios('');
        setEditLembreteEmail(false);
        setEditNome('');
        setEditSenha('');
        setEditTelefone('');

        setIsUpdate(false);
        setShowModal(true);
    };
    const openModalEdit = (item) => {
        setModalMessage('');
        setIdCliente(item.idCliente);
        setEditCliente(item.cliente);
        setEditCnpj(item.cnpj);
        setEditTelefone(item.telefone);
        setEditLembreteEmail(item.lembreteEmail);
        setEditDestinatarios(item.destinatarios);
        setEditComCopia(item.comCopia);
        setEditNome(item.nome);
        setEditSenha('');

        setNewCliente('');
        setNewCnpj('');
        setNewComCopia('');
        setNewDestinatarios('');
        setNewLembreteEmail(false);
        setNewNome('');
        setNewSenha('');
        setNewTelefone('');
        setIsUpdate(true);
        setShowModal(true);
    };
    const closeModal = () => {
        setIdCliente(null);

        setModalMessage('');
        setNewCliente('');
        setNewCnpj('');
        setNewComCopia('');
        setNewDestinatarios('');
        setNewLembreteEmail(false);
        setNewNome('');
        setNewSenha('');
        setNewTelefone('');

        setEditCliente(null);
        setEditCnpj('');
        setEditComCopia('');
        setEditDestinatarios('');
        setEditLembreteEmail(false);
        setEditNome('');
        setEditSenha('');
        setEditTelefone('');
        setIsUpdate(false);
        setShowModal(false);
    };

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>Clientes</h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>Clientes</h4></Card.Header>
                            <Card.Body>
                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    openModal={openModalAdd}
                                    fetchInitialData={fetchInitialData}
                                />
                                <CustomTable
                                    renderTableHeaders={renderTableHeaders}
                                    data={paginatedData}
                                    handleEdit={openModalEdit}
                                    handleDelete={handleDelete}
                                    excludeColumns={excludeColumns}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>

            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'Cliente - Editar' : 'Cliente - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(editCliente, editCnpj, editTelefone, editNome, editSenha, editLembreteEmail, editDestinatarios, editComCopia)
                    : () => handleSave(newCliente, newCnpj, newTelefone, newNome, newSenha, newLembreteEmail, newDestinatarios, newComCopia)}
                size="lg"
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
            >
                <Form.Group controlId="cliente">
                    <Form.Label className="bold-label">*Razão Social:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite a razão social"
                        maxLength="100"
                        autoFocus
                        value={isUpdate
                            ? editCliente
                            : newCliente}
                        onChange={handleClienteChange}
                    />
                </Form.Group>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="cnpj">
                        <Form.Label className="bold-label mt-3">*CNPJ:</Form.Label>
                        <InputMask
                            mask="99.999.999/9999-99"
                            value={isUpdate
                                ? editCnpj
                                : newCnpj}
                            onChange={handleCnpjChange}
                        >
                            {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="Digite o CNPJ" />}
                        </InputMask>
                    </Form.Group>
                    <Form.Group as={Col} controlId="telefone">
                        <Form.Label className="bold-label mt-3">Telefone:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o telefone"
                            maxLength="20"
                            value={isUpdate
                                ? editTelefone
                                : newTelefone}
                            onChange={handleTelefoneChange}
                        />
                    </Form.Group>
                </Row>
                <Form.Group controlId="enviarEmailLembrete" className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label="Enviar Email Lembrete?"
                        checked={isUpdate ? editLembreteEmail : newLembreteEmail}
                        onChange={handleLembreteEmailChange}
                        className='bold-label'
                    />
                </Form.Group>

                {(isUpdate ? editLembreteEmail : newLembreteEmail) && (
                    <>
                        <Form.Group controlId="destinatario" className="mb-3">
                            <Form.Label className="bold-label">Destinatário (s):</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digite os destinatários"
                                maxLength="600"
                                value={isUpdate
                                    ? editDestinatarios
                                    : newDestinatarios}
                                onChange={handleDestinatariosChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="comCopia" className="mb-3">
                            <Form.Label className="bold-label">CC:</Form.Label>
                            <Form.Control
                                type="text"
                                maxLength="600"
                                value={isUpdate
                                    ? editComCopia
                                    : newComCopia}
                                onChange={handleComCopiaChange}
                            />
                        </Form.Group>
                    </>
                )}

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="nomeusuariocliente">
                        <Form.Label className="bold-label mt-3">*Nome do usuário do cliente:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o usuário do cliente"
                            minLength="3"
                            maxLength="100"
                            autoComplete="off"
                            value={isUpdate ? editNome : newNome}
                            onChange={handleNomeUsuarioClienteChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="senha">
                        <Form.Label className="bold-label mt-3">*Senha:</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Digite a senha"
                                minLength="3"
                                maxLength="20"
                                autoComplete="off"
                                value={isUpdate ? editSenha : newSenha}
                                onChange={handleSenhaChange}
                            />
                        </div>
                    </Form.Group>
                </Row>

                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>
        </Container>
    )
};

export default Clientes;
