import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBarUser from '../Shared/CustomJs/SearchBarUser';
import TableUser from '../Shared/CustomJs/TableUser';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora, getTypeSystem } from './../Shared/Utils/helper';
import { isValidNumberOfCharacters } from './../Shared/Utils/validations';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/Usuarios`
const baseApiRoles = `${baseApiUrl}/api/Roles`

const Usuarios = () => {

    const listOptions = [

        { value: "userName", label: "Usuário" },
        { value: "fullName", label: "Nome Completo" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
        { value: "ativo", label: "Ativo" }
    ];

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 100; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [showModalPermission, setShowModalPermission] = useState(false);

    const [listUsuarioData, setListUsuarioData] = useState([]);
    const [constListData, seConstListData] = useState([]);

    const [showPassword, setShowPassword] = useState(false);

    const [id, setIdUsuario] = useState(null);

    //Add
    const [newUsuario, setNewUsuario] = useState('');
    const [newSenha, setNewSenha] = useState('');
    const [newNomeCompleto, setNewNomeCompleto] = useState('');

    //Edit
    const [editUsuario, setEditUsuario] = useState('');
    const [editSenha, setEditSenha] = useState(null);
    const [editNomeCompleto, setEditNomeCompleto] = useState(null);

    //Permissao
    const [permissionUsuario, setPermissionUsuario] = useState('');

    const [modalMessage, setModalMessage] = useState('');
    const itemInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("userName");
    const [selectedOptionAtivo, setSelectedOptionAtivo] = useState(null);

    const [isListAtivo, setIsListAtivo] = useState(true);

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitialData(true);
        }
    }, []);

    const fetchInitialData = async (ativo) => {
        try {
            setLoading(true);
            setIsListAtivo(ativo);
            setSearchTerm('');
            let url = `${baseApi}/get-users${ativo !== null && ativo !== undefined ? `?ativo=${ativo}` : ''}`;

            const response = await axios.get(url);

            setSelectedOptionAtivo(response.data.ativo);
            const formattedData = formatarDataComHora(response.data.users);
            setListUsuarioData(formattedData);
            seConstListData(formattedData);
            handleNoRecordsFoundAndLoadingFalse(response?.status);
        } catch (error) {
            const errorMessage = error.response?.status
                ? `Erro ${error.response.status}: ${error.response.statusText}`
                : error.message;
            console.error('Erro ao buscar dados:', errorMessage);
            handleLoadingFalse();
        }
    };

    const handleSave = async (userName, senha, fullName) => {

        if (
            !userName?.trim() ||
            !senha?.trim() ||
            !fullName?.trim()
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }
        if (!isValidNumberOfCharacters(senha, 4)) {
            setModalMessage('Erro ao adicionar o registro: A senha deve ter 4 ou mais caracteres');
            return;
        }

        const itemExistsUserName = listUsuarioData.some((item) => item.userName && item.userName.toLowerCase() === userName.toLowerCase());

        if (itemExistsUserName) {
            setModalMessage(`Registro com o nome ${userName} já existe`);
        }
        else {
            setLoading(true);
            try {

                const newItem = {
                    UserName: userName,
                    Password: senha,
                    FullName: fullName,
                    System: getTypeSystem()
                };

                const response = await axios.post(`${baseApi}/add-user`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response?.data?.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setNewUsuario('');
                        setNewSenha('');

                        setEditUsuario(null);
                        setEditSenha('');

                        setIsUpdate(false);
                        fetchInitialData(isListAtivo);
                    }

                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao adicionar o usuário:', response?.data?.message);
                    setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao adicionar o usuário:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
                //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleUpdate = async (userName, senha, fullName) => {

        if (
            !userName?.trim() ||
            !fullName?.trim()
        ) {
            setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        const itemExistsUserName = listUsuarioData.some((item) => item.id !== id && item.userName && item.userName.toLowerCase() === userName.toLowerCase());

        if (itemExistsUserName) {
            setModalMessage(`Registro com o nome ${userName} já existe`);
        } else {
            setLoading(true);

            try {

                const newItem = {
                    UserName: userName,
                    NewPassword: senha,
                    FullName: fullName,
                    System: 'INGUANTI'
                };

                const response = await axios.put(`${baseApi}/update-user/${id}`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response.data.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setNewUsuario('');
                        setNewSenha('');

                        setEditUsuario(null);
                        setEditSenha('');

                        setShowModal(false);
                        Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                        setTimeout(() => {
                            fetchInitialData(isListAtivo);
                        }, 50);
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao editar o usuário:', response?.data?.message);
                    setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao editar o usuário:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);

        }
    };

    const handleDelete = async (item) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${baseApi}/delete-user/${item.id}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData(isListAtivo);
                        });

                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleChangeEnableDisable = (event) => {
        const status = event.target.value;

        const statusToActiveMapping = {
            "1": true,
            "2": false
        };

        // Obtém o valor booleano correspondente ou null se não estiver definido
        const ativo = statusToActiveMapping.hasOwnProperty(status) ? statusToActiveMapping[status] : null;

        fetchInitialData(ativo);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === ' ') {
            event.preventDefault();
            Swal.fire('Atenção', 'O uso de espaços não é permitido neste campo)', 'info');
        }
    };


    const handleUsuarioChange = (event) => {
        const valueWithoutSpaces = event.target.value.replace(/\s+/g, '');

        isUpdate
            ? setEditUsuario(valueWithoutSpaces.toUpperCase())
            : setNewUsuario(valueWithoutSpaces.toUpperCase());
    };

    const handleNomeCompletoChange = (event) => {
        isUpdate
            ? setEditNomeCompleto(event.target.value.toUpperCase())
            : setNewNomeCompleto(event.target.value.toUpperCase());
    };

    const handleSenhaChange = (event) => {
        isUpdate
            ? setEditSenha(event.target.value)
            : setNewSenha(event.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleLoadingFalse = () => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const handleNoRecordsFoundAndLoadingFalse = (status) => {
        if (status === 200) {
            handleLoadingFalse();
        }
    };

    const handleUpdateAtivo = async (id, ativo) => {
        setLoading(true);
        try {
            const response = await axios.put(`${baseApi}/enable-disable-user/${id}/${ativo}`);
            if (response?.status === 200) {
                Swal.fire('Atualizado!', `${response?.data?.message}`, 'success').then(async () => {
                    fetchInitialData(!ativo);
                });

            } else {
                console.error('Erro ao ativar/desativar o registro:', response?.data?.message);
            }
        } catch (error) {
            console.error('Erro ao ativar/desativar o registro:', error?.response?.data?.errors[0]);
        }
        setLoading(false);
    };

    const generateCheckbox = () => {
        return {
            MenuArquivo: false,
            OrdemServico: false,
            Laudos: false,
            AreaCliente: false,
            MenuCadastro: false,
            Acessorios: false,
            Inspetor: false,
            ResponsavelTecnico: false,
            Clientes: false,
            Usuarios: false,
            DocumentosComplementares: false,
            MenuGerenciar: false,
            Dashboard: false,
            Boletos: false,
            TermosDeUso: false,
            ResponsavelLaudo: false,
            MetodologiaInspecao: false,
        }
    };

    const [checkboxState, setCheckboxState] = useState(generateCheckbox());

    const rolesMapping = {
        "MenuArquivo": ["OrdemServico", "Laudos", "AreaCliente", "DashboardCliente"],
        "MenuCadastro": ["Acessorios", "Inspetor", "ResponsavelTecnico", "Clientes", "Usuarios", "DocumentosComplementares", "ResponsavelLaudo", "MetodologiaInspecao"],
        "MenuGerenciar": ["Boletos"]
    };

    const rolesDescriptionMapping = {
        "OrdemServico": "Ordem Serviço",
        "Laudos": "O.S. Laudos",
        "AreaCliente": "Área Cliente",
        "Acessorios": "Acessórios",
        "Inspetor": "Inspetor",
        "ResponsavelTecnico": "Resp. Técnico",
        "Clientes": "Cliente",
        "Usuarios": "Usuário",
        "DocumentosComplementares": "Doc. Compl.",
        "Dashboard": "Dashboard",
        "Boletos": "Boletos",
        "TermosDeUso": "Termos de Uso",
        "ResponsavelLaudo": "Resp. Laudo",
        "MetodologiaInspecao": "Metod. Insp.",
        "DashboardCliente": "Dashboard",
    };

    async function updateRoles(newCheckboxState) {
        for (let role in newCheckboxState) {
            if (newCheckboxState[role]) {
                await addRole(role);
            } else {
                await removeRole(role);
            }
        }
    }

    function updateCheckboxState(name, checked, newCheckboxState) {
        setCheckboxState(prevState => ({
            ...prevState,
            ...newCheckboxState,
            [name]: checked
        }));
    }

    function isAnyCheckboxChecked(state, checkboxes) {
        return checkboxes.some(checkbox => state[checkbox]);
    }

    const handleCheckboxChange = async (e) => {
        const { name, checked } = e.target;
        let newCheckboxState;

        setLoading(true);

        if (rolesMapping[name]) {
            newCheckboxState = rolesMapping[name].reduce((state, role) => ({ ...state, [role]: checked }), {});
            await updateRoles(newCheckboxState);
        } else {
            if (checked) {
                await addRole(name);
            } else {
                await removeRole(name);
            }
            newCheckboxState = { [name]: checked };
        }

        updateCheckboxState(name, checked, newCheckboxState);

        for (let menu in rolesMapping) {
            setCheckboxState(prevState => {
                const newState = { ...prevState, [name]: checked };
                newState[menu] = isAnyCheckboxChecked(newState, rolesMapping[menu]);
                return newState;
            });
        }

        setLoading(false);
    };

    const addRole = async (role) => {

        const userName = permissionUsuario;
        const descriptionRole = rolesDescriptionMapping[role];

        const data = {
            UserId: id,
            UserName: userName,
            Role: role,
            DescriptionRole: descriptionRole
        };

        try {

            const response = await axios.post(`${baseApiRoles}/add-user-to-role`, data);

            if (response?.status === 200) {

                if (!(response?.data?.message.toLowerCase().includes('erro')
                    || response?.data?.message.toLowerCase().includes('existe'))
                ) {
                    setModalMessage(`${response?.data?.message}`);
                }

            } else {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao adicionar o usuário:', response?.data?.message);
                setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
            }


        } catch (error) {
            // console.error("Erro ao atualizar a role: ", error);
        }
    }

    const removeRole = async (role) => {

        const userName = permissionUsuario;
        const descriptionRole = rolesDescriptionMapping[role];

        const data = {
            UserId: id,
            UserName: userName,
            Role: role,
            DescriptionRole: descriptionRole
        };

        try {

            const response = await axios.post(`${baseApiRoles}/remove-user-to-role`, data);

            if (response?.status === 200) {

                if (!(response?.data?.message.toLowerCase().includes('erro')
                    || response?.data?.message.toLowerCase().includes('existe'))
                ) {
                    setModalMessage(`${response?.data?.message}`);
                }

            } else {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao adicionar o usuário:', response?.data?.message);
                setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
            }


        } catch (error) {
            // console.error("Erro ao atualizar a role: ", error);
        }
    }

    const getRolesForUser = async (idUsuario) => {
        setLoading(true);

        try {
            const response = await axios.get(`${baseApiRoles}/get-user-roles-for-user/${idUsuario}`);

            if (response?.status === 200) {
                const roles = response?.data;
                let newCheckboxState = roles.length > 0 ? generateCheckboxStateFromRoles(roles) : generateCheckbox();
                setCheckboxState(newCheckboxState);
            } else {
                console.error('Erro ao editar o usuário:', response?.data?.message);
                setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
            }
        } catch (error) {
            // Handle error
        }

        setLoading(false);
    };

    const generateCheckboxStateFromRoles = (roles) => {
        let newCheckboxState = generateCheckbox();

        roles.forEach(role => {
            if (newCheckboxState.hasOwnProperty(role)) {
                newCheckboxState[role] = true;
                // Atualizar o estado do menu correspondente
                for (let menu in rolesMapping) {
                    if (rolesMapping[menu].includes(role)) {
                        newCheckboxState[menu] = true;
                    }
                }
            }
        });

        return newCheckboxState;
    };

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    const openModalAdd = () => {
        setModalMessage('');
        setIdUsuario(null);
        setNewUsuario('');
        setNewSenha('');
        setNewNomeCompleto('');

        setEditUsuario(null);
        setEditSenha('');
        setEditNomeCompleto('');

        setIsUpdate(false);
        setShowModal(true);
        setShowModalPermission(false);
    };
    const openModalEdit = (item) => {
        setModalMessage('');
        setIdUsuario(item.id);
        setEditUsuario(item.userName);
        setEditSenha('');
        setEditNomeCompleto(item.fullName);

        setNewUsuario('');
        setNewSenha('');
        setNewSenha('');

        setIsUpdate(true);
        setShowModal(true);
        setShowModalPermission(false);
    };
    const openModalPermissions = (item) => {
        setModalMessage('');
        const idUsuario = item.id;
        setIdUsuario(idUsuario);
        setPermissionUsuario(item.userName);
        getRolesForUser(idUsuario);
        setShowModalPermission(true);
    };
    const closeModal = () => {
        setModalMessage('');
        setIsUpdate(false);
        setShowModal(false);
        setShowModalPermission(false);
    };

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0 && loading === false) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>Usuários</h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>Usuários</h4></Card.Header>
                            <Card.Body>
                                <SearchBarUser
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    openModal={openModalAdd}
                                    fetchInitialData={fetchInitialData}
                                    handleChangeEnableDisable={handleChangeEnableDisable}
                                    selectedOptionAtivo={selectedOptionAtivo}
                                />
                                <TableUser
                                    data={paginatedData}
                                    handleEdit={openModalEdit}
                                    handleDelete={handleDelete}
                                    handlePermission={openModalPermissions}
                                    handleUpdateAtivo={handleUpdateAtivo}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>

            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'Usuário - Editar' : 'Usuário - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(editUsuario, editSenha, editNomeCompleto)
                    : () => handleSave(newUsuario, newSenha, newNomeCompleto)}
                size="lg"
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
            >
                <Row className="mb-3">
                    <Form.Group as={Col} md="7" controlId="userName">
                        <Form.Label className="bold-label mt-3">*Nome do usuário:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Usuário"
                            minLength="3"
                            maxLength="50"
                            autoComplete="off"
                            value={isUpdate
                                ? editUsuario
                                : newUsuario}
                            onChange={handleUsuarioChange}
                            onKeyDown={handleKeyDown}
                            autoFocus
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="5" controlId="senha">
                        <Form.Label className="bold-label mt-3">*Senha:</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Digite a senha"
                                minLength="3"
                                maxLength="20"
                                autoComplete="off"
                                value={isUpdate ? editSenha : newSenha}
                                onChange={handleSenhaChange}
                            />

                            {(!isUpdate) && (
                                <i
                                    onClick={() => setShowPassword(!showPassword)}
                                    className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                                    style={{ cursor: 'pointer', paddingLeft: "7px", marginTop: "10px" }}
                                />
                            )}

                        </div>
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="userName">
                        <Form.Label className="bold-label mt-3">*Nome Completo:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome Completo"
                            minLength="3"
                            maxLength="150"
                            autoComplete="off"
                            value={isUpdate
                                ? editNomeCompleto
                                : newNomeCompleto}
                            onChange={handleNomeCompletoChange}
                            autoFocus
                        />
                    </Form.Group>
                </Row>


                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>

            <CustomModal
                show={showModalPermission}
                onHide={closeModal}
                title={'Permissões - Editar'}
                size="lg"
                saveButtonExist={false}
            >
                <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="userName">
                        <Form.Label className="bold-label mt-3">*Nome do usuário:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Usuário"
                            minLength="3"
                            maxLength="50"
                            autoComplete="off"
                            value={permissionUsuario}
                            disabled={true}
                            autoFocus
                        />
                    </Form.Group>

                    <div className="row mt-3">
                        {/* Menu Arquivo */}
                        <div className="col-12">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="chkMenuArquivo"
                                    name="MenuArquivo"
                                    checked={checkboxState.MenuArquivo}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="chkMenuArquivo" style={{ color: "red" }}>
                                    {" "}Menu Arquivo
                                </label>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-4">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkOrdemServico"
                                        name="OrdemServico"
                                        checked={checkboxState.OrdemServico}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkOrdemServico">
                                        {" "}Ordem Serviço
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkLaudos"
                                        name="Laudos"
                                        checked={checkboxState.Laudos}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkLaudos">
                                        {" "}O.S. Laudos
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkAreaCliente"
                                        name="AreaCliente"
                                        checked={checkboxState.AreaCliente}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkAreaCliente">
                                        {" "}Área Cliente
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* FIM Menu Arquivo */}

                        <hr class="hr hr-blurry" />

                        {/* Menu Cadastro */}
                        <div className="col-12">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="chkMenuCadastro"
                                    name="MenuCadastro"
                                    checked={checkboxState.MenuCadastro}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="chkMenuCadastro" style={{ color: "red" }}>
                                    {" "}Menu Cadastro
                                </label>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-4">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkAcessorios"
                                        name="Acessorios"
                                        checked={checkboxState.Acessorios}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkAcessorios">
                                        {" "}Acessórios
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkInspetor"
                                        name="Inspetor"
                                        checked={checkboxState.Inspetor}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkInspetor">
                                        {" "}Inspetor
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkResponsavelTecnico"
                                        name="ResponsavelTecnico"
                                        checked={checkboxState.ResponsavelTecnico}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkResponsavelTecnico">
                                        {" "}Resp. Técnico
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-4">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkClientes"
                                        name="Clientes"
                                        checked={checkboxState.Clientes}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkClientes">
                                        {" "}Cliente
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkUsuarios"
                                        name="Usuarios"
                                        checked={checkboxState.Usuarios}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkUsuarios">
                                        {" "}Usuário
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkDocumentosComplementares"
                                        name="DocumentosComplementares"
                                        checked={checkboxState.DocumentosComplementares}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkDocumentosComplementares">
                                        {" "}Doc. Compl.
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-4">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkRespLaudo"
                                        name="ResponsavelLaudo"
                                        checked={checkboxState.ResponsavelLaudo}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkRespLaudo">
                                        {" "}Resp. Laudo
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkMetodInsp"
                                        name="MetodologiaInspecao"
                                        checked={checkboxState.MetodologiaInspecao}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="MetodInsp">
                                        {" "}Metod. Insp.
                                    </label>
                                </div>
                            </div>


                        </div>
                        {/* FIM Menu Cadastro */}

                        <hr class="hr hr-blurry" />

                        {/* Menu Gerenciar */}
                        <div className="col-12">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="chkMenuGerenciar"
                                    name="MenuGerenciar"
                                    checked={checkboxState.MenuGerenciar}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="chkMenuGerenciar" style={{ color: "red" }}>
                                    {" "}Menu Gerenciar
                                </label>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="row mt-3">
                                <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input"
                                            type="checkbox"
                                            id="chkBoleto"
                                            name="Boletos"
                                            checked={checkboxState.Boletos}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="chkBoleto">
                                            {" "}Boletos
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* FIM Menu Gerenciar */}

                        <hr class="hr hr-blurry" />

                        <div className="col-12 col-sm-4">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                    type="checkbox"
                                    id="chkDashboard"
                                    name="Dashboard"
                                    checked={checkboxState.Dashboard}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="chkDashboard">
                                    {" "}Dashboard
                                </label>
                            </div>
                        </div>

                        <hr class="hr hr-blurry" />

                        <div className="row">
                            <div className="col-12 col-sm-4 mt-2 mt-sm-0">
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        id="chkTermosDeUso"
                                        name="TermosDeUso"
                                        checked={checkboxState.TermosDeUso}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label" htmlFor="chkTermosDeUso">
                                        {" "}Termos de Uso
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </Row>

                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>



        </Container>
    )
};

export default Usuarios;
