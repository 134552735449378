import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';
import fetchWithAuth from '../Config/fetchWithAuth';
import { useParams, useNavigate } from "react-router-dom";

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import TableLaudo from '../Shared/CustomJs/TableLaudo';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

import ExportToPDF from './ExportToPDF';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;

let baseApiUrl = process.env.REACT_APP_API_URL;

const baseApi = `${baseApiUrl}/api/Laudos`;
const baseApiGetPdf = `${baseApiUrl}/api/Files/download-pdf`;
const baseApiOrdemServico = `${baseApiUrl}/api/OrdemServico`;

const DetalharOs = () => {

    const { idOrdemServico } = useParams();

    const listOptions = [
        { value: "laudo", label: "Laudo" },
        { value: "art", label: "N° ART" },
        { value: "acessorio", label: "Acessórios" },
        { value: "tagInguanti", label: "Tag Inguanti" },
        { value: "tagCliente", label: "Tag" },
        { value: "fabricante", label: "Fabricante" },
        { value: "modelo", label: "Modelo" },
        { value: "capacidade", label: "Capacidade" },
        { value: "dimensoes", label: "Dimensões" },
        { value: "empresa", label: "Empresa" },
        { value: "cnpj", label: "CNPJ" },
        { value: "contato", label: "Contato" },
        { value: "setor", label: "Setor" },
        { value: "telefone", label: "Tel." },
        { value: "dataMetodologiaInspecao", label: "Data Met. Insp." },
        { value: "certificadoFabricante", label: "Certificado Fabricante" },
        { value: "registroInspecao", label: "Registro Inspeção" },
        { value: "registroReparo", label: "Registro Reparo" },
        { value: "nomeInspetor", label: "Inspetor" },
        { value: "numeroDocumentoInspetor", label: "CPF Inspetor" },
        { value: "nomeResponsavelTecnico", label: "Respons. Técnico" },
        { value: "crea", label: "CREA" },



        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
    ];

    const [loading, setLoading] = useState(true);
    const [loadingPDF, setLoadingPDF] = useState(false);

    const itemsPerPage = 50; // Número de itens por página

    const [responseData, setResponseData] = useState('');

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [showModalCopyLaudos, setShowModalCopyLaudos] = useState(false);

    const [listLaudoData, setListLaudoData] = useState([]);
    const [constListData, setConstListLaudoData] = useState([]);

    const [os, setOs] = useState(null);

    const [selectedOption, setSelectedOption] = useState("laudo");

    const [modalMessage, setModalMessage] = useState('');

    const [newOsEnviar, setNewOsEnviar] = useState('');
    const [newOsReceber, setNewOsReceber] = useState('');

    const navigate = useNavigate();

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitialData();
        }
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        fetchInitialDataOrdemServico();
        fetchInitialLaudoOrdeServico();
        handleLoadingFalse();
    }
    const fetchInitialDataOrdemServico = async () => {
        try {
            const url = `${baseApiOrdemServico}/get-ordemservico/${idOrdemServico}`;

            const response = await fetchWithAuth(url);
            const data = await response.json();

            setOs(data.os);
        } catch (error) {
            handleLoadingFalse();
            console.error("Erro ao buscar dados:", error);
        }
    };

    const fetchInitialLaudoOrdeServico = async () => {
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-laudo-por-ordem-servico/${idOrdemServico}`);
            const formattedData = formatarDataComHora(response.data);
            setListLaudoData(formattedData);
            setConstListLaudoData(formattedData);

        } catch (error) {
            handleLoadingFalse();
            console.log('Erro ao buscar dados:', error);
        }
    };

    const handleEditLaudo = async (item) => {
        navigate(`/laudos/edit/${item.idLaudo}`);
    }

    const handleDeleteLaudo = async (item) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${baseApi}/delete-laudo/${item.idLaudo}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData();
                        });

                    } else {
                        Swal.fire('Erro', `${response?.data?.message}`, 'error');
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    Swal.fire('Erro ao deletar o registro', `${error?.response?.data?.errors[0]}`, 'error');
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleOSEnviarChange = (event) => {
        setNewOsEnviar(event.target.value.toUpperCase());
    };
    const handleOSReceberChange = (event) => {
        setNewOsReceber(event.target.value.toUpperCase());
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDownloadPdf = async (filename) => {
        try {
            let cleanedFilePdf = filename.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");

            const response = await axios.get(`${baseApiGetPdf}/${cleanedFilePdf}.pdf`, {
                responseType: 'blob', // Mudança para 'blob' aqui
            });

            if (response?.data) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const urlPdf = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = urlPdf;
                link.download = `${cleanedFilePdf}.pdf`; // Força o download com o nome do arquivo
                link.target = '_blank'; // Tenta abrir em uma nova aba
                link.click();

                // Libera a URL do objeto Blob após o download.
                URL.revokeObjectURL(urlPdf);
            }
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };
    const handleLoadingFalse = () => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const handleCopiarLaudos = async (newOsEnviar, newOsReceber) => {
        if (!newOsEnviar?.trim() || !newOsReceber?.trim()) {
            setModalMessage('Erro ao copiar: É obrigatório preecher todos os campos com *');
            return;
        }
        setLoadingPDF(true);
        try {
            const newItem = {
                OsEnviar: newOsEnviar,
                OsReceber: newOsReceber,
                IdOrdemServico: idOrdemServico
            };

            const response = await axios.post(`${baseApiOrdemServico}/copy-ordemservico`, newItem);

            if (response?.status === 200) {
                const totalLaudos = response.data.length;
                let totalLaudosEnviados = 0;

                for (let index = 0; index < response.data.length; index++) {
                    const item = response.data[index];

                    const responseSave = await handleSave(
                        item.idOrdemServicoOsReceber,
                        item.caminhoEvidenciaFotograficaUm,
                        item.caminhoEvidenciaFotograficaDois,
                        item.caminhoEvidenciaFotograficaTres,
                        item.caminhoEvidenciaFotograficaQuatro,
                        item.acessorio,
                        item.tagInguanti,
                        item.tagCliente,
                        item.fabricante,
                        item.modelo,
                        item.capacidade,
                        item.dimensoes,
                        item.demaisInformacoesAcessorio,
                        item.contato,
                        item.setor,
                        item.telefone,
                        item.subSetor,
                        item.metodologiaInspecao,
                        item.dataMetodologiaInspecao,
                        item.certificadoFabricante,
                        item.registroInspecao,
                        item.registroReparo,
                        item.conclusaoTecnica,
                        item.recomendacoes,
                        item.razaoSocialInguanti,
                    );

                    if (responseSave.status == 200) {
                        totalLaudosEnviados = index + 1;
                        setResponseData(responseSave.data);
                        setModalMessage(`Laudos copiados: ${totalLaudosEnviados} de ${totalLaudos}`);
                    }
                    if (totalLaudosEnviados == totalLaudos) {
                        setModalMessage(`Todos os laudos foram copiados com sucesso. Laudos copiados: ${totalLaudosEnviados} de ${totalLaudos}`);
                    }
                }

            } else {
                handleLoadingFalse();
                console.error('Erro ao adicionar o registro:', response?.data?.message);
                setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
            }
        } catch (error) {
            console.error('Erro ao adicionar o registro:', error?.response?.data?.errors[0]);
            setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
        }
        //handleLoadingFalse();
    };

    const handleSave = async (
        IdOrdemServicoCopy,
        fileName1,
        fileName2,
        fileName3,
        fileName4,
        newAcessorio,
        newTagInguanti,
        newTagCliente,
        newFabricante,
        newModelo,
        newCapacidade,
        newDimensoes,
        newDemaisInformacoesAcessorio,
        newContato,
        newSetor,
        newTelefone,
        newSubsetor,
        newMetodologiaInspecao,
        newDataMetodologiaInspecao,
        newCertificadoFabricante,
        newRegistroInspecao,
        newRegistroReparo,
        newConclusaoTecnica,
        newRecomendacoes,
        razaoSocialInguanti
    ) => {

        newDemaisInformacoesAcessorio = 'N.A.';

        if (fileName1 === "" || fileName1 == null) {
            fileName1 = "SEMIMAGEM01.JPG";
        }
        if (fileName2 === "" || fileName1 == null) {
            fileName2 = "SEMIMAGEM02.JPG";
        }
        if (fileName3 === "" || fileName3 == null) {
            fileName3 = "SEMIMAGEM03.JPG";
        }
        if (fileName4 === "" || fileName4 == null) {
            fileName4 = "SEMIMAGEM04.JPG";
        }

        try {

            const newItem = {
                CaminhoEvidenciaFotograficaUm: fileName1,
                CaminhoEvidenciaFotograficaDois: fileName2,
                CaminhoEvidenciaFotograficaTres: fileName3,
                CaminhoEvidenciaFotograficaQuatro: fileName4,
                IdOrdemServico: IdOrdemServicoCopy,
                Acessorio: newAcessorio,
                TagInguanti: newTagInguanti,
                TagCliente: newTagCliente,
                Fabricante: newFabricante,
                Modelo: newModelo,
                Capacidade: newCapacidade,
                Dimensoes: newDimensoes,
                DemaisInformacoesAcessorio: newDemaisInformacoesAcessorio,
                Contato: newContato,
                Telefone: newTelefone,
                Setor: newSetor,
                SubSetor: newSubsetor,
                MetodologiaInspecao: newMetodologiaInspecao,
                DataMetodologiaInspecao: newDataMetodologiaInspecao,
                CertificadoFabricante: newCertificadoFabricante,
                RegistroInspecao: newRegistroInspecao,
                RegistroReparo: newRegistroReparo,
                ConclusaoTecnica: newConclusaoTecnica,
                Recomendacoes: newRecomendacoes,
                RazaoSocialInguanti: razaoSocialInguanti,
            };

            const response = await axios.post(`${baseApi}/add-laudo`, newItem);

            if (response?.status === 200) {

                if (!response?.data?.response?.mensagem.toLowerCase().includes("erro") &&
                    !response?.data?.response?.mensagem.toLowerCase().includes("existe")) {

                    setLoadingPDF(true);
                    return response;

                } else {
                    Swal.fire(
                        "Erro",
                        response?.data?.response?.mensagem,
                        "error"
                    );
                }

            } else {
                Swal.fire(
                    "Erro",
                    "Erro ao adicionar o laudo. Por favor, tente novamente.",
                    "error"
                );
                setTimeout(() => {
                    setLoading(false);
                    setLoadingPDF(false);
                }, 1);
            }

        } catch (error) {
            setLoading(false);
            setLoadingPDF(false);
            console.error(
                "Erro: ",
                error
            );

            Swal.fire({
                title: 'Laudos',
                text: 'Não foi possível adicionar o registro',
                icon: 'error',
            })
            console.log(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
        }

        return null;
    };

    const handlePdfUploadComplete = (success, pdfLink, cleanedLaudo) => {

        setLoadingPDF(false);

        if (success) {
            console.log(`Laudo atualizado com sucesso`);
        }
    };

    const copyLaudos = () => {
        setShowModalCopyLaudos(true);
    };

    const closeModal = () => {
        setModalMessage('');
        clearAll();
        setIsUpdate(false);
        setShowModal(false);
        setShowModalCopyLaudos(false);
    };

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    function clearAll() {

    }


    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0 && loading === false) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>Ordem de Serviço - N° {os} </h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>Ordem de Serviço - N° {os} </h4></Card.Header>
                            <Card.Body>
                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    fetchInitialData={fetchInitialData}
                                    copyLaudos={copyLaudos}
                                />
                                <TableLaudo
                                    data={paginatedData}
                                    handleDownloadPdf={handleDownloadPdf}
                                    handleEditLaudo={handleEditLaudo}
                                    handleDeleteLaudo={handleDeleteLaudo}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />

                                {loadingPDF && <Loading />}
                                {responseData && <ExportToPDF response={responseData} onPdfUploadComplete={handlePdfUploadComplete} />}

                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>

            <CustomModal
                show={showModalCopyLaudos}
                onHide={closeModal}
                title={'O.S. - Copiar Laudos'}
                onSave={() => handleCopiarLaudos(os, newOsReceber)}
                size="lg"
                saveButtonText={'Copiar'}
            >
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="osEnviar">
                        <Form.Label className="bold-label">*N° O.S - Enviar:</Form.Label>
                        <Form.Control
                            type="text"
                            value={os}
                            onChange={handleOSEnviarChange}
                            readOnly
                            style={{ backgroundColor: 'lightgray' }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="osReceber">
                        <Form.Label className="bold-label">*N° O.S - Receber:</Form.Label>
                        <Form.Control
                            type="text"
                            autoFocus
                            placeholder="Digite a O.S. que vai RECEBER os laudos"
                            value={newOsReceber}
                            onChange={handleOSReceberChange}
                        />
                    </Form.Group>
                </Row>
                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>


        </Container>
    )
};

export default DetalharOs;
