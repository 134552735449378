import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";


const IdentificacaoProprietario = ({
    textIdentificacaoProprietario,
    textEmpresa,
    empresa,
    textCnpj,
    cnpj,
    textContato,
    contato,
    textSetor,
    setor,
    textTelefone,
    telefone,
    textSubSetor,
    subSetor,
}) => (
    <>
        {/* Indentificação do Proprietário */}
        <View style={[styles.rectangleTitle, { marginTop: 8 }]}>
            <Text style={styles.numberText}>2.</Text>
            <View style={styles.verticalLine}></View>
            <Text style={{ paddingLeft: 176, fontSize: 9, fontFamily: "Arial", fontWeight: "bold" }}>
                {textIdentificacaoProprietario}
            </Text>
        </View>


        {/* Empresa e CNPJ */}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '73%' }]}>
                {textEmpresa}
                {empresa}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '27%' }]}>
                {textCnpj}
                {cnpj}
            </Text>
        </View>

        {/* Contato, setor e Telefone */}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '40.7%' }]}>
                {textContato}
                {contato}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '31.8%' }]}>
                {textSetor}
                {setor}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '26.5%' }]}>
                {textTelefone}
                {telefone}
            </Text>
        </View>

        {/* Sub Setor */}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '100%' }]}>
                {textSubSetor}
                {subSetor}
            </Text>
        </View>

    </>

);

export default IdentificacaoProprietario;
