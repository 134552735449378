import React, { useState, useEffect, useRef } from "react";
import axios from "../Config/axiosConfig";
import {
  BlobProvider,
  Page,
  Text,
  View,
  Document,
  Image,
  Font,
} from "@react-pdf/renderer";
import { styles } from "./ExportToPDFStyles";
import { formatarDataIsoString } from './../Shared/Utils/helper';
import CabecalhoLaudo from './ExportPDFComponent/CabecalhoLaudo';
import ConclusaoTecnica from './ExportPDFComponent/ConclusaoTecnica';
import DadosInspecaoExecutada from './ExportPDFComponent/DadosInspecaoExecutada';
import DocumentacaoAcessorio from './ExportPDFComponent/DocumentacaoAcessorio';
import EvidenciaFotograficas from './ExportPDFComponent/EvidenciaFotograficas';
import IdentificacaoAcessorio from './ExportPDFComponent/IdentificacaoAcessorio';
import IdentificacaoProprietario from './ExportPDFComponent/IdentificacaoProprietario';
import NossosContatos from './ExportPDFComponent/NossosContatos';
import Notas from './ExportPDFComponent/Notas';
import ResponsaveisLaudo from './ExportPDFComponent/ResponsaveisLaudo';


const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApiFiles = `${baseApiUrl}/api/Files`;
const baseApiPdf = `${baseApiUrl}/api/Files/add-pdf`;



// const splitTextForPages = (text, maxCharsPerPage) => {
//   const result = [];
//   for (let i = 0; i < text.length; i += maxCharsPerPage) {
//     const piece = text.substring(i, i + maxCharsPerPage);
//     result.push(piece);
//   }
//   return result;
// };


Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/fonts/arial-bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const ExportToPDFTeste = () => {

  const [response, setResponse] = useState({
    caminhoEvidenciaFotograficaUm: '17072023_14160603PM-WHAT.JPEG',
    caminhoEvidenciaFotograficaDois: '17072023_14160362PM-WHAT.JPEG',
    caminhoEvidenciaFotograficaTres: '17072023_14135053PM-WHAT.JPEG',
    caminhoEvidenciaFotograficaQuatro: 'SEMIMAGEM04.JPG',
    art: '28027230230970240',
    laudo: 'I.1041.23.01-0066',
    tipo: 'MANILHA',
    fabricante: 'HOLLAND CUM VELIT MORBI X',
    capacidade: '13,5 TON CUM VELIT MORBI L',
    demaisInformacoes: 'N/A',
    tagIng: 'ING 35484',
    modelo: 'CURVA CUM VELIT MORBI LEO',
    dimensoes: '1 3/8',
    // 1000 caracteres é o máximo
    //dimensoes: 'GERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMM GERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSPX ',
    //459 caracteres
    //dimensoes: 'GERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMM GERADOR LKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP RE LOREM IPSIUMM IPSIUMMGERADOR TESTE 12 DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMMGERADOR DE CARACTERS KJJLKDLKL LLL JLIOKLSP ÇPRE LOREM IPSIUMM IPSIUMMGERADOR DE CARACTERS',

    //dimensoes: 'ETIAM POSUERE QUAM AC QUAM. MAECENAS ALIQUET ALIQUET ALIQUET INTEGER IN SAPIEN UNDE ACCUMSAN LEO. NULLAM DAPIBUS FERMENTUM IPSUM. ETIAM QUIS QUAM. INTEGER LACINIA. NULLA SDVF EST. NULLA TURPIS MAGNA, CURSUS SIT AMET, SUSCIPIT A, INTERDUM ID, FELIS. INTEGER VULPUTATE SEM A NIBH RU INTEGER  INTEGER  INTE INTEGERINTEGER VULPUTATE ETIAM POSUERE QUAM AC QUAM. MAECENAS ALIQUET ACCUMSAN LEO. NULLAM DAPIBUS FERMENTUM IPSUM. ETIAM QUIS QUAM. INTEGER LACINIA. NULLA SDVF EST. NULLA TURPIS MAGNA, CURSUS SIT AMET, SUSCIPIT A, INTERDUM ID, FELIS. INTEGER VULPUTATE SEM A NIBH RU INTEGER  INTEGER  INTE INTEGERINTEGER VULPUTATE ETIAM POSUERE QUAM AC QUAM. MAECENAS ALIQUET ACCUMSAN LEO. NULLAM DAPIBUS FERMENTUM IPSUM. ETIAM QUIS QUAM. INTEGER LACINIA. NULLA SDVF EST. NULLA TURPIS MAGNA, CURSUS SIT AMET, SUSCIPIT A, INTERDUM ID, FELIS. INTEGER VULPUTATE SEM A NIBH RU INTEGER  INTEGER  INTE INTEGERINTEGER VULPUTATE INTEGERINTEGER VULPUTATE INTEGERINTEGER VULPUTATE INTEGERINTEGER VULPUTATE INTEGERINTEGER VULPUTATE INTEGERINTEGER VULPUTATE',


    tagCliente: 'N/A',
    empresa: 'MAYEKAWA 2023',
    contato: 'DANILO',
    cnpj: '03.666.421/0001-44',
    setor: 'N/A',
    tel: '(11) 4654-8084',
    subSetor: 'N/A',
    metodologiaInspecao: 'VISUAL SENSITIVA / DIMENSIONAL',
    dataMetodologiaInspecao: '2023-07-04T00:00:00',
    certificadoFabricante: 'N/A ',
    registroInspecao: 'N/A ',
    registroReparo: 'N/A ',
    conclusaoTecnica: 'Aprovado 12 meses',
    recomendacoes: 'N/A',
    nomeInspetor: 'TIAGO BARUEL',
    responsavelLaudo: 'PEDRO DA SILVA',
    numeroDocumentoInspetor: '38325325879',
    cpfResponsavelLaudo: '15630975080',
    caminhoAssinaturaInspetor: '24082023_203052021_Ass - teste 2.JPG',
    nomeResponsavelTecnico: 'ENGº ADRIANO INGUANTI',
    caminhoAssinaturaResponsavelTecnico: '24082023_202138489_Assin Escaneada Adri.jpg',
    caminhoAssinaturaResponsavelLaudo: '12042024_210543664_assinatura_manuel_alegre.svg.png',
    crea: '5062224256',
  });

  const [image1Data, setImage1Data] = useState("");
  const [image2Data, setImage2Data] = useState("");
  const [image3Data, setImage3Data] = useState("");
  const [image4Data, setImage4Data] = useState("");
  const [imageLogoTipoData, setImageLogoTipoData] = useState("");

  const [assinaturaInspetorImage, setAssinaturaInspetorImage] = useState("");
  const [assinaturaResponsavelImage, setAssinaturaResponsavelImage] = useState("");
  const [assinaturaResponsavelLaudoImage, setAssinaturaResponsavelLaudoImage] = useState("");


  const [textLaudoCertificadoInspecao, setTextLaudoCertificadoInspecao] = useState("LAUDO/CERTIFICADO DE INSPEÇÃO");

  const [textArt, setTextArt] = useState("ART - N° ");
  const [art, setArt] = useState(response.art);

  const [razaoSocialInguanti, setRazaoSocialInguanti] = useState("INGUANTI EQUIPAMENTOS LTDA - CNPJ 41.814.557/0001-43");

  const [textLaudo, setTextLaudo] = useState("LAUDO - ");
  const [laudo, setLaudo] = useState(response.laudo);

  const [textIdentificacaoAcessorio, setTextIdentificacaoAcessorio] = useState("IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO");

  const [textTipo, setTextTipo] = useState("Tipo: ");
  const [tipo, setTipo] = useState(response.tipo);

  const [textFabricante, setTextFabricante] = useState("Fabric.: ");
  const [fabricante, setFabricante] = useState(response.fabricante);

  const [textCapacidade, setTextCapacidade] = useState("Capac.: ");
  const [capacidade, setCapacidade] = useState(response.capacidade);

  const [textDemaisinformacoes, setTextDemaisinformacoes] = useState("Demais informações: ");
  const [demaisInformacoes, setDemaisinformacoes] = useState(response.demaisInformacoes);

  const [textTagIng, setTextTagIng] = useState("TAG ING: ");
  const [tagIng, setTaging] = useState(response.tagIng);

  const [textTagCliente, setTextTagCliente] = useState("TAG CLIENTE: ");
  const [tagCliente, setTagCliente] = useState(response.tagCliente);

  const [textModelo, setTextModelo] = useState("Mod.: ");
  const [modelo, setModelo] = useState(response.modelo);

  const [textDimensoes, setTextDimensoes] = useState("Dimensões: ");
  const [dimensoes, setDimensoes] = useState(response.dimensoes);


  const [textIdentificacaoProprietario, setTextIdentificacaoProprietario] = useState("IDENTIFICAÇÃO DO PROPRIETÁRIO");

  const [textEmpresa, setTextEmpresa] = useState("Empresa: ");
  const [empresa, setEmpresa] = useState(response.empresa);

  const [textContato, setTextContato] = useState("Contato: ");
  const [contato, setContato] = useState(response.contato);

  const [textSetor, setTextSetor] = useState("Setor: ");
  const [setor, setSetor] = useState(response.setor);

  const [textSubSetor, setTextSubSetor] = useState("Sub setor: ");
  const [subSetor, setSubSetor] = useState(response.subSetor);

  const [textCnpj, setTextCnpj] = useState("CNPJ: ");
  const [cnpj, setCnpj] = useState(response.cnpj);

  const [textTelefone, setTextTelefone] = useState("Tel: ");
  const [telefone, setTel] = useState(response.telefone);

  const [textDadosDaInspecaoExecutada, setTextDadosDaInspecaoExecutada] = useState("DADOS DA INSPEÇÃO EXECUTADA");

  const [textMetodologiaInspecao, setTextMetodologiaInspecao] = useState("Metodologia: ");
  const [metodologiaInspecao, setMetodologiaInspecao] = useState(response.metodologiaInspecao);

  const [textDataMetodologiaInspecao, setTextDataMetodologiaInspecao] = useState("Data: ");
  const [dataMetodologiaInspecao, setDataMetodologiaInspecao] = useState(response.dataMetodologiaInspecao);

  const [textDocumentacaoAcessorio, setTextDocumentacaoAcessorio] = useState("DOCUMENTAÇÃO DOS ACESSÓRIOS/DISPOSITIVO");

  const [textCertificadoFabricante, setTextCertificadoFabricante] = useState("Certificado do fabricante: ");
  const [certificadoFabricante, setCertificadoFabricante] = useState(response.certificadoFabricante);

  const [textRegistroInspecao, setTextRegistroInspecao] = useState("Registro de inspeção: ");
  const [registroInspecao, setRegistroInspecao] = useState(response.registroInspecao);

  const [textRegistroReparo, setTextRegistroReparo] = useState("Registro de reparo: ");
  const [registroReparo, setRegistroReparo] = useState(response.registroReparo);

  const [textEvidenciaFotografica, setTextEvidenciaFotografica] = useState("EVIDÊNCIA FOTOGRÁFICA");
  const [textCabecalhoConclusaoTecnica, setTextCabecalhoConclusaoTecnica] = useState("CONCLUSÃO TÉCNICA");

  const [textConclusaoTecnica, setTextConclusaoTecnica] = useState("Resultado: ");
  const [conclusaoTecnica, setConclusaoTecnica] = useState(response.conclusaoTecnica);

  const [textRecomendacoes, setTextRecomendacoes] = useState("Observações: ");
  // 1100 caracteres
  //const [recomendacoes, setRecomendacoes] = useState("UNDE CUM VELIT 9999658522  F123LOREM IPSUM DOLOR SAPIENTE SAPIENTE SIT AMET. EA SAPIENTE NULLA AB ULLAM UNDE CUM VELIT ODIT ET APERIAM APERIAM ET FUGIT DELENITI EST LABORUM NESCIUNT AUT ILLUM ILLO. SIT MINUS QUOS AUT LABORIOSAM BEATAE AUT CONSEQUATUR CONSEQUATUR VEL IURE QUOS. SED QUIS EIUS QUI TEMPORA CONSEQUATUR AUT ISTE ELIGENDI. HIC NECESSITATIBUS QUIS 33 EVENIET VOLUPTAS EST VOLUPTATES VITAE EA LABORE LABORIOSAM! SED POSSIMUS NOSTRUM TEMPOR  IPSUM DOLOR SAPIENTE SAPIENTE SIT AMET. EA SAPIENTE NULLA AB ULLAM UNDE CUM VELIT ODIT ET APERIAM APERIAM ET FUGIT DELENITI EST LABORUM NESCIUNT AUT ILLUM ILLO. SIT MINUS QUOS AUT LABORIOSAM BEATAE AUT CONSEQUATUR CONSEQUATUR VEL IURE QUOS. SED QUIS EIUS QUI TEMPORA CONSEQUATUR AUT ISTE ELIGENDI. HIC NECESSITATIBUS QUIS 33 EVENIET VOLUPTAS EST VOLUPTATES VITAE ! OLOR SAPIENTE SAPIENTE SIT AMET. EA SAPIENTE NULLA AB OLOR SAPIENTE SAPIENTE SIT AMET. EA SAPIENTE NULLA AB ENDI. HIC NECESSITATIBUS QUIS 33 EVENIET VOLUPTAS EST VOLUPTATES VITAE TESES IUS QUI TEMPORA CONSEQUATUR AUT ISTE ELIGENDI. HIC NECESSITATIBUS QUIS 33 EVENIET VOLUPTAS EST VOKK");

  // 501 Caracteres
  //const [recomendacoes, setRecomendacoes] = useState("UNDE CUM VELIT 9999658522  F123LOREM IPSUM DOLOR SAPIENTE SAPIENTE SIT AMET. EA SAPIENTE NULLA AB ULLAM UNDE CUM VELIT ODIT ET APERIAM APERIAM ET FUGIT DELENITI EST LABORUM NESCIUNT AUT ILLUM ILLO. SIT MINUS QUOS AUT LABORIOSAM BEATAE AUT CONSEQUATUR CONSEQUATUR VEL IURE QUOS. SED QUIS EIUS QUI TEMPORA CONSEQUATUR AUT ISTE ELIGENDI. HIC NECESUNDE CUM VELIT 9999658522  F123LOREM IPSUM DOLOR SAPIENTE SAPIENTE SIT AMET. EA SAPIENTE NULLA AB PORA CONSEQUATUR AUT ISTE ELI PORA CONSEQUATUR AUT ISTE ELI");
  const [recomendacoes, setRecomendacoes] = useState("DISPOSITIVO NÃO CONSTA FABRICANTE, RECOMENDA-SE QUE TODOS OS DISPOSITIVOS DE IÇAMENTO CONTENHA FABRICANTE E CAPACIDADE CONFORME A NR");

  const [textCabecalhoInspetor, setTextCabecalhoInspetor] = useState("RESP. PELA INSPEÇÃO");
  const [textCabecalhoResponsavelTecnico, setTextCabecalhoResponsavelTecnico] = useState("RESPONSÁVEL TÉCNICO");
  const [textCabecalhoResponsavelLaudo, setTextCabecalhoResponsavelLaudo] = useState("RESP. PELO LAUDO");

  const [textNomeInspetor, setTextNomeInspetor] = useState("Nome: ");
  const [nomeInspetor, setnomeInspetor] = useState(response.nomeInspetor);

  const [textNomeResponsavelLaudo, setTextNomeResponsavelLaudo] = useState("Nome: ");
  const [nomeResponsavelLaudo, setNomeResponsavelLaudo] = useState(response.responsavelLaudo)

  const [textNumeroDocumento, setTextNumeroDocumentoInspetor] = useState("Doc.: ");
  const [numeroDocumentoInspetor, setNumeroDocumentoInspetor] = useState(response.numeroDocumentoInspetor);
  const [numeroDocumentoRespLaudo, setNumeroDocumentoRespLaudo] = useState(response.cpfResponsavelLaudo);

  const [textNomeResponsavelTecnico, setTextNomeResponsavelTecnico] = useState("Nome: ");
  const [nomeResponsavelTecnico, setNomeResponsavelTecnico] = useState(response.nomeResponsavelTecnico);

  const [textCrea, setTextCrea] = useState("N° Crea ");
  const [crea, setCrea] = useState(response.crea);

  const [textNotas, setTextNotas] = useState("Notas:");
  const [textNotaUm, setTextNotaUm] = useState("1. Somente pessoal qualificado e competente devem inspecionar os acessórios");
  const [textNotaDois, setTextNotaDois] = useState("2.Ao iniciar certifique se está utilizando os Equipamentos de Proteção Individual (EPI'S)");
  const [textReferencias, setTextReferencias] = useState("Referências: NR's / NBR's / ASME's");

  const [textNossosContatos, setTextNossosContatos] = useState("Nossos contatos:");
  const [textFoneContatos, setTextFoneContatos] = useState("Fone: (11) 4654-4011 ou (11) 98283-2778");
  const [textEmailContatos, setTextEmailContatos] = useState("Email: contato@inguanti.com.br");
  const [textSaibaMaisContatos, setTextSaibaMaisContatos] = useState("Saiba mais em www.inguanti.com.br");

  // No react 18 é necessário para não chamar 2 vezes a API
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      setImageLogoTipoData(
        `${process.env.PUBLIC_URL}/img/jpeg/Inguanti_Inspecoes_new.jpg`
      );

      if (response.caminhoEvidenciaFotograficaUm == "SEMIMAGEM01.JPG") {
        setImage1Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaUm}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage1Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage1Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (response.caminhoEvidenciaFotograficaDois == "SEMIMAGEM02.JPG") {
        setImage2Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaDois}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage2Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage2Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (response.caminhoEvidenciaFotograficaTres == "SEMIMAGEM03.JPG") {
        setImage3Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaTres}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage3Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage3Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (response.caminhoEvidenciaFotograficaQuatro == "SEMIMAGEM04.JPG") {
        setImage4Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaQuatro}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage4Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage4Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      axios
        .get(
          `${baseApiFiles}/download-assinatura/${response.caminhoAssinaturaInspetor}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response?.data?.byteLength > 0) {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
            setAssinaturaInspetorImage(`data:${contentType};base64,${base64}`);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${baseApiFiles}/download-assinatura/${response.caminhoAssinaturaResponsavelTecnico}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response?.data?.byteLength > 0) {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
            setAssinaturaResponsavelImage(`data:${contentType};base64,${base64}`);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${baseApiFiles}/download-assinatura/${response.caminhoAssinaturaResponsavelLaudo}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response?.data?.byteLength > 0) {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
            setAssinaturaResponsavelLaudoImage(`data:${contentType};base64,${base64}`);
          }
        })
        .catch((error) => {
          console.error(error);
        });

    }

  }, []);

  const DocumentPdf = () => {

    const renderOnePage = () => (
      <Page size="A4" style={styles.page}>
        <View style={{ border: 2.5, height: 800 }}>
          {/* ************************************************** Cabeçalho *************************************************** */}
          <CabecalhoLaudo
            imageLogoTipoData={imageLogoTipoData}
            textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
            textArt={textArt}
            art={art}
            textLaudo={textLaudo}
            laudo={laudo}
            razaoSocialInguanti={razaoSocialInguanti}
          />
          {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
          <IdentificacaoAcessorio
            textIdentificacaoAcessorio={textIdentificacaoAcessorio}
            textTipo={textTipo}
            tipo={tipo}
            textTagIng={textTagIng}
            tagIng={tagIng}
            textTagCliente={textTagCliente}
            tagCliente={tagCliente}
            textFabricante={textFabricante}
            fabricante={fabricante}
            textModelo={textModelo}
            modelo={modelo}
            textCapacidade={textCapacidade}
            capacidade={capacidade}
            textDimensoes={textDimensoes}
            dimensoes={dimensoes}
          />
          {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

          {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
          <IdentificacaoProprietario
            textIdentificacaoProprietario={textIdentificacaoProprietario}
            textEmpresa={textEmpresa}
            empresa={empresa}
            textCnpj={textCnpj}
            cnpj={cnpj}
            textContato={textContato}
            contato={contato}
            textSetor={textSetor}
            setor={setor}
            textTelefone={textTelefone}
            telefone={telefone}
            textSubSetor={textSubSetor}
            subSetor={subSetor}
          />
          {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

          {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

          <DadosInspecaoExecutada
            textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
            textMetodologiaInspecao={textMetodologiaInspecao}
            metodologiaInspecao={metodologiaInspecao}
            textDataMetodologiaInspecao={textDataMetodologiaInspecao}
            dataMetodologiaInspecao={dataMetodologiaInspecao}
          />

          {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


          {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

          <DocumentacaoAcessorio
            textDocumentacaoAcessorio={textDocumentacaoAcessorio}
            textCertificadoFabricante={textCertificadoFabricante}
            certificadoFabricante={certificadoFabricante}
            textRegistroInspecao={textRegistroInspecao}
            registroInspecao={registroInspecao}
            textRegistroReparo={textRegistroReparo}
            registroReparo={registroReparo}

          />

          {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

          {/* ************************************************** 5 *************************************************** */}

          <EvidenciaFotograficas
            textEvidenciaFotografica={textEvidenciaFotografica}
            image1Data={image1Data}
            image2Data={image2Data}
            image3Data={image3Data}
            image4Data={image4Data}
          />
          {/* ************************************************ FIM 5 *************************************************** */}

          {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

          <ConclusaoTecnica
            textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
            textConclusaoTecnica={textConclusaoTecnica}
            conclusaoTecnica={conclusaoTecnica}
            textRecomendacoes={textRecomendacoes}
            recomendacoes={recomendacoes}
          />

          {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

          {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

          <ResponsaveisLaudo
            textCabecalhoInspetor={textCabecalhoInspetor}
            textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
            textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
            textNomeInspetor={textNomeInspetor}
            textNomeResponsavelLaudo={textNomeResponsavelLaudo}
            nomeResponsavelLaudo={nomeResponsavelLaudo}
            nomeInspetor={nomeInspetor}
            textNomeResponsavelTecnico={textNomeResponsavelTecnico}
            nomeResponsavelTecnico={nomeResponsavelTecnico}
            textNumeroDocumento={textNumeroDocumento}
            numeroDocumentoInspetor={numeroDocumentoInspetor}
            numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
            textCrea={textCrea}
            crea={crea}
            assinaturaInspetorImage={assinaturaInspetorImage}
            assinaturaResponsavelImage={assinaturaResponsavelImage}
            assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
          />

          {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


          {/* ******************************************  Notas ******************************************* */}
          <Notas
            textNotas={textNotas}
            textNotaUm={textNotaUm}
            textNotaDois={textNotaDois}
            textReferencias={textReferencias}
          />

          {/* ****************************************** FIM Notas ******************************************* */}

          {/* ************************************************ Nossos Contatos *************************************************** */}

          <NossosContatos
            textNossosContatos={textNossosContatos}
            textFoneContatos={textFoneContatos}
            textEmailContatos={textEmailContatos}
            textSaibaMaisContatos={textSaibaMaisContatos}
          />

          {/* ************************************************ FIM Nossos Contatos *************************************************** */}
        </View>
        <Text style={styles.watermark1}>INGUANTI</Text>
        <Text style={styles.watermark2}>INGUANTI</Text>
        <Text style={styles.textPagina1de1}>1 de 1</Text>
      </Page>
    );
    const renderTwoPagesNossosContatos = () => (
      <>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************** Cabeçalho *************************************************** */}
            <CabecalhoLaudo
              imageLogoTipoData={imageLogoTipoData}
              textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
              textArt={textArt}
              art={art}
              textLaudo={textLaudo}
              laudo={laudo}
              razaoSocialInguanti={razaoSocialInguanti}
            />
            {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
            <IdentificacaoAcessorio
              textIdentificacaoAcessorio={textIdentificacaoAcessorio}
              textTipo={textTipo}
              tipo={tipo}
              textTagIng={textTagIng}
              tagIng={tagIng}
              textTagCliente={textTagCliente}
              tagCliente={tagCliente}
              textFabricante={textFabricante}
              fabricante={fabricante}
              textModelo={textModelo}
              modelo={modelo}
              textCapacidade={textCapacidade}
              capacidade={capacidade}
              textDimensoes={textDimensoes}
              dimensoes={dimensoes}
            />
            {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

            {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
            <IdentificacaoProprietario
              textIdentificacaoProprietario={textIdentificacaoProprietario}
              textEmpresa={textEmpresa}
              empresa={empresa}
              textCnpj={textCnpj}
              cnpj={cnpj}
              textContato={textContato}
              contato={contato}
              textSetor={textSetor}
              setor={setor}
              textTelefone={textTelefone}
              telefone={telefone}
              textSubSetor={textSubSetor}
              subSetor={subSetor}
            />
            {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

            {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

            <DadosInspecaoExecutada
              textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
              textMetodologiaInspecao={textMetodologiaInspecao}
              metodologiaInspecao={metodologiaInspecao}
              textDataMetodologiaInspecao={textDataMetodologiaInspecao}
              dataMetodologiaInspecao={dataMetodologiaInspecao}
            />

            {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


            {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

            <DocumentacaoAcessorio
              textDocumentacaoAcessorio={textDocumentacaoAcessorio}
              textCertificadoFabricante={textCertificadoFabricante}
              certificadoFabricante={certificadoFabricante}
              textRegistroInspecao={textRegistroInspecao}
              registroInspecao={registroInspecao}
              textRegistroReparo={textRegistroReparo}
              registroReparo={registroReparo}

            />

            {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

            {/* ************************************************** 5 *************************************************** */}

            <EvidenciaFotograficas
              textEvidenciaFotografica={textEvidenciaFotografica}
              image1Data={image1Data}
              image2Data={image2Data}
              image3Data={image3Data}
              image4Data={image4Data}
            />
            {/* ************************************************ FIM 5 *************************************************** */}

            {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

            <ConclusaoTecnica
              textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
              textConclusaoTecnica={textConclusaoTecnica}
              conclusaoTecnica={conclusaoTecnica}
              textRecomendacoes={textRecomendacoes}
              recomendacoes={recomendacoes}
            />

            {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

            {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

            <ResponsaveisLaudo
              textCabecalhoInspetor={textCabecalhoInspetor}
              textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
              textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
              textNomeInspetor={textNomeInspetor}
              textNomeResponsavelLaudo={textNomeResponsavelLaudo}
              nomeResponsavelLaudo={nomeResponsavelLaudo}
              nomeInspetor={nomeInspetor}
              textNomeResponsavelTecnico={textNomeResponsavelTecnico}
              nomeResponsavelTecnico={nomeResponsavelTecnico}
              textNumeroDocumento={textNumeroDocumento}
              numeroDocumentoInspetor={numeroDocumentoInspetor}
              numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
              textCrea={textCrea}
              crea={crea}
              assinaturaInspetorImage={assinaturaInspetorImage}
              assinaturaResponsavelImage={assinaturaResponsavelImage}
              assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
            />

            {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


            {/* ******************************************  Notas ******************************************* */}
            <Notas
              textNotas={textNotas}
              textNotaUm={textNotaUm}
              textNotaDois={textNotaDois}
              textReferencias={textReferencias}
            />

            {/* ****************************************** FIM Notas ******************************************* */}

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina1de2}>1 de 2</Text>

          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************ Nossos Contatos *************************************************** */}

            <NossosContatos
              textNossosContatos={textNossosContatos}
              textFoneContatos={textFoneContatos}
              textEmailContatos={textEmailContatos}
              textSaibaMaisContatos={textSaibaMaisContatos}
            />

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina2de2}>2 de 2</Text>

            {/* ************************************************ FIM Nossos Contatos *************************************************** */}
          </View>
        </Page>
      </>
    );
    const renderTwoPagesNotasEhNossosContatos = () => (
      <>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************** Cabeçalho *************************************************** */}
            <CabecalhoLaudo
              imageLogoTipoData={imageLogoTipoData}
              textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
              textArt={textArt}
              art={art}
              textLaudo={textLaudo}
              laudo={laudo}
              razaoSocialInguanti={razaoSocialInguanti}
            />
            {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
            <IdentificacaoAcessorio
              textIdentificacaoAcessorio={textIdentificacaoAcessorio}
              textTipo={textTipo}
              tipo={tipo}
              textTagIng={textTagIng}
              tagIng={tagIng}
              textTagCliente={textTagCliente}
              tagCliente={tagCliente}
              textFabricante={textFabricante}
              fabricante={fabricante}
              textModelo={textModelo}
              modelo={modelo}
              textCapacidade={textCapacidade}
              capacidade={capacidade}
              textDimensoes={textDimensoes}
              dimensoes={dimensoes}
            />
            {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

            {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
            <IdentificacaoProprietario
              textIdentificacaoProprietario={textIdentificacaoProprietario}
              textEmpresa={textEmpresa}
              empresa={empresa}
              textCnpj={textCnpj}
              cnpj={cnpj}
              textContato={textContato}
              contato={contato}
              textSetor={textSetor}
              setor={setor}
              textTelefone={textTelefone}
              telefone={telefone}
              textSubSetor={textSubSetor}
              subSetor={subSetor}
            />
            {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

            {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

            <DadosInspecaoExecutada
              textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
              textMetodologiaInspecao={textMetodologiaInspecao}
              metodologiaInspecao={metodologiaInspecao}
              textDataMetodologiaInspecao={textDataMetodologiaInspecao}
              dataMetodologiaInspecao={dataMetodologiaInspecao}
            />

            {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


            {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

            <DocumentacaoAcessorio
              textDocumentacaoAcessorio={textDocumentacaoAcessorio}
              textCertificadoFabricante={textCertificadoFabricante}
              certificadoFabricante={certificadoFabricante}
              textRegistroInspecao={textRegistroInspecao}
              registroInspecao={registroInspecao}
              textRegistroReparo={textRegistroReparo}
              registroReparo={registroReparo}

            />

            {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

            {/* ************************************************** 5 *************************************************** */}

            <EvidenciaFotograficas
              textEvidenciaFotografica={textEvidenciaFotografica}
              image1Data={image1Data}
              image2Data={image2Data}
              image3Data={image3Data}
              image4Data={image4Data}
            />
            {/* ************************************************ FIM 5 *************************************************** */}

            {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

            <ConclusaoTecnica
              textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
              textConclusaoTecnica={textConclusaoTecnica}
              conclusaoTecnica={conclusaoTecnica}
              textRecomendacoes={textRecomendacoes}
              recomendacoes={recomendacoes}
            />

            {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

            {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

            <ResponsaveisLaudo
              textCabecalhoInspetor={textCabecalhoInspetor}
              textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
              textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
              textNomeInspetor={textNomeInspetor}
              textNomeResponsavelLaudo={textNomeResponsavelLaudo}
              nomeResponsavelLaudo={nomeResponsavelLaudo}
              nomeInspetor={nomeInspetor}
              textNomeResponsavelTecnico={textNomeResponsavelTecnico}
              nomeResponsavelTecnico={nomeResponsavelTecnico}
              textNumeroDocumento={textNumeroDocumento}
              numeroDocumentoInspetor={numeroDocumentoInspetor}
              numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
              textCrea={textCrea}
              crea={crea}
              assinaturaInspetorImage={assinaturaInspetorImage}
              assinaturaResponsavelImage={assinaturaResponsavelImage}
              assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
            />

            {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina1de2}>1 de 2</Text>

          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ******************************************  Notas ******************************************* */}
            <Notas
              textNotas={textNotas}
              textNotaUm={textNotaUm}
              textNotaDois={textNotaDois}
              textReferencias={textReferencias}
            />

            {/* ****************************************** FIM Notas ******************************************* */}
            {/* ************************************************ Nossos Contatos *************************************************** */}

            <NossosContatos
              textNossosContatos={textNossosContatos}
              textFoneContatos={textFoneContatos}
              textEmailContatos={textEmailContatos}
              textSaibaMaisContatos={textSaibaMaisContatos}
            />

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina2de2}>2 de 2</Text>

            {/* ************************************************ FIM Nossos Contatos *************************************************** */}
          </View>
        </Page>
      </>
    );

    const renderTwoPages7 = () => (
      <>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************** Cabeçalho *************************************************** */}
            <CabecalhoLaudo
              imageLogoTipoData={imageLogoTipoData}
              textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
              textArt={textArt}
              art={art}
              textLaudo={textLaudo}
              laudo={laudo}
              razaoSocialInguanti={razaoSocialInguanti}
            />
            {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
            <IdentificacaoAcessorio
              textIdentificacaoAcessorio={textIdentificacaoAcessorio}
              textTipo={textTipo}
              tipo={tipo}
              textTagIng={textTagIng}
              tagIng={tagIng}
              textTagCliente={textTagCliente}
              tagCliente={tagCliente}
              textFabricante={textFabricante}
              fabricante={fabricante}
              textModelo={textModelo}
              modelo={modelo}
              textCapacidade={textCapacidade}
              capacidade={capacidade}
              textDimensoes={textDimensoes}
              dimensoes={dimensoes}
            />
            {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

            {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
            <IdentificacaoProprietario
              textIdentificacaoProprietario={textIdentificacaoProprietario}
              textEmpresa={textEmpresa}
              empresa={empresa}
              textCnpj={textCnpj}
              cnpj={cnpj}
              textContato={textContato}
              contato={contato}
              textSetor={textSetor}
              setor={setor}
              textTelefone={textTelefone}
              telefone={telefone}
              textSubSetor={textSubSetor}
              subSetor={subSetor}
            />
            {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

            {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

            <DadosInspecaoExecutada
              textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
              textMetodologiaInspecao={textMetodologiaInspecao}
              metodologiaInspecao={metodologiaInspecao}
              textDataMetodologiaInspecao={textDataMetodologiaInspecao}
              dataMetodologiaInspecao={dataMetodologiaInspecao}
            />

            {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


            {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

            <DocumentacaoAcessorio
              textDocumentacaoAcessorio={textDocumentacaoAcessorio}
              textCertificadoFabricante={textCertificadoFabricante}
              certificadoFabricante={certificadoFabricante}
              textRegistroInspecao={textRegistroInspecao}
              registroInspecao={registroInspecao}
              textRegistroReparo={textRegistroReparo}
              registroReparo={registroReparo}

            />

            {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

            {/* ************************************************** 5 *************************************************** */}

            <EvidenciaFotograficas
              textEvidenciaFotografica={textEvidenciaFotografica}
              image1Data={image1Data}
              image2Data={image2Data}
              image3Data={image3Data}
              image4Data={image4Data}
            />
            {/* ************************************************ FIM 5 *************************************************** */}

            {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

            <ConclusaoTecnica
              textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
              textConclusaoTecnica={textConclusaoTecnica}
              conclusaoTecnica={conclusaoTecnica}
              textRecomendacoes={textRecomendacoes}
              recomendacoes={recomendacoes}
            />

            {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina1de2}>1 de 2</Text>

          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>


            {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

            <ResponsaveisLaudo
              textCabecalhoInspetor={textCabecalhoInspetor}
              textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
              textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
              textNomeInspetor={textNomeInspetor}
              textNomeResponsavelLaudo={textNomeResponsavelLaudo}
              nomeResponsavelLaudo={nomeResponsavelLaudo}
              nomeInspetor={nomeInspetor}
              textNomeResponsavelTecnico={textNomeResponsavelTecnico}
              nomeResponsavelTecnico={nomeResponsavelTecnico}
              textNumeroDocumento={textNumeroDocumento}
              numeroDocumentoInspetor={numeroDocumentoInspetor}
              numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
              textCrea={textCrea}
              crea={crea}
              assinaturaInspetorImage={assinaturaInspetorImage}
              assinaturaResponsavelImage={assinaturaResponsavelImage}
              assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
            />

            {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}

            {/* ******************************************  Notas ******************************************* */}
            <Notas
              textNotas={textNotas}
              textNotaUm={textNotaUm}
              textNotaDois={textNotaDois}
              textReferencias={textReferencias}
            />

            {/* ****************************************** FIM Notas ******************************************* */}
            {/* ************************************************ Nossos Contatos *************************************************** */}

            <NossosContatos
              textNossosContatos={textNossosContatos}
              textFoneContatos={textFoneContatos}
              textEmailContatos={textEmailContatos}
              textSaibaMaisContatos={textSaibaMaisContatos}
            />

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina2de2}>2 de 2</Text>

            {/* ************************************************ FIM Nossos Contatos *************************************************** */}
          </View>
        </Page>
      </>
    );    

    // Cria variáveis locais e garante que sejam não-negativas
    const recomLength = recomendacoes.length > 0 ? recomendacoes.length : 0;
    const dimLength = dimensoes.length > 0 ? dimensoes.length : 0;

    const soma = recomLength + dimLength;
    if (soma > 725) {

      if (recomLength >= 805 && recomLength < 900) {        
      
        if (dimLength > 400) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (recomLength >= 900 && recomLength < 1000) { 
        if (dimLength > 289) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (recomLength >= 1000) {        
      
        if (dimLength > 284) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (dimLength >= 805 && dimLength < 900) {
        if (recomLength > 400) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (dimLength >= 900 && dimLength < 1000) {
        if (recomLength > 289) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (dimLength >= 1000) {
        if (recomLength > 284) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }  
      return (
        <Document>
          {renderTwoPagesNotasEhNossosContatos()}
        </Document>
      );
    }

    if (recomLength >= 440 || dimLength >= 450) {

      if (recomLength >= 440 && recomLength < 805) {
        if (dimLength < 450) {
          return (
            <Document>
              {renderTwoPagesNossosContatos()}
            </Document>
          );
        }
        else if (dimLength >= 450 && dimLength <= 800) {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }
      }

      if (dimLength >= 450 && dimLength < 900) {
        return (
          <Document>
            {renderTwoPagesNossosContatos()}
          </Document>
        );

      }
      if (dimLength >= 900) {
        return (
          <Document>
            {renderTwoPagesNotasEhNossosContatos()}
          </Document>
        );
      }
      if (dimLength >= 450 && recomLength >= 440) {
        return (
          <Document>
            {renderTwoPagesNossosContatos()}
          </Document>
        );
      }
    }

    else {
      return (
        <Document>
          {renderOnePage()}
        </Document>
      );
    }
  };
  const DocumentPdf2 = () => (
    <Document>
      {recomendacoes.length >= 440 && recomendacoes.length < 805 ? (
        <>
          <Page size="A4" style={styles.page}>
            <View style={{ border: 2.5, height: 800 }}>
              {/* ************************************************** Cabeçalho *************************************************** */}
              <CabecalhoLaudo
                imageLogoTipoData={imageLogoTipoData}
                textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
                textArt={textArt}
                art={art}
                textLaudo={textLaudo}
                laudo={laudo}
                razaoSocialInguanti={razaoSocialInguanti}
              />
              {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
              <IdentificacaoAcessorio
                textIdentificacaoAcessorio={textIdentificacaoAcessorio}
                textTipo={textTipo}
                tipo={tipo}
                textTagIng={textTagIng}
                tagIng={tagIng}
                textTagCliente={textTagCliente}
                tagCliente={tagCliente}
                textFabricante={textFabricante}
                fabricante={fabricante}
                textModelo={textModelo}
                modelo={modelo}
                textCapacidade={textCapacidade}
                capacidade={capacidade}
                textDimensoes={textDimensoes}
                dimensoes={dimensoes}
              />
              {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

              {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
              <IdentificacaoProprietario
                textIdentificacaoProprietario={textIdentificacaoProprietario}
                textEmpresa={textEmpresa}
                empresa={empresa}
                textCnpj={textCnpj}
                cnpj={cnpj}
                textContato={textContato}
                contato={contato}
                textSetor={textSetor}
                setor={setor}
                textTelefone={textTelefone}
                telefone={telefone}
                textSubSetor={textSubSetor}
                subSetor={subSetor}
              />
              {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

              {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

              <DadosInspecaoExecutada
                textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
                textMetodologiaInspecao={textMetodologiaInspecao}
                metodologiaInspecao={metodologiaInspecao}
                textDataMetodologiaInspecao={textDataMetodologiaInspecao}
                dataMetodologiaInspecao={dataMetodologiaInspecao}
              />

              {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


              {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

              <DocumentacaoAcessorio
                textDocumentacaoAcessorio={textDocumentacaoAcessorio}
                textCertificadoFabricante={textCertificadoFabricante}
                certificadoFabricante={certificadoFabricante}
                textRegistroInspecao={textRegistroInspecao}
                registroInspecao={registroInspecao}
                textRegistroReparo={textRegistroReparo}
                registroReparo={registroReparo}

              />

              {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

              {/* ************************************************** 5 *************************************************** */}

              <EvidenciaFotograficas
                textEvidenciaFotografica={textEvidenciaFotografica}
                image1Data={image1Data}
                image2Data={image2Data}
                image3Data={image3Data}
                image4Data={image4Data}
              />
              {/* ************************************************ FIM 5 *************************************************** */}

              {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

              <ConclusaoTecnica
                textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
                textConclusaoTecnica={textConclusaoTecnica}
                conclusaoTecnica={conclusaoTecnica}
                textRecomendacoes={textRecomendacoes}
                recomendacoes={recomendacoes}
              />

              {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

              {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

              <ResponsaveisLaudo
                textCabecalhoInspetor={textCabecalhoInspetor}
                textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
                textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
                textNomeInspetor={textNomeInspetor}
                textNomeResponsavelLaudo={textNomeResponsavelLaudo}
                nomeResponsavelLaudo={nomeResponsavelLaudo}
                nomeInspetor={nomeInspetor}
                textNomeResponsavelTecnico={textNomeResponsavelTecnico}
                nomeResponsavelTecnico={nomeResponsavelTecnico}
                textNumeroDocumento={textNumeroDocumento}
                numeroDocumentoInspetor={numeroDocumentoInspetor}
                numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
                textCrea={textCrea}
                crea={crea}
                assinaturaInspetorImage={assinaturaInspetorImage}
                assinaturaResponsavelImage={assinaturaResponsavelImage}
                assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
              />

              {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


              {/* ******************************************  Notas ******************************************* */}
              <Notas
                textNotas={textNotas}
                textNotaUm={textNotaUm}
                textNotaDois={textNotaDois}
                textReferencias={textReferencias}
              />

              {/* ****************************************** FIM Notas ******************************************* */}

              <Text style={styles.watermark1}>INGUANTI</Text>
              <Text style={styles.watermark2}>INGUANTI</Text>
              <Text style={styles.textPagina1de2}>1 de 2</Text>

            </View>
          </Page>

          <Page size="A4" style={styles.page}>
            <View style={{ border: 2.5, height: 800 }}>
              {/* ************************************************ Nossos Contatos *************************************************** */}

              <NossosContatos
                textNossosContatos={textNossosContatos}
                textFoneContatos={textFoneContatos}
                textEmailContatos={textEmailContatos}
                textSaibaMaisContatos={textSaibaMaisContatos}
              />

              <Text style={styles.watermark1}>INGUANTI</Text>
              <Text style={styles.watermark2}>INGUANTI</Text>
              <Text style={styles.textPagina2de2}>2 de 2</Text>

              {/* ************************************************ FIM Nossos Contatos *************************************************** */}
            </View>
          </Page>

        </>
      ) : recomendacoes.length >= 805 ? (
        <>
          <Page size="A4" style={styles.page}>
            <View style={{ border: 2.5, height: 800 }}>
              {/* ************************************************** Cabeçalho *************************************************** */}
              <CabecalhoLaudo
                imageLogoTipoData={imageLogoTipoData}
                textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
                textArt={textArt}
                art={art}
                textLaudo={textLaudo}
                laudo={laudo}
                razaoSocialInguanti={razaoSocialInguanti}
              />
              {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
              <IdentificacaoAcessorio
                textIdentificacaoAcessorio={textIdentificacaoAcessorio}
                textTipo={textTipo}
                tipo={tipo}
                textTagIng={textTagIng}
                tagIng={tagIng}
                textTagCliente={textTagCliente}
                tagCliente={tagCliente}
                textFabricante={textFabricante}
                fabricante={fabricante}
                textModelo={textModelo}
                modelo={modelo}
                textCapacidade={textCapacidade}
                capacidade={capacidade}
                textDimensoes={textDimensoes}
                dimensoes={dimensoes}
              />
              {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

              {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
              <IdentificacaoProprietario
                textIdentificacaoProprietario={textIdentificacaoProprietario}
                textEmpresa={textEmpresa}
                empresa={empresa}
                textCnpj={textCnpj}
                cnpj={cnpj}
                textContato={textContato}
                contato={contato}
                textSetor={textSetor}
                setor={setor}
                textTelefone={textTelefone}
                telefone={telefone}
                textSubSetor={textSubSetor}
                subSetor={subSetor}
              />
              {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

              {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

              <DadosInspecaoExecutada
                textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
                textMetodologiaInspecao={textMetodologiaInspecao}
                metodologiaInspecao={metodologiaInspecao}
                textDataMetodologiaInspecao={textDataMetodologiaInspecao}
                dataMetodologiaInspecao={dataMetodologiaInspecao}
              />

              {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


              {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

              <DocumentacaoAcessorio
                textDocumentacaoAcessorio={textDocumentacaoAcessorio}
                textCertificadoFabricante={textCertificadoFabricante}
                certificadoFabricante={certificadoFabricante}
                textRegistroInspecao={textRegistroInspecao}
                registroInspecao={registroInspecao}
                textRegistroReparo={textRegistroReparo}
                registroReparo={registroReparo}

              />

              {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

              {/* ************************************************** 5 *************************************************** */}

              <EvidenciaFotograficas
                textEvidenciaFotografica={textEvidenciaFotografica}
                image1Data={image1Data}
                image2Data={image2Data}
                image3Data={image3Data}
                image4Data={image4Data}
              />
              {/* ************************************************ FIM 5 *************************************************** */}

              {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

              <ConclusaoTecnica
                textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
                textConclusaoTecnica={textConclusaoTecnica}
                conclusaoTecnica={conclusaoTecnica}
                textRecomendacoes={textRecomendacoes}
                recomendacoes={recomendacoes}
              />

              {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

              {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

              <ResponsaveisLaudo
                textCabecalhoInspetor={textCabecalhoInspetor}
                textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
                textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
                textNomeInspetor={textNomeInspetor}
                textNomeResponsavelLaudo={textNomeResponsavelLaudo}
                nomeResponsavelLaudo={nomeResponsavelLaudo}
                nomeInspetor={nomeInspetor}
                textNomeResponsavelTecnico={textNomeResponsavelTecnico}
                nomeResponsavelTecnico={nomeResponsavelTecnico}
                textNumeroDocumento={textNumeroDocumento}
                numeroDocumentoInspetor={numeroDocumentoInspetor}
                numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
                textCrea={textCrea}
                crea={crea}
                assinaturaInspetorImage={assinaturaInspetorImage}
                assinaturaResponsavelImage={assinaturaResponsavelImage}
                assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
              />

              {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}

              <Text style={styles.watermark1}>INGUANTI</Text>
              <Text style={styles.watermark2}>INGUANTI</Text>
              <Text style={styles.textPagina1de2}>1 de 2</Text>

            </View>
          </Page>


          <Page size="A4" style={styles.page}>
            <View style={{ border: 2.5, height: 800 }}>
              {/* ******************************************  Notas ******************************************* */}
              <Notas
                textNotas={textNotas}
                textNotaUm={textNotaUm}
                textNotaDois={textNotaDois}
                textReferencias={textReferencias}
              />

              {/* ****************************************** FIM Notas ******************************************* */}
              {/* ************************************************ Nossos Contatos *************************************************** */}

              <NossosContatos
                textNossosContatos={textNossosContatos}
                textFoneContatos={textFoneContatos}
                textEmailContatos={textEmailContatos}
                textSaibaMaisContatos={textSaibaMaisContatos}
              />

              <Text style={styles.watermark1}>INGUANTI</Text>
              <Text style={styles.watermark2}>INGUANTI</Text>
              <Text style={styles.textPagina2de2}>2 de 2</Text>

              {/* ************************************************ FIM Nossos Contatos *************************************************** */}
            </View>
          </Page>
        </>
      ) : (
        <>
          <Page size="A4" style={styles.page}>
            <View style={{ border: 2.5, height: 800 }}>
              {/* ************************************************** Cabeçalho *************************************************** */}
              <CabecalhoLaudo
                imageLogoTipoData={imageLogoTipoData}
                textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
                textArt={textArt}
                art={art}
                textLaudo={textLaudo}
                laudo={laudo}
                razaoSocialInguanti={razaoSocialInguanti}
              />
              {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
              <IdentificacaoAcessorio
                textIdentificacaoAcessorio={textIdentificacaoAcessorio}
                textTipo={textTipo}
                tipo={tipo}
                textTagIng={textTagIng}
                tagIng={tagIng}
                textTagCliente={textTagCliente}
                tagCliente={tagCliente}
                textFabricante={textFabricante}
                fabricante={fabricante}
                textModelo={textModelo}
                modelo={modelo}
                textCapacidade={textCapacidade}
                capacidade={capacidade}
                textDimensoes={textDimensoes}
                dimensoes={dimensoes}
              />
              {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

              {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
              <IdentificacaoProprietario
                textIdentificacaoProprietario={textIdentificacaoProprietario}
                textEmpresa={textEmpresa}
                empresa={empresa}
                textCnpj={textCnpj}
                cnpj={cnpj}
                textContato={textContato}
                contato={contato}
                textSetor={textSetor}
                setor={setor}
                textTelefone={textTelefone}
                telefone={telefone}
                textSubSetor={textSubSetor}
                subSetor={subSetor}
              />
              {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

              {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

              <DadosInspecaoExecutada
                textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
                textMetodologiaInspecao={textMetodologiaInspecao}
                metodologiaInspecao={metodologiaInspecao}
                textDataMetodologiaInspecao={textDataMetodologiaInspecao}
                dataMetodologiaInspecao={dataMetodologiaInspecao}
              />

              {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


              {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

              <DocumentacaoAcessorio
                textDocumentacaoAcessorio={textDocumentacaoAcessorio}
                textCertificadoFabricante={textCertificadoFabricante}
                certificadoFabricante={certificadoFabricante}
                textRegistroInspecao={textRegistroInspecao}
                registroInspecao={registroInspecao}
                textRegistroReparo={textRegistroReparo}
                registroReparo={registroReparo}

              />

              {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

              {/* ************************************************** 5 *************************************************** */}

              <EvidenciaFotograficas
                textEvidenciaFotografica={textEvidenciaFotografica}
                image1Data={image1Data}
                image2Data={image2Data}
                image3Data={image3Data}
                image4Data={image4Data}
              />
              {/* ************************************************ FIM 5 *************************************************** */}

              {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

              <ConclusaoTecnica
                textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
                textConclusaoTecnica={textConclusaoTecnica}
                conclusaoTecnica={conclusaoTecnica}
                textRecomendacoes={textRecomendacoes}
                recomendacoes={recomendacoes}
              />

              {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

              {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

              <ResponsaveisLaudo
                textCabecalhoInspetor={textCabecalhoInspetor}
                textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
                textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
                textNomeInspetor={textNomeInspetor}
                textNomeResponsavelLaudo={textNomeResponsavelLaudo}
                nomeResponsavelLaudo={nomeResponsavelLaudo}
                nomeInspetor={nomeInspetor}
                textNomeResponsavelTecnico={textNomeResponsavelTecnico}
                nomeResponsavelTecnico={nomeResponsavelTecnico}
                textNumeroDocumento={textNumeroDocumento}
                numeroDocumentoInspetor={numeroDocumentoInspetor}
                numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
                textCrea={textCrea}
                crea={crea}
                assinaturaInspetorImage={assinaturaInspetorImage}
                assinaturaResponsavelImage={assinaturaResponsavelImage}
                assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
              />

              {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


              {/* ******************************************  Notas ******************************************* */}
              <Notas
                textNotas={textNotas}
                textNotaUm={textNotaUm}
                textNotaDois={textNotaDois}
                textReferencias={textReferencias}
              />

              {/* ****************************************** FIM Notas ******************************************* */}

              {/* ************************************************ Nossos Contatos *************************************************** */}

              <NossosContatos
                textNossosContatos={textNossosContatos}
                textFoneContatos={textFoneContatos}
                textEmailContatos={textEmailContatos}
                textSaibaMaisContatos={textSaibaMaisContatos}
              />

              {/* ************************************************ FIM Nossos Contatos *************************************************** */}
            </View>
            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina1de1}>1 de 1</Text>
          </Page>

        </>
      )}
    </Document>
  );

  const sendPdfToServer = async (blob) => {
    const formData = new FormData();
    let cleanedLaudo = laudo.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");
    formData.append("file", blob, `${cleanedLaudo}.pdf`);

    try {
      const result = await axios.post(baseApiPdf, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <BlobProvider document={<DocumentPdf />}>
        {({ blob, url, loading, error }) => {
          if (!loading && blob) {
            sendPdfToServer(blob);
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                Visualizar Relatório
              </a>
            );
          } else if (error) {
            return <div>Error occurred</div>;
          } else {
            return <div>Loading...</div>;
          }
        }}
      </BlobProvider>
    </div>
  );
};

export default ExportToPDFTeste;
