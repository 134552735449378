import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './Menu/Menu';
import PrivateRoute from './Context/PrivateRoute';
import { AuthProvider } from './Context/Auth';
import Login from './Components/Login';
import Home from './Components/Home';
import Acessorios from './Components/Acessorios';
import Clientes from './Components/Clientes';
import DocumentosComplementares from './Components/DocumentosComplementares';
import Inspetor from './Components/Inspetor';
import ResponsavelLaudo from './Components/ResponsavelLaudo';
import AreaCliente from './Components/AreaCliente';
import ResponsavelTecnico from './Components/ResponsavelTecnico';
import OrdemServico from './Components/OrdemServico';
import Usuarios from './Components/Usuarios';
import Dashboard from './Components/Dashboard';
import DashboardCliente from './Components/DashboardCliente';
import Boletos from './Components/Boletos';
import Teste from './Components/Teste';
import AccessDenied from './Components/AccessDenied';
import TermosDeUso from './Components/TermosDeUso';
import OsLaudos from './Components/OsLaudos';
import Laudos from './Components/Laudos';
import DetalharOs from './Components/DetalharOs';
import DocOs from './Components/DocOs';
import OsAcessorios from './Components/OsAcessorios';
import MetodologiaInspecao from './Components/MetodologiaInspecao'
import ExportToPDF from './Components/ExportToPDF';
import ExportToPDFTeste from './Components/ExportToPDFTeste';
import SessionCheckWrapper from './Components/SessionCheckWrapper';
import SessionCheckWrapper2 from './Components/SessionCheckWrapper2';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Menu />
        {/* <SessionCheckWrapper> */}
        <SessionCheckWrapper2>
          <ToastContainer />
          <Routes>
            <Route path="/exportToPDF" element={<ExportToPDF />} />
            <Route path="/exportToPDFTeste" element={<ExportToPDFTeste />} />
            <Route path="/teste" element={<Teste />} />
            <Route path="/acesso-negado" element={<AccessDenied />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/ordemservico" element={<PrivateRoute requiredPermission="OrdemServico"><OrdemServico /></PrivateRoute>} />
            <Route path="/os-laudos" element={<PrivateRoute requiredPermission="Laudos"><OsLaudos /></PrivateRoute>} />
            <Route path="/laudos/:idOrdemServico" element={<PrivateRoute requiredPermission="Laudos"><Laudos /></PrivateRoute>} />
            <Route path="/laudos/edit/:idLaudo" element={<PrivateRoute requiredPermission="Laudos"><Laudos /></PrivateRoute>} />
            <Route path="/detalhar-os/:idOrdemServico" element={<PrivateRoute requiredPermission="Laudos"><DetalharOs /></PrivateRoute>} />
            <Route path="/doc-os/:idOrdemServico" element={<PrivateRoute requiredPermission="Laudos"><DocOs /></PrivateRoute>} />
            <Route path="/os-acessorios/:idOrdemServico" element={<PrivateRoute requiredPermission="OrdemServico"><OsAcessorios /></PrivateRoute>} />
            <Route path="/areacliente" element={<PrivateRoute requiredPermission="AreaCliente"><AreaCliente /></PrivateRoute>} />
            <Route path="/acessorios" element={<PrivateRoute requiredPermission="Acessorios"><Acessorios /></PrivateRoute>} />
            <Route path="/inspetor" element={<PrivateRoute requiredPermission="Inspetor"><Inspetor /></PrivateRoute>} />
            <Route path="/responsavellaudo" element={<PrivateRoute requiredPermission="ResponsavelLaudo"><ResponsavelLaudo /></PrivateRoute>} />
            <Route path="/responsaveltecnico" element={<PrivateRoute requiredPermission="ResponsavelTecnico"><ResponsavelTecnico /></PrivateRoute>} />
            <Route path="/usuarios" element={<PrivateRoute requiredPermission="Usuarios"><Usuarios /></PrivateRoute>} />
            <Route path="/clientes" element={<PrivateRoute requiredPermission="Clientes"><Clientes /></PrivateRoute>} />
            <Route path="/metodologiaInspecao" element={<PrivateRoute requiredPermission="MetodologiaInspecao"><MetodologiaInspecao /></PrivateRoute>} />
            <Route path="/documentoscomplementares" element={<PrivateRoute requiredPermission="DocumentosComplementares"><DocumentosComplementares /></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute requiredPermission="Dashboard"><Dashboard /></PrivateRoute>} />
            <Route path="/boletos" element={<PrivateRoute requiredPermission="Boletos"><Boletos /></PrivateRoute>} />
            <Route path="/dashboardcliente" element={<PrivateRoute requiredPermission="DashboardCliente"><DashboardCliente /></PrivateRoute>} />
            <Route path="/termosdeuso" element={<PrivateRoute requiredPermission="TermosDeUso"><TermosDeUso /></PrivateRoute>} />
          </Routes>
        </SessionCheckWrapper2>
      </Router>
    </AuthProvider>
  );
};

export default App;
