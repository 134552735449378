import React from 'react';
import { Table, Card } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import "./DashboardCliente.css";

const getClassName = (value, type) => {
    if (value > 0) {
        if (type === 'A') return 'green-bg';
        if (type === 'AR') return 'yellow-bg';
        if (type === 'R') return 'red-bg';
    }
    return '';
};

const DashboardClienteTable = ({ groupedData, uniqueAcessorios, totals, data, accessoryTotals }) => {
    const totalAprovado = Object.values(groupedData).reduce((acc, curr) => acc + curr.aprovado, 0);
    const totalAprovadoRecomendacoes = Object.values(groupedData).reduce((acc, curr) => acc + curr.aprovadoRecomendacoes, 0);
    const totalReprovado = Object.values(groupedData).reduce((acc, curr) => acc + curr.reprovado, 0);

    return (
        <>
            <Card className="mb-4 mt-4">
                <Card.Body>
                    <Card.Title>
                        <h1 className="table-title">Indicadores Estatísticos das Inspeções Realizadas</h1>
                    </Card.Title>
                    <div className="table-responsive">
                        <Table bordered striped className="small-font-table">
                            <thead>
                                <tr className="center-text">
                                    <th rowSpan="2">Sub Setores</th>
                                    <th rowSpan="2" className="qtde-header">Qtde</th>
                                    <th colSpan="3" className="resultado-header">Resultado do Sub Setor</th>
                                    {uniqueAcessorios.map((acessorio, index) => (
                                        <th colSpan="3" key={index} className="thick-border-right">{acessorio}</th>
                                    ))}
                                </tr>
                                <tr className="center-text">
                                    <Tooltip title="Aprovado" arrow>
                                        <th className='green-bg'>A</th>
                                    </Tooltip>
                                    <Tooltip title="Aprovado com Recomendações" arrow>
                                        <th className='yellow-bg'>AR</th>
                                    </Tooltip>
                                    <Tooltip title="Reprovado" arrow>
                                        <th className='red-bg thick-border-right'>R</th>
                                    </Tooltip>
                                    {uniqueAcessorios.map((acessorio, index) => (
                                        <React.Fragment key={index}>
                                            <Tooltip title="Aprovado" arrow>
                                                <th className='green-bg'>A</th>
                                            </Tooltip>
                                            <Tooltip title="Aprovado com Recomendações" arrow>
                                                <th className='yellow-bg'>AR</th>
                                            </Tooltip>
                                            <Tooltip title="Reprovado" arrow>
                                                <th className='red-bg thick-border-right'>R</th>
                                            </Tooltip>
                                        </React.Fragment>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupedData).map((subSetor, index) => (
                                    <tr key={index} className="center-text">
                                        <td>{subSetor}</td>
                                        <td className="qtde-column">{groupedData[subSetor].aprovado + groupedData[subSetor].aprovadoRecomendacoes + groupedData[subSetor].reprovado}</td>
                                        {groupedData[subSetor].aprovado > 0 ? (
                                            <Tooltip title="Aprovado" arrow>
                                                <td className={getClassName(groupedData[subSetor].aprovado, 'A')}>
                                                    {groupedData[subSetor].aprovado}
                                                </td>
                                            </Tooltip>
                                        ) : (
                                            <td className={getClassName(groupedData[subSetor].aprovado, 'A')}>
                                                {groupedData[subSetor].aprovado === 0 ? '' : groupedData[subSetor].aprovado}
                                            </td>
                                        )}
                                        {groupedData[subSetor].aprovadoRecomendacoes > 0 ? (
                                            <Tooltip title="Aprovado com Recomendações" arrow>
                                                <td className={getClassName(groupedData[subSetor].aprovadoRecomendacoes, 'AR')}>
                                                    {groupedData[subSetor].aprovadoRecomendacoes}
                                                </td>
                                            </Tooltip>
                                        ) : (
                                            <td className={getClassName(groupedData[subSetor].aprovadoRecomendacoes, 'AR')}>
                                                {groupedData[subSetor].aprovadoRecomendacoes === 0 ? '' : groupedData[subSetor].aprovadoRecomendacoes}
                                            </td>
                                        )}
                                        {groupedData[subSetor].reprovado > 0 ? (
                                            <Tooltip title="Reprovado" arrow>
                                                <td className={getClassName(groupedData[subSetor].reprovado, 'R') + ' thick-border-right'}>
                                                    {groupedData[subSetor].reprovado}
                                                </td>
                                            </Tooltip>
                                        ) : (
                                            <td className={getClassName(groupedData[subSetor].reprovado, 'R') + ' thick-border-right'}>
                                                {groupedData[subSetor].reprovado === 0 ? '' : groupedData[subSetor].reprovado}
                                            </td>
                                        )}
                                        {uniqueAcessorios.map((acessorio, i) => {
                                            const filteredItems = data.filter(item => item.subSetor === subSetor && item.acessorio === acessorio);
                                            const aprovado = filteredItems.reduce((acc, item) => acc + item.aprovado, 0);
                                            const aprovadoRecomendacoes = filteredItems.reduce((acc, item) => acc + item.aprovadoRecomendacoes, 0);
                                            const reprovado = filteredItems.reduce((acc, item) => acc + item.reprovado, 0);
                                            return (
                                                <React.Fragment key={i}>
                                                    {aprovado > 0 ? (
                                                        <Tooltip title="Aprovado" arrow>
                                                            <td className={getClassName(aprovado, 'A')}>
                                                                {aprovado}
                                                            </td>
                                                        </Tooltip>
                                                    ) : (
                                                        <td className={getClassName(aprovado, 'A')}>
                                                            {aprovado === 0 ? '' : aprovado}
                                                        </td>
                                                    )}
                                                    {aprovadoRecomendacoes > 0 ? (
                                                        <Tooltip title="Aprovado com Recomendações" arrow>
                                                            <td className={getClassName(aprovadoRecomendacoes, 'AR')}>
                                                                {aprovadoRecomendacoes}
                                                            </td>
                                                        </Tooltip>
                                                    ) : (
                                                        <td className={getClassName(aprovadoRecomendacoes, 'AR')}>
                                                            {aprovadoRecomendacoes === 0 ? '' : aprovadoRecomendacoes}
                                                        </td>
                                                    )}
                                                    {reprovado > 0 ? (
                                                        <Tooltip title="Reprovado" arrow>
                                                            <td className={getClassName(reprovado, 'R') + ' thick-border-right'}>
                                                                {reprovado}
                                                            </td>
                                                        </Tooltip>
                                                    ) : (
                                                        <td className={getClassName(reprovado, 'R') + ' thick-border-right'}>
                                                            {reprovado === 0 ? '' : reprovado}
                                                        </td>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </tr>
                                ))}
                                <tr className="center-text">
                                    <td>TOTAL</td>
                                    <td className="qtde-column">{totals.total}</td>
                                    <td className={getClassName(totals.aprovado, 'A')}>{totalAprovado}</td>
                                    <td className={getClassName(totals.aprovadoRecomendacoes, 'AR')}>{totalAprovadoRecomendacoes}</td>
                                    <td className={getClassName(totals.reprovado, 'R') + ' thick-border-right'}>{totalReprovado}</td>
                                    {uniqueAcessorios.map((acessorio, index) => (
                                        <React.Fragment key={index}>
                                            <td className={index === uniqueAcessorios.length ? 'thick-border-right' : ''}>{accessoryTotals[acessorio].aprovado}</td>
                                            <td className={index === uniqueAcessorios.length ? 'thick-border-right' : ''}>{accessoryTotals[acessorio].aprovadoRecomendacoes}</td>
                                            <td className='thick-border-right'>{accessoryTotals[acessorio].reprovado}</td>
                                        </React.Fragment>
                                    ))}
                                </tr>

                                <tr className="center-text">
                                    <td>Soma A AR R</td>
                                    <td></td>
                                    <td colSpan="3" className="qtde-header">{totalAprovado + totalAprovadoRecomendacoes + totalReprovado}</td>
                                    {uniqueAcessorios.map((acessorio, index) => (
                                        <React.Fragment key={index}>
                                            <td colSpan="3" className='last-line-white-bg'>{accessoryTotals[acessorio].aprovado + accessoryTotals[acessorio].aprovadoRecomendacoes + accessoryTotals[acessorio].reprovado}</td>
                                        </React.Fragment>
                                    ))}
                                </tr>

                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default DashboardClienteTable;
