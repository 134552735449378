import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';
import { useParams } from "react-router-dom";

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';
import TableDocOs from '../Shared/CustomJs/TableDocOs';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora, renameFileWithTimestamp } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/DocCompOs`;
const baseApiOrdemServico = `${baseApiUrl}/api/OrdemServico`;
const baseApiDocumentosComplementares = `${baseApiUrl}/api/DocumentosComplementares`;
const baseApiPdf = `${baseApiUrl}/api/Files/add-pdf`;
const baseApiGetPdf = `${baseApiUrl}/api/Files/download-pdf`;

const DocOs = () => {

    const { idOrdemServico } = useParams();

    const listOptions = [
        { value: "tipoDocumento", label: "Tipo de Documento" },
        { value: "descricao", label: "Descrição" },
        { value: "ordemServico", label: "Ordem de Serviço" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
    ];
   

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [listDocOsData, setListDocOsData] = useState([]);
    const [constListData, setConstListData] = useState([]);
    const [constListTipoDocumentoData, setConstListTipoDocumentoData] = useState([]);

    const [os, setOs] = useState(null);

    //Add
    const [newTipoDocumento, setNewTipoDocumento] = useState('');
    const [newDescricao, setNewDescricao] = useState('');
    const [newIdDocumentosComp, setNewIdDocumentosComp] = useState(null);
    const [newDocumentoComplementar, setNewDocumentoComplementar] = useState('');
    const [newObservacao, setNewObservacao] = useState('');

    //Edit  
    const [editTipoDocumento, setEditTipoDocumento] = useState(null);
    const [editDescricao, setEditDescricao] = useState(null);
    const [editIdDocumentosComp, setEditIdDocumentosComp] = useState(null);
    const [editDocumentoComplementar, setEditDocumentoComplementar] = useState(null);
    const [editObservacao, setEditObservacao] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const [idCliente, setIdCliente] = useState(null);

    const [idDocOs, setIdDocOs] = useState(null);

    const [modalMessage, setModalMessage] = useState('');
    const fileInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("tipoDocumento");

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchAll();
        }
    }, []);

    const fetchAll = async () => {
        fetchInitialDataOs();
        fetchInitialDataOrdemServico();
        fetchInitialDataTipoDocumento();
    }
    const fetchInitialDataOs = async () => {
        setLoading(true);
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-doc-comp-os/${idOrdemServico}`);
            const formattedData = formatarDataComHora(response.data);
            setListDocOsData(formattedData);
            setConstListData(formattedData);
        } catch (error) {
            console.log('Erro ao buscar dados:', error);
        }
        setLoading(false);
    };

    const fetchInitialDataOrdemServico = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${baseApiOrdemServico}/get-ordemservico/${idOrdemServico}`
            );
            setOs(response.data.os);
            setIdCliente(response.data.idCliente);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
        setLoading(false);
    };

    const fetchInitialDataTipoDocumento = async () => {
        try {
            const response = await axios.get(
                `${baseApiDocumentosComplementares}/get-documentos-complementares`
            );
            setConstListTipoDocumentoData(response.data);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    const handleSave = async (idOrdemServico, idDocumentosComp, idCliente, ordemServico, observacao = 'N/A') => {

        if (
            !ordemServico?.trim(),
            !idDocumentosComp,
            !idCliente
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (
            // idOrdemServico <= 0,
            idDocumentosComp <= 0,
            idCliente <= 0
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!selectedFile) {
            setModalMessage('Por favor, selecione um arquivo.');
            return;
        }

        setLoading(true);
        try {

            const formData = new FormData();
            if (selectedFile) {
                const renamedFile = renameFileWithTimestamp(selectedFile);

                let cleanedFileName = renamedFile.name.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");
                formData.append("file", renamedFile, `${cleanedFileName}.pdf`);

                const result = await axios.post(baseApiPdf, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (result?.status === 200) {
                    const newItem = {
                        IdOrdemServico: idOrdemServico,
                        IdDocumentosComp: idDocumentosComp,
                        IdCliente: idCliente,
                        OrdemServico: ordemServico,
                        CaminhoDocumento: renamedFile.name,
                        Observacao: observacao,
                    };

                    const response = await axios.post(`${baseApi}/add-doc-comp-os`, newItem);

                    if (response?.status === 200) {
                        setModalMessage(`${response?.data?.message}`);

                        if (!(response?.data?.message.toLowerCase().includes('erro')
                            || response?.data?.message.toLowerCase().includes('existe'))
                        ) {
                            setModalMessage('');
                            setNewTipoDocumento('');
                            setNewDescricao('');
                            setNewObservacao('');
                            setIdDocOs(null);
                            setEditTipoDocumento(null);
                            setEditDescricao(null);
                            setEditObservacao(null);
                            fetchAll();
                        }

                    } else {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1);
                        console.error('Erro ao adicionar o registro:', response?.data?.message);
                        setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                    }
                }
            }

        } catch (error) {
            console.error('Erro ao adicionar o registro:', error?.response?.data?.errors[0]);
            setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1);

    };

    const handleUpdate = async (idOrdemServico, idDocumentosComp, idCliente, ordemServico, observacao = 'N/A') => {

        if (
            !ordemServico?.trim(),
            !idDocumentosComp,
            !idCliente
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (
            // idOrdemServico <= 0,
            idDocumentosComp <= 0,
            idCliente <= 0
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!selectedFile) {
            setModalMessage('Por favor, selecione um arquivo.');
            return;
        }
        setLoading(true);

        try {

            const formData = new FormData();

            if (selectedFile) {
                const renamedFile = renameFileWithTimestamp(selectedFile);

                let cleanedFileName = renamedFile.name.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");
                formData.append("file", renamedFile, `${cleanedFileName}.pdf`);

                const result = await axios.post(baseApiPdf, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });


                if (result?.status === 200) {
                    const newItem = {
                        IdOrdemServico: idOrdemServico,
                        IdDocumentosComp: idDocumentosComp,
                        IdCliente: idCliente,
                        OrdemServico: ordemServico,
                        CaminhoDocumento: renamedFile.name,
                        Observacao: observacao,
                    };

                    const response = await axios.put(`${baseApi}/update-doc-comp-os/${idDocOs}`, newItem);

                    if (response?.status === 200) {
                        setModalMessage(`${response?.data?.message}`);

                        if (!(response?.data?.message.toLowerCase().includes('erro')
                            || response?.data?.message.toLowerCase().includes('existe'))
                        ) {
                            setModalMessage('');
                            setNewTipoDocumento('');
                            setNewDescricao('');
                            setNewObservacao('');
                            setIdDocOs(null);
                            setEditTipoDocumento(null);
                            setEditDescricao(null);
                            setEditObservacao(null);
                            setShowModal(false);
                            Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                            setTimeout(() => {
                                fetchAll();
                            }, 50);
                        }

                    } else {
                        setTimeout(() => {
                            setLoading(false);
                        }, 1);
                        console.error('Erro ao adicionar o registro:', response?.data?.message);
                        setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                    }
                }
            }
        } catch (error) {
            setTimeout(() => {
                setLoading(false);
            }, 1);
            console.error('Erro ao editar o registro:', error?.response?.data?.errors[0]);
            setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1);


    };

    const handleDelete = async (item) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${baseApi}/delete-doc-comp-os/${item.idDocOs}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchAll();
                        });

                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleDownloadPdf = async (filename) => {
        try {
            let cleanedFilePdf = filename.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");

            const response = await axios.get(`${baseApiGetPdf}/${cleanedFilePdf}.pdf`, {
                responseType: 'blob', // Mudança para 'blob' aqui
            });

            if (response?.data) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const urlPdf = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = urlPdf;
                link.download = `${cleanedFilePdf}.pdf`; // Força o download com o nome do arquivo
                link.target = '_blank'; // Tenta abrir em uma nova aba
                link.click();

                // Libera a URL do objeto Blob após o download.
                URL.revokeObjectURL(urlPdf);
            }
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleTipoDocumentoChange = (event) => {
        const selectedTipo = event.target.value.toUpperCase();

        // Se a opção selecionada for "SELECIONE"
        if (selectedTipo === "-1") {
            if (isUpdate) {
                setEditTipoDocumento("");
                setEditDescricao("");
            } else {
                setNewTipoDocumento("");
                setNewDescricao("");
            }
            return;
        }

        const list = constListTipoDocumentoData.find(item => item.tipoDocumento.toUpperCase() === selectedTipo);

        if (isUpdate) {
            setEditTipoDocumento(selectedTipo);
            if (list) {
                setEditDescricao(list.descricao);
                setEditIdDocumentosComp(list.idDocumentosComp);
            }
        } else {
            setNewTipoDocumento(selectedTipo);
            if (list) {
                setNewDescricao(list.descricao);
                setNewIdDocumentosComp(list.idDocumentosComp);
            }
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleObservacaoChange = (event) => {
        isUpdate
            ? setEditObservacao(event.target.value.toUpperCase())
            : setNewObservacao(event.target.value.toUpperCase());
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    const openModalAdd = () => {
        setModalMessage('');
        setNewTipoDocumento('');
        setNewDescricao('');
        setNewObservacao('');
        setIdDocOs(null);
        setEditTipoDocumento(null);
        setEditDescricao(null);
        setEditObservacao('');
        setIsUpdate(false);
        setShowModal(true);
    };
    const openModalEdit = (item) => {
        setModalMessage('');
        setEditIdDocumentosComp(item.idDocumentosComp);
        setEditDescricao(item.descricao);
        setEditTipoDocumento(item.tipoDocumento);
        setEditObservacao(item.observacao);
        setIdDocOs(item.idDocOs);
        setNewTipoDocumento('');
        setNewDescricao('');
        setNewObservacao('');
        setIsUpdate(true);
        setShowModal(true);
    };

    const closeModal = () => {
        setModalMessage('');
        setNewTipoDocumento('');
        setNewDescricao('');
        setEditTipoDocumento(null);
        setEditDescricao(null);
        setIdDocOs(null);
        setIsUpdate(false);
        setShowModal(false);
    };

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Col>
                                    <h4 style={{ color: 'red' }}>Doc OS - Ordem de Serviço - N° {os} </h4>
                                </Col>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row>
                <Col>
                    <Form>
                        <Row className='mt-3'>
                            <Col>
                                <h4 style={{ color: 'red' }}>Doc OS - Ordem de Serviço - N° {os} </h4>
                            </Col>
                        </Row>
                        <SearchBar
                            listOptions={listOptions}
                            handleChangeSearchTerm={handleChangeSearchTerm}
                            handleSelectChange={handleSelectChange}
                            handleSearchClick={handleSearchClick}
                            openModal={openModalAdd}
                            fetchInitialData={fetchInitialDataOs}
                        />

                    </Form>                

                    <TableDocOs
                        data={paginatedData}
                        handleDownloadPdf={handleDownloadPdf}
                        handleEdit={openModalEdit}
                        handleDelete={handleDelete}
                    />

                    <CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Col>
            </Row>

            <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />

            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'Doc. Os - Editar' : 'Doc. Os - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(idOrdemServico, editIdDocumentosComp, idCliente, os, editObservacao)
                    : () => handleSave(idOrdemServico, newIdDocumentosComp, idCliente, os, newObservacao)}
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
                size={"lg"}
            >

                <Row className="mt-2">
                    <Form.Group as={Col} controlId="tipodocumento" xs={12} sm={4} md={4} lg={4}>
                        <Form.Label className="bold-label">
                            *Tipo de documento:
                        </Form.Label>
                        <Form.Select
                            className="uppercase-input"
                            autoFocus
                            value={isUpdate
                                ? editTipoDocumento
                                : newTipoDocumento}
                            onChange={handleTipoDocumentoChange}
                        >
                            <option key={-1} value={"-1"}>
                                SELECIONE
                            </option>
                            {constListTipoDocumentoData
                                .sort((a, b) => a.tipoDocumento.localeCompare(b.tipoDocumento))
                                .map((item) => (
                                    <option key={item.idDocumentosComp} value={item.tipoDocumento}>
                                        {item.tipoDocumento}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="descrição">
                        <Form.Label className="bold-label">*Descrição:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="DESCRIÇÃO"
                            value={isUpdate ? editDescricao : newDescricao}
                            readOnly
                            className="disabled-background"
                        />
                    </Form.Group>
                </Row>
                <Row className="mt-3">
                    <Form.Group controlId="documentocomplementar">
                        <Form.Label className="bold-label">*Documento Complementar:</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={handleFileChange}
                        />
                    </Form.Group>

                </Row>
                <Row className="mt-3">
                    <Form.Group controlId="observacao">
                        <Form.Label>Observação:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="OBSERVAÇÃO"
                            maxLength="200"
                            value={isUpdate
                                ? editObservacao
                                : newObservacao}
                            onChange={handleObservacaoChange}
                        />
                    </Form.Group>
                </Row>

                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>
        </Container>
    )
};

export default DocOs;
