import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { BsTrash, BsPencil } from 'react-icons/bs';
import './CustomTable.css';

const TableOrdemServico = ({ data, handleEdit, handleDelete,handleOsAcessorios }) => {
    return (
        <div className="table-responsive custom-div-table">
            <Table responsive striped bordered hover className="table-sm custom-table">
                <thead>
                    <tr style={{ fontSize: "14px", color: "#236CB3" }}>
                        <th></th>
                        <th></th>
                        <th>O.S.</th>
                        <th>Cliente</th>
                        <th>Acessórios</th>
                        <th>Qt Acess.</th>
                        <th>Inspetor</th>
                        <th>Resp. Téc.</th>
                        <th>N° Art</th>
                        <th>Razão Social</th>
                        <th>Finalizado</th>
                        <th>Cadastrado Por</th>
                        <th>Data Cadastro</th>
                        <th>Alterado Por</th>
                        <th>Data Alteração</th>
                    </tr>
                </thead>
                <tbody >
                    {data.map((item, index) => (
                        <tr key={index} >
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Editar" arrow>
                                    <Button
                                        size="sm"
                                        onClick={() => handleEdit(item)}
                                        className="custom-edit-button"
                                    >
                                        <BsPencil className="custom-icon-trash-pencil" />
                                    </Button>
                                </Tooltip>

                            </td>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Deletar" arrow>
                                    <Button
                                        size="sm"
                                        onClick={() => handleDelete(item)}
                                        className="custom-delete-button"
                                    >
                                        <BsTrash className="custom-icon-trash-pencil" />
                                    </Button>
                                </Tooltip>
                            </td>

                            <td>{item.os}</td>
                            <td>{item.cliente}</td>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Incluir acessórios na O.S." arrow>
                                    <Button variant="link" onClick={() => handleOsAcessorios(item.idOrdemServico)}>
                                        <img src="/img/png/gancho_36x40.png" alt="Gancho" width="24" height="28" />
                                    </Button>
                                </Tooltip>
                            </td>
                            <td>{item.quantidadeAcessorios}</td>
                            <td>{item.inspetor}</td>
                            <td>{item.responsavelTecnico}</td>
                            <td>{item.art}</td>
                            <td>{item.razaoSocialInguanti}</td>
                            <td style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: '1.7em' }}>
                                    <Form.Check
                                        type="checkbox"
                                        id={`check-api-${index}`}
                                        label=""
                                        checked={item.finalizado}
                                        readOnly
                                        style={{ paddingLeft: '75%' }}
                                    />
                                </div>
                            </td>
                            <td>{item.cadastradoPor}</td>
                            <td>{item.dataCadastro}</td>
                            <td>{item.alteradoPor}</td>
                            <td>{item.dataAlteracao}</td>
                        </tr>
                    ))}
                </tbody>


            </Table>
        </div>
    );
};

export default TableOrdemServico;
