import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import axios from '../../Config/axiosConfig';
import './DownloadImages.css';

const DownloadImages = ({ caminhoImage, style, api, name, value }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [isZoomed, setIsZoomed] = useState(false); // Estado para controlar o zoom
    const [copied, setCopied] = useState(false);

    const fetchImage = async (imagePath) => {
        try {
            const response = await axios.post(`${api}`, { path: imagePath }, { responseType: 'arraybuffer' });
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    '',
                ),
            );
            setImageSrc(`data:${response.headers['content-type'].toLowerCase()};base64,${base64}`);
        } catch (error) {
            console.error("Erro ao buscar imagem:", error);
        }
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(value);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Esconde a mensagem após 2 segundos
        } catch (err) {
            console.error("Falha ao copiar: ", err);
        }
    };

    useEffect(() => {
        fetchImage(caminhoImage);
    }, [caminhoImage]);

    // Função para alternar o estado de zoom
    const toggleZoom = () => {
        setIsZoomed(!isZoomed);
    };

    return (
        <>
            {isZoomed && (
                <div className="image-zoom-overlay show-image-zoom-overlay" onClick={toggleZoom}>
                    <img src={imageSrc} alt={`Imagem ampliada de ${name}`} />
                    <div style={{ display: 'flex', color: 'white', justifyContent: 'center', alignItems: 'center' }}>
                        {value}
                        {value && ( // Verifica se value não está vazio
                            <button
                                onClick={(e) => { e.stopPropagation(); copyToClipboard(); }}
                                className='button-copy'>
                                C
                            </button>
                        )}
                        {copied && <span className='success-copied'>Copiado com sucesso!</span>}
                    </div>
                </div>
            )}
            <Tooltip title="Clique aqui para ampliar" arrow>
                <img
                    src={imageSrc}
                    alt={`Imagem de ${name}`}
                    style={style}
                    className="zoom-on-hover"
                    onClick={toggleZoom} // Adiciona a funcionalidade de zoom ao clicar
                />
            </Tooltip>
        </>
    );
};

export default DownloadImages;
