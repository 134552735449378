import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//import './CustomModal.css'; 

const CustomModal = ({
    show,
    onHide,
    title,
    children,
    onSave,
    size = null,
    saveButtonExist = true,
    saveButtonText = 'Salvar',
    cancelButtonText = 'Fechar',
}) => {
    return (
        <Modal show={show} onHide={onHide} backdrop="static" size={size}>
            <Modal.Header closeButton>
                <Modal.Title className="title-red-bold">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>{children}</Form>
            </Modal.Body>
            <Modal.Footer>
                {saveButtonExist &&
                    <Button onClick={onSave} variant="primary">
                        {saveButtonText}
                    </Button>
                }
                <Button variant="secondary" onClick={onHide}>
                    {cancelButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomModal;
