import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomTable from '../Shared/CustomJs/CustomTable';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora, renameFileWithTimestamp } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/ResponsavelTecnico`;

const ResponsavelTecnico = () => {

    const listOptions = [
        { value: "nome", label: "Responsável Técnico" },
        { value: "crea", label: "Crea" },
        { value: "caminhoAssinatura", label: "Assinatura" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
    ];

    const excludeColumns = ["idResponsavelTecnico", "ativo", "assinatura"]; // Colunas que você quer excluir da tabela

    const renderTableHeaders = () => {
        const emptyColumns = [<th key="empty1"></th>, <th key="empty2"></th>];

        const optionColumns = listOptions.map((option, index) => (
            <th key={index}>{option.label}</th>
        ));

        return [...emptyColumns, ...optionColumns];
    };

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [listResponsavelTecnicoData, setListResponsavelTecnicoData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    const [idResponsavelTecnico, setIdResponsavelTecnico] = useState(null);

    //Add
    const [newResponsavel, setNewResponsavelTecnico] = useState('');
    const [newCrea, setNewCrea] = useState('');
    const [newAssinatura, setNewAssinatura] = useState('');

    //Edit    
    const [editResponsavel, setEditResponsavelTecnico] = useState(null);
    const [editCrea, setEditCrea] = useState(null);

    const [modalMessage, setModalMessage] = useState('');

    const fileInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("nome");

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitialData();
        }
    }, []);


    const fetchInitialData = async () => {
        setLoading(true);
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-responsaveis-tecnicos`);
            const formattedData = formatarDataComHora(response.data);
            setListResponsavelTecnicoData(formattedData);
            setConstListData(formattedData);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
        setLoading(false);
    };

    const handleSave = async (nome, crea) => {
        if (
            !nome?.trim() ||
            !crea?.trim()
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        const itemExists = listResponsavelTecnicoData.some((item) => item.crea && item.crea === crea);

        if (itemExists) {
            setModalMessage(`Registro com CREA: ${crea} já existe`);
        }
        else {
            setLoading(true);
            try {

                const originalFile = fileInputRef.current.files[0];
                const newFile = renameFileWithTimestamp(originalFile);

                const formData = new FormData();
                formData.append("Nome", nome);
                formData.append("Crea", crea);
                formData.append("Assinatura", newFile);

                const response = await axios.post(`${baseApi}/add-responsavel-tecnico`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response?.status === 200) {
                    setModalMessage(`${response?.data?.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setEditResponsavelTecnico(null);
                        setNewResponsavelTecnico('');
                        setEditCrea(null);
                        setNewCrea('');
                        fetchInitialData();
                    }

                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao adicionar o responsável técnico:', response?.data?.message);
                    setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao adicionar o responsável técnico:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleUpdate = async (nome, crea) => {

        if (
            !nome?.trim() ||
            !crea?.trim()
        ) {
            setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        const itemExists = listResponsavelTecnicoData.some((item) => item.idResponsavelTecnico !== idResponsavelTecnico && item.crea && item.crea === crea);

        if (itemExists) {
            setModalMessage(`Registro com CREA: ${crea} já existe`);
        }
        else {
            setLoading(true);
            try {
                const originalFile = fileInputRef.current.files[0];
                const newFile = renameFileWithTimestamp(originalFile);

                const formData = new FormData();
                formData.append("Nome", nome);
                formData.append("Crea", crea);
                formData.append("Assinatura", newFile);

                const response = await axios.put(`${baseApi}/update-responsavel-tecnico/${idResponsavelTecnico}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response?.status === 200) {

                    setModalMessage(`${response?.data?.message}`);
                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setEditResponsavelTecnico(null);
                        setNewResponsavelTecnico('');
                        setEditCrea(null);
                        setNewCrea('');
                        setShowModal(false);
                        Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');

                        setTimeout(() => {
                            fetchInitialData();
                        }, 50);
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao editar o responsável técnico:', response?.data?.message);
                    setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao editar o responsável técnico:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleDelete = async (item) => {
        setIdResponsavelTecnico(item.idResponsavelTecnico);
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const response = await axios.delete(`${baseApi}/delete-responsavel-tecnico/${item.idResponsavelTecnico}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData();
                        });
                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleResponsavelTecnicoChange = (event) => {
        isUpdate
            ? setEditResponsavelTecnico(event.target.value.toUpperCase())
            : setNewResponsavelTecnico(event.target.value.toUpperCase());
    };

    const handleCreaChange = (event) => {
        isUpdate
            ? setEditCrea(event.target.value.toUpperCase())
            : setNewCrea(event.target.value.toUpperCase());
    };

    const handleFileChange = (event) => {
        setNewAssinatura(event.target.value.toLowerCase());
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredInspetorData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredInspetorData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredInspetorData.length / itemsPerPage);
    const totalRecords = filteredInspetorData.length;

    const openModalAdd = () => {
        setModalMessage('');
        setNewResponsavelTecnico('');
        setNewCrea('');
        setEditResponsavelTecnico(null);
        setEditCrea(null);
        setIsUpdate(false);
        setShowModal(true);
    };
    const openModalEdit = (item) => {
        setModalMessage('');
        setIdResponsavelTecnico(item.idResponsavelTecnico);
        setEditResponsavelTecnico(item.nome);
        setEditCrea(item.crea);
        setNewResponsavelTecnico(null);
        setNewCrea(null);
        setIsUpdate(true);
        setShowModal(true);
    };
    const closeModal = () => {
        setModalMessage('');
        setNewResponsavelTecnico('');
        setNewCrea('');
        setEditResponsavelTecnico(null);
        setEditCrea(null);
        setIdResponsavelTecnico(null);
        setIsUpdate(false);
        setShowModal(false);
    };

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>Responsável Técnico</h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>Responsável Técnico</h4></Card.Header>
                            <Card.Body>

                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    openModal={openModalAdd}
                                    fetchInitialData={fetchInitialData}
                                />
                                <CustomTable
                                    renderTableHeaders={renderTableHeaders}
                                    data={paginatedData}
                                    handleEdit={openModalEdit}
                                    handleDelete={handleDelete}
                                    excludeColumns={excludeColumns}
                                    api={`${baseApi}/download-assinatura`}
                                    name={'responsavel'}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>


            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'Responsável Técnico - Editar' : 'Responsável Técnico - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(editResponsavel, editCrea)
                    : () => handleSave(newResponsavel, newCrea)}
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
            >
                <Form.Group controlId="responsavel">
                    <Form.Label className="bold-label">*Nome:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o nome do responsável técnico"
                        maxLength="100"
                        autoFocus
                        value={isUpdate
                            ? editResponsavel
                            : newResponsavel}
                        onChange={handleResponsavelTecnicoChange}
                    />
                </Form.Group>

                <Form.Group controlId="crea">
                    <Form.Label className="bold-label mt-3">*CREA:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite o crea"
                        maxLength="20"
                        value={isUpdate
                            ? editCrea
                            : newCrea}
                        onChange={handleCreaChange}
                    />
                </Form.Group>

                <Form.Group controlId="assinatura">
                    <Form.Label className="bold-label mt-3">*Assinatura do Técnico:</Form.Label>
                    <Form.Control
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </Form.Group>


                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>
        </Container>
    )
};

export default ResponsavelTecnico;
