import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";

const Notas = ({
    textNotas,
    textNotaUm,
    textNotaDois,
    textReferencias
}) => (
    <>
        <View style={[styles.rectangleNotas]}>
            <Text style={[styles.textNotas, { marginTop: 2 }]}>
                {textNotas}
            </Text>
            <Text style={[styles.textNotas]}>
                {textNotaUm}
            </Text>
            <Text style={[styles.textNotas]}>
                {textNotaDois}
            </Text>
            <Text style={[styles.textNotas, { marginBottom: 5 }]}>
                {textReferencias}
            </Text>
        </View>
    </>
);

export default Notas;
