import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import Assinatura from '../Downloads/DownloadImages';
import Tooltip from '@mui/material/Tooltip';
import { BsTrash, BsPencil } from 'react-icons/bs';
import './CustomTable.css';

const CustomTable = ({ renderTableHeaders, data, handleEdit, handleDelete, excludeColumns = [], api = '', name = '' }) => {
    return (
        <div className="table-responsive custom-div-table">
            <Table responsive striped bordered hover className="table-sm custom-table" >
                <thead>
                    <tr style={{ fontSize: "14px", color: "#236CB3" }}>{renderTableHeaders()}</tr>
                </thead>
                <tbody>
                    {data &&
                        data.length > 0 &&
                        data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{ width: '1vh' }}>
                                        <Tooltip title="Editar" arrow>
                                            <Button
                                                size="sm"
                                                onClick={() => handleEdit(item)}
                                                className="custom-edit-button"
                                            >
                                                <BsPencil className="custom-icon-trash-pencil" />
                                            </Button>
                                        </Tooltip>

                                    </td>
                                    <td style={{ width: '1vh' }}>
                                        <Tooltip title="Deletar" arrow>
                                            <Button
                                                size="sm"
                                                onClick={() => handleDelete(item)}
                                                className="custom-delete-button"
                                            >
                                                <BsTrash className="custom-icon-trash-pencil" />
                                            </Button>
                                        </Tooltip>
                                    </td>

                                    {
                                        Object.entries(item)
                                            .filter(([key]) => !excludeColumns.includes(key))
                                            .map(([key, value], i) => {
                                                if (key === "caminhoAssinatura") {
                                                    return (
                                                        <td key={i}>
                                                            <Assinatura caminhoImage={value} style={{ width: '100px', height: '50px', objectFit: 'contain', cursor: 'pointer' }} api={api} name={name} />
                                                        </td>
                                                    );
                                                }
                                                else if (key === "finalizado") {
                                                    return (
                                                        <td key={i} style={{ textAlign: 'center' }}>
                                                            <div style={{ fontSize: '1.7em' }}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={`check-api-${i}`}
                                                                    label=""
                                                                    checked={value}
                                                                    readOnly
                                                                    style={{ paddingLeft: '65%' }}
                                                                />
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                                else if (key === "response") {
                                                    return <td key={i}>{JSON.stringify(value)}</td>;
                                                }
                                                else {
                                                    return <td key={i}>{value}</td>;
                                                }
                                            })
                                    }
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};

export default CustomTable;
