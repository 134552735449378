import React, { useState } from 'react';
import { useAuth } from '../Context/Auth';
import { useNavigate } from 'react-router-dom';
import axios from '../Config/axiosConfig';
import Loading from '../Shared/Loading/Loading';
import Swal from 'sweetalert2';
import './Login.css';
import { getTypeSystem } from './../Shared/Utils/helper';

const baseApiUrl = process.env.REACT_APP_API_URL;

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const auth = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username || !password) {
            setErrorMessage('Usuário e senha são obrigatórios');
            return;
        }

        setLoading(true);

        try {

            const response = await axios.post(`${baseApiUrl}/api/Account/login`, {
                userName: username,
                password: password,
                System: getTypeSystem()
            });

            if (response.data) {
                const user = {
                    token: response.data.accessToken,
                    userName: username,
                    roles: response.data.roles,
                    isClient: response.data.isClient
                }
                const isClient = response?.data?.isClient;
                
                localStorage.setItem('isClient', isClient);
                localStorage.setItem('user', JSON.stringify(user));

                // Use a função setCurrentUser do contexto Auth para definir o usuário atual
                auth.setCurrentUser(user);

                const agora = new Date();
                const dataFormatada = `${agora.getDate()}/${agora.getMonth() + 1}/${agora.getFullYear()} ${agora.getHours()}:${agora.getMinutes()}:${agora.getSeconds()}`;
                console.log(`Login bem sucedido! - ${dataFormatada}`);

                navigate(isClient ? '/areacliente' : '/');
            }
        } catch (error) {
            // handle error
            console.log(error);
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.headers);
                Swal.fire('Erro', `O usuário ou a senha está incorreta.`, 'error');
                //setErrorMessage('Usuário ou senha incorreto.');
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }
        setLoading(false);
    }



    if (loading) {
        return <Loading />;
    }
    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <LoginHeader />
                <InputField
                    id="txtUsuario"
                    type="text"
                    name="username"
                    placeholder="Usuário"
                    maxLength="30"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <InputField
                    id="txtSenha"
                    type="password"
                    name="password"
                    placeholder="Senha"
                    maxLength="30"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <SubmitButton text="Entrar no portal" />
                <div className="login-footer">
                    {/* Links para esqueci minha senha, etc. */}
                </div>
                <div>
                    {errorMessage && <p>{errorMessage}</p>}
                </div>
            </form>
        </div>
    );
}

const LoginHeader = () => (
    <div className="login-header">
        <CornerImage />
        <LogoImage />
        <Separator />
        <WelcomeMessage />
    </div>
);

const CornerImage = () => (
    <div className="corner-image-container">
        <div className="corner-text">Conheça nosso site:</div>
        <a href="https://www.inguanti.com.br">
            <img
                src={`${process.env.PUBLIC_URL}/img/png/logo-inguanti_new.png`}
                alt="INGUANTI ENGENHARIA"
            />
        </a>
    </div>
);


const LogoImage = () => (
    <div className="logo-container">
        <img
            src={`${process.env.PUBLIC_URL}/img/png/Inguanti_Inspecoes_new_175x87.png`}
            alt="INGUANTI INSPEÇÕES"
        />
    </div>
);

const Separator = () => <div className="separator"></div>;

const WelcomeMessage = () => (
    <div className="welcome-message">
        Bem-vindo(a) ao <span className="inspweb">INSPWEB</span>, seu portal das inspeções.
    </div>
);

const InputField = ({ id, type, name, placeholder, maxLength, value, onChange }) => (
    <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        className="input-field"
        autoComplete="off"
        required
    />
);

const SubmitButton = ({ text }) => (
    <button type="submit" className="submit-button">{text}</button>
);


export default Login;

