import React from "react";
import { Table, Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { BsTrash, BsPencil } from 'react-icons/bs';
import { BiKey } from 'react-icons/bi';
import './CustomTable.css';

const TableUser = ({ data, handleEdit, handleDelete, handlePermission, handleUpdateAtivo }) => {
    return (
        <div className="table-responsive custom-div-table">
            <Table responsive striped bordered hover className="table-sm custom-table">
                <thead>
                    <tr style={{ fontSize: "14px", color: "#236CB3" }}>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Usuário</th>
                        <th>Nome Completo</th>
                        <th>Cadastrado Por</th>
                        <th>Data Cadastro</th>
                        <th>Alterado Por</th>
                        <th>Data Alteração</th>
                        <th>Ativo</th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.length > 0 &&
                        data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td style={{ width: '1vh' }}>
                                        <Tooltip title="Editar" arrow>
                                            <Button
                                                size="sm"
                                                onClick={() => handleEdit(item)}
                                                className="custom-edit-button"
                                            >
                                                <BsPencil className="custom-icon-trash-pencil" />
                                            </Button>
                                        </Tooltip>
                                    </td>
                                    <td style={{ width: '1vh' }}>
                                        <Tooltip title="Deletar" arrow>
                                            <Button
                                                size="sm"
                                                onClick={() => handleDelete(item)}
                                                className="custom-delete-button"
                                            >
                                                <BsTrash className="custom-icon-trash-pencil" />
                                            </Button>
                                        </Tooltip>
                                    </td>
                                    <td style={{ width: '1vh' }}>
                                        <Tooltip title="Permissões" arrow>
                                            <Button
                                                size="sm"
                                                onClick={() => handlePermission(item)}
                                                className="custom-permission-button"
                                            >
                                                <BiKey className="custom-icon-key" />
                                            </Button>
                                        </Tooltip>
                                    </td>

                                    <td>{item.userName}</td>
                                    <td>{item.fullName}</td>
                                    <td>{item.cadastradoPor}</td>
                                    <td>{item.dataCadastro}</td>
                                    <td>{item.alteradoPor}</td>
                                    <td>{item.dataAlteracao}</td>
                                    <td><input
                                        type="checkbox"
                                        checked={item.ativo}
                                        onChange={(e) => handleUpdateAtivo(item.id, e.target.checked)}
                                        readOnly
                                    />
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};

export default TableUser;
