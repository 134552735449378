import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import axios from '../Config/axiosConfig';
import "./Dashboard.css";

//Compartilhados
import Loading from '../Shared/Loading/Loading';
import './../Shared/sharedStyles.css';

//CHARTS
import LineChartInspecoesTodosMeses from '../Shared/Charts/LineChartInspecoesTodosMeses';
import StatisticsCards from '../Shared/Charts/StatisticsCards';
import ResultadoPieChart from '../Shared/Charts/ResultadoPieChart';
import InspetorChart from '../Shared/Charts/InspetorChart';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [inspecoesPorMesDataCurrentYear, setInspecoesPorMesDataCurrentYear] = useState([]);
  const [inspecoesPorMesDataLastYear, setInspecoesPorMesDataLastYear] = useState([]);
  const [inspecoesPorMesDataPenultimateYear, setInspecoesPorMesDataPenultimateYear] = useState([]);

  const baseApiUrl = process.env.REACT_APP_API_URL;
  const baseApi = `${baseApiUrl}/api/Dashboard`;

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [lastYear, setLastYear] = useState(currentYear - 1);
  const [penultimateYear, setPenultimateYear] = useState(currentYear - 2);

  const [dataStatisticsCards, setDataStatisticsCards] = useState({ TotalLaudos: 0, TotalOs: 0, TotalClientes: 0 });
  const [dataPieChart, setDataPieChart] = useState({ TotalGeralAprovados: 0, TotalReprovado: 0, TotalAprovadoRecomendacoes: 0 });
  const [dataInspecoesChartBar, setDataInspecoesChartBar] = useState({ Inspetor: '', QuantidadeInspecoes: 0 });

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchInitialData();
    }
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const obterInspecoesTodosMesesPorAno = 'obter-inspecoes-todos-meses-por-ano'
      let date = { Ano: currentYear };
      let response = await axios.post(`${baseApi}/${obterInspecoesTodosMesesPorAno}`, date);
      setInspecoesPorMesDataCurrentYear(response.data);

      date = { Ano: lastYear };
      response = await axios.post(`${baseApi}/${obterInspecoesTodosMesesPorAno}`, date);
      setInspecoesPorMesDataLastYear(response.data);

      date = { Ano: penultimateYear };
      response = await axios.post(`${baseApi}/${obterInspecoesTodosMesesPorAno}`, date);
      setInspecoesPorMesDataPenultimateYear(response.data);

      const responseStatisticsCards = await axios.get(`${baseApi}/obter-total-laudos-os-clientes`);    
      setDataStatisticsCards(responseStatisticsCards.data);

      const responseResultadoPieChart = await axios.get(`${baseApi}/obter-total-conclusao-tecnica`);  
      setDataPieChart(responseResultadoPieChart.data);


      const responseResultadosetInspecoesChartBar = await axios.get(`${baseApi}/obter-total-inspecoes-inspetor`);  
      setDataInspecoesChartBar(responseResultadosetInspecoesChartBar.data);

    } catch (error) {
      console.log('Erro ao buscar dados:', error);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  if (inspecoesPorMesDataCurrentYear.length <= 0 && loading === false) {
    return (
        <Container>         
            <Row>
                <Col>
                    <Form>
                        <Row className='mt-3'>                          
                        </Row>                      
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

  return (
    <Container>

      <StatisticsCards data={dataStatisticsCards} />

      <LineChartInspecoesTodosMeses data={{
        currentYear: inspecoesPorMesDataCurrentYear,
        lastYear: inspecoesPorMesDataLastYear,
        penultimateYear: inspecoesPorMesDataPenultimateYear
      }}
        currentYear={currentYear}
        lastYear={lastYear}
        penultimateYear={penultimateYear}
      />

      <ResultadoPieChart dataPieChart={dataPieChart} />
      <InspetorChart dataInspecoes={dataInspecoesChartBar} />

    </Container>
  );

};

export default Dashboard;
