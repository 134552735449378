import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';
import { FaSearch, FaList, FaWhatsapp } from 'react-icons/fa';
import { AiOutlineClear } from "react-icons/ai";
import Tooltip from '@mui/material/Tooltip';
import './AreaCliente.css';


//Compartilhados
import { TableAreaCliente, SimpleTable } from '../Shared/CustomJs/TableAreaCliente';
import TableDocumentosComplementares from '../Shared/CustomJs/TableDocumentosComplementares';
import TableAcessoriosCliente from '../Shared/CustomJs/TableAcessoriosCliente';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

import './../Shared/sharedStyles.css';
import { formatarDataComHora, formatCompleteDate } from './../Shared/Utils/helper';
import { CheckBox } from '@mui/icons-material';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/AreaCliente`;
const baseApiSession = `${baseApiUrl}/api/Session`;

const AreaCliente = () => {

    const [loading, setLoading] = useState(true);
    const [isLoadingTable, setIsLoadingTable] = useState(false);

    const itemsPerPage = 1000; // Número de itens por página

    const [totalRecords, setTotalRecords] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const [listData, setListData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    const [isOpenDocumentosComplementares, setIsOpenDocumentosComplementares] = useState(true);
    const [isOpenLaudo, setIsOpenLaudo] = useState(true);
    const [isOpenAcessorios, setIsOpenAcessorios] = useState(true);

    const [listDataDocumentosComplemetares, setListDataDocumentosComplemetares] = useState(null);

    const resultados = [
        "SELECIONE",
        "APROVADO 12 MESES",
        "APROVADO 6 MESES",
        "APROVADO 3 MESES",
        "APROVADO/RECOMENDAÇÕES",
        "REPROVADO",
        "REPROVADO/RECOMENDAÇÕES"
    ];

    const [listDataAcessorios, setListDataAcessorios] = useState([]);

    const [empresa, setEmpresa] = useState(null);

    const currentYear = new Date().getFullYear();

    const copyrightStyle = {
        textAlign: 'center',
        fontSize: '11px',
        marginTop: '20px',
        marginBottom: '10px',
        whiteSpace: 'pre-line' // respeita as quebras de linha
    };

    const [timeoutPrint, setTimeoutPrint] = useState(2000)

    //Search
    const [searchResultado, setSearchResultado] = useState('SELECIONE');
    const [searchLaudo, setSearchLaudo] = useState('');
    const [searchSetor, setSearchSetor] = useState('');
    const [searchSubSetor, setSearchSubSetor] = useState('');
    const [searchMetodologiaInspecao, setSearchMetodologiaInspecao] = useState('');
    const [searchAcessorio, setSearchAcessorio] = useState('');
    const [searchTagInguanti, setSearchTagInguanti] = useState('');
    const [searchTagCliente, setSearchTagCliente] = useState('');
    const [searchRecomendacoes, setSearchRecomendacoes] = useState('');
    const [searchDataInicio, setSearchDataInicio] = useState('');
    const [searchDataFim, setSearchDataFim] = useState('');

    const whatsappNumber = "5511982832778";
    const whatsappLink = `https://wa.me/${whatsappNumber}`;

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const [isMaxHeightAppliedAcessorios, setIsMaxHeightAppliedAcessorios] = useState(true);
    const [isMaxHeightAppliedLaudos, setIsMaxHeightAppliedLaudos] = useState(true);


    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!initialized.current) {
                initialized.current = true;

                setLoading(true);
                const idOs = await fetchSessionId(1);
                await fetchAcessoriosClienteData();
                await fetchDocumentosComplementaresData(idOs);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchSessionId = async (pageNumber) => {
        try {
            const response = await axios.get(`${baseApiSession}/get-session-id`);
            if (response.data.idUser) {
                setIsUserLoggedIn(true);
                setIsMaxHeightAppliedAcessorios(true);
                setIsMaxHeightAppliedLaudos(true);
                return await fetchLaudosUserData(pageNumber);
            }
            else {
                setIsUserLoggedIn(false);
                setIsMaxHeightAppliedAcessorios(false);
                setIsMaxHeightAppliedLaudos(false);

            }
            if (response.data.idCliente) {
                return await fetchLaudosClienteData(pageNumber);
            }
        } catch (error) {
            console.log('Erro ao buscar ID da sessão:', error);
        }
        return null;
    };

    const fetchLaudosClienteData = async (pageNumber) => {

        const inicio = (pageNumber - 1) * itemsPerPage;
        const quantidade = itemsPerPage;

        setIsLoadingTable(true);
        try {
            const response = await axios.get(`${baseApi}/get-laudos-cliente/${inicio}/${quantidade}`);
            const formattedData = formatarDataComHora(response.data.items);
            setListData(formattedData);
            setConstListData(formattedData);
            setTotalRecords(response.data.totalRecords);
            setEmpresa(response?.data.items[0]?.empresa);
            setIsLoadingTable(false);
        } catch (error) {
            console.log('Erro ao buscar dados:', error);
            setIsLoadingTable(false);
        }
        return Promise.resolve();  // Retorne uma promessa resolvida    
    };

    const fetchLaudosUserData = async (pageNumber) => {
        const inicio = (pageNumber - 1) * itemsPerPage;
        const quantidade = itemsPerPage;
        setIsLoadingTable(true);
        try {
            const response = await axios.get(`${baseApi}/get-laudos-user/${inicio}/${quantidade}`);          
            const formattedData = formatarDataComHora(response.data.items);
            setListData(formattedData);
            setConstListData(formattedData);
            setTotalRecords(response.data.totalRecords);
            setEmpresa("Todas Empresas");
            setIsLoadingTable(false);
            return response?.data.items[0]?.idOrdemServico;
        } catch (error) {
            console.log('Erro ao buscar dados:', error);
            setIsLoadingTable(false);
        }
        return Promise.resolve();  // Retorne uma promessa resolvida
    };

    const fetchAcessoriosClienteData = async () => {

        try {
            const response = await axios.get(`${baseApi}/get-acessorios`);
            setListDataAcessorios(response.data);
        } catch (error) {
            console.log('Erro ao buscar dados:', error);
        }
    };

    const fetchDocumentosComplementaresData = async (idOrdemServico) => {
        if (!idOrdemServico)
            return;
        try {
            const response = await axios.get(`${baseApi}/get-doc_os/${idOrdemServico}`);
            setListDataDocumentosComplemetares(response.data);
        } catch (error) {
            console.log('Erro ao buscar dados:', error);
        }
    };

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        await fetchSessionId(pageNumber);
    };

    const handleSearchClick = async (event) => {
        event.preventDefault();
        try {
            setIsLoadingTable(true);
            let resultado = searchResultado;

            if (searchResultado == '' || searchResultado == 'SELECIONE') {
                resultado = null;
            }

            const laudo = searchLaudo !== '' ? searchLaudo : null;
            const setor = searchSetor !== '' ? searchSetor : null;
            const subSetor = searchSubSetor !== '' ? searchSubSetor : null;
            const metodologiaInspecao = searchMetodologiaInspecao !== '' ? searchMetodologiaInspecao : null;
            const acessorio = searchAcessorio !== '' ? searchAcessorio : null;
            const tagInguanti = searchTagInguanti !== '' ? searchTagInguanti : null;
            const tagCliente = searchTagCliente !== '' ? searchTagCliente : null;
            const recomendacoes = searchRecomendacoes !== '' ? searchRecomendacoes : null;
            const dataInicio = searchDataInicio !== '' ? searchDataInicio : null;
            const dataFim = searchDataFim !== '' ? searchDataFim : null;

            const filterData = {
                AreaCliente: {
                    ConclusaoTecnica: resultado,
                    Laudo: laudo,
                    Setor: setor,
                    SubSetor: subSetor,
                    MetodologiaInspecao: metodologiaInspecao,
                    Acessorio: acessorio,
                    TagInguanti: tagInguanti,
                    TagCliente: tagCliente,
                    Recomendacoes: recomendacoes,
                    DataInicio: dataInicio,
                    DataFim: dataFim,
                },
                Inicio: 0,
                Quantidade: 1000
            }
            const response = await axios.post(`${baseApi}/get-laudos-filter`, filterData);
            const formattedData = formatarDataComHora(response.data.items);
            setListData(formattedData);
            setConstListData(formattedData);
            setTotalRecords(response.data.totalRecords);

            const responseAcessorios = await axios.post(`${baseApi}/get-acessorios-filter`, filterData);
            setListDataAcessorios(responseAcessorios.data);

            setIsLoadingTable(false);

        } catch (error) {

        }
    };

    const handleList = async () => {
        await fetchSessionId(currentPage);
        await handleClearFields();
    };

    const handleDownloadPdf = async (filename, idLaudo) => {
        try {
            let cleanedFilePdf = filename.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");

            const response = await axios.get(`${baseApi}/download-pdf/${idLaudo}/${cleanedFilePdf}.pdf`, {
                responseType: 'blob',
            });

            if (response?.status === 200) {

                if (response?.data) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const urlPdf = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = urlPdf;
                    link.download = `${cleanedFilePdf}.pdf`; // Força o download com o nome do arquivo
                    link.target = '_blank'; // Tenta abrir em uma nova aba
                    link.click();

                    // Libera a URL do objeto Blob após o download.
                    URL.revokeObjectURL(urlPdf);
                }
                else {
                    Swal.fire({
                        title: 'Download PDF',
                        text: 'Usuário não autorizado ou arquivo não encontrado.',
                        icon: 'error',
                    })
                }
            }
            else {
                Swal.fire({
                    title: 'Download PDF',
                    text: 'Usuário não autorizado ou arquivo não encontrado.',
                    icon: 'error',
                })
            }
        } catch (error) {
            Swal.fire({
                title: 'Download PDF',
                text: 'Usuário não autorizado ou arquivo não encontrado.',
                icon: 'error',
            })
        }
    };

    const handleDownloadPdfDocOs = async (filename, idDocOs) => {
        try {
            let cleanedFilePdf = filename.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");

            const response = await axios.get(`${baseApi}/download-pdf-doc-os/${idDocOs}/${cleanedFilePdf}.pdf`, {
                responseType: 'blob', // Mudança para 'blob' aqui
            });

            if (response?.data) {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const urlPdf = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = urlPdf;
                link.download = `${cleanedFilePdf}.pdf`; // Força o download com o nome do arquivo
                link.target = '_blank'; // Tenta abrir em uma nova aba
                link.click();

                // Libera a URL do objeto Blob após o download.
                URL.revokeObjectURL(urlPdf);
            }
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };

    const handleDownloadMergePdf = async () => {
        try {
            setLoading(true);

            if (listData.length > 200) {
                setLoading(false);
                Swal.fire({
                    title: 'Download PDF',
                    text: 'Quantidade máxima permitida para gerar pdf é de 200 laudos',
                    icon: 'error',
                })
                return;
            }

            const listFileNames = listData.map(item => item.laudo);
            const listIdLaudos = listData.map(item => item.idLaudo);

            // Removendo caracteres especiais e espaços do nome do arquivo
            const cleanedListFileNames = listFileNames.map(p =>
                `${p.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "")}.pdf`
            );

            const requestBody = {
                ListIdLaudos: listIdLaudos,
                ListFileNames: cleanedListFileNames
            };

            // Fazendo uma chamada POST com Axios
            const response = await axios.post(`${baseApi}/download-mergepdfs`, requestBody, {
                responseType: 'blob', // Importante para tratar a resposta como um arquivo binário
            });

            if (response?.status === 200) {

                if (response?.data) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const urlPdf = URL.createObjectURL(blob);

                    const formattedDate = formatCompleteDate(new Date());

                    const link = document.createElement('a');
                    link.href = urlPdf;
                    link.download = `Laudos_${formattedDate}-U.pdf`; // Força o download com o nome do arquivo
                    link.target = '_blank'; // Tenta abrir em uma nova aba
                    link.click();

                    // Libera a URL do objeto Blob após o download.
                    URL.revokeObjectURL(urlPdf);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    Swal.fire({
                        title: 'Download PDF',
                        text: 'Usuário não autorizado ou arquivo não encontrado.',
                        icon: 'error',
                    })
                }
            }
            else {
                setLoading(false);

                Swal.fire({
                    title: 'Download PDF',
                    text: 'Usuário não autorizado ou arquivo não encontrado.',
                    icon: 'error',
                })
            }
        } catch (error) {
            setLoading(false);

            Swal.fire({
                title: 'Download PDF',
                text: 'Usuário não autorizado ou arquivo não encontrado.',
                icon: 'error',
            })
        }
    };

    const handleDownloadLotePdfsIndividual = async () => {
        try {
            setLoading(true);

            if (listData.length > 200) {
                setLoading(false);
                Swal.fire({
                    title: 'Download PDF',
                    text: 'Quantidade máxima permitida para gerar pdf é de 200 laudos',
                    icon: 'error',
                })
                return;
            }

            const listFileNames = listData.map(item => item.laudo);
            const listIdLaudos = listData.map(item => item.idLaudo);

            // Removendo caracteres especiais e espaços do nome do arquivo
            const cleanedListFileNames = listFileNames.map(p =>
                `${p.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "")}.pdf`
            );

            const requestBody = {
                ListIdLaudos: listIdLaudos,
                ListFileNames: cleanedListFileNames
            };

            // Fazendo uma chamada POST com Axios
            const response = await axios.post(`${baseApi}/download-lote-pdfs-individual`, requestBody, {
                responseType: 'blob', // Importante para tratar a resposta como um arquivo binário
            });

            if (response?.status === 200) {

                if (response?.data) {
                    const blob = new Blob([response.data], { type: 'application/zip' });
                    const urlPdf = URL.createObjectURL(blob);

                    const formattedDate = formatCompleteDate(new Date());

                    const link = document.createElement('a');
                    link.href = urlPdf;
                    link.download = `Laudos_${formattedDate}-I.zip`; // Força o download com o nome do arquivo
                    link.target = '_blank'; // Tenta abrir em uma nova aba
                    link.click();

                    // Libera a URL do objeto Blob após o download.
                    URL.revokeObjectURL(urlPdf);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    Swal.fire({
                        title: 'Download PDF',
                        text: 'Usuário não autorizado ou arquivo não encontrado.',
                        icon: 'error',
                    })
                }
            }
            else {
                setLoading(false);

                Swal.fire({
                    title: 'Download PDF',
                    text: 'Usuário não autorizado ou arquivo não encontrado.',
                    icon: 'error',
                })
            }
        } catch (error) {
            setLoading(false);

            Swal.fire({
                title: 'Download PDF',
                text: 'Usuário não autorizado ou arquivo não encontrado.',
                icon: 'error',
            })
        }
    };

    const handleDownloadLotePdfsIndividualDocCompl = async () => {
        try {
            setLoading(true);

            if (listData.length > 200) {
                setLoading(false);
                Swal.fire({
                    title: 'Download PDF',
                    text: 'Quantidade máxima permitida para gerar pdf é de 200 laudos',
                    icon: 'error',
                })
                return;
            };

            const listFileNames = listDataDocumentosComplemetares.map(item => item.caminhoDocumento);
            const listIdDocOs = listDataDocumentosComplemetares.map(item => item.idDocOs);

            // Removendo caracteres especiais e espaços do nome do arquivo
            const cleanedListFileNames = listFileNames.map(p =>
                `${p.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "")}.pdf`
            );

            const requestBody = {
                ListIdDocOs: listIdDocOs,
                ListFileNames: cleanedListFileNames
            };

            // Fazendo uma chamada POST com Axios
            const response = await axios.post(`${baseApi}/download-lote-docs-individual`, requestBody, {
                responseType: 'blob', // Importante para tratar a resposta como um arquivo binário
            });

            if (response?.status === 200) {

                if (response?.data) {
                    const blob = new Blob([response.data], { type: 'application/zip' });
                    const urlPdf = URL.createObjectURL(blob);

                    const formattedDate = formatCompleteDate(new Date());

                    const link = document.createElement('a');
                    link.href = urlPdf;
                    link.download = `DOCS_${formattedDate}-D.zip`; // Força o download com o nome do arquivo
                    link.target = '_blank'; // Tenta abrir em uma nova aba
                    link.click();

                    // Libera a URL do objeto Blob após o download.
                    URL.revokeObjectURL(urlPdf);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    Swal.fire({
                        title: 'Download PDF',
                        text: 'Usuário não autorizado ou arquivo não encontrado.',
                        icon: 'error',
                    })
                }
            }
            else {
                setLoading(false);

                Swal.fire({
                    title: 'Download PDF',
                    text: 'Usuário não autorizado ou arquivo não encontrado.',
                    icon: 'error',
                })
            }
        } catch (error) {
            setLoading(false);

            Swal.fire({
                title: 'Download PDF',
                text: 'Usuário não autorizado ou arquivo não encontrado.',
                icon: 'error',
            })
        }
    };

    const handleDownloadMergePdfDocCompl = async () => {
        try {
            setLoading(true);

            if (listData.length > 200) {
                setLoading(false);
                Swal.fire({
                    title: 'Download PDF',
                    text: 'Quantidade máxima permitida para gerar pdf é de 200 laudos',
                    icon: 'error',
                })
                return;
            };

            const listFileNames = listDataDocumentosComplemetares.map(item => item.caminhoDocumento);
            const listIdDocOs = listDataDocumentosComplemetares.map(item => item.idDocOs);

            // Removendo caracteres especiais e espaços do nome do arquivo
            const cleanedListFileNames = listFileNames.map(p =>
                `${p.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "")}.pdf`
            );

            const requestBody = {
                ListIdDocOs: listIdDocOs,
                ListFileNames: cleanedListFileNames
            };

            // Fazendo uma chamada POST com Axios
            const response = await axios.post(`${baseApi}/download-mergepdfs`, requestBody, {
                responseType: 'blob', // Importante para tratar a resposta como um arquivo binário
            });

            if (response?.status === 200) {

                if (response?.data) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const urlPdf = URL.createObjectURL(blob);

                    const formattedDate = formatCompleteDate(new Date());

                    const link = document.createElement('a');
                    link.href = urlPdf;
                    link.download = `DOCS_${formattedDate}-D.pdf`; // Força o download com o nome do arquivo
                    link.target = '_blank'; // Tenta abrir em uma nova aba
                    link.click();

                    // Libera a URL do objeto Blob após o download.
                    URL.revokeObjectURL(urlPdf);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                    Swal.fire({
                        title: 'Download PDF',
                        text: 'Usuário não autorizado ou arquivo não encontrado.',
                        icon: 'error',
                    })
                }
            }
            else {
                setLoading(false);

                Swal.fire({
                    title: 'Download PDF',
                    text: 'Usuário não autorizado ou arquivo não encontrado.',
                    icon: 'error',
                })
            }
        } catch (error) {
            setLoading(false);

            Swal.fire({
                title: 'Download PDF',
                text: 'Usuário não autorizado ou arquivo não encontrado.',
                icon: 'error',
            })
        }
    };

    const handleResultadoChange = (event) => {
        setSearchResultado(event.target.value);
    };
    const handleLaudoChange = (event) => {
        setSearchLaudo(event.target.value.toUpperCase());
    };
    const handleSetorChange = (event) => {
        setSearchSetor(event.target.value.toUpperCase());
    };
    const handleSubSetorChange = (event) => {
        setSearchSubSetor(event.target.value.toUpperCase());
    };
    const handleMetodologiaInspecaoChange = (event) => {
        setSearchMetodologiaInspecao(event.target.value.toUpperCase());
    };
    const handleAcessorioChange = (event) => {
        setSearchAcessorio(event.target.value.toUpperCase());
    };
    const handleTagInguantiChange = (event) => {
        setSearchTagInguanti(event.target.value.toUpperCase());
    };
    const handleTagClienteChange = (event) => {
        setSearchTagCliente(event.target.value.toUpperCase());
    };
    const handleRecomendacoesChange = (event) => {
        setSearchRecomendacoes(event.target.value.toUpperCase());
    };
    const handleDataInicioChange = (event) => {
        setSearchDataInicio(event.target.value);
    };
    const handleDataFimChange = (event) => {
        setSearchDataFim(event.target.value);
    };

    const handleClearFields = async () => {
        setSearchLaudo('');
        setSearchSetor('');
        setSearchSubSetor('');
        setSearchMetodologiaInspecao('');
        setSearchAcessorio('');
        setSearchTagInguanti('');
        setSearchTagCliente('');
        setSearchRecomendacoes('');
        setSearchDataInicio('');
        setSearchDataFim('');
        const valueDefault = "SELECIONE";
        setSearchResultado(valueDefault);

    }

    const handlePrintGeral = () => {
        // Elementos que podem ou não existir
        const printContentAcessorios = document.getElementById("printableAcessorios");
        const printContentDocComplementar = document.getElementById("printableDocComplementar");
        const printContentAreaCliente = document.getElementById("printableAreaCliente");

        // Verifica se o elemento obrigatório existe
        if (!printContentAreaCliente) {
            console.error('Elemento principal para impressão não encontrado');
            return;
        }

        // console.log(timeoutPrint);

        // Detecta o navegador
        const userAgent = window.navigator.userAgent;
        const isEdge = /Edge|Edg\//.test(userAgent);
        const isChrome = /Chrome\//.test(userAgent) && !isEdge; // Edge também inclui 'Chrome' na userAgent string
        const isFirefox = /Firefox\//.test(userAgent);

        //   const customStyles = `<link rel="stylesheet" type="text/css" href="AreaCliente.css">`;

        // Estilos personalizados para a tabela
        const customStyles = `<style>
    table {
      width: 100%;
      max-width: 95%;
      border-collapse: collapse; /* Colapsa as bordas para que cada célula compartilhe as bordas */
    }
    th, td {
      border: 1px solid #ddd; /* Adiciona borda para th e td */
      padding: 8px; /* Espaçamento interno */
      text-align: left; /* Alinha texto à esquerda; altere para center se desejar centralizar */
    }
    th {
      background-color: #c5c5c5; /* Cor de fundo para th */
      font-weight: bold; /* Deixa o texto em negrito */
      text-align: center; /* Centraliza o texto do th */
    }
    tr:nth-child(even) {
      background-color: #c5c5c5; /* Cor de fundo alternada para linhas pares */
    }
    .table-wrapper {
        margin-bottom: 20px;        
    }
    .no-print {
      display: none; /* Classe para ocultar elementos que não devem ser impressos */
    }
    body::after {
        content: "INGUANTI inspeções";
        color: rgba(0, 0, 0, 0.045); /* Torna a marca d'água menos visível (ajuste a opacidade conforme necessário) */
        font-size: 150px; /* Tamanho da fonte da marca d'água */
        font-weight: bold; /* Deixa o texto em negrito */
        position: fixed; /* Posicionamento fixo */
        top: 50%; /* Centraliza verticalmente */
        left: 50%; /* Centraliza horizontalmente */
        transform: translate(-50%, -50%) rotate(-45deg); /* Ajusta o posicionamento e inclina a marca d'água */
        z-index: 1000; /* Garante que a marca d'água fique atrás do conteúdo */
    }

  </style>`;

        const uniqueName = new Date();
        const windowName = 'Print' + uniqueName.getTime();

        // Tenta calcular o centro da tela
        const width = 800; // Largura da janela de impressão
        const height = 600; // Altura da janela de impressão
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);

        // Abre uma nova janela aproximadamente no centro da tela
        const printWindow = window.open('', windowName, `left=${left},top=${top},width=${width},height=${height},scrollbars=yes`);

        // Inicializa htmlContent
        let htmlContent = '';
        let htmlContentLaudo = '';

        // Verifica e adiciona a tabela de acessórios primeiro, se existir
        if (printContentAcessorios) {
            htmlContent += `<div class='table-wrapper'>${printContentAcessorios.outerHTML}</div>`;
        }

        // Verifica e adiciona a tabela de documentos complementares, se existir
        if (printContentDocComplementar) {
            htmlContent += `<div class='table-wrapper'>${printContentDocComplementar.outerHTML}</div>`;
        }
        if (isEdge) {
            // Sempre adiciona a tabela de laudos
            htmlContentLaudo = `<div class='table-wrapper' style='transform: scale(0.75); margin-left:-15%; width: auto;'>${printContentAreaCliente.outerHTML}</div>`;
        }
        else {
            // Sempre adiciona a tabela de laudos
            htmlContentLaudo = `<div class='table-wrapper'>${printContentAreaCliente.outerHTML}</div>`;
        }

        // Combina o conteúdo das três tabelas em uma única página para impressão
        printWindow.document.write(`
            <!DOCTYPE html>
            <html>
            <head>
                <title>Impressão de Relatório</title>
                ${customStyles}
            </head>
            <body>
                ${htmlContent} <!-- Conteúdo a ser impresso -->
                ${htmlContentLaudo} <!-- Conteúdo a ser impresso -->
            </body>     
            </html>
      `);

        printWindow.document.close();

        // Utiliza o evento onload para garantir que o conteúdo esteja carregado
        printWindow.onload = function () {
            setTimeout(() => { // Ajuste o delay conforme necessário
                printWindow.focus();
                printWindow.print();
                printWindow.close();
            }, timeoutPrint);
        };
    };

    const handlePrintLaudos = () => {
        const printContent = document.getElementById("printableAreaCliente");
        if (!printContent) {
            console.error('Elemento para impressão não encontrado');
            return;
        }

        // Detecta o navegador
        const userAgent = window.navigator.userAgent;
        const isEdge = /Edge|Edg\//.test(userAgent);
        const isChrome = /Chrome\//.test(userAgent) && !isEdge; // Edge também inclui 'Chrome' na userAgent string
        const isFirefox = /Firefox\//.test(userAgent);

        // Estilos padrão
        let browserSpecificStyles = `<style>
        html, body {
            width: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
            overflow: visible !important;
        }
         table {
           width: 100%;
           max-width: 100%;
           border-collapse: collapse; /* Colapsa as bordas para que cada célula compartilhe as bordas */
         }
         th, td {
           border: 1px solid #ddd; /* Adiciona borda para th e td */
           padding: 8px; /* Espaçamento interno */
           text-align: left; /* Alinha texto à esquerda; altere para center se desejar centralizar */
         }
         th {
           background-color: #c5c5c5; /* Cor de fundo para th */
           font-weight: bold; /* Deixa o texto em negrito */
           text-align: center; /* Centraliza o texto do th */
         }
         tr:nth-child(even) {
           background-color: #c5c5c5; /* Cor de fundo alternada para linhas pares */
         }
         .no-print {
           display: none; /* Classe para ocultar elementos que não devem ser impressos */
         }
         body::after {
             content: "INGUANTI inspeções";
             color: rgba(0, 0, 0, 0.045); /* Torna a marca d'água menos visível (ajuste a opacidade conforme necessário) */
             font-size: 150px; /* Tamanho da fonte da marca d'água */
             font-weight: bold; /* Deixa o texto em negrito */
             position: fixed; /* Posicionamento fixo */
             top: 50%; /* Centraliza verticalmente */
             left: 50%; /* Centraliza horizontalmente */
             transform: translate(-50%, -50%) rotate(-45deg); /* Ajusta o posicionamento e inclina a marca d'água */
             z-index: 1000; /* Garante que a marca d'água fique atrás do conteúdo */
         }
       </style>`;

        // Estilos adicionais para Edge
        const edgeStyles = isEdge ? `
        <style>
            html, body {
                width: 100% !important;
                margin: 0;
                padding: 0;
                overflow: visible !important;
            }
            table {
            width: auto !important;            
            border-collapimport MetodologiaInspecao from 'src\Components\MetodologiaInspecao';
se: collapse; /* Colapsa as bordas para que cada célula compartilhe as bordas */
            transform: scale(0.75);
            margin-left: -15%;
            }
            th, td {
            border: 1px solid #ddd; /* Adiciona borda para th e td */
            padding: 5px; /* Espaçamento interno */
            text-align: left; /* Alinha texto à esquerda; altere para center se desejar centralizar */
            }
            th {
            background-color: #c5c5c5; /* Cor de fundo para th */
            font-weight: bold; /* Deixa o texto em negrito */
            text-align: center; /* Centraliza o texto do th */
            }
            tr:nth-child(even) {
            background-color: #c5c5c5; /* Cor de fundo alternada para linhas pares */
            }
            .no-print {
            display: none; /* Classe para ocultar elementos que não devem ser impressos */
            }
            body::after {
                content: "INGUANTI inspeções";
                color: rgba(0, 0, 0, 0.045); /* Torna a marca d'água menos visível (ajuste a opacidade conforme necessário) */
                font-size: 150px; /* Tamanho da fonte da marca d'água */
                font-weight: bold; /* Deixa o texto em negrito */
                position: fixed; /* Posicionamento fixo */
                top: 50%; /* Centraliza verticalmente */
                left: 50%; /* Centraliza horizontalmente */
                transform: translate(-50%, -50%) rotate(-45deg); /* Ajusta o posicionamento e inclina a marca d'água */
                z-index: 1000; /* Garante que a marca d'água fique atrás do conteúdo */
            }
        </style>`: '';

        // Estilos adicionais para Edge
        const chromeStyles = isChrome ? `<style>
        table {
          width: 100%;
          max-width: 100%;
          border-collapse: collapse; /* Colapsa as bordas para que cada célula compartilhe as bordas */
        }
        th, td {
          border: 1px solid #ddd; /* Adiciona borda para th e td */
          padding: 8px; /* Espaçamento interno */
          text-align: left; /* Alinha texto à esquerda; altere para center se desejar centralizar */
        }
        th {
          background-color: #c5c5c5; /* Cor de fundo para th */
          font-weight: bold; /* Deixa o texto em negrito */
          text-align: center; /* Centraliza o texto do th */
        }
        tr:nth-child(even) {
          background-color: #c5c5c5; /* Cor de fundo alternada para linhas pares */
        }
        .no-print {
          display: none; /* Classe para ocultar elementos que não devem ser impressos */
        }
        body::after {
            content: "INGUANTI inspeções";
            color: rgba(0, 0, 0, 0.045); /* Torna a marca d'água menos visível (ajuste a opacidade conforme necessário) */
            font-size: 150px; /* Tamanho da fonte da marca d'água */
            font-weight: bold; /* Deixa o texto em negrito */
            position: fixed; /* Posicionamento fixo */
            top: 50%; /* Centraliza verticalmente */
            left: 50%; /* Centraliza horizontalmente */
            transform: translate(-50%, -50%) rotate(-45deg); /* Ajusta o posicionamento e inclina a marca d'água */
            z-index: 1000; /* Garante que a marca d'água fique atrás do conteúdo */
        }
        </style>`: '';

        const firefoxStyles = isFirefox ? `<style>
      table {
        width: 100%;
        max-width: 100%;
        border-collapse: collapse; /* Colapsa as bordas para que cada célula compartilhe as bordas */
      }
      th, td {
        border: 1px solid #ddd; /* Adiciona borda para th e td */
        padding: 8px; /* Espaçamento interno */
        text-align: left; /* Alinha texto à esquerda; altere para center se desejar centralizar */
      }
      th {
        background-color: #c5c5c5; /* Cor de fundo para th */
        font-weight: bold; /* Deixa o texto em negrito */
        text-align: center; /* Centraliza o texto do th */
      }
      tr:nth-child(even) {
        background-color: #c5c5c5; /* Cor de fundo alternada para linhas pares */
      }
      .no-print {
        display: none; /* Classe para ocultar elementos que não devem ser impressos */
      }
      body::after {
          content: "INGUANTI inspeções";
          color: rgba(0, 0, 0, 0.045); /* Torna a marca d'água menos visível (ajuste a opacidade conforme necessário) */
          font-size: 150px; /* Tamanho da fonte da marca d'água */
          font-weight: bold; /* Deixa o texto em negrito */
          position: fixed; /* Posicionamento fixo */
          top: 50%; /* Centraliza verticalmente */
          left: 50%; /* Centraliza horizontalmente */
          transform: translate(-50%, -50%) rotate(-45deg); /* Ajusta o posicionamento e inclina a marca d'água */
          z-index: 1000; /* Garante que a marca d'água fique atrás do conteúdo */
      }
        </style>`: '';

        if (isEdge) {
            browserSpecificStyles = edgeStyles;
        } else if (isChrome) {
            browserSpecificStyles = chromeStyles;
        } else if (isFirefox) {
            browserSpecificStyles = firefoxStyles;
        }

        const uniqueName = new Date();
        const windowName = 'Print' + uniqueName.getTime();

        // Tenta calcular o centro da tela
        const width = 800; // Largura da janela de impressão
        const height = 600; // Altura da janela de impressão
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);

        // Abre uma nova janela aproximadamente no centro da tela
        const printWindow = window.open('', windowName, `left=${left},top=${top},width=${width},height=${height},scrollbars=yes`);


        // Escreve o conteúdo da tabela e os estilos personalizados na nova janela 
        printWindow.document.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <title>Impressão de Relatório</title>
        ${browserSpecificStyles}
      </head>
      <body>
        ${printContent.outerHTML} <!-- Conteúdo a ser impresso -->
      </body>     
    </html>
  `);
        printWindow.document.close();

        // Utiliza o evento onload para garantir que o conteúdo esteja carregado
        printWindow.onload = function () {
            setTimeout(() => { // Ajuste o delay conforme necessário
                printWindow.focus();
                printWindow.print();
                printWindow.close();
            }, timeoutPrint);
        };
    };

    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    if (loading) {
        return <Loading />;
    }

    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card >
                            <div className='mt-2 mb-2 d-flex justify-content-between' style={{ marginLeft: '19px', marginRight: '19px' }}>
                                <Tooltip title="Imprime todos relatórios da página" arrow>
                                    <Button size={"sm"} style={{ fontSize: '12px' }} onClick={handlePrintGeral}>
                                        Imprimir Relatório Geral
                                    </Button>
                                </Tooltip>

                                {!isUserLoggedIn && (
                                    <Tooltip title="Fale conosco no WhatsApp" arrow>
                                        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                                            <Button className="btn-whatsapp-custom" size="sm">
                                                <FaWhatsapp style={{ fontSize: '20px' }} />
                                                <span style={{ marginLeft: '8px' }}>Contato via Whatsapp</span>
                                            </Button>
                                        </a>
                                    </Tooltip>
                                )}
                            </div>
                            <Card.Header className='title-card-custom' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h4>{empresa}</h4>
                            </Card.Header>
                            <Card.Body>
                                {/* ACESSÓRIOS */}
                                <Card>
                                    <Card.Header className='title-card-custom' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Tooltip title="Clique para expandir ou contrair a seção" arrow>
                                            <Button
                                                className="btn-link"
                                                onClick={() => setIsOpenAcessorios(!isOpenAcessorios)}
                                                aria-controls="collapse-body"
                                                aria-expanded={isOpenAcessorios}
                                            >
                                                ACESSÓRIOS
                                            </Button>
                                        </Tooltip>
                                        <div>
                                            <Tooltip title="Clique para expandir ou contrair a lista" arrow>
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id="chkApplyMaxHeightAcessorios"
                                                    name="applyMaxHeightAcessorios"
                                                    checked={isMaxHeightAppliedAcessorios}
                                                    onChange={(e) => setIsMaxHeightAppliedAcessorios(e.target.checked)}
                                                />
                                            </Tooltip>
                                        </div>
                                    </Card.Header>
                                    <Collapse in={isOpenAcessorios}>
                                        <Card.Body id="collapse-body">
                                            <TableAcessoriosCliente
                                                data={listDataAcessorios}
                                                applyMaxHeight={isMaxHeightAppliedAcessorios}
                                                maxHeight="200px"
                                            />
                                        </Card.Body>
                                    </Collapse>
                                </Card>

                                {/* DOCUMENTOS COMPLEMENTARES */}
                                {
                                    listDataDocumentosComplemetares && listDataDocumentosComplemetares.length > 0 && (
                                        <Card className='mt-2'>
                                            <Card.Header className='title-card-custom'>
                                                <Tooltip title="Clique para expandir ou contrair a seção" arrow>
                                                    <Button
                                                        className="btn-link"
                                                        onClick={() => setIsOpenDocumentosComplementares(!isOpenDocumentosComplementares)}
                                                        aria-controls="collapse-body"
                                                        aria-expanded={isOpenDocumentosComplementares}
                                                    >
                                                        DOCUMENTOS COMPLEMENTARES
                                                    </Button>
                                                </Tooltip>
                                            </Card.Header>
                                            <Collapse in={isOpenDocumentosComplementares}>
                                                <Card.Body id="collapse-body">
                                                    <TableDocumentosComplementares
                                                        data={listDataDocumentosComplemetares}
                                                        handleDownloadPdf={handleDownloadPdfDocOs}
                                                        handleDownloadMergePdfDocCompl={handleDownloadMergePdfDocCompl}
                                                        handleDownloadLotePdfsIndividualDocCompl={handleDownloadLotePdfsIndividualDocCompl} />
                                                </Card.Body>
                                            </Collapse>
                                        </Card>
                                    )
                                }

                                {/* LAUDOS */}
                                <Card className='mt-2'>
                                    <Card.Header className='title-card-custom' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Tooltip title="Clique para expandir ou contrair a seção" arrow>
                                            <Button
                                                className="btn-link"
                                                onClick={() => setIsOpenLaudo(!isOpenLaudo)}
                                                aria-controls="collapse-body"
                                                aria-expanded={isOpenLaudo}
                                            >
                                                LAUDOS
                                            </Button>
                                        </Tooltip>
                                        <div>
                                            <Tooltip title="Clique para expandir ou contrair a lista" arrow>
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id="chkApplyMaxHeightLaudos"
                                                    name="applyMaxHeightLaudos"
                                                    checked={isMaxHeightAppliedLaudos}
                                                    onChange={(e) => setIsMaxHeightAppliedLaudos(e.target.checked)}
                                                />
                                            </Tooltip>
                                        </div>
                                    </Card.Header>
                                    <Collapse in={isOpenLaudo}>
                                        <Card.Body>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div>
                                                    <Tooltip title="Mescla todos os PDFs em 1." arrow>
                                                        <Button className='btn-gerar-pdf' onClick={handleDownloadMergePdf}>
                                                            Gerar PDF
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <Tooltip title="Gera um PDF em lote de forma individual" arrow>
                                                        <Button className='btn-gerar-pdf' onClick={handleDownloadLotePdfsIndividual} >
                                                            Gerar PDF lote individual
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <Row className="mt-2">
                                                <Form.Group as={Col} controlId="resultado" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Resultado
                                                    </Form.Label>
                                                    <Form.Select
                                                        size={"sm"}
                                                        className="uppercase-input"
                                                        autoFocus
                                                        onChange={handleResultadoChange}
                                                        value={searchResultado}
                                                    >
                                                        {resultados.map((resultado, index) => (
                                                            <option key={index} value={resultado}>
                                                                {resultado}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="laudo" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Laudo
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={40}
                                                        value={searchLaudo}
                                                        onChange={handleLaudoChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="setor" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Setor
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={25}
                                                        value={searchSetor}
                                                        onChange={handleSetorChange}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mt-1">
                                                <Form.Group as={Col} controlId="subsetor" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Sub Setor
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={30}
                                                        value={searchSubSetor}
                                                        onChange={handleSubSetorChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="acessorios" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Acessório
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={30}
                                                        value={searchAcessorio}
                                                        onChange={handleAcessorioChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="taginguanti" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Tag Inguanti
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={30}
                                                        value={searchTagInguanti}
                                                        onChange={handleTagInguantiChange}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mt-1">
                                                <Form.Group as={Col} controlId="tagcliente" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Tag Cliente
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={30}
                                                        value={searchTagCliente}
                                                        onChange={handleTagClienteChange}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="metodologiaInspecao" xs={12} sm={8} md={8} lg={8}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Metodologia da Inspecao
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={100}
                                                        value={searchMetodologiaInspecao}
                                                        onChange={handleMetodologiaInspecaoChange}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} controlId="recomendacoes" xs={12} sm={12} md={12} lg={12}>
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Recomendações
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="text"
                                                        placeholder="Digite para fazer uma pesquisa"
                                                        className="uppercase-input"
                                                        maxLength={30}
                                                        value={searchRecomendacoes}
                                                        onChange={handleRecomendacoesChange}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mt-1">
                                                <Form.Group as={Col} controlId="datainicio" xs={12} sm={4} md={4} lg={4}>
                                                    <Form.Label className="bold-label font-label-laudo" style={{ marginBottom: -15 }}>
                                                        Data Início
                                                    </Form.Label>
                                                    <Form.Control
                                                        size={"sm"}
                                                        type="date"
                                                        value={searchDataInicio}
                                                        onChange={handleDataInicioChange}
                                                        isInvalid={!!searchDataFim && !searchDataInicio}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="datafim" xs={12} sm={5} md={5} lg={5} >
                                                    <Form.Label className="bold-label font-label-laudo">
                                                        Data Fim
                                                    </Form.Label>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Form.Control
                                                            size={"sm"}
                                                            type="date"
                                                            value={searchDataFim}
                                                            onChange={handleDataFimChange}
                                                            isInvalid={!!searchDataInicio && !searchDataFim}
                                                        />
                                                        <div style={{ marginLeft: 25 }}>
                                                            <Tooltip title="Pesquisar" arrow>
                                                                <Button
                                                                    variant="outline-secondary"
                                                                    size={"sm"}
                                                                    onClick={handleSearchClick}
                                                                    disabled={(searchDataInicio && !searchDataFim) || (!searchDataInicio && searchDataFim)}
                                                                >
                                                                    <FaSearch style={{ marginBottom: '3px' }} />
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Tooltip title="Listar" arrow>
                                                                <Button
                                                                    variant="outline-secondary"
                                                                    size={"sm"}
                                                                    onClick={handleList}
                                                                >
                                                                    <span style={{ backgroundColor: 'lightblue', borderRadius: '20%' }}>
                                                                        <FaList color="blue" />
                                                                    </span>
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Tooltip title="Limpar campos" arrow>
                                                                <Button
                                                                    variant="outline-secondary"
                                                                    size={"sm"}
                                                                    onClick={handleClearFields}
                                                                >
                                                                    <AiOutlineClear style={{ marginBottom: '3px' }} />
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group>
                                                    {!!searchDataInicio && !searchDataFim && (
                                                        <Row className="mt-1" style={{ fontSize: '11px', color: 'red', marginLeft: '0' }}>
                                                            Por favor, preencha a Data Fim.
                                                        </Row>
                                                    )
                                                    }
                                                    {!!searchDataFim && !searchDataInicio && (
                                                        <Row className="mt-1" style={{ fontSize: '11px', color: 'red', marginLeft: '0' }}>
                                                            Por favor, preencha a Data Início.
                                                        </Row>
                                                    )
                                                    }
                                                </Form.Group>
                                            </Row>

                                            <TableAreaCliente
                                                data={listData}
                                                handleDownloadPdf={handleDownloadPdf}
                                                handlePrintLaudos={handlePrintLaudos}
                                                isLoading={isLoadingTable}
                                                applyMaxHeight={isMaxHeightAppliedLaudos}
                                                maxHeight="65vh"
                                            />
                                            <div style={{ display: 'none' }}>
                                                <SimpleTable data={listData} />
                                            </div>

                                            <CustomPagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </Card.Body>
                                    </Collapse>
                                </Card>
                            </Card.Body>
                            <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} paddingLeftValue={30} paddingRightValue={30} />
                        </Card>
                    </Form>
                </Col>
            </Row >


            <div style={copyrightStyle}>
                © Copyright {currentYear} Desenvolvido por Ventura Sistemas. Todos Direitos Reservados.<br />
                venturasistemas@gmail.com Whats 9-9426-9153
            </div>
        </Container >
    )
};

export default AreaCliente;

// Para grandes quantidade de dados, porém ver a hipotese de fazer outra tabela escondida
// transform: scale(0.63);
// margin-left: -22%;