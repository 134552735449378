import * as XLSX from 'xlsx';

const exportToExcel = (data, sheetName = "Dados", fileName = "Dados.xlsx", columnsToRemove = []) => {
    const filteredData = data.map(row => {
        const newRow = { ...row };
        columnsToRemove.forEach(column => {
            delete newRow[column];
        });
        return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);
};

export { exportToExcel };
