import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Card } from 'react-bootstrap';

// Registrar os componentes do Chart.js e o plugin de DataLabels
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const DashboardClienteAccessoryBarChart = ({ totalsByAcessorio }) => {
    // Ordena os dados por porcentagem
    const sortedData = [...totalsByAcessorio].sort((a, b) => b.porcentagem - a.porcentagem);

    // Configura os dados para o gráfico
    const data = {
        labels: sortedData.map(item => item.acessorio),
        datasets: [
            {
                label: 'Porcentagem',
                data: sortedData.map(item => item.porcentagem),
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Configurações adicionais do gráfico
    const options = {
        indexAxis: 'y', // Para barras horizontais
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => `${value}%`,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false, // Ocultar a legenda
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.raw}%`,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (value) => `${value}%`,
                color: '#000',
                font: {
                    weight: 'bold',
                },
            },
        },
    };

    return (
        <>
            <Card className="mb-4 mt-4">
                <Card.Body>                    
                    <div className="table-responsive">
                        <Bar data={data} options={options} />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default DashboardClienteAccessoryBarChart;
