import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Row, Col, Card } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardComparisonPieCharts = ({ totals }) => {
    // Dados para o gráfico de valores absolutos
    const chartDataValues = {
        labels: ['Aprovado', 'Aprovado com Recomend.', 'Reprovado'],
        datasets: [
            {
                label: 'Totais',
                data: [totals.aprovado, totals.aprovadoRecomendacoes, totals.reprovado],
                backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
                borderColor: ['#28a745', '#ffc107', '#dc3545'],
                borderWidth: 1,
            },
        ],
    };

    // Dados para o gráfico de porcentagens
    const totalSum = totals.aprovado + totals.aprovadoRecomendacoes + totals.reprovado;
    const chartDataPercentages = {
        labels: ['Aprovado', 'Aprovado com Recomend.', 'Reprovado'],
        datasets: [
            {
                label: 'Porcentagens',
                data: [
                    ((totals.aprovado / totalSum) * 100).toFixed(2),
                    ((totals.aprovadoRecomendacoes / totalSum) * 100).toFixed(2),
                    ((totals.reprovado / totalSum) * 100).toFixed(2)
                ],
                backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
                borderColor: ['#28a745', '#ffc107', '#dc3545'],
                borderWidth: 1,
            },
        ],
    };

    const optionsValues = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                    padding: 10,
                    font: {
                        size: 12, // Diminui o tamanho da fonte das legendas
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}`; // Sem o símbolo de porcentagem
                    },
                },
            },
            datalabels: {
                color: 'black',
                font: {
                    size: 18, // Diminui o tamanho da fonte dos dados no gráfico
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `${value}` : '', // Sem o símbolo de porcentagem
            },
        },
    };

    const optionsPercentages = {
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                    padding: 10,
                    font: {
                        size: 12, // Diminui o tamanho da fonte das legendas
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw}%`; // Com o símbolo de porcentagem
                    },
                },
            },
            datalabels: {
                color: 'black',
                font: {
                    size: 12, // Diminui o tamanho da fonte dos dados no gráfico
                    weight: 'bold',
                },
                formatter: (value) => value > 0 ? `${value}%` : '', // Com o símbolo de porcentagem
            },
        },
    };

    return (
        <Card className="mb-4 mt-4">
            <Card.Body>
                <Row>
                    <Col md={6}>
                        <h5 style={{ textAlign: 'center', minHeight: '40px' }}>Totais em Valores</h5>
                        <div style={{ width: '80%', margin: '0 auto' }}>
                            <Pie data={chartDataValues} options={optionsValues} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <h5 style={{ textAlign: 'center', minHeight: '40px' }}>Totais em Porcentagens</h5>
                        <div style={{ width: '80%', margin: '0 auto' }}>
                            <Pie data={chartDataPercentages} options={optionsPercentages} />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default DashboardComparisonPieCharts;
