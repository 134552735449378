import { useState } from 'react';

export const LaudosState = () => {

    //Add
    const [newTagInguanti, setNewTagInguanti] = useState("");
    const [newAcessorio, setNewAcessorio] = useState("");
    const [newTagCliente, setNewTagCliente] = useState("");
    const [newFabricante, setNewFabricante] = useState("");
    const [newModelo, setNewModelo] = useState("");
    const [newCapacidade, setNewCapacidade] = useState("");
    const [newDimensoes, setNewDimensoes] = useState("");
    const [newDemaisInformacoesAcessorio, setNewDemaisInformacoesAcessorio] = useState("");
    const [newContato, setNewContato] = useState("");
    const [newSetor, setNewSetor] = useState("");
    const [newDDD, setNewDDD] = useState("");
    const [newTelefone, setNewTelefone] = useState("");
    const [newSubsetor, setNewSubsetor] = useState("");
    const [newMetodologiaInspecao, setNewMetodologiaInspecao] = useState("");
    const [newDataMetodologiaInspecao, setNewDataMetodologiaInspecao] = useState("");
    const [newCertificadoFabricante, setNewCertificadoFabricante] = useState("");
    const [newRegistroInspecao, setNewRegistroInspecao] = useState("");
    const [newRegistroReparo, setNewRegistroReparo] = useState("");
    const [newConclusaoTecnica, setNewConclusaoTecnica] = useState("");
    const [newRecomendacoes, setNewRecomendacoes] = useState("");

    //Edit
    const [editAcessorio, setEditAcessorio] = useState(null);
    const [editTagInguanti, setEditTagInguanti] = useState(null);
    const [editTagCliente, setEditTagCliente] = useState(null);
    const [editFabricante, setEditFabricante] = useState(null);
    const [editModelo, setEditModelo] = useState(null);
    const [editCapacidade, setEditCapacidade] = useState(null);
    const [editDimensoes, setEditDimensoes] = useState(null);
    const [editDemaisInformacoesAcessorio, setEditDemaisInformacoesAcessorio] = useState(null);
    const [editContato, setEditContato] = useState(null);
    const [editSetor, setEditSetor] = useState(null);
    const [editDDD, setEditDDD] = useState(null);
    const [editTelefone, setEditTelefone] = useState(null);
    const [editSubsetor, setEditSubsetor] = useState(null);
    const [editMetodologiaInspecao, setEditMetodologiaInspecao] = useState(null);
    const [editDataMetodologiaInspecao, setEditDataMetodologiaInspecao] = useState(null);
    const [editCertificadoFabricante, setEditCertificadoFabricante] = useState(null);
    const [editRegistroInspecao, setEditRegistroInspecao] = useState(null);
    const [editRegistroReparo, setEditRegistroReparo] = useState(null);
    const [editConclusaoTecnica, setEditConclusaoTecnica] = useState(null);
    const [editRecomendacoes, setEditRecomendacoes] = useState(null);

    return {
        //Add
        newTagInguanti, setNewTagInguanti,
        newAcessorio, setNewAcessorio,
        newTagCliente, setNewTagCliente,
        newFabricante, setNewFabricante,
        newModelo, setNewModelo,
        newCapacidade, setNewCapacidade,
        newDimensoes, setNewDimensoes,
        newDemaisInformacoesAcessorio, setNewDemaisInformacoesAcessorio,
        newContato, setNewContato,
        newSetor, setNewSetor,
        newDDD, setNewDDD,
        newTelefone, setNewTelefone,
        newSubsetor, setNewSubsetor,
        newMetodologiaInspecao, setNewMetodologiaInspecao,
        newDataMetodologiaInspecao, setNewDataMetodologiaInspecao,
        newCertificadoFabricante, setNewCertificadoFabricante,
        newRegistroInspecao, setNewRegistroInspecao,
        newRegistroReparo, setNewRegistroReparo,
        newConclusaoTecnica, setNewConclusaoTecnica,
        newRecomendacoes, setNewRecomendacoes,

        //Edit
        editAcessorio, setEditAcessorio,
        editTagInguanti, setEditTagInguanti,
        editTagCliente, setEditTagCliente,
        editFabricante, setEditFabricante,
        editModelo, setEditModelo,
        editCapacidade, setEditCapacidade,
        editDimensoes, setEditDimensoes,
        editDemaisInformacoesAcessorio, setEditDemaisInformacoesAcessorio,
        editContato, setEditContato,
        editSetor, setEditSetor,
        editDDD, setEditDDD,
        editTelefone, setEditTelefone,
        editSubsetor, setEditSubsetor,
        editMetodologiaInspecao, setEditMetodologiaInspecao,
        editDataMetodologiaInspecao, setEditDataMetodologiaInspecao,
        editCertificadoFabricante, setEditCertificadoFabricante,
        editRegistroInspecao, setEditRegistroInspecao,
        editRegistroReparo, setEditRegistroReparo,
        editConclusaoTecnica, setEditConclusaoTecnica,
        editRecomendacoes, setEditRecomendacoes,

    };
};
