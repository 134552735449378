import React, { useState, useEffect, useRef } from "react";
import axios from "../Config/axiosConfig";
import {
  BlobProvider,
  Page,
  Text,
  View,
  Document,
  Image,
  Font,
} from "@react-pdf/renderer";
import { styles } from "./ExportToPDFStyles";

//Compartilhados
import Loading from "../Shared/Loading/Loading";
import { formatarDataIsoString } from './../Shared/Utils/helper';
import CabecalhoLaudo from './ExportPDFComponent/CabecalhoLaudo';
import ConclusaoTecnica from './ExportPDFComponent/ConclusaoTecnica';
import DadosInspecaoExecutada from './ExportPDFComponent/DadosInspecaoExecutada';
import DocumentacaoAcessorio from './ExportPDFComponent/DocumentacaoAcessorio';
import EvidenciaFotograficas from './ExportPDFComponent/EvidenciaFotograficas';
import IdentificacaoAcessorio from './ExportPDFComponent/IdentificacaoAcessorio';
import IdentificacaoProprietario from './ExportPDFComponent/IdentificacaoProprietario';
import NossosContatos from './ExportPDFComponent/NossosContatos';
import Notas from './ExportPDFComponent/Notas';
import ResponsaveisLaudo from './ExportPDFComponent/ResponsaveisLaudo';

import Swal from "sweetalert2";

const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApiFiles = `${baseApiUrl}/api/Files`;
const baseApiPdf = `${baseApiUrl}/api/Files/add-pdf`;
const baseApiGetPdf = `${baseApiUrl}/api/Files/download-pdf`;

Font.register({
  family: "Arial",
  fonts: [
    {
      src: "/fonts/arial-bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const ExportToPDF = ({ response, onPdfUploadComplete }) => {
 // console.log('response Laudo', response);

  let varRazaoSocialInguanti = null;

  if (response.razaoSocialInguanti != null && response.razaoSocialInguanti !== undefined) {
    varRazaoSocialInguanti = `INGUANTI ${response.razaoSocialInguanti}`;
  }

  const [uploadStatus, setUploadStatus] = useState(null);
  const [urlPdf, setUrlPdf] = useState(null);

  const [image1Data, setImage1Data] = useState(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
  const [image2Data, setImage2Data] = useState(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
  const [image3Data, setImage3Data] = useState(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
  const [image4Data, setImage4Data] = useState(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
  const [imageLogoTipoData, setImageLogoTipoData] = useState("");

  const [assinaturaInspetorImage, setAssinaturaInspetorImage] = useState("");
  const [assinaturaResponsavelImage, setAssinaturaResponsavelImage] = useState("");
  const [assinaturaResponsavelLaudoImage, setAssinaturaResponsavelLaudoImage] = useState("");

  const [textLaudoCertificadoInspecao, setTextLaudoCertificadoInspecao] = useState("LAUDO/CERTIFICADO DE INSPEÇÃO");

  const [textArt, setTextArt] = useState("ART - N° ");
  const [art, setArt] = useState(response.art);

  const [razaoSocialInguanti, setRazaoSocialInguanti] = useState(varRazaoSocialInguanti);

  const [textLaudo, setTextLaudo] = useState("LAUDO - ");
  const [laudo, setLaudo] = useState(response.laudo);

  const [textIdentificacaoAcessorio, setTextIdentificacaoAcessorio] = useState("IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO");

  const [textTipo, setTextTipo] = useState("Tipo: ");
  const [tipo, setTipo] = useState(response.acessorio);

  const [textFabricante, setTextFabricante] = useState("Fabric.: ");
  const [fabricante, setFabricante] = useState(response.fabricante);

  const [textCapacidade, setTextCapacidade] = useState("Capac.: ");
  const [capacidade, setCapacidade] = useState(response.capacidade);

  const [textDemaisinformacoes, setTextDemaisinformacoes] = useState("Demais informações: ");
  const [demaisInformacoes, setDemaisinformacoes] = useState(response.demaisInformacoesAcessorio);

  const [textTagIng, setTextTagIng] = useState("TAG ING: ");
  const [tagIng, setTaging] = useState(response.tagInguanti);

  const [textTagCliente, setTextTagCliente] = useState("TAG CLIENTE: ");
  const [tagCliente, setTagCliente] = useState(response.tagCliente);

  const [textModelo, setTextModelo] = useState("Mod.: ");
  const [modelo, setModelo] = useState(response.modelo);

  const [textDimensoes, setTextDimensoes] = useState("Dimensões: ");
  const [dimensoes, setDimensoes] = useState(response.dimensoes);


  const [textIdentificacaoProprietario, setTextIdentificacaoProprietario] = useState("IDENTIFICAÇÃO DO PROPRIETÁRIO");

  const [textEmpresa, setTextEmpresa] = useState("Empresa: ");
  const [empresa, setEmpresa] = useState(response.empresa);

  const [textContato, setTextContato] = useState("Contato: ");
  const [contato, setContato] = useState(response.contato);

  const [textSetor, setTextSetor] = useState("Setor: ");
  const [setor, setSetor] = useState(response.setor);

  const [textSubSetor, setTextSubSetor] = useState("Sub setor: ");
  const [subSetor, setSubSetor] = useState(response.subSetor);

  const [textCnpj, setTextCnpj] = useState("CNPJ: ");
  const [cnpj, setCnpj] = useState(response.cnpj);

  const [textTelefone, setTextTelefone] = useState("Tel: ");
  const [telefone, setTel] = useState(response.telefone);

  const [textDadosDaInspecaoExecutada, setTextDadosDaInspecaoExecutada] = useState("DADOS DA INSPEÇÃO EXECUTADA");

  const [textMetodologiaInspecao, setTextMetodologiaInspecao] = useState("Metodologia: ");
  const [metodologiaInspecao, setMetodologiaInspecao] = useState(response.metodologiaInspecao);

  const [textDataMetodologiaInspecao, setTextDataMetodologiaInspecao] = useState("Data: ");
  const [dataMetodologiaInspecao, setDataMetodologiaInspecao] = useState(response.dataMetodologiaInspecao);

  const [textDocumentacaoAcessorio, setTextDocumentacaoAcessorio] = useState("DOCUMENTAÇÃO DOS ACESSÓRIOS/DISPOSITIVO");

  const [textCertificadoFabricante, setTextCertificadoFabricante] = useState("Certificado do fabricante: ");
  const [certificadoFabricante, setCertificadoFabricante] = useState(response.certificadoFabricante);

  const [textRegistroInspecao, setTextRegistroInspecao] = useState("Registro de inspeção: ");
  const [registroInspecao, setRegistroInspecao] = useState(response.registroInspecao);

  const [textRegistroReparo, setTextRegistroReparo] = useState("Registro de reparo: ");
  const [registroReparo, setRegistroReparo] = useState(response.registroReparo);

  const [textEvidenciaFotografica, setTextEvidenciaFotografica] = useState("EVIDÊNCIA FOTOGRÁFICA");
  const [textCabecalhoConclusaoTecnica, setTextCabecalhoConclusaoTecnica] = useState("CONCLUSÃO TÉCNICA");

  const [textConclusaoTecnica, setTextConclusaoTecnica] = useState("Resultado: ");
  const [conclusaoTecnica, setConclusaoTecnica] = useState(response.conclusaoTecnica);

  const [textRecomendacoes, setTextRecomendacoes] = useState("Observações: ");
  const [recomendacoes, setRecomendacoes] = useState(response.recomendacoes);

  const [textCabecalhoInspetor, setTextCabecalhoInspetor] = useState("RESP. PELA INSPEÇÃO");
  const [textCabecalhoResponsavelTecnico, setTextCabecalhoResponsavelTecnico] = useState("RESPONSÁVEL TÉCNICO");
  const [textCabecalhoResponsavelLaudo, setTextCabecalhoResponsavelLaudo] = useState("RESP. PELO LAUDO");


  const [textNomeInspetor, setTextNomeInspetor] = useState("Nome: ");
  const [nomeInspetor, setnomeInspetor] = useState(response.nomeInspetor);

  const [textNomeResponsavelLaudo, setTextNomeResponsavelLaudo] = useState("Nome: ");
  const [nomeResponsavelLaudo, setNomeResponsavelLaudo] = useState(response.responsavelLaudo)

  const [textNumeroDocumento, setTextNumeroDocumentoInspetor] = useState("Doc.: ");
  const [numeroDocumentoInspetor, setNumeroDocumentoInspetor] = useState(response.numeroDocumentoInspetor);
  const [numeroDocumentoRespLaudo, setNumeroDocumentoRespLaudo] = useState(response.cpfResponsavelLaudo);

  const [textNomeResponsavelTecnico, setTextNomeResponsavelTecnico] = useState("Nome: ");
  const [nomeResponsavelTecnico, setNomeResponsavelTecnico] = useState(response.nomeResponsavelTecnico);

  const [textCrea, setTextCrea] = useState("N° Crea ");
  const [crea, setCrea] = useState(response.crea);

  const [textNotas, setTextNotas] = useState("Notas:");
  const [textNotaUm, setTextNotaUm] = useState("1. Somente pessoal qualificado e competente devem inspecionar os acessórios");
  const [textNotaDois, setTextNotaDois] = useState("2.Ao iniciar certifique se está utilizando os Equipamentos de Proteção Individual (EPI'S)");
  const [textReferencias, setTextReferencias] = useState("Referências: NR's / NBR's / ASME's");

  const [textNossosContatos, setTextNossosContatos] = useState("Nossos contatos:");
  const [textFoneContatos, setTextFoneContatos] = useState("Fone: (11) 4654-4011 ou (11) 98283-2778");
  const [textEmailContatos, setTextEmailContatos] = useState("Email: contato@inguanti.com.br");
  const [textSaibaMaisContatos, setTextSaibaMaisContatos] = useState("Saiba mais em www.inguanti.com.br");

  const [loading, setLoading] = useState(true);

  // No react 18 é necessário para não chamar 2 vezes a API
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      setLoading(true);

      setImageLogoTipoData(
        `${process.env.PUBLIC_URL}/img/jpeg/Inguanti_Inspecoes_new.jpg`
      );

      if (response.caminhoEvidenciaFotograficaUm == "SEMIMAGEM01.JPG") {
        setImage1Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaUm}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage1Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage1Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (response.caminhoEvidenciaFotograficaDois == "SEMIMAGEM02.JPG") {
        setImage2Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaDois}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage2Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage2Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (response.caminhoEvidenciaFotograficaTres == "SEMIMAGEM03.JPG") {
        setImage3Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaTres}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage3Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage3Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      if (response.caminhoEvidenciaFotograficaQuatro == "SEMIMAGEM04.JPG") {
        setImage4Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        axios
          .get(
            `${baseApiFiles}/download-evidencia-fotografica/${response.caminhoEvidenciaFotograficaQuatro}`,
            {
              responseType: "arraybuffer",
            }
          )
          .then((response) => {
            if (response?.data?.byteLength > 0) {
              const base64 = btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
              setImage4Data(`data:${contentType};base64,${base64}`);
            } else {
              setImage4Data(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      axios
        .get(
          `${baseApiFiles}/download-assinatura/${response.caminhoAssinaturaInspetor}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response?.data?.byteLength > 0) {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
            setAssinaturaInspetorImage(`data:${contentType};base64,${base64}`);
          }
          else {
            Swal.fire('Erro', 'Assinatura do inspetor não foi encontrada', 'error');
          }
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${baseApiFiles}/download-assinatura/${response.caminhoAssinaturaResponsavelTecnico}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response?.data?.byteLength > 0) {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
            setAssinaturaResponsavelImage(`data:${contentType};base64,${base64}`);
          }
          else {
            Swal.fire('Erro', 'Assinatura do responsável técnico não foi encontrada', 'error');
          }
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${baseApiFiles}/download-assinatura/${response.caminhoAssinaturaResponsavelLaudo}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          if (response?.data?.byteLength > 0) {
            const base64 = btoa(
              new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            const contentType = response.headers["content-type"]; // Aqui você obtém o tipo MIME
            setAssinaturaResponsavelLaudoImage(`data:${contentType};base64,${base64}`);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      setLoading(false);

    }

  }, []);

  const DocumentPdf = () => {

    const renderOnePage = () => (
      <Page size="A4" style={styles.page}>
        <View style={{ border: 2.5, height: 800 }}>
          {/* ************************************************** Cabeçalho *************************************************** */}
          <CabecalhoLaudo
            imageLogoTipoData={imageLogoTipoData}
            textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
            textArt={textArt}
            art={art}
            textLaudo={textLaudo}
            laudo={laudo}
            razaoSocialInguanti={razaoSocialInguanti}
          />
          {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
          <IdentificacaoAcessorio
            textIdentificacaoAcessorio={textIdentificacaoAcessorio}
            textTipo={textTipo}
            tipo={tipo}
            textTagIng={textTagIng}
            tagIng={tagIng}
            textTagCliente={textTagCliente}
            tagCliente={tagCliente}
            textFabricante={textFabricante}
            fabricante={fabricante}
            textModelo={textModelo}
            modelo={modelo}
            textCapacidade={textCapacidade}
            capacidade={capacidade}
            textDimensoes={textDimensoes}
            dimensoes={dimensoes}
          />
          {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

          {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
          <IdentificacaoProprietario
            textIdentificacaoProprietario={textIdentificacaoProprietario}
            textEmpresa={textEmpresa}
            empresa={empresa}
            textCnpj={textCnpj}
            cnpj={cnpj}
            textContato={textContato}
            contato={contato}
            textSetor={textSetor}
            setor={setor}
            textTelefone={textTelefone}
            telefone={telefone}
            textSubSetor={textSubSetor}
            subSetor={subSetor}
          />
          {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

          {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

          <DadosInspecaoExecutada
            textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
            textMetodologiaInspecao={textMetodologiaInspecao}
            metodologiaInspecao={metodologiaInspecao}
            textDataMetodologiaInspecao={textDataMetodologiaInspecao}
            dataMetodologiaInspecao={dataMetodologiaInspecao}
          />

          {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


          {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

          <DocumentacaoAcessorio
            textDocumentacaoAcessorio={textDocumentacaoAcessorio}
            textCertificadoFabricante={textCertificadoFabricante}
            certificadoFabricante={certificadoFabricante}
            textRegistroInspecao={textRegistroInspecao}
            registroInspecao={registroInspecao}
            textRegistroReparo={textRegistroReparo}
            registroReparo={registroReparo}

          />

          {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

          {/* ************************************************** 5 *************************************************** */}

          <EvidenciaFotograficas
            textEvidenciaFotografica={textEvidenciaFotografica}
            image1Data={image1Data}
            image2Data={image2Data}
            image3Data={image3Data}
            image4Data={image4Data}
          />
          {/* ************************************************ FIM 5 *************************************************** */}

          {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

          <ConclusaoTecnica
            textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
            textConclusaoTecnica={textConclusaoTecnica}
            conclusaoTecnica={conclusaoTecnica}
            textRecomendacoes={textRecomendacoes}
            recomendacoes={recomendacoes}
          />

          {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

          {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

          <ResponsaveisLaudo
            textCabecalhoInspetor={textCabecalhoInspetor}
            textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
            textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
            textNomeInspetor={textNomeInspetor}
            textNomeResponsavelLaudo={textNomeResponsavelLaudo}
            nomeResponsavelLaudo={nomeResponsavelLaudo}
            nomeInspetor={nomeInspetor}
            textNomeResponsavelTecnico={textNomeResponsavelTecnico}
            nomeResponsavelTecnico={nomeResponsavelTecnico}
            textNumeroDocumento={textNumeroDocumento}
            numeroDocumentoInspetor={numeroDocumentoInspetor}
            numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
            textCrea={textCrea}
            crea={crea}
            assinaturaInspetorImage={assinaturaInspetorImage}
            assinaturaResponsavelImage={assinaturaResponsavelImage}
            assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
          />

          {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


          {/* ******************************************  Notas ******************************************* */}
          <Notas
            textNotas={textNotas}
            textNotaUm={textNotaUm}
            textNotaDois={textNotaDois}
            textReferencias={textReferencias}
          />

          {/* ****************************************** FIM Notas ******************************************* */}

          {/* ************************************************ Nossos Contatos *************************************************** */}

          <NossosContatos
            textNossosContatos={textNossosContatos}
            textFoneContatos={textFoneContatos}
            textEmailContatos={textEmailContatos}
            textSaibaMaisContatos={textSaibaMaisContatos}
          />

          {/* ************************************************ FIM Nossos Contatos *************************************************** */}
        </View>
        <Text style={styles.watermark1}>INGUANTI</Text>
        <Text style={styles.watermark2}>INGUANTI</Text>
        <Text style={styles.textPagina1de1}>1 de 1</Text>
      </Page>
    );
    const renderTwoPagesNossosContatos = () => (
      <>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************** Cabeçalho *************************************************** */}
            <CabecalhoLaudo
              imageLogoTipoData={imageLogoTipoData}
              textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
              textArt={textArt}
              art={art}
              textLaudo={textLaudo}
              laudo={laudo}
              razaoSocialInguanti={razaoSocialInguanti}
            />
            {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
            <IdentificacaoAcessorio
              textIdentificacaoAcessorio={textIdentificacaoAcessorio}
              textTipo={textTipo}
              tipo={tipo}
              textTagIng={textTagIng}
              tagIng={tagIng}
              textTagCliente={textTagCliente}
              tagCliente={tagCliente}
              textFabricante={textFabricante}
              fabricante={fabricante}
              textModelo={textModelo}
              modelo={modelo}
              textCapacidade={textCapacidade}
              capacidade={capacidade}
              textDimensoes={textDimensoes}
              dimensoes={dimensoes}
            />
            {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

            {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
            <IdentificacaoProprietario
              textIdentificacaoProprietario={textIdentificacaoProprietario}
              textEmpresa={textEmpresa}
              empresa={empresa}
              textCnpj={textCnpj}
              cnpj={cnpj}
              textContato={textContato}
              contato={contato}
              textSetor={textSetor}
              setor={setor}
              textTelefone={textTelefone}
              telefone={telefone}
              textSubSetor={textSubSetor}
              subSetor={subSetor}
            />
            {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

            {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

            <DadosInspecaoExecutada
              textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
              textMetodologiaInspecao={textMetodologiaInspecao}
              metodologiaInspecao={metodologiaInspecao}
              textDataMetodologiaInspecao={textDataMetodologiaInspecao}
              dataMetodologiaInspecao={dataMetodologiaInspecao}
            />

            {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


            {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

            <DocumentacaoAcessorio
              textDocumentacaoAcessorio={textDocumentacaoAcessorio}
              textCertificadoFabricante={textCertificadoFabricante}
              certificadoFabricante={certificadoFabricante}
              textRegistroInspecao={textRegistroInspecao}
              registroInspecao={registroInspecao}
              textRegistroReparo={textRegistroReparo}
              registroReparo={registroReparo}

            />

            {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

            {/* ************************************************** 5 *************************************************** */}

            <EvidenciaFotograficas
              textEvidenciaFotografica={textEvidenciaFotografica}
              image1Data={image1Data}
              image2Data={image2Data}
              image3Data={image3Data}
              image4Data={image4Data}
            />
            {/* ************************************************ FIM 5 *************************************************** */}

            {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

            <ConclusaoTecnica
              textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
              textConclusaoTecnica={textConclusaoTecnica}
              conclusaoTecnica={conclusaoTecnica}
              textRecomendacoes={textRecomendacoes}
              recomendacoes={recomendacoes}
            />

            {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

            {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

            <ResponsaveisLaudo
              textCabecalhoInspetor={textCabecalhoInspetor}
              textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
              textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
              textNomeInspetor={textNomeInspetor}
              textNomeResponsavelLaudo={textNomeResponsavelLaudo}
              nomeResponsavelLaudo={nomeResponsavelLaudo}
              nomeInspetor={nomeInspetor}
              textNomeResponsavelTecnico={textNomeResponsavelTecnico}
              nomeResponsavelTecnico={nomeResponsavelTecnico}
              textNumeroDocumento={textNumeroDocumento}
              numeroDocumentoInspetor={numeroDocumentoInspetor}
              numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
              textCrea={textCrea}
              crea={crea}
              assinaturaInspetorImage={assinaturaInspetorImage}
              assinaturaResponsavelImage={assinaturaResponsavelImage}
              assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
            />

            {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


            {/* ******************************************  Notas ******************************************* */}
            <Notas
              textNotas={textNotas}
              textNotaUm={textNotaUm}
              textNotaDois={textNotaDois}
              textReferencias={textReferencias}
            />

            {/* ****************************************** FIM Notas ******************************************* */}

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina1de2}>1 de 2</Text>

          </View>
        </Page>

        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************ Nossos Contatos *************************************************** */}

            <NossosContatos
              textNossosContatos={textNossosContatos}
              textFoneContatos={textFoneContatos}
              textEmailContatos={textEmailContatos}
              textSaibaMaisContatos={textSaibaMaisContatos}
            />

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina2de2}>2 de 2</Text>

            {/* ************************************************ FIM Nossos Contatos *************************************************** */}
          </View>
        </Page>
      </>
    );
    const renderTwoPagesNotasEhNossosContatos = () => (
      <>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************** Cabeçalho *************************************************** */}
            <CabecalhoLaudo
              imageLogoTipoData={imageLogoTipoData}
              textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
              textArt={textArt}
              art={art}
              textLaudo={textLaudo}
              laudo={laudo}
              razaoSocialInguanti={razaoSocialInguanti}
            />
            {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
            <IdentificacaoAcessorio
              textIdentificacaoAcessorio={textIdentificacaoAcessorio}
              textTipo={textTipo}
              tipo={tipo}
              textTagIng={textTagIng}
              tagIng={tagIng}
              textTagCliente={textTagCliente}
              tagCliente={tagCliente}
              textFabricante={textFabricante}
              fabricante={fabricante}
              textModelo={textModelo}
              modelo={modelo}
              textCapacidade={textCapacidade}
              capacidade={capacidade}
              textDimensoes={textDimensoes}
              dimensoes={dimensoes}
            />
            {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

            {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
            <IdentificacaoProprietario
              textIdentificacaoProprietario={textIdentificacaoProprietario}
              textEmpresa={textEmpresa}
              empresa={empresa}
              textCnpj={textCnpj}
              cnpj={cnpj}
              textContato={textContato}
              contato={contato}
              textSetor={textSetor}
              setor={setor}
              textTelefone={textTelefone}
              telefone={telefone}
              textSubSetor={textSubSetor}
              subSetor={subSetor}
            />
            {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

            {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

            <DadosInspecaoExecutada
              textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
              textMetodologiaInspecao={textMetodologiaInspecao}
              metodologiaInspecao={metodologiaInspecao}
              textDataMetodologiaInspecao={textDataMetodologiaInspecao}
              dataMetodologiaInspecao={dataMetodologiaInspecao}
            />

            {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


            {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

            <DocumentacaoAcessorio
              textDocumentacaoAcessorio={textDocumentacaoAcessorio}
              textCertificadoFabricante={textCertificadoFabricante}
              certificadoFabricante={certificadoFabricante}
              textRegistroInspecao={textRegistroInspecao}
              registroInspecao={registroInspecao}
              textRegistroReparo={textRegistroReparo}
              registroReparo={registroReparo}

            />

            {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

            {/* ************************************************** 5 *************************************************** */}

            <EvidenciaFotograficas
              textEvidenciaFotografica={textEvidenciaFotografica}
              image1Data={image1Data}
              image2Data={image2Data}
              image3Data={image3Data}
              image4Data={image4Data}
            />
            {/* ************************************************ FIM 5 *************************************************** */}

            {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

            <ConclusaoTecnica
              textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
              textConclusaoTecnica={textConclusaoTecnica}
              conclusaoTecnica={conclusaoTecnica}
              textRecomendacoes={textRecomendacoes}
              recomendacoes={recomendacoes}
            />

            {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

            {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

            <ResponsaveisLaudo
              textCabecalhoInspetor={textCabecalhoInspetor}
              textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
              textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
              textNomeInspetor={textNomeInspetor}
              textNomeResponsavelLaudo={textNomeResponsavelLaudo}
              nomeResponsavelLaudo={nomeResponsavelLaudo}
              nomeInspetor={nomeInspetor}
              textNomeResponsavelTecnico={textNomeResponsavelTecnico}
              nomeResponsavelTecnico={nomeResponsavelTecnico}
              textNumeroDocumento={textNumeroDocumento}
              numeroDocumentoInspetor={numeroDocumentoInspetor}
              numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
              textCrea={textCrea}
              crea={crea}
              assinaturaInspetorImage={assinaturaInspetorImage}
              assinaturaResponsavelImage={assinaturaResponsavelImage}
              assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
            />

            {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina1de2}>1 de 2</Text>

          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ******************************************  Notas ******************************************* */}
            <Notas
              textNotas={textNotas}
              textNotaUm={textNotaUm}
              textNotaDois={textNotaDois}
              textReferencias={textReferencias}
            />

            {/* ****************************************** FIM Notas ******************************************* */}
            {/* ************************************************ Nossos Contatos *************************************************** */}

            <NossosContatos
              textNossosContatos={textNossosContatos}
              textFoneContatos={textFoneContatos}
              textEmailContatos={textEmailContatos}
              textSaibaMaisContatos={textSaibaMaisContatos}
            />

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina2de2}>2 de 2</Text>

            {/* ************************************************ FIM Nossos Contatos *************************************************** */}
          </View>
        </Page>
      </>
    );

    const renderTwoPages7 = () => (
      <>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>
            {/* ************************************************** Cabeçalho *************************************************** */}
            <CabecalhoLaudo
              imageLogoTipoData={imageLogoTipoData}
              textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
              textArt={textArt}
              art={art}
              textLaudo={textLaudo}
              laudo={laudo}
              razaoSocialInguanti={razaoSocialInguanti}
            />
            {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
            <IdentificacaoAcessorio
              textIdentificacaoAcessorio={textIdentificacaoAcessorio}
              textTipo={textTipo}
              tipo={tipo}
              textTagIng={textTagIng}
              tagIng={tagIng}
              textTagCliente={textTagCliente}
              tagCliente={tagCliente}
              textFabricante={textFabricante}
              fabricante={fabricante}
              textModelo={textModelo}
              modelo={modelo}
              textCapacidade={textCapacidade}
              capacidade={capacidade}
              textDimensoes={textDimensoes}
              dimensoes={dimensoes}
            />
            {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

            {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
            <IdentificacaoProprietario
              textIdentificacaoProprietario={textIdentificacaoProprietario}
              textEmpresa={textEmpresa}
              empresa={empresa}
              textCnpj={textCnpj}
              cnpj={cnpj}
              textContato={textContato}
              contato={contato}
              textSetor={textSetor}
              setor={setor}
              textTelefone={textTelefone}
              telefone={telefone}
              textSubSetor={textSubSetor}
              subSetor={subSetor}
            />
            {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

            {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

            <DadosInspecaoExecutada
              textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
              textMetodologiaInspecao={textMetodologiaInspecao}
              metodologiaInspecao={metodologiaInspecao}
              textDataMetodologiaInspecao={textDataMetodologiaInspecao}
              dataMetodologiaInspecao={dataMetodologiaInspecao}
            />

            {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


            {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

            <DocumentacaoAcessorio
              textDocumentacaoAcessorio={textDocumentacaoAcessorio}
              textCertificadoFabricante={textCertificadoFabricante}
              certificadoFabricante={certificadoFabricante}
              textRegistroInspecao={textRegistroInspecao}
              registroInspecao={registroInspecao}
              textRegistroReparo={textRegistroReparo}
              registroReparo={registroReparo}

            />

            {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

            {/* ************************************************** 5 *************************************************** */}

            <EvidenciaFotograficas
              textEvidenciaFotografica={textEvidenciaFotografica}
              image1Data={image1Data}
              image2Data={image2Data}
              image3Data={image3Data}
              image4Data={image4Data}
            />
            {/* ************************************************ FIM 5 *************************************************** */}

            {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

            <ConclusaoTecnica
              textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
              textConclusaoTecnica={textConclusaoTecnica}
              conclusaoTecnica={conclusaoTecnica}
              textRecomendacoes={textRecomendacoes}
              recomendacoes={recomendacoes}
            />

            {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina1de2}>1 de 2</Text>

          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={{ border: 2.5, height: 800 }}>


            {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

            <ResponsaveisLaudo
              textCabecalhoInspetor={textCabecalhoInspetor}
              textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
              textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
              textNomeInspetor={textNomeInspetor}
              textNomeResponsavelLaudo={textNomeResponsavelLaudo}
              nomeResponsavelLaudo={nomeResponsavelLaudo}
              nomeInspetor={nomeInspetor}
              textNomeResponsavelTecnico={textNomeResponsavelTecnico}
              nomeResponsavelTecnico={nomeResponsavelTecnico}
              textNumeroDocumento={textNumeroDocumento}
              numeroDocumentoInspetor={numeroDocumentoInspetor}
              numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
              textCrea={textCrea}
              crea={crea}
              assinaturaInspetorImage={assinaturaInspetorImage}
              assinaturaResponsavelImage={assinaturaResponsavelImage}
              assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
            />

            {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}

            {/* ******************************************  Notas ******************************************* */}
            <Notas
              textNotas={textNotas}
              textNotaUm={textNotaUm}
              textNotaDois={textNotaDois}
              textReferencias={textReferencias}
            />

            {/* ****************************************** FIM Notas ******************************************* */}
            {/* ************************************************ Nossos Contatos *************************************************** */}

            <NossosContatos
              textNossosContatos={textNossosContatos}
              textFoneContatos={textFoneContatos}
              textEmailContatos={textEmailContatos}
              textSaibaMaisContatos={textSaibaMaisContatos}
            />

            <Text style={styles.watermark1}>INGUANTI</Text>
            <Text style={styles.watermark2}>INGUANTI</Text>
            <Text style={styles.textPagina2de2}>2 de 2</Text>

            {/* ************************************************ FIM Nossos Contatos *************************************************** */}
          </View>
        </Page>
      </>
    );    

    // Cria variáveis locais e garante que sejam não-negativas
    const recomLength = recomendacoes.length > 0 ? recomendacoes.length : 0;
    const dimLength = dimensoes.length > 0 ? dimensoes.length : 0;

    const soma = recomLength + dimLength;
    if (soma > 725) {

      if (recomLength >= 805 && recomLength < 900) {        
      
        if (dimLength > 400) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (recomLength >= 900 && recomLength < 1000) { 
        if (dimLength > 289) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (recomLength >= 1000) {        
      
        if (dimLength > 284) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (dimLength >= 805 && dimLength < 900) {
        if (recomLength > 400) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (dimLength >= 900 && dimLength < 1000) {
        if (recomLength > 289) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }

      if (dimLength >= 1000) {
        if (recomLength > 284) {
          return (
            <Document>
              {renderTwoPages7()}
            </Document>
          );
        }
        else {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }      
      }  
      return (
        <Document>
          {renderTwoPagesNotasEhNossosContatos()}
        </Document>
      );
    }

    if (recomLength >= 440 || dimLength >= 450) {

      if (recomLength >= 440 && recomLength < 805) {
        if (dimLength < 450) {
          return (
            <Document>
              {renderTwoPagesNossosContatos()}
            </Document>
          );
        }
        else if (dimLength >= 450 && dimLength <= 800) {
          return (
            <Document>
              {renderTwoPagesNotasEhNossosContatos()}
            </Document>
          );
        }
      }

      if (dimLength >= 450 && dimLength < 900) {
        return (
          <Document>
            {renderTwoPagesNossosContatos()}
          </Document>
        );

      }
      if (dimLength >= 900) {
        return (
          <Document>
            {renderTwoPagesNotasEhNossosContatos()}
          </Document>
        );
      }
      if (dimLength >= 450 && recomLength >= 440) {
        return (
          <Document>
            {renderTwoPagesNossosContatos()}
          </Document>
        );
      }
    }

    else {
      return (
        <Document>
          {renderOnePage()}
        </Document>
      );
    }
  };

  // const DocumentPdf = () => (
  //   <Document>
  //     {recomendacoes.length >= 440 && recomendacoes.length < 805 ? (
  //       <>
  //         <Page size="A4" style={styles.page}>
  //           <View style={{ border: 2.5, height: 800 }}>
  //             {/* ************************************************** Cabeçalho *************************************************** */}
  //             <CabecalhoLaudo
  //               imageLogoTipoData={imageLogoTipoData}
  //               textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
  //               textArt={textArt}
  //               art={art}
  //               textLaudo={textLaudo}
  //               laudo={laudo}
  //               razaoSocialInguanti={razaoSocialInguanti}
  //             />
  //             {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
  //             <IdentificacaoAcessorio
  //               textIdentificacaoAcessorio={textIdentificacaoAcessorio}
  //               textTipo={textTipo}
  //               tipo={tipo}
  //               textTagIng={textTagIng}
  //               tagIng={tagIng}
  //               textTagCliente={textTagCliente}
  //               tagCliente={tagCliente}
  //               textFabricante={textFabricante}
  //               fabricante={fabricante}
  //               textModelo={textModelo}
  //               modelo={modelo}
  //               textCapacidade={textCapacidade}
  //               capacidade={capacidade}
  //               textDimensoes={textDimensoes}
  //               dimensoes={dimensoes}
  //               textDemaisinformacoes={textDemaisinformacoes}
  //               demaisInformacoes={demaisInformacoes}
  //             />
  //             {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

  //             {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
  //             <IdentificacaoProprietario
  //               textIdentificacaoProprietario={textIdentificacaoProprietario}
  //               textEmpresa={textEmpresa}
  //               empresa={empresa}
  //               textCnpj={textCnpj}
  //               cnpj={cnpj}
  //               textContato={textContato}
  //               contato={contato}
  //               textSetor={textSetor}
  //               setor={setor}
  //               textTelefone={textTelefone}
  //               telefone={telefone}
  //               textSubSetor={textSubSetor}
  //               subSetor={subSetor}
  //             />
  //             {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

  //             {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

  //             <DadosInspecaoExecutada
  //               textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
  //               textMetodologiaInspecao={textMetodologiaInspecao}
  //               metodologiaInspecao={metodologiaInspecao}
  //               textDataMetodologiaInspecao={textDataMetodologiaInspecao}
  //               dataMetodologiaInspecao={dataMetodologiaInspecao}
  //             />

  //             {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


  //             {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

  //             <DocumentacaoAcessorio
  //               textDocumentacaoAcessorio={textDocumentacaoAcessorio}
  //               textCertificadoFabricante={textCertificadoFabricante}
  //               certificadoFabricante={certificadoFabricante}
  //               textRegistroInspecao={textRegistroInspecao}
  //               registroInspecao={registroInspecao}
  //               textRegistroReparo={textRegistroReparo}
  //               registroReparo={registroReparo}

  //             />

  //             {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

  //             {/* ************************************************** 5 *************************************************** */}

  //             <EvidenciaFotograficas
  //               textEvidenciaFotografica={textEvidenciaFotografica}
  //               image1Data={image1Data}
  //               image2Data={image2Data}
  //               image3Data={image3Data}
  //               image4Data={image4Data}
  //             />
  //             {/* ************************************************ FIM 5 *************************************************** */}

  //             {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

  //             <ConclusaoTecnica
  //               textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
  //               textConclusaoTecnica={textConclusaoTecnica}
  //               conclusaoTecnica={conclusaoTecnica}
  //               textRecomendacoes={textRecomendacoes}
  //               recomendacoes={recomendacoes}
  //             />

  //             {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

  //             {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

  //             <ResponsaveisLaudo
  //               textCabecalhoInspetor={textCabecalhoInspetor}
  //               textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
  //               textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
  //               textNomeInspetor={textNomeInspetor}
  //               textNomeResponsavelLaudo={textNomeResponsavelLaudo}
  //               nomeResponsavelLaudo={nomeResponsavelLaudo}
  //               nomeInspetor={nomeInspetor}
  //               textNomeResponsavelTecnico={textNomeResponsavelTecnico}
  //               nomeResponsavelTecnico={nomeResponsavelTecnico}
  //               textNumeroDocumento={textNumeroDocumento}
  //               numeroDocumentoInspetor={numeroDocumentoInspetor}
  //               numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
  //               textCrea={textCrea}
  //               crea={crea}
  //               assinaturaInspetorImage={assinaturaInspetorImage}
  //               assinaturaResponsavelImage={assinaturaResponsavelImage}
  //               assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
  //             />

  //             {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


  //             {/* ******************************************  Notas ******************************************* */}
  //             <Notas
  //               textNotas={textNotas}
  //               textNotaUm={textNotaUm}
  //               textNotaDois={textNotaDois}
  //               textReferencias={textReferencias}
  //             />

  //             {/* ****************************************** FIM Notas ******************************************* */}

  //             <Text style={styles.watermark1}>INGUANTI</Text>
  //             <Text style={styles.watermark2}>INGUANTI</Text>
  //             <Text style={styles.textPagina1de2}>1 de 2</Text>

  //           </View>
  //         </Page>


  //         <Page size="A4" style={styles.page}>
  //           <View style={{ border: 2.5, height: 800 }}>
  //             {/* ************************************************ Nossos Contatos *************************************************** */}

  //             <NossosContatos
  //               textNossosContatos={textNossosContatos}
  //               textFoneContatos={textFoneContatos}
  //               textEmailContatos={textEmailContatos}
  //               textSaibaMaisContatos={textSaibaMaisContatos}
  //             />

  //             <Text style={styles.watermark1}>INGUANTI</Text>
  //             <Text style={styles.watermark2}>INGUANTI</Text>
  //             <Text style={styles.textPagina2de2}>2 de 2</Text>

  //             {/* ************************************************ FIM Nossos Contatos *************************************************** */}
  //           </View>

  //         </Page>

  //       </>
  //     ) : recomendacoes.length >= 805 ? (
  //       <>
  //         <Page size="A4" style={styles.page}>
  //           <View style={{ border: 2.5, height: 800 }}>
  //             {/* ************************************************** Cabeçalho *************************************************** */}
  //             <CabecalhoLaudo
  //               imageLogoTipoData={imageLogoTipoData}
  //               textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
  //               textArt={textArt}
  //               art={art}
  //               textLaudo={textLaudo}
  //               laudo={laudo}
  //               razaoSocialInguanti={razaoSocialInguanti}
  //             />
  //             {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
  //             <IdentificacaoAcessorio
  //               textIdentificacaoAcessorio={textIdentificacaoAcessorio}
  //               textTipo={textTipo}
  //               tipo={tipo}
  //               textTagIng={textTagIng}
  //               tagIng={tagIng}
  //               textTagCliente={textTagCliente}
  //               tagCliente={tagCliente}
  //               textFabricante={textFabricante}
  //               fabricante={fabricante}
  //               textModelo={textModelo}
  //               modelo={modelo}
  //               textCapacidade={textCapacidade}
  //               capacidade={capacidade}
  //               textDimensoes={textDimensoes}
  //               dimensoes={dimensoes}
  //               textDemaisinformacoes={textDemaisinformacoes}
  //               demaisInformacoes={demaisInformacoes}
  //             />
  //             {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

  //             {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
  //             <IdentificacaoProprietario
  //               textIdentificacaoProprietario={textIdentificacaoProprietario}
  //               textEmpresa={textEmpresa}
  //               empresa={empresa}
  //               textCnpj={textCnpj}
  //               cnpj={cnpj}
  //               textContato={textContato}
  //               contato={contato}
  //               textSetor={textSetor}
  //               setor={setor}
  //               textTelefone={textTelefone}
  //               telefone={telefone}
  //               textSubSetor={textSubSetor}
  //               subSetor={subSetor}
  //             />
  //             {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

  //             {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

  //             <DadosInspecaoExecutada
  //               textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
  //               textMetodologiaInspecao={textMetodologiaInspecao}
  //               metodologiaInspecao={metodologiaInspecao}
  //               textDataMetodologiaInspecao={textDataMetodologiaInspecao}
  //               dataMetodologiaInspecao={dataMetodologiaInspecao}
  //             />

  //             {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


  //             {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

  //             <DocumentacaoAcessorio
  //               textDocumentacaoAcessorio={textDocumentacaoAcessorio}
  //               textCertificadoFabricante={textCertificadoFabricante}
  //               certificadoFabricante={certificadoFabricante}
  //               textRegistroInspecao={textRegistroInspecao}
  //               registroInspecao={registroInspecao}
  //               textRegistroReparo={textRegistroReparo}
  //               registroReparo={registroReparo}

  //             />

  //             {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

  //             {/* ************************************************** 5 *************************************************** */}

  //             <EvidenciaFotograficas
  //               textEvidenciaFotografica={textEvidenciaFotografica}
  //               image1Data={image1Data}
  //               image2Data={image2Data}
  //               image3Data={image3Data}
  //               image4Data={image4Data}
  //             />
  //             {/* ************************************************ FIM 5 *************************************************** */}

  //             {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

  //             <ConclusaoTecnica
  //               textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
  //               textConclusaoTecnica={textConclusaoTecnica}
  //               conclusaoTecnica={conclusaoTecnica}
  //               textRecomendacoes={textRecomendacoes}
  //               recomendacoes={recomendacoes}
  //             />

  //             {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

  //             {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

  //             <ResponsaveisLaudo
  //               textCabecalhoInspetor={textCabecalhoInspetor}
  //               textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
  //               textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
  //               textNomeInspetor={textNomeInspetor}
  //               textNomeResponsavelLaudo={textNomeResponsavelLaudo}
  //               nomeResponsavelLaudo={nomeResponsavelLaudo}
  //               nomeInspetor={nomeInspetor}
  //               textNomeResponsavelTecnico={textNomeResponsavelTecnico}
  //               nomeResponsavelTecnico={nomeResponsavelTecnico}
  //               textNumeroDocumento={textNumeroDocumento}
  //               numeroDocumentoInspetor={numeroDocumentoInspetor}
  //               numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
  //               textCrea={textCrea}
  //               crea={crea}
  //               assinaturaInspetorImage={assinaturaInspetorImage}
  //               assinaturaResponsavelImage={assinaturaResponsavelImage}
  //               assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
  //             />

  //             {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}

  //             <Text style={styles.watermark1}>INGUANTI</Text>
  //             <Text style={styles.watermark2}>INGUANTI</Text>
  //             <Text style={styles.textPagina1de2}>1 de 2</Text>

  //           </View>
  //         </Page>


  //         <Page size="A4" style={styles.page}>
  //           <View style={{ border: 2.5, height: 800 }}>
  //             {/* ******************************************  Notas ******************************************* */}
  //             <Notas
  //               textNotas={textNotas}
  //               textNotaUm={textNotaUm}
  //               textNotaDois={textNotaDois}
  //               textReferencias={textReferencias}
  //             />

  //             {/* ****************************************** FIM Notas ******************************************* */}
  //             {/* ************************************************ Nossos Contatos *************************************************** */}

  //             <NossosContatos
  //               textNossosContatos={textNossosContatos}
  //               textFoneContatos={textFoneContatos}
  //               textEmailContatos={textEmailContatos}
  //               textSaibaMaisContatos={textSaibaMaisContatos}
  //             />

  //             <Text style={styles.watermark1}>INGUANTI</Text>
  //             <Text style={styles.watermark2}>INGUANTI</Text>
  //             <Text style={styles.textPagina2de2}>2 de 2</Text>

  //             {/* ************************************************ FIM Nossos Contatos *************************************************** */}
  //           </View>
  //         </Page>
  //       </>
  //     ) : (
  //       <>
  //         <Page size="A4" style={styles.page}>
  //           <View style={{ border: 2.5, height: 800 }}>
  //             {/* ************************************************** Cabeçalho *************************************************** */}
  //             <CabecalhoLaudo
  //               imageLogoTipoData={imageLogoTipoData}
  //               textLaudoCertificadoInspecao={textLaudoCertificadoInspecao}
  //               textArt={textArt}
  //               art={art}
  //               textLaudo={textLaudo}
  //               laudo={laudo}
  //               razaoSocialInguanti={razaoSocialInguanti}
  //             />
  //             {/* ***************************************** 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ****************************************** */}
  //             <IdentificacaoAcessorio
  //               textIdentificacaoAcessorio={textIdentificacaoAcessorio}
  //               textTipo={textTipo}
  //               tipo={tipo}
  //               textTagIng={textTagIng}
  //               tagIng={tagIng}
  //               textTagCliente={textTagCliente}
  //               tagCliente={tagCliente}
  //               textFabricante={textFabricante}
  //               fabricante={fabricante}
  //               textModelo={textModelo}
  //               modelo={modelo}
  //               textCapacidade={textCapacidade}
  //               capacidade={capacidade}
  //               textDimensoes={textDimensoes}
  //               dimensoes={dimensoes}
  //               textDemaisinformacoes={textDemaisinformacoes}
  //               demaisInformacoes={demaisInformacoes}
  //             />
  //             {/* ******************************************* FIM 1 IDENTIFICAÇÃO DO ACESSÓRIO/DISPOSITIVO ********************************************** */}

  //             {/* ********************************************* 2 Identificacao do Proprietario ********************************************** */}
  //             <IdentificacaoProprietario
  //               textIdentificacaoProprietario={textIdentificacaoProprietario}
  //               textEmpresa={textEmpresa}
  //               empresa={empresa}
  //               textCnpj={textCnpj}
  //               cnpj={cnpj}
  //               textContato={textContato}
  //               contato={contato}
  //               textSetor={textSetor}
  //               setor={setor}
  //               textTelefone={textTelefone}
  //               telefone={telefone}
  //               textSubSetor={textSubSetor}
  //               subSetor={subSetor}
  //             />
  //             {/* ******************************************* FIM 2 Identificacao do Proprietario ********************************************** */}

  //             {/* ********************************************* 3 Dados da inspeção Executada********************************************** */}

  //             <DadosInspecaoExecutada
  //               textDadosDaInspecaoExecutada={textDadosDaInspecaoExecutada}
  //               textMetodologiaInspecao={textMetodologiaInspecao}
  //               metodologiaInspecao={metodologiaInspecao}
  //               textDataMetodologiaInspecao={textDataMetodologiaInspecao}
  //               dataMetodologiaInspecao={dataMetodologiaInspecao}
  //             />

  //             {/* ************************************************ FIM 3 Dados da inspeção Executada *************************************************** */}


  //             {/* ************************************************** 4 Documentacao Acessorio*************************************************** */}

  //             <DocumentacaoAcessorio
  //               textDocumentacaoAcessorio={textDocumentacaoAcessorio}
  //               textCertificadoFabricante={textCertificadoFabricante}
  //               certificadoFabricante={certificadoFabricante}
  //               textRegistroInspecao={textRegistroInspecao}
  //               registroInspecao={registroInspecao}
  //               textRegistroReparo={textRegistroReparo}
  //               registroReparo={registroReparo}

  //             />

  //             {/* ************************************************ FIM 4 Documentacao Acessorio*************************************************** */}

  //             {/* ************************************************** 5 *************************************************** */}

  //             <EvidenciaFotograficas
  //               textEvidenciaFotografica={textEvidenciaFotografica}
  //               image1Data={image1Data}
  //               image2Data={image2Data}
  //               image3Data={image3Data}
  //               image4Data={image4Data}
  //             />
  //             {/* ************************************************ FIM 5 *************************************************** */}

  //             {/* ********************************************* 6 Conclusão tecnica ********************************************** */}

  //             <ConclusaoTecnica
  //               textCabecalhoConclusaoTecnica={textCabecalhoConclusaoTecnica}
  //               textConclusaoTecnica={textConclusaoTecnica}
  //               conclusaoTecnica={conclusaoTecnica}
  //               textRecomendacoes={textRecomendacoes}
  //               recomendacoes={recomendacoes}
  //             />

  //             {/* ******************************************* FIM 6 Conclusão tecnica ********************************************** */}

  //             {/* ****************************************** 7 Responsáveis Pelo Laudo ******************************************* */}

  //             <ResponsaveisLaudo
  //               textCabecalhoInspetor={textCabecalhoInspetor}
  //               textCabecalhoResponsavelTecnico={textCabecalhoResponsavelTecnico}
  //               textCabecalhoResponsavelLaudo={textCabecalhoResponsavelLaudo}
  //               textNomeInspetor={textNomeInspetor}
  //               textNomeResponsavelLaudo={textNomeResponsavelLaudo}
  //               nomeResponsavelLaudo={nomeResponsavelLaudo}
  //               nomeInspetor={nomeInspetor}
  //               textNomeResponsavelTecnico={textNomeResponsavelTecnico}
  //               nomeResponsavelTecnico={nomeResponsavelTecnico}
  //               textNumeroDocumento={textNumeroDocumento}
  //               numeroDocumentoInspetor={numeroDocumentoInspetor}
  //               numeroDocumentoRespLaudo={numeroDocumentoRespLaudo}
  //               textCrea={textCrea}
  //               crea={crea}
  //               assinaturaInspetorImage={assinaturaInspetorImage}
  //               assinaturaResponsavelImage={assinaturaResponsavelImage}
  //               assinaturaResponsavelLaudoImage={assinaturaResponsavelLaudoImage}
  //             />

  //             {/* ************************************************ FIM 7 Responsáveis Pelo Laudo *************************************************** */}


  //             {/* ******************************************  Notas ******************************************* */}
  //             <Notas
  //               textNotas={textNotas}
  //               textNotaUm={textNotaUm}
  //               textNotaDois={textNotaDois}
  //               textReferencias={textReferencias}
  //             />

  //             {/* ****************************************** FIM Notas ******************************************* */}

  //             {/* ************************************************ Nossos Contatos *************************************************** */}

  //             <NossosContatos
  //               textNossosContatos={textNossosContatos}
  //               textFoneContatos={textFoneContatos}
  //               textEmailContatos={textEmailContatos}
  //               textSaibaMaisContatos={textSaibaMaisContatos}
  //             />

  //             {/* ************************************************ FIM Nossos Contatos *************************************************** */}
  //           </View>
  //           <Text style={styles.watermark1}>INGUANTI</Text>
  //           <Text style={styles.watermark2}>INGUANTI</Text>
  //           <Text style={styles.textPagina1de1}>1 de 1</Text>
  //         </Page>

  //       </>
  //     )}
  //   </Document>
  // );

  const sendPdfToServer = async (blob) => {
   
    const formData = new FormData();
    let cleanedLaudo = laudo.replace(/[^a-zA-Z0-9\u00C0-\u00FF]|[\s]/g, "");
    formData.append("file", blob, `${cleanedLaudo}.pdf`);

    try {
      const result = await axios.post(baseApiPdf, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const response = await axios.get(
        `${baseApiGetPdf}/${cleanedLaudo}.pdf`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response?.data?.byteLength > 0) {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const contentType = response.headers["content-type"];
        const urlPdf = `data:${contentType};base64,${base64}`;

        onPdfUploadComplete && onPdfUploadComplete(true, urlPdf, cleanedLaudo);

      } else {
        onPdfUploadComplete && onPdfUploadComplete(false);
      }

    } catch (error) {
      console.error(error);
      onPdfUploadComplete && onPdfUploadComplete(false);
    }
  };


  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <BlobProvider document={<DocumentPdf />}>
        {({ blob, url, loading, error }) => {
          if (!loading && blob) {
            sendPdfToServer(blob);
            return (
              <>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  Visualizar Relatório
                </a>
                {uploadStatus === 'success' && <div>PDF enviado com sucesso!</div>}
                {uploadStatus === 'error' && <div>Erro ao enviar PDF para o servidor.</div>}
              </>
            );
          } else if (error) {
            return <div>Não foi possível gerar o relátorio</div>;
          } else {
            return <Loading />;
          }
        }}
      </BlobProvider>
    </div>
  );
};

export default ExportToPDF;
