import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';
import InputMask from 'react-input-mask';

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomTable from '../Shared/CustomJs/CustomTable';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { isCpfValid } from './../Shared/Utils/validations';
import { formatarDataComHora, renameFileWithTimestamp } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/ResponsavelLaudo`;

const ResponsavelLaudo = () => {

    const listOptions = [
        { value: "responsavelLaudo", label: "Resp. Laudo" },
        { value: "cpf", label: "CPF" },
        { value: "rg", label: "RG" },
        { value: "caminhoAssinatura", label: "Assinatura" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
    ];

    const excludeColumns = ["idResponsavelLaudo", "idUsuario", "ativo", "assinatura"]; // Colunas que você quer excluir da tabela

    const renderTableHeaders = () => {
        const emptyColumns = [<th key="empty1"></th>, <th key="empty2"></th>];

        const optionColumns = listOptions.map((option, index) => (
            <th key={index}>{option.label}</th>
        ));

        return [...emptyColumns, ...optionColumns];
    };

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [listResponsavelLaudoData, setListResponsavelLaudoData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    const [listUsuarioData, setListUsuarioData] = useState([]);
    const [constListUsuarioData, setConstListUsuarioData] = useState([]);

    const [idResponsavelLaudo, setIdResponsavelLaudo] = useState(null);

    //Add
    //const [newResponsavelLaudo, setNewResponsavelLaudo] = useState('');
    const [newCpf, setNewCpf] = useState('');
    const [newRg, setNewRg] = useState('');
    const [newAssinatura, setNewAssinatura] = useState('');
    const [newSelectedResponsavelLaudo, setNewSelectedResponsavelLaudo] = useState("");

    //Edit    
    //const [editResponsavelLaudo, setEditResponsavelLaudo] = useState(null);
    const [editCpf, setEditCpf] = useState(null);
    const [editRg, setEditRg] = useState(null);
    const [editSelectedResponsavelLaudo, setEditSelectedResponsavelLaudo] = useState("");

    const [modalMessage, setModalMessage] = useState('');

    const fileInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("responsavelLaudo");


    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitialData();
        }
    }, []);

    const fetchInitialData = async () => {
        try {
            setLoading(true);
            await fetchUsersData();
            await fetchResponsavelLaudoData();
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            handleLoadingFalse();
        }
    };

    const fetchUsersData = async () => {
        try {
            setSearchTerm('');
            let url = `${baseApi}/get-users?ativo=${true}`;
            const response = await axios.get(url);
            const formattedData = formatarDataComHora(response.data.users);
            setListUsuarioData(formattedData);
            setConstListUsuarioData(formattedData);

        } catch (error) {
            const errorMessage = error.response?.status
                ? `Erro ${error.response.status}: ${error.response.statusText}`
                : error.message;
            console.error('Erro ao buscar dados:', errorMessage);
            handleLoadingFalse();
        }
    };
    const fetchResponsavelLaudoData = async () => {
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-responsavel-laudo`);
            const formattedData = formatarDataComHora(response.data);

            setListResponsavelLaudoData(formattedData);
            setConstListData(formattedData);
            handleNoRecordsFoundAndLoadingFalse(response?.status);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            handleLoadingFalse();
        }
    };

    const handleSave = async (idUsuario, cpf, rg) => {

        if (
            !rg?.trim() ||
            !cpf?.trim() ||
            idUsuario <= 0
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!isCpfValid(cpf)) {
            setModalMessage('Erro. CPF inválido');
            return;
        }
        cpf = cpf.replace(/\D/g, '')
        rg = rg.replace(/\D/g, '')

        const itemExistsCpf = listResponsavelLaudoData.some((item) => item.cpf && item.cpf.replace(/\D/g, '') === cpf.replace(/\D/g, ''));
        const itemExistsRg = listResponsavelLaudoData.some((item) => item.rg && item.rg.replace(/\D/g, '') === rg.replace(/\D/g, ''));
        const itemExistsIdUsuario = listResponsavelLaudoData.some((item) => item.idUsuario === +idUsuario);

        if (itemExistsCpf) {
            setModalMessage(`Registro com CPF: ${cpf} já existe`);
        }
        else if (itemExistsRg) {
            setModalMessage(`Registro com RG: ${rg} já existe`);
        }
        else if (itemExistsIdUsuario) {
            setModalMessage(`Registro com esse nome já existe`);
        }
        else {
            setLoading(true);
            try {

                const originalFile = fileInputRef.current.files[0];
                const newFile = renameFileWithTimestamp(originalFile);

                const formData = new FormData();
                formData.append("IdUsuario", idUsuario);
                formData.append("Cpf", cpf);
                formData.append("Rg", rg);
                formData.append("Assinatura", newFile);

                const response = await axios.post(`${baseApi}/add-responsavel-laudo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response?.status === 200) {
                    setModalMessage(`${response?.data?.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setEditSelectedResponsavelLaudo(-1);
                        setNewSelectedResponsavelLaudo(-1);
                        setEditCpf(null);
                        setNewCpf('');
                        setEditRg(null);
                        setNewRg('');
                        fetchInitialData();
                        setListUsuarioData(constListUsuarioData);
                        const usuariosFiltrados = filtrarUsuarios(listUsuarioData, constListData);
                        // Atualize o estado com os usuários filtrados
                        setListUsuarioData(usuariosFiltrados);
                    }

                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao adicionar o responsável pelo laudo:', response?.data?.message);
                    setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao adicionar o responsável pelo laudo:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
                //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleUpdate = async (idUsuario, cpf, rg) => {

        if (
            !rg?.trim() ||
            !cpf?.trim() ||
            idUsuario <= 0
        ) {
            setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        if (!isCpfValid(cpf)) {
            setModalMessage('Erro. CPF inválido');
            return;
        }
        cpf = cpf.replace(/\D/g, '');
        rg = rg.replace(/\D/g, '');

        const itemExistsCpf = listResponsavelLaudoData.some((item) => item.idResponsavelLaudo !== idResponsavelLaudo && item.cpf && item.cpf.replace(/\D/g, '') === cpf.replace(/\D/g, ''));
        const itemExistsRg = listResponsavelLaudoData.some((item) => item.idResponsavelLaudo !== idResponsavelLaudo && item.rg && item.rg.replace(/\D/g, '') === rg.replace(/\D/g, ''));

        if (itemExistsCpf) {
            setModalMessage(`Registro com CPF: ${cpf} já existe`);
        }
        else if (itemExistsRg) {
            setModalMessage(`Registro com RG: ${rg} já existe`);
        }
        else {
            setLoading(true);
            try {

                const originalFile = fileInputRef.current.files[0];
                const newFile = renameFileWithTimestamp(originalFile);

                const formData = new FormData();
                formData.append("IdUsuario", idUsuario);
                formData.append("Cpf", cpf);
                formData.append("Rg", rg);
                formData.append("Assinatura", newFile);

                const response = await axios.put(`${baseApi}/update-responsavel-laudo/${idResponsavelLaudo}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response?.status === 200) {

                    setModalMessage(`${response?.data?.message}`);
                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        setEditSelectedResponsavelLaudo(-1);
                        setNewSelectedResponsavelLaudo(-1);
                        setEditCpf(null);
                        setNewCpf('');
                        setEditRg(null);
                        setNewRg('');
                        setShowModal(false);
                        Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                        setTimeout(() => {
                            fetchInitialData();
                            setListUsuarioData(constListUsuarioData);
                            const usuariosFiltrados = filtrarUsuarios(listUsuarioData, constListData);
                            // Atualize o estado com os usuários filtrados
                            setListUsuarioData(usuariosFiltrados);
                        }, 50);
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1);
                    console.error('Erro ao editar o responsável pelo laudo:', response?.data?.message);
                    setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1);
                console.error('Erro ao editar o responsável pelo laudo:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
            }
            setTimeout(() => {
                setLoading(false);
            }, 1);
        }
    };

    const handleDelete = async (item) => {
        setIdResponsavelLaudo(item.idResponsavelLaudo);
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const response = await axios.delete(`${baseApi}/delete-responsavel-laudo/${item.idResponsavelLaudo}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData();
                        });
                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleResponsavelLaudoChange = (event) => {
        isUpdate
            ? setEditSelectedResponsavelLaudo(event.target.value)
            : setNewSelectedResponsavelLaudo(event.target.value);
    };

    const handleCpfChange = (event) => {
        isUpdate
            ? setEditCpf(event.target.value.toUpperCase())
            : setNewCpf(event.target.value.toUpperCase());
    };

    const handleRgChange = (event) => {
        isUpdate
            ? setEditRg(event.target.value.toUpperCase())
            : setNewRg(event.target.value.toUpperCase());
    };

    const handleFileChange = (event) => {
        setNewAssinatura(event.target.value.toLowerCase());
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleLoadingFalse = () => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const handleNoRecordsFoundAndLoadingFalse = (status) => {
        if (status === 200) {
            handleLoadingFalse();
        }
    };

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    const openModalAdd = () => {

        setListUsuarioData(constListUsuarioData);
       const usuariosFiltrados = filtrarUsuarios(listUsuarioData, constListData);

       // Atualize o estado com os usuários filtrados
       setListUsuarioData(usuariosFiltrados);

        // Limpa as mensagens de erro ou informações e reseta os campos de formulário
        setModalMessage('');
        setNewSelectedResponsavelLaudo(-1);
        setNewCpf('');
        setNewRg('');

        setEditSelectedResponsavelLaudo(-1);
        setEditCpf(null);
        setEditRg(null);
        setIsUpdate(false);

        // Abre o modal
        setShowModal(true);
    };
    const openModalEdit = (item) => {
        setListUsuarioData(constListUsuarioData);     

        setModalMessage('');
        setIdResponsavelLaudo(item.idResponsavelLaudo);

        setEditSelectedResponsavelLaudo(item.idUsuario);
        setEditCpf(item.cpf);
        setEditRg(item.rg);

        setNewSelectedResponsavelLaudo(-1);
        setNewCpf(null);
        setNewRg(null);

        setIsUpdate(true);
        setShowModal(true);
    };
    const closeModal = () => {
        setModalMessage('');
        setNewSelectedResponsavelLaudo(-1);
        setNewCpf('');
        setNewRg('');
        setEditSelectedResponsavelLaudo(-1);
        setEditCpf(null);
        setEditRg(null);
        setIdResponsavelLaudo(null);
        setIsUpdate(false);
        setShowModal(false);
    };

    const filtrarUsuarios = (listUsuario, listResponsaveis) => {
        // Caso existir em listResponsaveis não deve aparecer em listUsuario
        const responsaveisExistentes = new Set(listResponsaveis.map(item => item.responsavelLaudo));
        return listUsuario.filter(user => !responsaveisExistentes.has(user.fullName));
    };

    if (loading) {
        return <Loading />;
    }   
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>Responsável pelo Laudo</h4></Card.Header>
                            <Card.Body>
                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    openModal={openModalAdd}
                                    fetchInitialData={fetchInitialData}
                                />

                                {constListData.length <= 0 && !loading && (
                                    <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                        <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                                    </div>
                                )}

                                {constListData.length > 0 && (
                                    <CustomTable
                                        renderTableHeaders={renderTableHeaders}
                                        data={paginatedData}
                                        handleEdit={openModalEdit}
                                        handleDelete={handleDelete}
                                        excludeColumns={excludeColumns}
                                        api={`${baseApi}/download-assinatura`}
                                        name={'responsavelLaudo'}
                                    />
                                )}

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                            </Card.Body>

                        </Card>
                    </Form>
                </Col>
            </Row>

            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'Responsável Laudo - Editar' : 'Responsável Laudo - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(editSelectedResponsavelLaudo, editCpf, editRg)
                    : () => handleSave(newSelectedResponsavelLaudo, newCpf, newRg)}
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
            >
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="responsavelLaudo">
                        <Form.Label className="bold-label">*Resp. Laudo:</Form.Label>
                        <Form.Select
                            value={isUpdate ? editSelectedResponsavelLaudo : newSelectedResponsavelLaudo}
                            autoFocus={isUpdate
                                ? true
                                : false}
                            onChange={handleResponsavelLaudoChange}
                        >
                            <option key={-1} value={"-1"}>SELECIONE</option>

                            {listUsuarioData.map((item, index) => (
                                <option
                                    key={item.id}
                                    value={item.id}
                                >
                                    {item.fullName}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} controlId="cpf">
                        <Form.Label className="bold-label mt-3">*CPF:</Form.Label>
                        <InputMask
                            mask="999.999.999-99"
                            value={isUpdate
                                ? editCpf
                                : newCpf}
                            onChange={handleCpfChange}
                        >
                            {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="Digite o CPF" />}
                        </InputMask>
                    </Form.Group>
                    <Form.Group as={Col} controlId="rg">
                        <Form.Label className="bold-label mt-3">*RG:</Form.Label>
                        <InputMask
                            mask="99.999.999-9"
                            value={isUpdate
                                ? editRg
                                : newRg}
                            onChange={handleRgChange}
                        >
                            {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="Digite o RG" />}
                        </InputMask>
                    </Form.Group>
                </Row>

                <Form.Group controlId="assinatura">
                    <Form.Label className="bold-label mt-3">*Assinatura do Resp.:</Form.Label>
                    <Form.Control
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                    />
                </Form.Group>


                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>
        </Container>
    )
};

export default ResponsavelLaudo;
