
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomTable from '../Shared/CustomJs/CustomTable';
import TableOrdemServico from '../Shared/CustomJs/TableOrdemServico';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/OrdemServico`;
const baseApiClientes = `${baseApiUrl}/api/Clientes`;
const baseApiInspetores = `${baseApiUrl}/api/Inspetor`;
const baseApiResponsaveisTecnicos = `${baseApiUrl}/api/ResponsavelTecnico`;


const OrdemServico = () => {

    const listOptions = [
        { value: "os", label: "N° O.S." },
        { value: "cliente", label: "Cliente" },
        { value: "inspetor", label: "Inspetor" },
        { value: "responsavelTecnico", label: "Resp. Téc." },
        { value: "art", label: "N° Art" },
        { value: "cadastradoPor", label: "Cadastrado Por" },
        { value: "dataCadastro", label: "Data Cadastro" },
        { value: "alteradoPor", label: "Alterado Por" },
        { value: "dataAlteracao", label: "Data Alteração" },
    ];

    const listRazaoSocialInguanti = [
        { id: 1, razaoSocial: "EQUIPAMENTOS LTDA - CNPJ 41.814.557/0001-43" },
        { id: 2, razaoSocial: "ENGENHARIA LTDA - CNPJ 15.066.495/0001-29" }
    ];

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [listOrdemServicoData, setListOrdemServicoData] = useState([]);
    const [constListData, setConstListData] = useState([]);

    //Add
    const [newOs, setNewOs] = useState('');
    const [newSelectedCliente, setNewSelectedCliente] = useState("");
    const [newSelectedInspetor, setNewSelectedInspetor] = useState("");
    const [newSelectedResponsavelTecnico, setNewSelectedResponsavelTecnico] = useState("");
    const [newArt, setNewArt] = useState('');
    const [newSelectedRazaoSocialInguanti, setNewSelectedRazaoSocialInguanti] = useState('');
    const [isNewFinished, setIsNewFinished] = useState(true);

    //Edit  
    const [editOs, setEditOs] = useState(null);
    const [editSelectedCliente, setEditSelectedCliente] = useState(null);
    const [editSelectedInspetor, setEditSelectedInspetor] = useState(null);
    const [editSelectedResponsavelTecnico, setEditSelectedResponsavelTecnico] = useState(null);
    const [editArt, setEditArt] = useState(null);
    const [editSelectedRazaoSocialInguanti, setEditSelectedRazaoSocialInguanti] = useState(null);
    const [isEditFinished, setIsEditFinished] = useState(null);

    const [idOrdemServico, setIdOrdemServico] = useState(null);

    const [modalMessage, setModalMessage] = useState('');
    const itemInputRef = useRef(null);

    const [selectedOption, setSelectedOption] = useState("os");

    const [listClientes, setListClientes] = useState([]);
    const [listInspetores, setListInspetores] = useState([]);
    const [listResponsaveisTecnicos, setListResponsaveisTecnicos] = useState([]);

    //const [listRazaoSocialInguanti, setListRazaoSocialInguanti] = useState(['EQUIPAMENTOS LTDA - CNPJ 41.814.557/0001-43', 'ENGENHARIA LTDA - CNPJ 15.066.495/0001-29']);

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            fetchInitialData();
        }
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        fetchClienteData();
        fetchOrdemServicoData();
        fetchInspetorData();
        fetchResponsavelTecnicoData();

    };

    const fetchOrdemServicoData = async () => {
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-ordemservicos`);
            const formattedData = formatarDataComHora(response.data);
            setListOrdemServicoData(formattedData);
            setConstListData(formattedData);
            handleNoRecordsFoundAndLoadingFalse(response?.status);
        } catch (error) {
            handleLoadingFalse();
            console.error('Erro ao buscar dados:', error);
        }
    };

    const fetchClienteData = async () => {
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApiClientes}/get-clientes`);
            const formattedData = formatarDataComHora(response.data);
            // Ordenando o array formattedData por nome
            formattedData.sort((a, b) => a.nome.localeCompare(b.nome));
            setListClientes(formattedData);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            handleLoadingFalse();
        }

    };

    const fetchInspetorData = async () => {
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApiInspetores}/get-inspetores`);
            const formattedData = formatarDataComHora(response.data);
            setListInspetores(formattedData);
        } catch (error) {
            handleLoadingFalse();
            console.error('Erro ao buscar dados:', error);
        }

    };

    const fetchResponsavelTecnicoData = async () => {
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApiResponsaveisTecnicos}/get-responsaveis-tecnicos`);
            const formattedData = formatarDataComHora(response.data);
            setListResponsaveisTecnicos(formattedData);
        } catch (error) {
            handleLoadingFalse();
            console.error('Erro ao buscar dados:', error);
        }

    };

    const handleSave = async (newOs, newSelectedCliente, newSelectedInspetor, newSelectedResponsavelTecnico, newArt, isNewFinished, newSelectedRazaoSocialInguanti) => {
        if (
            !newArt?.trim() ||
            !newOs?.trim() ||
            newSelectedCliente <= 0 ||
            newSelectedInspetor <= 0 ||
            newSelectedRazaoSocialInguanti <= 0 ||
            newSelectedResponsavelTecnico <= 0
        ) {
            setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
            return;
        }

        const itemExists = listOrdemServicoData.some((item) => item.newOs && item.newOs.toLowerCase() === newOs.toLowerCase());
        if (itemExists) {
            setModalMessage(`Registro ${newOs} já existe`);
        } else {
            setLoading(true);
            try {
                const newItem = {
                    IdCliente: newSelectedCliente,
                    IdInspetor: newSelectedInspetor,
                    IdResponsaveltecnico: newSelectedResponsavelTecnico,
                    Os: newOs,
                    Art: newArt,
                    RazaoSocialInguanti: newSelectedRazaoSocialInguanti,
                    Finalizado: isNewFinished
                };

                const response = await axios.post(`${baseApi}/add-ordemservico`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response?.data?.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        clearAll();
                        fetchInitialData();
                    }

                } else {
                    handleLoadingFalse();
                    console.error('Erro ao adicionar o acessório:', response?.data?.message);
                    setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                console.error('Erro ao adicionar o acessório:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
                //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
            }
            handleLoadingFalse();
        }
    };

    const handleUpdate = async (editOs, editSelectedCliente, editSelectedInspetor, editSelectedResponsavelTecnico, editArt, isEditFinished, editSelectedRazaoSocialInguanti) => {

        if (
            !editArt?.trim() ||
            !editOs?.trim() ||
            editSelectedCliente <= 0 ||
            editSelectedInspetor <= 0 ||
            editSelectedRazaoSocialInguanti <= 0 ||
            editSelectedResponsavelTecnico <= 0
        ) {
            setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
            return;
        }


        const itemExists = listOrdemServicoData.some((item) => item.editOs && item.editOs.toLowerCase() === editOs.toLowerCase());
        if (itemExists) {
            setModalMessage(`Registro ${editOs} já existe`);
        } else {
            setLoading(true);

            try {

                const newItem = {
                    IdCliente: editSelectedCliente,
                    IdInspetor: editSelectedInspetor,
                    IdResponsaveltecnico: editSelectedResponsavelTecnico,
                    Os: editOs,
                    Art: editArt,
                    RazaoSocialInguanti: editSelectedRazaoSocialInguanti,
                    Finalizado: isEditFinished
                };

                const response = await axios.put(`${baseApi}/update-ordemservico/${idOrdemServico}`, newItem);

                if (response?.status === 200) {
                    setModalMessage(`${response.data.message}`);

                    if (!(response?.data?.message.toLowerCase().includes('erro')
                        || response?.data?.message.toLowerCase().includes('existe'))
                    ) {
                        clearAll();
                        setShowModal(false);
                        Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                        setTimeout(() => {
                            fetchInitialData();
                        }, 50);
                    }
                    handleLoadingFalse();
                } else {
                    handleLoadingFalse();
                    console.error('Erro ao editar o acessório:', response?.data?.message);
                    setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                }
            } catch (error) {
                handleLoadingFalse();
                console.error('Erro ao editar o acessório:', error?.response?.data?.errors[0]);
                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
            }
            handleLoadingFalse();
        }
    };

    const handleDelete = async (item) => {
        Swal.fire({
            title: 'Tem certeza?',
            text: 'Você tem certeza que deseja excluir esse registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${baseApi}/delete-ordemservico/${item.idOrdemServico}`);
                    if (response?.status === 200) {
                        Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                            fetchInitialData();
                        });

                    } else {
                        console.error('Erro ao deletar o registro:', response?.data?.message);
                    }
                } catch (error) {
                    console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
            }
        });
    };   

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleOSChange = (event) => {
        isUpdate
            ? setEditOs(event.target.value.toUpperCase())
            : setNewOs(event.target.value.toUpperCase());
    };

    const handleClienteChange = (event) => {
        isUpdate
            ? setEditSelectedCliente(event.target.value)
            : setNewSelectedCliente(event.target.value);
    }

    const handleInspetorChange = (event) => {
        isUpdate
            ? setEditSelectedInspetor(event.target.value)
            : setNewSelectedInspetor(event.target.value);
    }
    const handleResponsavelTecnicoChange = (event) => {
        isUpdate
            ? setEditSelectedResponsavelTecnico(event.target.value)
            : setNewSelectedResponsavelTecnico(event.target.value);
    }
    const handleArtChange = (event) => {
        isUpdate
            ? setEditArt(event.target.value.toUpperCase())
            : setNewArt(event.target.value.toUpperCase());
    }
    const handleCheckboxChange = (event) => {
        isUpdate
            ? setIsEditFinished(event.target.checked)
            : setIsNewFinished(event.target.checked);
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleOsAcessorios = (idOrdemServico) => {
        navigate(`/os-acessorios/${idOrdemServico}`);
    }
    const handleLoadingFalse = () => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };
    const handleRazaoSocialInguantiChange = (event) => {
        isUpdate
            ? setEditSelectedRazaoSocialInguanti(event.target.value)
            : setNewSelectedRazaoSocialInguanti(event.target.value);

    };
    const handleNoRecordsFoundAndLoadingFalse = (status) => {
        if (status === 200) {
            handleLoadingFalse();
        }
    };

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    const openModalAdd = () => {
        setModalMessage('');
        setNewOs('');
        setNewArt('');
        setNewSelectedCliente(-1);
        setNewSelectedInspetor(-1);
        setNewSelectedResponsavelTecnico(-1);
        setNewSelectedRazaoSocialInguanti(-1);
        setIsNewFinished(false);

        setEditOs('');
        setEditArt('');
        setEditOs(null);
        setEditSelectedCliente(-1);
        setEditSelectedInspetor(-1);
        setEditSelectedResponsavelTecnico(-1);
        setEditSelectedRazaoSocialInguanti(-1);

        setIdOrdemServico(null);
        setIsUpdate(false);
        setShowModal(true);
    };
    const openModalEdit = (item) => {
        setIdOrdemServico(item.idOrdemServico);
        setModalMessage('');

        setEditOs(item.os);
        setEditArt(item.art);
        setEditSelectedCliente(item.idCliente);
        setEditSelectedInspetor(item.idInspetor);
        setEditSelectedResponsavelTecnico(item.idResponsaveltecnico);
        setEditSelectedRazaoSocialInguanti(item.razaoSocialInguanti);
        setIsEditFinished(item.finalizado);

        setNewOs('');
        setNewArt('');
        setNewSelectedCliente(-1);
        setNewSelectedInspetor(-1);
        setNewSelectedResponsavelTecnico(-1);
        setNewSelectedRazaoSocialInguanti(-1);
        setIsNewFinished(false);

        setIsUpdate(true);
        setShowModal(true);
    };
    const closeModal = () => {
        setModalMessage('');
        clearAll();
        setIsUpdate(false);
        setShowModal(false);
    };
    function clearAll() {
        setNewOs('');
        setNewArt('');
        setNewSelectedCliente(-1);
        setNewSelectedInspetor(-1);
        setNewSelectedResponsavelTecnico(-1);
        setIsNewFinished(false);

        setEditOs('');
        setEditArt('');
        setEditOs(null);
        setEditSelectedCliente(-1);
        setEditSelectedInspetor(-1);
        setEditSelectedResponsavelTecnico(-1);
        setIsEditFinished(false);

        setIdOrdemServico(null);
    }

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0 && loading === false) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>O.S.</h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>O.S.</h4></Card.Header>
                            <Card.Body>

                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    openModal={openModalAdd}
                                    fetchInitialData={fetchInitialData}
                                />

                                <TableOrdemServico
                                    data={paginatedData}
                                    handleEdit={openModalEdit}
                                    handleDelete={handleDelete}
                                    handleOsAcessorios={handleOsAcessorios}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>


            <CustomModal
                show={showModal}
                onHide={closeModal}
                title={isUpdate ? 'O.S. - Editar' : 'O.S. - Incluir'}
                onSave={isUpdate
                    ? () => handleUpdate(editOs, editSelectedCliente, editSelectedInspetor, editSelectedResponsavelTecnico, editArt, isEditFinished, editSelectedRazaoSocialInguanti)
                    : () => handleSave(newOs, newSelectedCliente, newSelectedInspetor, newSelectedResponsavelTecnico, newArt, isNewFinished, newSelectedRazaoSocialInguanti)}
                size="lg"
                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
            >
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="os">
                        <Form.Label className="bold-label">*N° O.S::</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite a O.S."
                            autoFocus={isUpdate
                                ? false
                                : true}
                            value={isUpdate
                                ? editOs
                                : newOs}
                            disabled={isUpdate
                                ? true
                                : false}
                            onChange={handleOSChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="cliente">
                        <Form.Label className="bold-label">*Cliente:</Form.Label>
                        <Form.Select
                            value={isUpdate ? editSelectedCliente : newSelectedCliente}
                            autoFocus={isUpdate
                                ? true
                                : false}
                            onChange={handleClienteChange}
                        >
                            <option key={-1} value={"-1"}>SELECIONE</option>

                            {listClientes.map((cliente, index) => (
                                <option
                                    key={index}
                                    value={cliente.idCliente}
                                >
                                    {cliente.cliente}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="inspetor">
                        <Form.Label className="bold-label">*Inspetor:</Form.Label>
                        <Form.Select
                            value={isUpdate ? editSelectedInspetor : newSelectedInspetor}
                            onChange={handleInspetorChange}
                        >
                            <option key={-1} value={"-1"}>SELECIONE</option>

                            {listInspetores.map((p, index) => (
                                <option
                                    key={index}
                                    value={p.idInspetor}
                                >
                                    {p.nome}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} controlId="responsaveltecnico">
                        <Form.Label className="bold-label">*Responsável Técnico::</Form.Label>
                        <Form.Select
                            value={isUpdate ? editSelectedResponsavelTecnico : newSelectedResponsavelTecnico}
                            onChange={handleResponsavelTecnicoChange}
                        >
                            <option key={-1} value={"-1"}>SELECIONE</option>

                            {listResponsaveisTecnicos.map((p, index) => (
                                <option
                                    key={index}
                                    value={p.idResponsavelTecnico}
                                >
                                    {p.nome}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>

                <Form.Group controlId="art">
                    <Form.Label className="bold-label">*N° ART:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite a ART"
                        value={isUpdate
                            ? editArt
                            : newArt}
                        onChange={handleArtChange}
                    />
                </Form.Group>

                <Row className="mb-3  mt-3">
                    <Form.Group as={Col} controlId="razaoSocialInguanti">
                        <Form.Label className="bold-label">*Razão Social/CNPJ:</Form.Label>
                        <Form.Select
                            value={isUpdate ? editSelectedRazaoSocialInguanti : newSelectedRazaoSocialInguanti}
                            autoFocus={isUpdate
                                ? true
                                : false}
                            onChange={handleRazaoSocialInguantiChange}
                        >
                            <option key={-1} value={"-1"}>SELECIONE</option>

                            {listRazaoSocialInguanti.map((item, id) => (
                                <option
                                    key={id}
                                    value={item.razaoSocial}
                                >
                                    {item.razaoSocial}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>

                <Form.Group className="mb-2 mt-3" controlId="finalizado">
                    <Form.Check
                        type="checkbox"
                        label="Finalizado"
                        checked={isUpdate
                            ? isEditFinished
                            : isNewFinished}
                        onChange={handleCheckboxChange}
                        disabled={isUpdate
                            ? false
                            : true}
                    />
                </Form.Group>

                {modalMessage && (
                    <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                        {modalMessage}
                    </p>
                )}
            </CustomModal>           
        </Container>
    )
};

export default OrdemServico;
