// SessionCheckWrapper.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';

let timerInterval;

let WARNING_TIME_IN_SECONDS = 420; // 7 minutos
let tokenCheckInterval = 1000;

const SessionCheckWrapper = ({ children }) => {
  const location = useLocation(); // Pegue a localização atual

  useEffect(() => {
    if (location.pathname !== "/login") {
      const now = new Date();
      const millisecondsUntilNextMinute = 60 * 1000 - (now.getSeconds() * 1000 + now.getMilliseconds());
      const timeoutId = setTimeout(() => {
        tokenCheckInterval = setInterval(checkTokenStatus, 60 * 1000);
      }, millisecondsUntilNextMinute);

      return () => {
        clearTimeout(timeoutId);
        clearInterval(tokenCheckInterval);
      };
    }
  }, [location.pathname]);


  //let hasNotified = false;
  let numberOfNotificationsCanAppear = localStorage.getItem("numberOfNotificationsCanAppear");
  let parsedNumber = parseInt(numberOfNotificationsCanAppear != null ? numberOfNotificationsCanAppear : "0"
  );

  const checkTokenStatus = async () => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      const user = JSON.parse(storedUser);
      const token = user.token;
      if (token) {
        const decoded = jwtDecode(token);
        const expirationTimeInSeconds = decoded.exp;
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        const timeRemainingTrue = expirationTimeInSeconds - currentTimeInSeconds;

        //Colocar para expirar um pouco antes do jwt token do servidor (6 minutos antes)
        let timeRemaining = timeRemainingTrue - 360;
        localStorage.setItem("timeRemaining", timeRemaining.toString());

        // Se o token estiver expirado.
        if (timeRemaining <= 0) {
          sessionExpiredNotification();
          clearInterval(tokenCheckInterval);
          console.log("Expirou");
          return;
        }

        // Se faltar 3 minutos ou menos e o usuário ainda não foi notificado.
        if (timeRemaining <= WARNING_TIME_IN_SECONDS && parsedNumber > 0) {
          let shouldNotify = false;

          if (parsedNumber === 3 && timeRemaining > 179 && timeRemaining <= 239) {
            shouldNotify = true;
          } else if (parsedNumber === 2 && timeRemaining > 120 && timeRemaining <= 179) {
            shouldNotify = true;
          } else if (parsedNumber === 1 && timeRemaining > 60 && timeRemaining <= 119) {
            shouldNotify = true;
          }
          else {
            if (parsedNumber <= 3 && timeRemaining > 60 && timeRemaining < 239) {
              shouldNotify = true;
            }
          }

          if (shouldNotify) {
            notifyExpirationWarning(timeRemaining);
            parsedNumber--;
            localStorage.setItem("numberOfNotificationsCanAppear", parsedNumber.toString());
          }
        }
      }
    }
  };

  const notifyExpirationWarning = (timeRemaining) => {
    const now = new Date();

    // Obtendo a data e hora atual
    let currentDay = now.getDate();
    let currentMonth = now.getMonth() + 1;
    let currentYear = now.getFullYear();
    let currentHour = now.getHours();
    let currentMinute = now.getMinutes();
    let currentSecond = now.getSeconds();

    const currentDateTime = new Date(`${currentDay}/${currentMonth}/${currentYear} ${currentHour}:${currentMinute}:${currentSecond}`);

    // Recupera a data de expiração da sessionStorage
    const expirationDateString = sessionStorage.getItem("notifyExpirationWarningDate");
    const expirationDate = expirationDateString ? new Date(expirationDateString) : null; 

    // Se não houver data de expiração ou a data atual for maior do que a data de expiração, defina uma nova data de expiração
    if (!expirationDate || currentDateTime > expirationDate) {

      now.setSeconds(now.getSeconds() + 40);

      let newDay = now.getDate();
      let newMonth = now.getMonth() + 1;
      let newYear = now.getFullYear();
      let newHour = now.getHours();
      let newMinute = now.getMinutes();
      let newSecond = now.getSeconds();

      const newExpirationDateString = `${newDay}/${newMonth}/${newYear} ${newHour}:${newMinute}:${newSecond}`;
      sessionStorage.setItem("notifyExpirationWarningDate", newExpirationDateString);

      const timeInMinutes = Math.floor(timeRemaining / 60); // Convertendo segundos em minutos

      const warningMessage = `Sua sessão expirará em aproximadamente ${timeInMinutes} minuto(s). Por favor, considere fazer login novamente.`;

      // Chame a função de notificação com a mensagem
      notifyToast(warningMessage);
    }
  };

  const sessionExpiredNotification = () => {
    Swal.fire({
      icon: "error",
      title: "Sessão Expirada!",
      html: `Sua sessão expirou. Por favor, faça login novamente. <br><br> Redirecionando em <b id="countdown">10</b> segundos...`,
      timer: 10000,
      timerProgressBar: true,
      willOpen: () => {
        timerInterval = setInterval(() => {
          const b = document.getElementById("countdown");
          if (b) {
            b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/login");
    });
  };

  return children;
};

export default SessionCheckWrapper;

// Você pode colocar essa função em um arquivo separado ou no mesmo arquivo do SessionCheckWrapper
export const notifyToast = (message) => {
  toast.info(message, {
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "dark",
  });
};

