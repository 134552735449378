const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "10%",
        maxWidth: "100%",
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/img/png/Inguanti_Inspecoes_new.png`}
        alt="Inguanti Inspeções"
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
    </div>
  );
};

export default Home;
