import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardAccessoryPieChart = ({ acessorio, data }) => {
  const total = data.aprovado + data.aprovadoRecomendacoes + data.reprovado;

  // Calcula as porcentagens
  const porcentagens = [
    ((data.aprovado / total) * 100).toFixed(2),
    ((data.aprovadoRecomendacoes / total) * 100).toFixed(2),
    ((data.reprovado / total) * 100).toFixed(2)
  ];

  // Filtra os dados para remover valores 0 apenas na exibição
  const filteredData = porcentagens.filter(value => value > 0);
  const filteredLabels = ['Aprovado', 'Aprovado com Recomend.', 'Reprovado'].filter((label, index) => porcentagens[index] > 0);
  const filteredBackgroundColors = ['#28a745', '#ffc107', '#dc3545'].filter((color, index) => porcentagens[index] > 0);

  // Mantém as legendas fixas, independentemente dos valores reais presentes
  const chartData = {
    labels: ['Aprovado', 'Aprovado com Recomend.', 'Reprovado'],
    datasets: [
      {
        label: `${acessorio}`,
        data: porcentagens,
        backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
        borderColor: ['#28a745', '#ffc107', '#dc3545'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 20,  // Define o tamanho das caixas de legenda
          padding: 10,   // Espaçamento entre as caixas de legenda e o texto
          font: {
            size: 13,  // Padroniza o tamanho da fonte na legenda
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      datalabels: {
        color: 'black',
        font: {
          size: 14,  // Padroniza o tamanho da fonte
          weight: 'bold',
        },
        formatter: (value) => value > 0 ? `${value}%` : '',
      },
    },
  };

  return (
    <div style={{ width: '250px', margin: '10px' }}>
      <h5 style={{ textAlign: 'center', minHeight: '40px' }}>{acessorio}</h5>  {/* Define uma altura mínima para os títulos */}
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default DashboardAccessoryPieChart;
