import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";


const DocumentacaoAcessorio = ({
    textDocumentacaoAcessorio,
    textCertificadoFabricante,
    certificadoFabricante,
    textRegistroInspecao,
    registroInspecao,
    textRegistroReparo,
    registroReparo
}) => (
    <>
        {/* Documentação dos acessórios/dispositivo */}
        <View style={[styles.rectangleTitle, { marginTop: 8 }]}>
            <Text style={styles.numberText}>4.</Text>
            <View style={styles.verticalLine}></View>
            <Text style={{ paddingLeft: 140, fontSize: 9, fontFamily: "Arial", fontWeight: "bold" }}>
                {textDocumentacaoAcessorio}
            </Text>
        </View>

        {/* Certificado e Registros */}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '35.0%' }]}>
                {textCertificadoFabricante}
                {certificadoFabricante}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '31.8%' }]}>
                {textRegistroInspecao}
                {registroInspecao}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '26.5%' }]}>
                {textRegistroReparo}
                {registroReparo}
            </Text>
        </View>
    </>
);
export default DocumentacaoAcessorio;
