import React, { } from 'react';
import { Line } from 'react-chartjs-2';
import { Row, Card } from 'react-bootstrap';

//Compartilhados
import '../sharedStyles.css';

//CHARTS
import { Chart, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js/auto';
import "chartjs-plugin-datalabels";
import { Chart as chartRegister } from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";
chartRegister.register(ChartDataLabels);;

const LineChartInspecoesTodosMeses = ({ data, currentYear, lastYear, penultimateYear }) => {

  const months = data.currentYear.map(item => item.mes);
  const quantitiesCurrentYear = data.currentYear.map(item => item.quantidade);
  const quantitiesLastYear = data.lastYear.map(item => item.quantidade);
  const quantitiesPenultimateYear = data.penultimateYear.map(item => item.quantidade);

  const chartData = {
    labels: months,
    datasets: [
      {
        label: currentYear,
        data: quantitiesCurrentYear,
        borderColor: '#007BFF',
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
        pointBackgroundColor: '#007BFF',
        fill: 'start',
      },
      {
        label: lastYear,
        data: quantitiesLastYear,
        borderColor: '#FF4500',
        backgroundColor: 'rgba(255, 69, 0, 0.2)',
        pointBackgroundColor: '#FF4500',
        fill: 'start',
      },
      {
        label: penultimateYear,
        data: quantitiesPenultimateYear,
        borderColor: '#32CD32',
        backgroundColor: 'rgb(50, 205, 50,0.2)',
        pointBackgroundColor: '#32CD32',
        fill: 'start',
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          color: function (context) {
            if (context.tick.value === 0) {
              return '#ff0000';
            } else {
              return 'rgba(0, 0, 0, 0.1)';
            }
          },
        }
      },
      x: {
        grid: {
          display: false,
        }
      }
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 3, // Defina o raio para mostrar os pontos
        hoverRadius: 5, // Tamanho do ponto ao passar o mouse
        hitRadius: 5, // Quão perto o cursor deve estar para detectar o ponto
      }
    },
    interaction: {
      mode: 'index', // Destaca todos os itens no mesmo índice
      intersect: false, // Não requer que o cursor esteja diretamente sobre o ponto
    },
    plugins: {
      filler: {
        propagate: true,
      },
      legend: {
        display: true,
        position: 'top'
      },
      title: {
        display: false,
        text: "Quantidade de inspeções nos últimos 3 anos",
      },
      tooltip: { // Configurações para a tooltip (caixa de informações)
        enabled: true,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        displayColors: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          }
        }
      },
      datalabels: {
        display: true, // habilita os rótulos de dados
        color: 'black', // cor do texto dos rótulos
        anchor: 'end', // onde o rótulo de dados será ancorado
        align: 'top', // alinhamento do rótulo de dados
        offset: -2, // deslocamento do ponto de ancoragem
        formatter: (value) => {
          return value; // exibe o valor atual
        },
      }
    }
  };
  

  return (

    <Row className='mt-3'>
      <div>
        <Card>
          <Card.Header className='card-chart-title'>Inspeções dos últimos 3 anos</Card.Header>
          <Card.Body>
            <div style={{ marginTop: '2%', width: '100%', height: '300px' }}>
              <Line data={chartData} options={options} />
            </div>
          </Card.Body>
        </Card>
      </div>
    </Row>

  );
};

export default LineChartInspecoesTodosMeses;
