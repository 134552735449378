import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loading = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <div>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="3x"
            style={{ color: 'rgba(0, 0, 0, 0.4)' }}
          />
        </div>
        <p style={{ marginTop: '1rem' }}>Por favor, aguarde...</p>
      </div>
    </div>
  );
};

export default Loading;
