import React from "react";
import {
    Text,
    View,
    Image,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";


const CabecalhoLaudo = ({ imageLogoTipoData, textLaudoCertificadoInspecao, textArt, art, textLaudo, laudo, razaoSocialInguanti }) => (
    <>
        <View>
            <Image src={imageLogoTipoData} style={styles.logoTipoSection} />
        </View>
        <View>
            <Text style={styles.textLaudoCertificadoInspecaoSection}>
                {textLaudoCertificadoInspecao}
            </Text>
        </View>
        <View>
            <Text style={styles.textArtSection}>
                {textArt}
                {art}
            </Text>
        </View>
        <View>
            <Text style={styles.textLaudoSection}>
                {textLaudo}
                {laudo}
            </Text>
        </View>
        <View style={styles.viewRazaoSocialSection}>
            <Text style={styles.textRazaoSocialSection}>
                {razaoSocialInguanti}
            </Text>
        </View>

        <View style={styles.borderBottomCabecalho}></View>

    </>
);

export default CabecalhoLaudo;
