import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Para o registro automático dos componentes necessários
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Row, Card, Col } from 'react-bootstrap';
import './../sharedStyles.css'

const options = {
  plugins: {
    datalabels: {
      color: 'black',     
      formatter: (value) => {
        return value;
      },
      font: {
        weight: 'bold'
      }
    },
    legend: {
      position: 'top',
      labels: {
        boxWidth: 20,
        padding: 20,
        usePointStyle: true,
        pointStyle: 'circle'
      }
    }
  },
  aspectRatio: 1, // Manter o gráfico como um círculo perfeito
};


const optionsPercent = {
  plugins: {
    datalabels: {
      color: 'black',
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          let sum = datasets[0].data.reduce((a, b) => a + b, 0);
          let percentage = Math.round((value / sum) * 100) + '%';
          return percentage;
        } else {
          return '';
        }
      },
      font: {
        weight: 'bold'
      }
    },
    legend: {
      position: 'top',
      labels: {
        boxWidth: 20,
        padding: 20,
        usePointStyle: true,
        pointStyle: 'circle'
      }
    }
  },
  aspectRatio: 1, // Manter o gráfico como um círculo perfeito
};

const ResultadoPieChart = ({ dataPieChart }) => {
  const dataForChart = {
    labels: ['Aprovados', 'Reprovados', 'Aprovados com Recomendações'],
    datasets: [
      {
        label: 'Laudos',
        data: [
          dataPieChart.totalGeralAprovados,
          dataPieChart.totalReprovado,
          dataPieChart.totalAprovadoRecomendacoes,
        ],
        backgroundColor: [
          '#00EC76',
          '#EC0000',
          '#ECEC00'
        ],
        hoverOffset: 4
      }
    ]
  };

  return (
    <Row className='mt-3 g-3'>
      <Col md={6}> 
        <Card>
          <Card.Header className='card-chart-title'>Resultado %</Card.Header>
          <Card.Body style={{ position: 'relative' }}>
            <Pie data={dataForChart} options={optionsPercent} plugins={[ChartDataLabels]} style={{maxHeight:'300px'}} />
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}> 
        <Card>
          <Card.Header className='card-chart-title'>Resultado</Card.Header>
          <Card.Body style={{ position: 'relative' }}>
            <Pie data={dataForChart} options={options} plugins={[ChartDataLabels]} style={{maxHeight:'300px'}} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ResultadoPieChart;


