import { format, isValid } from 'date-fns';
import moment from 'moment';

export const formatarDataComHora = (data) => {
    return data.map(item => {
        const formattedItem = { ...item };

        // Format dataAlteracao
        const dateAlteracao = new Date(item.dataAlteracao);
        if (item.dataAlteracao && isValid(dateAlteracao)) {
            formattedItem.dataAlteracao = format(dateAlteracao, 'dd/MM/yyyy HH:mm:ss');
        }

        // Format dataCadastro
        const dateCadastro = new Date(item.dataCadastro);
        if (item.dataCadastro && isValid(dateCadastro)) {
            formattedItem.dataCadastro = format(dateCadastro, 'dd/MM/yyyy HH:mm:ss');
        }

        // Verifique a existência de dataMetodologiaInspecao e formate se necessário
        if (item.dataMetodologiaInspecao) {
            const dateMetodologiaInspecao = new Date(item.dataMetodologiaInspecao);
            if (isValid(dateMetodologiaInspecao)) {
                formattedItem.dataMetodologiaInspecao = format(dateMetodologiaInspecao, 'dd/MM/yyyy');
            }
        }

        if (item.vencimento) {
            const vencimento = new Date(item.vencimento);
            if (isValid(vencimento)) {
                formattedItem.vencimento = format(vencimento, 'dd/MM/yyyy');
            }
        }

        return formattedItem;
    });
};

export const formatarData = (data) => {
    return data.map(item => {
        const formattedItem = { ...item };

        // Format dataAlteracao
        const dateAlteracao = new Date(item.dataAlteracao);
        if (item.dataAlteracao && isValid(dateAlteracao)) {
            formattedItem.dataAlteracao = format(dateAlteracao, 'dd/MM/yyyy');
        }

        // Format dataCadastro
        const dateCadastro = new Date(item.dataCadastro);
        if (item.dataCadastro && isValid(dateCadastro)) {
            formattedItem.dataCadastro = format(dateCadastro, 'dd/MM/yyyy');
        }

        // Verifique a existência de dataMetodologiaInspecao e formate se necessário
        if (item.dataMetodologiaInspecao) {
            const dateMetodologiaInspecao = new Date(item.dataMetodologiaInspecao);
            if (isValid(dateMetodologiaInspecao)) {
                formattedItem.dataMetodologiaInspecao = format(dateMetodologiaInspecao, 'dd/MM/yyyy');
            }
        }

        if (item.vencimento) {
            const vencimento = new Date(item.vencimento);
            if (isValid(vencimento)) {
                formattedItem.vencimento = format(vencimento, 'dd/MM/yyyy');
            }
        }

        return formattedItem;
    });
}

// Formato 2023-07-04T00:00:00
export const formatarDataIsoString = (dataIsoString) => {
    const date = new Date(dataIsoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 porque os meses começam de 0 (janeiro) a 11 (dezembro)
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export function renameFileWithTimestamp(originalFile) {
    const timestamp = moment().format('DDMMYYYY_HHmmssSSS');

    let originalFileName = originalFile.name;

    // Removendo todos os espaços em branco
    originalFileName = originalFileName.replace(/\s+/g, '');

    // Se o nome do arquivo original tiver mais de 90 caracteres, reduza para 90
    if (originalFileName.length > 90) {
        const extension = originalFileName.slice(-5);
        originalFileName = originalFileName.slice(0, 85) + extension;
    }

    const newFileName = `${timestamp}_${originalFileName}`.toLowerCase();

    // Criar um novo File com o nome modificado
    const newFile = new File([originalFile], newFileName, { type: originalFile.type });

    return newFile;
}

export const formatCompleteDate = (date) => {
    const pad = (num) => num.toString().padStart(2, '0');

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Janeiro é 0!
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const millis = date.getMilliseconds().toString().padStart(3, '0'); // Assegura 3 dígitos para milissegundos

    return `${day}${month}${year}_${hours}${minutes}${seconds}_${millis}`;
};

export const isValidDate = (dateString) => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Formato inválido
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Data inválida
    return d.toISOString().slice(0, 10) === dateString;
};

export function onlyNumbers(e) {
    const val = e.target.value;
    if (isNaN(val)) {
        return val.slice(0, -1);
    }
    return val;
}

export const getTypeSystem = () => {
    return 'INGUANTI'
}
