import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row, Col } from 'react-bootstrap';

const CustomFooter = ({
    currentPage,
    totalPages,
    totalRecords,
    paddingLeftValue,
    paddingRightValue
}) => {
    const isXsScreen = useMediaQuery({ maxWidth: 575 }); // Tela xs: até 575px
    return (
        <Row>
            <Col md={6} sm={6} xs={12} className="text-md-left text-sm-left text-left" style={{ paddingLeft: paddingLeftValue + 'px' }}>
                <p>
                    Página {currentPage} de {totalPages}
                </p>
            </Col>
            <Col md={6} sm={6} xs={12} className="text-md-right text-sm-right text-right" style={isXsScreen ? { textAlign: 'left', paddingLeft: paddingLeftValue + 'px' } : { textAlign: 'right', paddingRight: paddingRightValue + 'px' }}>
                <p >Total de registros: {totalRecords}</p>
            </Col>
        </Row>
    );
};

export default CustomFooter;
