import React, { useState } from 'react';
import { Table } from "react-bootstrap";
import './CustomTable.css';

const TableAcessoriosCliente = ({ data, applyMaxHeight = false, maxHeight = '200px' }) => {
    const hasData = data && data.length > 0;
    const totalInspeccoes = hasData ? data.reduce((acc, item) => acc + item.quantidadeInspecoes, 0) : 0;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {hasData ? (
                <div className="table-responsive mt-3" style={{ flexGrow: 1, maxHeight: applyMaxHeight ? maxHeight : 'none' }}>
                    <Table striped bordered hover className="table-sm custom-table" style={{fontSize:'16px'}} id="printableAcessorios">
                        <thead className="thead-fix-acessorios">
                            <tr style={{ color: "#236CB3" }}>
                                <th>Acessório</th>
                                <th>Qtde. Insp.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.acessorio}</td>
                                    <td>{item.quantidadeInspecoes}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className="tfoot-fix">
                                <td>TOTAL DE INSPEÇÕES</td>
                                <td>{totalInspeccoes}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            ) : (
                <div>Nenhum registro foi encontrado</div>
            )}           
        </div>
    );
};

export default TableAcessoriosCliente;