const fetchWithAuth = async (url, options = {}) => {
    // Obter o token de autenticação do armazenamento local
    const storedUser = localStorage.getItem('user');
    const token = storedUser ? JSON.parse(storedUser).token : null;

    // Configurar cabeçalhos de autorização
    const headers = new Headers(options.headers || {});
    if (token) {
        headers.append('Authorization', `Bearer ${token}`);
    }

    // Configurar opções da requisição
    const config = {
        ...options,
        headers,
    };

    // Realizar a requisição
    const response = await fetch(url, config);

    // Tratamento de resposta
    if (!response.ok) {
        throw new Error(`Erro HTTP: ${response.status}`);
    }

    return response;
};

export default fetchWithAuth;
