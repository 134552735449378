import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    // flexDirection: "column",
    backgroundColor: "#ffff",
    paddingTop: 13,
    paddingLeft: 28,
    paddingRight: 28,
  },
  logoTipoSection: {
    maxWidth: 75,
    paddingLeft: 5,
    marginTop: 7,
    // paddingTop: 5,
  },
  textLaudoCertificadoInspecaoSection: {
    paddingLeft: 165,
    fontSize: 14,
    // paddingTop: -35,
    marginTop: -38,
    fontFamily: "Arial",
    fontWeight: "bold",
  },
  textArtSection: {
    paddingLeft: 98,
    fontSize: 11,
    // paddingTop: -25,
    marginTop: -16,

    fontFamily: "Arial",
    fontWeight: "bold",
  },
  textRazaoSocialSection: {
    fontSize: 8,
    fontFamily: "Arial",
    fontWeight: "bold",
  },
  viewRazaoSocialSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    // borderWidth: 1,
    // borderColor: '#000',
  },
  textLaudoSection: {
    paddingLeft: 335,
    fontSize: 11,
    // paddingTop: -42,
    marginTop: -16,
    fontFamily: "Arial",
    fontWeight: "bold",
  },
  borderBottomCabecalho: {
    borderBottomWidth: 2.5,
    borderBottomColor: "#000", // você pode definir a cor que preferir
    borderBottomStyle: "solid",
    marginTop: 4,
  },

  rectangleTitle: {
    flexDirection: 'row',    // Dispor os filhos lado a lado
    alignItems: 'center',    // Centralizar verticalmente os filhos
    borderWidth: 2,
    borderColor: '#000',
    width: '98.5%',
    height: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 5
  },

  rectangle: {
    flexDirection: 'row',    // Dispor os filhos lado a lado
    alignItems: 'center',    // Centralizar verticalmente os filhos
    borderWidth: 2,
    borderColor: '#000',
    width: '98.5%',
    height: 17,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -2.5
  },

  rectangleTopAlign: {
    flexDirection: 'row',    // Dispor os filhos lado a lado
    //alignItems: 'center',    // Centralizar verticalmente os filhos
    borderWidth: 2,
    borderColor: '#000',
    width: '98.5%',
    minHeight: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -2.5
  },

  rectangleNotas: {
    borderWidth: 1,
    borderColor: '#000',
    width: '98.5%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 3
  },

  rectangleNossosContatos: {
    borderWidth: 1,
    borderColor: '#000',
    width: '98.5%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 3
  },


  containerEvidenciaFotografica: {
    flexDirection: 'row',  // Coloca as Views filhas lado a lado
    alignItems: 'center',  // Centralizar verticalmente os filhos
    justifyContent: 'center', // Centralizar horizontalmente os filhos
  },

  rectangleEvidenciaFotografica: {
    alignItems: 'center',    // Centralizar verticalmente os filhos
    borderWidth: 2,
    borderColor: '#000',
    width: '47%',
    height: 109,
    marginTop: 2
  },

  innerRectangle: {
    borderWidth: 1.5,
    borderColor: '#000',
    width: '55%',
    height: '90%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 4
  },


  textSection: {
    fontSize: 9,
    fontFamily: "Arial",
    fontWeight: "bold",
    paddingLeft: 1,
  },

  textNotas: {
    fontSize: 8,
    fontFamily: "Arial",
    paddingLeft: 1,
    fontWeight: "normal"
  },

  textNossosContatos: {
    fontSize: 8,
    fontFamily: "Arial",
    paddingLeft: 1,
    fontWeight: "normal"
  },

  numberText: {
    fontSize: 9,
    fontFamily: "Arial",
    fontWeight: "bold",
    paddingRight: 3,         // Espaçamento à direita para não tocar na linha vertical
    paddingLeft: 3,         // Espaçamento à direita para não tocar na linha vertical
  },
  verticalLine: {
    height: '105%',          // Faz a linha vertical ocupar toda a altura do retângulo
    width: 1.5,                // Largura da linha vertical
    backgroundColor: '#000', // Cor da linha vertical
  },

  watermark1: {
    position: 'absolute',
    fontSize: 70,
    color: 'lightgrey',
    opacity: 0.3,
    transform: 'rotate(-45deg)',
    transformOrigin: '50% 50%',
    top: 130,
    left: 140,
  },

  watermark2: {
    position: 'absolute',
    fontSize: 70,
    color: 'lightgrey',
    opacity: 0.3,
    transform: 'rotate(-45deg)',
    transformOrigin: '50% 50%',
    top: 630,
    left: 140,
  },

  textPagina1de1: {
    position: 'absolute',
    fontSize: 9,
    color: 'black',
    top: 815,
    left: 540,
  },

  textPagina1de2: {
    position: 'absolute',
    fontSize: 9,
    color: 'black',
    top: 800,
    left: 510,
  },

  textPagina2de2: {
    position: 'absolute',
    fontSize: 9,
    color: 'black',
    top: 800,
    left: 510,
  },


});
