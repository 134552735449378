import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Tooltip from "@mui/material/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "../Config/axiosConfig";
import "./../Shared/CustomJs/CustomTable.css";

//Compartilhados
import Loading from "../Shared/Loading/Loading";
import { LaudosState } from './LaudosState';
import "./../Shared/sharedStyles.css";
import { onlyNumbers, isValidDate } from "../Shared/Utils/helper";

//import { exportToPDF } from './ExportToPDF';
import ExportToPDF from './ExportToPDF';

const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApiFiles = `${baseApiUrl}/api/Files`;
const baseApiOrdemServico = `${baseApiUrl}/api/OrdemServico`;
const baseApiLaudos = `${baseApiUrl}/api/Laudos`;
const baseApiOsAcessorios = `${baseApiUrl}/api/OsAcessorios`;
const baseApiSession = `${baseApiUrl}/api/Session`;

const Laudos = () => {
  const params = useParams();

  const [idLaudo, setIdLaudo] = useState(params.idLaudo);
  const [idOrdemServico, setIdOrdemServico] = useState(params.idOrdemServico);

  const [loading, setLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [constListOrdemServicoData, setConstListOrdemServicoData] = useState([]);
  const [constListAcessoriosData, setConstListAcessoriosData] = useState([]);
  const [metodologiasInspecaoList, setMetodologiasInspecaoList] = useState([]);

  const [previewImage1, setPreviewImage1] = useState('');
  const [previewImage2, setPreviewImage2] = useState('');
  const [previewImage3, setPreviewImage3] = useState('');
  const [previewImage4, setPreviewImage4] = useState('');

  const [file1, setFile1] = useState('');
  const [file2, setFile2] = useState('');
  const [file3, setFile3] = useState('');
  const [file4, setFile4] = useState('');

  const [isUploading1, setIsUploading1] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [isUploading3, setIsUploading3] = useState(false);
  const [isUploading4, setIsUploading4] = useState(false);

  const [uploadResult1, setUploadResult1] = useState('');
  const [uploadResult2, setUploadResult2] = useState('');
  const [uploadResult3, setUploadResult3] = useState('');
  const [uploadResult4, setUploadResult4] = useState('');

  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [fileName3, setFileName3] = useState("");
  const [fileName4, setFileName4] = useState("");

  const [os, setOs] = useState('');
  const [razaoSocialInguanti, setRazaoSocialInguanti] = useState('');

  const [copiarLaudo, setCopiarLaudo] = useState('');
  const [copiarTagInguanti, setCopiarTagInguanti] = useState('');

  const {
    //Add
    newTagInguanti, setNewTagInguanti,
    newAcessorio, setNewAcessorio,
    newTagCliente, setNewTagCliente,
    newFabricante, setNewFabricante,
    newModelo, setNewModelo,
    newCapacidade, setNewCapacidade,
    newDimensoes, setNewDimensoes,
    newDemaisInformacoesAcessorio, setNewDemaisInformacoesAcessorio,
    newContato, setNewContato,
    newSetor, setNewSetor,
    newDDD, setNewDDD,
    newTelefone, setNewTelefone,
    newSubsetor, setNewSubsetor,
    newMetodologiaInspecao, setNewMetodologiaInspecao,
    newDataMetodologiaInspecao, setNewDataMetodologiaInspecao,
    newCertificadoFabricante, setNewCertificadoFabricante,
    newRegistroInspecao, setNewRegistroInspecao,
    newRegistroReparo, setNewRegistroReparo,
    newConclusaoTecnica, setNewConclusaoTecnica,
    newRecomendacoes, setNewRecomendacoes,

    //Edit
    editAcessorio, setEditAcessorio,
    editTagInguanti, setEditTagInguanti,
    editTagCliente, setEditTagCliente,
    editFabricante, setEditFabricante,
    editModelo, setEditModelo,
    editCapacidade, setEditCapacidade,
    editDimensoes, setEditDimensoes,
    editDemaisInformacoesAcessorio, setEditDemaisInformacoesAcessorio,
    editContato, setEditContato,
    editSetor, setEditSetor,
    editDDD, setEditDDD,
    editTelefone, setEditTelefone,
    editSubsetor, setEditSubsetor,
    editMetodologiaInspecao, setEditMetodologiaInspecao,
    editDataMetodologiaInspecao, setEditDataMetodologiaInspecao,
    editCertificadoFabricante, setEditCertificadoFabricante,
    editRegistroInspecao, setEditRegistroInspecao,
    editRegistroReparo, setEditRegistroReparo,
    editConclusaoTecnica, setEditConclusaoTecnica,
    editRecomendacoes, setEditRecomendacoes,

  } = LaudosState();

  const [isUploadingContinuar, setIsUploadingContinuar] = useState(false);

  const [showEvidencias, setShowEvidencias] = useState(true);

  const [responseData, setResponseData] = useState('');

  const [isUpdate, setIsUpdate] = useState(false);

  // No react 18 é necessário para não chamar 2 vezes a API
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;

    const storedUser = JSON.parse(localStorage.getItem("user"));

    const handleInitialization = async () => {

      if (storedUser && storedUser.roles && storedUser.roles.includes('Master')) {
        await fetchInitialData();
      } else {
        const isAuthorized = await checkAuthorization();
        if (!isAuthorized) {
          console.log("Não está autorizado, esse perfil não esta cadastrado em Resp. pelo Laudo");
          window.location.href = '/acesso-negado';
          return;
        }
        await fetchInitialData();
      }
    };

    handleInitialization();
  }, [idLaudo]); // Inclua todas as variáveis que o efeito depende


  const fetchInitialData = async () => {
    limparCampos();
    if (idLaudo) {
      // Editar
      await fetchInitialDataByIdLaudo();
    } else {
      // Novo
      await fetchInitialDataOrdemServico();
      await fetchInitialDataAcessorios();
      await fetchInitialDataOrdemServicoPreencher();
      await fetchInitialDataMetodologiaInspecao();

    }
  };

  const checkAuthorization = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseApiSession}/is-user-resp-laudo`);
      return response.data;
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialDataByIdLaudo = async () => {
    setLoading(true);
    try {
      setShowEvidencias(false);
      setIsUpdate(true);

      const response = await axios.get(`${baseApiLaudos}/get-laudo-por-idlaudo/${idLaudo}`);

      setIdOrdemServico(response.data.idOrdemServico);

      await fetchInitialDataOrdemServicoByIdLaudo(response.data.idOrdemServico);
      await downloadImage(response.data.caminhoEvidenciaFotograficaUm, setPreviewImage1, setFileName1);
      await downloadImage(response.data.caminhoEvidenciaFotograficaDois, setPreviewImage2, setFileName2);
      await downloadImage(response.data.caminhoEvidenciaFotograficaTres, setPreviewImage3, setFileName3);
      await downloadImage(response.data.caminhoEvidenciaFotograficaQuatro, setPreviewImage4, setFileName4);
      await fetchInitialDataAcessorios(response.data.idOrdemServico);
      await fetchInitialDataMetodologiaInspecao(true, response.metodologiaInspecao);


      setConstListOrdemServicoData(response.data);
      setEditAcessorio(response.data.acessorio);
      setEditTagInguanti(response.data.tagInguanti);
      setEditTagCliente(response.data.tagCliente);
      setEditFabricante(response.data.fabricante);
      setEditModelo(response.data.modelo);
      setEditCapacidade(response.data.capacidade);
      setEditDimensoes(response.data.dimensoes);
      setEditDemaisInformacoesAcessorio(response.data.demaisInformacoesAcessorio);
      setEditContato(response.data.contato);
      setEditSetor(response.data.setor);

      const match = handleTelefone(response.data.telefone);

      if (match) {
        setEditDDD(match[1]); // DDD
        setEditTelefone(match[2]); // Número do telefone
      }

      setEditSubsetor(response.data.subSetor);

      // Extraímos apenas a parte da data da string ISO 8601
      const datePart = response.data.dataMetodologiaInspecao.split('T')[0];
      setEditDataMetodologiaInspecao(datePart);
      setEditMetodologiaInspecao(response.data.metodologiaInspecao);
      setEditCertificadoFabricante(response.data.certificadoFabricante);
      setEditRegistroInspecao(response.data.registroInspecao);
      setEditRegistroReparo(response.data.registroReparo);
      setEditConclusaoTecnica(response.data.conclusaoTecnica);
      setEditRecomendacoes(response.data.recomendacoes);

      setRazaoSocialInguanti(response.data.razaoSocialInguanti);

      setLoading(false);
      //setOs(response.data.os);
    } catch (error) {
      setLoading(false);
      console.error("Erro ao buscar dados:", error);
    }
  }

  const downloadImage = async (imageName, setPreviewImage, setFileName) => {
    try {
      if (imageName.includes('SEMIMAGEM')) {
        setPreviewImage(`${process.env.PUBLIC_URL}/img/jpeg/SEMIMAGEM.JPG`);
      } else {
        const response = await axios.get(`${baseApiFiles}/download-evidencia-fotografica/${imageName}`, {
          responseType: 'blob', // Importante para receber o arquivo como um Blob
        });

        // Criar uma URL de objeto para o Blob recebido
        const imageBlob = response.data;
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setPreviewImage(imageObjectURL);
        setFileName(imageName);
      }

    } catch (error) {
      console.error('Erro ao fazer download da imagem:', error);
    }
  };

  const fetchInitialDataOrdemServicoByIdLaudo = async (idOrdemServicoByIdLaudo) => {
    setLoading(true);
    try {
      setIdOrdemServico(idOrdemServicoByIdLaudo);
      const response = await axios.get(
        `${baseApiOrdemServico}/get-ordemservico/${idOrdemServicoByIdLaudo}`
      );
      //const formattedData = formatarDataComHora(response.data);
      setConstListOrdemServicoData(response.data);
      setOs(response.data.os);
    } catch (error) {
      setLoading(false);
      console.error("Erro ao buscar dados:", error);
    }
  };

  const fetchInitialDataOrdemServico = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseApiOrdemServico}/get-ordemservico/${idOrdemServico}`
      );
      setConstListOrdemServicoData(response.data);
      setOs(response.data.os);
      setRazaoSocialInguanti(response.data.razaoSocialInguanti);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
    setLoading(false);
  };

  const fetchInitialDataAcessorios = async (idOrdemServicoPar = 0) => {
    try {

      let idOrdemServicoVar = idOrdemServico || idOrdemServicoPar;

      const responseOsAcessorios = await axios.get(`${baseApiOsAcessorios}/get-os-acessorios-by-id-os/${idOrdemServicoVar}`);

      if (responseOsAcessorios.data.length > 0) {
        setConstListAcessoriosData(responseOsAcessorios.data);
      } else {
        const responseLaudos = await axios.get(`${baseApiLaudos}/get-acessorios-laudos`);
        setConstListAcessoriosData(responseLaudos.data);
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const fetchInitialDataOrdemServicoPreencher = async () => {
    try {
      const response = await axios.get(
        `${baseApiLaudos}/get-os-preencher-campos/${idOrdemServico}`
      );

      const match = handleTelefone(response.data.telefone);

      if (match) {
        setEditDDD(match[1]); // DDD
        setEditTelefone(match[2]); // Número do telefone
      }
      if (match) {
        setNewDDD(match[1]);  // DDD
        setNewTelefone(match[2]); // Número do telefone
      }

      setNewContato(response.data.contato);
      setNewSetor(response.data.setor);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const fetchInitialDataMetodologiaInspecao = async (isUpdate, responseUpdate) => {
    try {
      const response = await axios.get(
        `${baseApiLaudos}/get-metodologias-inspecao`
      );
      setMetodologiasInspecaoList(response.data);

      if (isUpdate) {
        // setNewMetodologiaInspecao(response.data[0].tipoMetodologia);
      }
      else
        setNewMetodologiaInspecao(response.data[0].tipoMetodologia);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const handleTelefone = (telefone) => {
    // Remove tudo que não é dígito
    const cleanPhone = telefone.replace(/[^\d]/g, '');

    // Supondo que o cleanPhone agora é uma string somente com dígitos
    // Separa os dois primeiros dígitos como DDD e o restante como número de telefone
    const phonePattern = /^(\d{2})(\d+)$/; // Expressão regular para capturar o DDD e o número
    return cleanPhone.match(phonePattern);
  }

  const getMissingFields = () => {

    const commonFields = [
      { name: 'Tag Inguanti', value: isUpdate ? editTagInguanti : newTagInguanti },
      { name: 'Fabricante', value: isUpdate ? editFabricante : newFabricante },
      { name: 'Modelo', value: isUpdate ? editModelo : newModelo },
      { name: 'Capacidade', value: isUpdate ? editCapacidade : newCapacidade },
      { name: 'Dimensoes', value: isUpdate ? editDimensoes : newDimensoes },
      { name: 'Contato', value: isUpdate ? editContato : newContato },
      { name: 'Setor', value: isUpdate ? editSetor : newSetor },
      { name: 'Metodologia da Inspeção', value: isUpdate ? editMetodologiaInspecao : newMetodologiaInspecao },
      { name: 'Certificado Fabricante', value: isUpdate ? editCertificadoFabricante : newCertificadoFabricante },
      { name: 'Registro Inspeção', value: isUpdate ? editRegistroInspecao : newRegistroInspecao },
      { name: 'Registro Reparo', value: isUpdate ? editRegistroReparo : newRegistroReparo },
    ];

    const additionalFields = [
      { name: 'Conclusão Técnica', condition: (value) => value === "-1" || value === "SELECIONE" || value === "", value: isUpdate ? editConclusaoTecnica : newConclusaoTecnica },
      { name: 'Acessórios', condition: (value) => value === "-1" || value === "SELECIONE" || value === "", value: isUpdate ? editAcessorio : newAcessorio },
      { name: 'Data da Inspeção', condition: (value) => !isValidDate(value), value: isUpdate ? editDataMetodologiaInspecao : newDataMetodologiaInspecao },
    ];

    // Adiciona campos condicionais ao array comum se a condição for verdadeira
    additionalFields.forEach(field => {
      if (field.condition(field.value)) {
        commonFields.push({ name: field.name, value: "" });
      }
    });

    // Verifica os campos faltantes
    return commonFields.filter(field => !field.value?.trim()).map(field => field.name);
  };

  const handlePdfUploadComplete = (success, pdfLink, cleanedLaudo) => {

    setLoadingPDF(false);

    if (success) {
      const linkElement = document.createElement('a');
      linkElement.href = pdfLink;
      linkElement.download = `${cleanedLaudo}.pdf`;
      linkElement.innerHTML = 'Visualizar Relatório';
      linkElement.target = '_blank';

      if (isUpdate) {
        Swal.fire({
          title: 'Laudos',
          html: 'Laudo atualizado com sucesso.<br><br>' + linkElement.outerHTML,
          icon: 'success',
        }).then(() => {
          window.location.href = `/detalhar-os/${idOrdemServico}`;
        });
      } else {
        Swal.fire({
          title: 'Laudos',
          html: 'Laudo incluído com sucesso. Deseja adicionar outro?<br><br>' + linkElement.outerHTML,
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          } else {
            window.location.href = "/os-laudos";
          }
        });
      }
    } else {
      // Falha ao enviar o PDF
      Swal.fire(
        "Erro",
        "Erro ao enviar o PDF. Por favor, tente novamente.",
        "error"
      );
    }
  };

  const handleSave = async (
    fileName1,
    fileName2,
    fileName3,
    fileName4,
    newAcessorio,
    newTagInguanti,
    newTagCliente,
    newFabricante,
    newModelo,
    newCapacidade,
    newDimensoes,
    newDemaisInformacoesAcessorio,
    newContato,
    newSetor,
    newTelefone,
    newDDD,
    newSubsetor,
    newMetodologiaInspecao,
    newDataMetodologiaInspecao,
    newCertificadoFabricante,
    newRegistroInspecao,
    newRegistroReparo,
    newConclusaoTecnica,
    newRecomendacoes,
  ) => {

    newDemaisInformacoesAcessorio = 'N.A.';
    const missingFields = getMissingFields();

    if (missingFields.length > 0) {
      const formattedList = missingFields.map(field => `- ${field}`).join('<br/>');

      Swal.fire(
        "Atenção",
        `Os seguintes campos são obrigatórios e não foram preenchidos:<br/>${formattedList}`,
        "info"
      );
      return;
    }

    if (fileName1 === "" || fileName1 == null) {
      fileName1 = "SEMIMAGEM01.JPG";
    }
    if (fileName2 === "" || fileName1 == null) {
      fileName2 = "SEMIMAGEM02.JPG";
    }
    if (fileName3 === "" || fileName3 == null) {
      fileName3 = "SEMIMAGEM03.JPG";
    }
    if (fileName4 === "" || fileName4 == null) {
      fileName4 = "SEMIMAGEM04.JPG";
    }

    try {
      setLoading(true);

      const formattedPhone = `(${newDDD})${newTelefone}`;

      const newItem = {
        CaminhoEvidenciaFotograficaUm: fileName1,
        CaminhoEvidenciaFotograficaDois: fileName2,
        CaminhoEvidenciaFotograficaTres: fileName3,
        CaminhoEvidenciaFotograficaQuatro: fileName4,
        IdOrdemServico: idOrdemServico,
        Acessorio: newAcessorio,
        TagInguanti: newTagInguanti,
        TagCliente: newTagCliente,
        Fabricante: newFabricante,
        Modelo: newModelo,
        Capacidade: newCapacidade,
        Dimensoes: newDimensoes,
        DemaisInformacoesAcessorio: newDemaisInformacoesAcessorio,
        Contato: newContato,
        Telefone: formattedPhone,
        Setor: newSetor,
        SubSetor: newSubsetor,
        MetodologiaInspecao: newMetodologiaInspecao,
        DataMetodologiaInspecao: newDataMetodologiaInspecao,
        CertificadoFabricante: newCertificadoFabricante,
        RegistroInspecao: newRegistroInspecao,
        RegistroReparo: newRegistroReparo,
        ConclusaoTecnica: newConclusaoTecnica,
        Recomendacoes: newRecomendacoes,
        RazaoSocialInguanti: razaoSocialInguanti,
      };

      const response = await axios.post(`${baseApiLaudos}/add-laudo`, newItem);

      if (response?.status === 200) {

        if (!response?.data?.response?.mensagem.toLowerCase().includes("erro") &&
          !response?.data?.response?.mensagem.toLowerCase().includes("existe")) {

          setResponseData(response);
          setLoading(false);
          setLoadingPDF(true);

        } else {
          Swal.fire(
            "Erro",
            response?.data?.response?.mensagem,
            "error"
          );
        }

      } else {
        Swal.fire(
          "Erro",
          "Erro ao adicionar o laudo. Por favor, tente novamente.",
          "error"
        );
        setTimeout(() => {
          setLoading(false);
          setLoadingPDF(false);
        }, 1);
      }

    } catch (error) {
      setLoading(false);
      setLoadingPDF(false);
      console.error(
        "Erro: ",
        error
      );

      Swal.fire({
        title: 'Laudos',
        text: 'Não foi possível adicionar o registro',
        icon: 'error',
      })
      console.log(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
    }
  };

  const handleUpdate = async (
    idLaudo,
    fileName1,
    fileName2,
    fileName3,
    fileName4,
    editAcessorio,
    editTagInguanti,
    editTagCliente,
    editFabricante,
    editModelo,
    editCapacidade,
    editDimensoes,
    editDemaisInformacoesAcessorio,
    editContato,
    editSetor,
    editTelefone,
    editDDD,
    editSubsetor,
    editMetodologiaInspecao,
    editDataMetodologiaInspecao,
    editCertificadoFabricante,
    editRegistroInspecao,
    editRegistroReparo,
    editConclusaoTecnica,
    editRecomendacoes,
  ) => {
    editDemaisInformacoesAcessorio = 'N.A.';

    const missingFields = getMissingFields();

    if (missingFields.length > 0) {
      const formattedList = missingFields.map(field => `- ${field}`).join('<br/>');

      Swal.fire(
        "Atenção",
        `Os seguintes campos são obrigatórios e não foram preenchidos:<br/>${formattedList}`,
        "info"
      );
      return;
    }

    if (fileName1 === "" || fileName1 == null) {
      fileName1 = "SEMIMAGEM01.JPG";
    }
    if (fileName2 === "" || fileName1 == null) {
      fileName2 = "SEMIMAGEM02.JPG";
    }
    if (fileName3 === "" || fileName3 == null) {
      fileName3 = "SEMIMAGEM03.JPG";
    }
    if (fileName4 === "" || fileName4 == null) {
      fileName4 = "SEMIMAGEM04.JPG";
    }

    try {
      setLoading(true);

      const formattedPhone = `(${editDDD})${editTelefone}`;

      const editItem = {
        IdLaudo: idLaudo,
        CaminhoEvidenciaFotograficaUm: fileName1,
        CaminhoEvidenciaFotograficaDois: fileName2,
        CaminhoEvidenciaFotograficaTres: fileName3,
        CaminhoEvidenciaFotograficaQuatro: fileName4,
        IdOrdemServico: idOrdemServico,
        Acessorio: editAcessorio,
        TagInguanti: editTagInguanti,
        TagCliente: editTagCliente,
        Fabricante: editFabricante,
        Modelo: editModelo,
        Capacidade: editCapacidade,
        Dimensoes: editDimensoes,
        DemaisInformacoesAcessorio: editDemaisInformacoesAcessorio,
        Contato: editContato,
        Telefone: formattedPhone,
        Setor: editSetor,
        SubSetor: editSubsetor,
        MetodologiaInspecao: editMetodologiaInspecao,
        DataMetodologiaInspecao: editDataMetodologiaInspecao,
        CertificadoFabricante: editCertificadoFabricante,
        RegistroInspecao: editRegistroInspecao,
        RegistroReparo: editRegistroReparo,
        ConclusaoTecnica: editConclusaoTecnica,
        Recomendacoes: editRecomendacoes,
        RazaoSocialInguanti: razaoSocialInguanti,
      };

      const response = await axios.post(`${baseApiLaudos}/update-laudo`, editItem);

      if (response?.status === 200) {

        if (!response?.data?.response?.mensagem.toLowerCase().includes("erro") &&
          !response?.data?.response?.mensagem.toLowerCase().includes("existe")) {

          setResponseData(response);
          setLoading(false);
          setLoadingPDF(true);

        } else {
          setLoading(false);
          setLoadingPDF(false);
          Swal.fire(
            "Erro",
            response?.data?.response?.mensagem,
            "error"
          );
        }

      } else {
        Swal.fire(
          "Erro",
          "Erro ao atualizar o laudo. Por favor, tente novamente.",
          "error"
        );
        setTimeout(() => {
          setLoading(false);
          setLoadingPDF(false);

        }, 1);
      }

    } catch (error) {
      setLoading(false);
      setLoadingPDF(false);
      console.error(
        "Erro: ",
        error
      );

      Swal.fire({
        title: 'Laudos',
        text: 'Não foi possível atualizar o registro',
        icon: 'error',
      })
      console.log(`Erro ao atualizar o registro: ${error?.response?.data?.errors[0]}`);
      //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
    }
  };

  const handleCopiarLaudo = async (laudoCopy, tagInguanti) => {
    let response;
    if (laudoCopy) {
      response = await axios.get(`${baseApiLaudos}/copy-laudo-by-laudo/${laudoCopy}`);
    } else if (tagInguanti) {
      response = await axios.get(`${baseApiLaudos}/copy-laudo-by-taginguanti/${tagInguanti}`);
    }

    if (response?.status === 200) {
      let telefone = response.data.telefone || ''; // Garante que telefone não seja undefined ou null
      telefone = telefone.replace(/[()]/g, ''); // Remove os parênteses
      let ddd = '';
      if (telefone.length > 2) {
        ddd = telefone.slice(0, 2); // Pega os dois primeiros caracteres como DDD
        telefone = telefone.slice(2); // Remove os dois primeiros caracteres do telefone
      }

      setNewTagInguanti(response.data.tagInguanti);
      setNewAcessorio(response.data.acessorio);
      setNewTagCliente(response.data.tagCliente);
      setNewFabricante(response.data.fabricante);
      setNewModelo(response.data.modelo);
      setNewCapacidade(response.data.capacidade);
      setNewDimensoes(response.data.dimensoes);
      setNewDemaisInformacoesAcessorio(response.data.demaisInformacoesAcessorio);
      setNewContato(response.data.contato);
      setNewSetor(response.data.setor);
      setNewTelefone(telefone);
      setNewDDD(ddd); // Define o DDD
      setNewSubsetor(response.data.subSetor);
      setNewMetodologiaInspecao(response.data.metodologiaInspecao);
      // setNewDataMetodologiaInspecao(response.data.dataMetodologiaInspecao);
      setNewCertificadoFabricante(response.data.certificadoFabricante);
      setNewRegistroInspecao(response.data.registroInspecao);
      setNewRegistroReparo(response.data.registroReparo);
      setNewConclusaoTecnica(response.data.conclusaoTecnica);
      setNewRecomendacoes(response.data.recomendacoes);
    }
  }

  const handleImageChange = (e, setPreview, setFile, setUploadResult) => {
    const file = e.target.files[0];

    if (file.type === "image/bmp") {
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // Convert to JPG
          const dataURL = canvas.toDataURL('image/jpeg', 1.0);
          const convertedBlob = dataURLToBlob(dataURL);

          // Update preview and set the file
          setPreview(dataURL);
          setFile(new File([convertedBlob], "converted.jpg", {
            type: 'image/jpeg',
          }));
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      const reader = new FileReader();
      reader.onload = function (event) {
        setPreview(event.target.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleFileUpload = async (
    file,
    evidenciaFotografica,
    setIsUploading,
    setUploadResult,
    setFileName
  ) => {
    if (!file) {
      return;
    }

    setIsUploading(true);

    const MAX_SIZE = 400000;
    let resizedFile = file;

    if (file.size > MAX_SIZE) {
      try {
        resizedFile = await resizeImage(file);
      } catch (error) {
        console.error(error);
        return;
      }
    }

    try {
      // Prepare os dados do formulário
      const formData = new FormData();
      formData.append("file", resizedFile);

      // Faça o upload do arquivo
      const response = await axios.post(`${baseApiFiles}/add-image`, formData);

      const data = response.data;

      setFileName(data.fileName);

      // Atualize o resultado do upload
      if (response.status === 200) {
        if (data?.success) setUploadResult("success");
      } else {
        setUploadResult("error");
      }
    } catch (error) {
      console.error(error);
      setUploadResult("error");
    } finally {
      setIsUploading(false);
    }
  };

  const resizeImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const resizedFile = new File([blob], file.name, {
                type: "image/jpeg",
              });
              resolve(resizedFile);
            },
            "image/jpeg",
            0.7
          );
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleAcessorioChange = (event) => {
    isUpdate
      ? setEditAcessorio(event.target.value.toUpperCase())
      : setNewAcessorio(event.target.value.toUpperCase());
  };

  const handleTagInguantiChange = (event) => {
    isUpdate
      ? setEditTagInguanti(event.target.value.toUpperCase())
      : setNewTagInguanti(event.target.value.toUpperCase());
  };

  const handleTagClienteChange = (event) => {
    isUpdate
      ? setEditTagCliente(event.target.value.toUpperCase())
      : setNewTagCliente(event.target.value.toUpperCase());
  };
  const handleFabricanteChange = (event) => {
    isUpdate
      ? setEditFabricante(event.target.value.toUpperCase())
      : setNewFabricante(event.target.value.toUpperCase());
  };
  const handleModeloChange = (event) => {
    isUpdate
      ? setEditModelo(event.target.value.toUpperCase())
      : setNewModelo(event.target.value.toUpperCase());
  };
  const handleCapacidadeChange = (event) => {
    isUpdate
      ? setEditCapacidade(event.target.value.toUpperCase())
      : setNewCapacidade(event.target.value.toUpperCase());
  };
  const handleDimensoesChange = (event) => {
    isUpdate
      ? setEditDimensoes(event.target.value.toUpperCase())
      : setNewDimensoes(event.target.value.toUpperCase());
  };

  const handleObservacaoChange = (event) => {
    isUpdate
      ? setEditDemaisInformacoesAcessorio(event.target.value.toUpperCase())
      : setNewDemaisInformacoesAcessorio(event.target.value.toUpperCase());
  };

  const handleContatoChange = (event) => {
    isUpdate
      ? setEditContato(event.target.value.toUpperCase())
      : setNewContato(event.target.value.toUpperCase());
  };
  const handleSetorChange = (event) => {
    isUpdate
      ? setEditSetor(event.target.value.toUpperCase())
      : setNewSetor(event.target.value.toUpperCase());
  };
  const handleSubSetorChange = (event) => {
    isUpdate
      ? setEditSubsetor(event.target.value.toUpperCase())
      : setNewSubsetor(event.target.value.toUpperCase());
  };
  const handleDataMetodologiaInspecaoChange = (event) => {
    isUpdate
      ? setEditDataMetodologiaInspecao(event.target.value)
      : setNewDataMetodologiaInspecao(event.target.value);
  };

  const handleMetodologiaInspecaoChange = (event) => {
    isUpdate
      ? setEditMetodologiaInspecao(event.target.value.toUpperCase())
      : setNewMetodologiaInspecao(event.target.value.toUpperCase());
  };
  const handleCertificadoFabricanteChange = (event) => {
    isUpdate
      ? setEditCertificadoFabricante(event.target.value.toUpperCase())
      : setNewCertificadoFabricante(event.target.value.toUpperCase());
  };
  const handleRegistroInspecaoChange = (event) => {
    isUpdate
      ? setEditRegistroInspecao(event.target.value.toUpperCase())
      : setNewRegistroInspecao(event.target.value.toUpperCase());
  };
  const handleRegistroReparoChange = (event) => {
    isUpdate
      ? setEditRegistroReparo(event.target.value.toUpperCase())
      : setNewRegistroReparo(event.target.value.toUpperCase());
  };
  const handleConclusaoTecnicaChange = (event) => {
    isUpdate
      ? setEditConclusaoTecnica(event.target.value.toUpperCase())
      : setNewConclusaoTecnica(event.target.value.toUpperCase());
  };
  const handleRecomendacoesChange = (event) => {
    isUpdate
      ? setEditRecomendacoes(event.target.value.toUpperCase())
      : setNewRecomendacoes(event.target.value.toUpperCase());
  };
  const handleDDDChange = (event) => {
    var value = onlyNumbers(event);

    if (isUpdate) {
      setEditDDD(value);
    } else {
      setNewDDD(value);
    }
  };
  const handleTelefoneChange = (event) => {
    var value = onlyNumbers(event);

    if (isUpdate) {
      setEditTelefone(value);
    } else {
      setNewTelefone(value);
    }
  };
  const handleCopiarPorLaudoChange = (e) => {
    setCopiarLaudo(e.target.value);
    if (e.target.value) {
      setCopiarTagInguanti('');
    }
  };
  const handleCopiarPorTagInguantiChange = (e) => {
    setCopiarTagInguanti(e.target.value);
    if (e.target.value) {
      setCopiarLaudo('');
    }
  };

  const limparCampos = () => {
    setEditAcessorio(null);
    setEditTagInguanti(null);
    setEditTagCliente(null);
    setEditFabricante(null);
    setEditModelo(null);
    setEditCapacidade(null);
    setEditDimensoes(null);
    setEditDemaisInformacoesAcessorio(null);
    setEditContato(null);
    setEditSetor(null);
    setEditDDD(null);
    setEditTelefone(null);
    setEditSubsetor(null);
    setEditDataMetodologiaInspecao(null);
    setEditMetodologiaInspecao(null);
    setEditCertificadoFabricante(null);
    setEditRegistroInspecao(null);
    setEditRegistroReparo(null);
    setEditConclusaoTecnica(null);
    setEditRecomendacoes(null);

    setNewAcessorio("SELECIONE");
    setNewTagInguanti("");
    setNewTagCliente("");
    setNewFabricante("");
    setNewModelo("");
    setNewCapacidade("");
    setNewDimensoes("");
    setNewDemaisInformacoesAcessorio("");
    setNewContato("");
    setNewSetor("");
    setNewDDD("");
    setNewTelefone("");
    setNewSubsetor("");
    setNewDataMetodologiaInspecao("");
    setNewMetodologiaInspecao("");
    setNewCertificadoFabricante("");
    setNewRegistroInspecao("");
    setNewRegistroReparo("");
    setNewConclusaoTecnica("");
    setNewRecomendacoes("");
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Container fluid>

      <Card className="mt-3">
        <Card.Header className='title-card-custom'><h4>Ordem de Serviço - N° {os} </h4></Card.Header>
        <Card.Body>

          {showEvidencias ? (
            <>
              {/* Evidência 1 */}
              <Row className="mt-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Evidência fotográfica 01:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      handleImageChange(
                        e,
                        setPreviewImage1,
                        setFile1,
                        setUploadResult1
                      )
                    }
                    size="sm"
                  />
                  <Tooltip title="Enviar envidência fotográfica" arrow>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        handleFileUpload(
                          file1,
                          "EvidenciaFotografica01",
                          setIsUploading1,
                          setUploadResult1,
                          setFileName1
                        )
                      }
                      className="custom-laudo-send-button"
                      size="sm"
                    >
                      {isUploading1 ? <Spinner animation="border" /> : "Enviar"}
                    </Button>
                  </Tooltip>
                </InputGroup>
                <div>
                  {previewImage1 && (
                    <img
                      src={previewImage1}
                      alt="Preview da imagem"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  )}
                  {uploadResult1 === "success" && (
                    <>
                      <FaCheckCircle
                        style={{ color: "green", marginLeft: "5px" }}
                      />{" "}
                      <span>Imagem salva com sucesso.</span>
                    </>
                  )}
                  {uploadResult1 === "error" && (
                    <>
                      <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                      <span>Não foi possível salvar essa imagem</span>
                    </>
                  )}
                </div>
              </Row>

              {/* Evidência 2 */}
              <Row className="mt-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Evidência fotográfica 02:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      handleImageChange(
                        e,
                        setPreviewImage2,
                        setFile2,
                        setUploadResult2
                      )
                    }
                    size="sm"
                  />
                  <Tooltip title="Enviar envidência fotográfica" arrow>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        handleFileUpload(
                          file2,
                          "EvidenciaFotografica02",
                          setIsUploading2,
                          setUploadResult2,
                          setFileName2
                        )
                      }
                      className="custom-laudo-send-button"
                      size="sm"
                    >
                      {isUploading2 ? <Spinner animation="border" /> : "Enviar"}
                    </Button>
                  </Tooltip>
                </InputGroup>
                <div>
                  {previewImage2 && (
                    <img
                      src={previewImage2}
                      alt="Preview da imagem"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  )}
                  {uploadResult2 === "success" && (
                    <>
                      <FaCheckCircle
                        style={{ color: "green", marginLeft: "5px" }}
                      />{" "}
                      <span>Imagem salva com sucesso.</span>
                    </>
                  )}
                  {uploadResult2 === "error" && (
                    <>
                      <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                      <span>Não foi possível salvar essa imagem</span>
                    </>
                  )}
                </div>
              </Row>

              {/* Evidência 3 */}
              <Row className="mt-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Evidência fotográfica 03:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      handleImageChange(
                        e,
                        setPreviewImage3,
                        setFile3,
                        setUploadResult3
                      )
                    }
                    size="sm"
                  />
                  <Tooltip title="Enviar envidência fotográfica" arrow>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        handleFileUpload(
                          file3,
                          "EvidenciaFotografica03",
                          setIsUploading3,
                          setUploadResult3,
                          setFileName3
                        )
                      }
                      className="custom-laudo-send-button"
                      size="sm"
                    >
                      {isUploading3 ? <Spinner animation="border" /> : "Enviar"}
                    </Button>
                  </Tooltip>
                </InputGroup>
                <div>
                  {previewImage3 && (
                    <img
                      src={previewImage3}
                      alt="Preview da imagem"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  )}
                  {uploadResult3 === "success" && (
                    <>
                      <FaCheckCircle
                        style={{ color: "green", marginLeft: "5px" }}
                      />{" "}
                      <span>Imagem salva com sucesso.</span>
                    </>
                  )}
                  {uploadResult3 === "error" && (
                    <>
                      <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                      <span>Não foi possível salvar essa imagem</span>
                    </>
                  )}
                </div>
              </Row>

              {/* Evidência 4 */}
              <Row className="mt-3">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Evidência fotográfica 04:
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="file"
                    onChange={(e) =>
                      handleImageChange(
                        e,
                        setPreviewImage4,
                        setFile4,
                        setUploadResult4
                      )
                    }
                    size="sm"
                  />
                  <Tooltip title="Enviar envidência fotográfica" arrow>
                    <Button
                      variant="outline-secondary"
                      onClick={() =>
                        handleFileUpload(
                          file4,
                          "EvidenciaFotografica04",
                          setIsUploading4,
                          setUploadResult4,
                          setFileName4
                        )
                      }
                      className="custom-laudo-send-button"
                      size="sm"
                    >
                      {isUploading4 ? <Spinner animation="border" /> : "Enviar"}
                    </Button>
                  </Tooltip>
                </InputGroup>
                <div>
                  {previewImage4 && (
                    <img
                      src={previewImage4}
                      alt="Preview da imagem"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                  )}
                  {uploadResult4 === "success" && (
                    <>
                      <FaCheckCircle
                        style={{ color: "green", marginLeft: "5px" }}
                      />{" "}
                      <span>Imagem salva com sucesso.</span>
                    </>
                  )}
                  {uploadResult4 === "error" && (
                    <>
                      <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                      <span>Não foi possível salvar essa imagem</span>
                    </>
                  )}
                </div>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "6vh",
                  marginTop: "1vh",
                }}
              >
                <Button
                  variant="outline-secondary"
                  className="custom-laudo-continue-button"
                  onClick={() => setShowEvidencias(false)}
                >
                  {isUploadingContinuar ? (
                    <Spinner animation="border" />
                  ) : (
                    "Continuar"
                  )}
                </Button>
              </div>
            </>
          ) : (
            <>

              {isUpdate ? (
                <>

                </>
              ) : (
                <>
                  <Row className="mt-2 align-items-center">
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <div className="form-group">
                        <label className="bold-label font-label-laudo">
                          Copiar dados do Laudo:
                        </label>
                        <input
                          size={"sm"}
                          type="text"
                          placeholder="Digite um Laudo"
                          className="form-control form-control-sm uppercase-input"
                          maxLength={40}
                          value={copiarLaudo}
                          onChange={handleCopiarPorLaudoChange}
                          disabled={copiarTagInguanti.length > 0}
                          style={copiarTagInguanti.length > 0 ? { backgroundColor: '#e9ecef', cursor: 'not-allowed' } : {}}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <div className="form-group">
                        <label className="bold-label font-label-laudo">
                          Copiar dados usando TAG Inguanti:
                        </label>
                        <input
                          size={"sm"}
                          type="text"
                          placeholder="Digite Tag Inguanti"
                          className="form-control form-control-sm uppercase-input"
                          maxLength={10}
                          value={copiarTagInguanti}
                          onChange={handleCopiarPorTagInguantiChange}
                          disabled={copiarLaudo.length > 0}
                          style={copiarLaudo.length > 0 ? { backgroundColor: '#e9ecef', cursor: 'not-allowed' } : {}}
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} style={{ marginTop: '22px' }}>
                      <Button
                        variant="outline-success"
                        className="btn-sm"
                        onClick={() =>
                          handleCopiarLaudo(
                            copiarLaudo.length > 0 ? copiarLaudo : null,
                            copiarTagInguanti.length > 0 ? copiarTagInguanti : null
                          )
                        }

                      >
                        Copiar Laudo
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Form.Group as={Col} controlId="evidenciafotografica1" xs={12} sm={6} md={6} lg={6} >
                      <Form.Label className="bold-label font-label-laudo">
                        *Evidência fotográfica 01:
                      </Form.Label>
                      <Form.Control
                        size={"sm"}
                        type="text"
                        className="uppercase-input"
                        disabled
                        onChange={(e) =>
                          (e.target.value = e.target.value.toLowerCase())
                        }
                        value={
                          fileName1 !== "" ? fileName1.toLowerCase() : "SEMIMAGEM01.JPG".toLowerCase()
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="evidenciafotografica2" xs={12} sm={6} md={6} lg={6}>
                      <Form.Label className="bold-label font-label-laudo">
                        *Evidência fotográfica 02:
                      </Form.Label>
                      <Form.Control
                        size={"sm"}
                        type="text"
                        className="uppercase-input"
                        disabled
                        onChange={(e) =>
                          (e.target.value = e.target.value.toLowerCase())
                        }
                        value={
                          fileName2 != "" ? fileName2.toLowerCase() : "SEMIMAGEM02.JPG".toLowerCase()
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Row className="mt-2">
                    <Form.Group as={Col} controlId="evidenciafotografica3" xs={12} sm={6} md={6} lg={6}>
                      <Form.Label className="bold-label font-label-laudo">
                        *Evidência fotográfica 03:
                      </Form.Label>
                      <Form.Control
                        size={"sm"}
                        type="text"
                        className="uppercase-input"
                        disabled
                        onChange={(e) =>
                          (e.target.value = e.target.value.toLowerCase())
                        }
                        value={
                          fileName3 != "" ? fileName3.toLowerCase() : "SEMIMAGEM03.JPG".toLowerCase()
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="evidenciafotografica4" xs={12} sm={6} md={6} lg={6}>
                      <Form.Label className="bold-label font-label-laudo">
                        *Evidência fotográfica 04:
                      </Form.Label>
                      <Form.Control
                        size={"sm"}
                        type="text"
                        className="uppercase-input"
                        disabled
                        onChange={(e) =>
                          (e.target.value = e.target.value.toLowerCase())
                        }
                        value={
                          fileName4 != "" ? fileName4.toLowerCase() : "SEMIMAGEM04.JPG".toLowerCase()
                        }
                      />
                    </Form.Group>
                  </Row>
                </>
              )}

              <Row className="mt-2">
                <Form.Group as={Col} controlId="acessorio" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Acessorio:
                  </Form.Label>
                  <Form.Select
                    size={"sm"}
                    className="uppercase-input"
                    autoFocus
                    value={isUpdate ? editAcessorio : newAcessorio}
                    onChange={handleAcessorioChange}
                  >
                    <option key={-1} value={"-1"}>
                      SELECIONE
                    </option>
                    {constListAcessoriosData
                      .sort((a, b) => a.acessorio.localeCompare(b.acessorio))
                      .map((item) => (
                        <option key={item.idAcessorios} value={item.acessorio}>
                          {item.acessorio}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="taginguanti" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *TAG Inguanti:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="TAG INGUANTI"
                    className="uppercase-input"
                    maxLength={10}
                    value={isUpdate ? editTagInguanti : newTagInguanti}
                    onChange={handleTagInguantiChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="tagcliente" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    TAG Cliente:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="TAG CLIENTE"
                    className="uppercase-input"
                    maxLength={10}
                    value={isUpdate ? editTagCliente : newTagCliente}
                    onChange={handleTagClienteChange}
                  />
                </Form.Group>

              </Row>

              <Row className="mt-2">
                <Form.Group as={Col} controlId="fabricante" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Fabricante:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="FABRICANTE"
                    className="uppercase-input"
                    maxLength={25}
                    value={isUpdate ? editFabricante : newFabricante}
                    onChange={handleFabricanteChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="modelo" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Modelo:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="MODELO"
                    className="uppercase-input"
                    maxLength={25}
                    value={isUpdate ? editModelo : newModelo}
                    onChange={handleModeloChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="capacidade" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Capacidade:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="CAPACIDADE"
                    className="uppercase-input"
                    maxLength={25}
                    value={isUpdate ? editCapacidade : newCapacidade}
                    onChange={handleCapacidadeChange}
                  />
                </Form.Group>

              </Row>

              <Row className="mt-2">
                <Col>
                  <Form.Group controlId="dimensoes">
                    <Form.Label className="bold-label font-label-laudo">
                      *Dimensões:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="DIMENSÕES"
                      rows={5}
                      className="uppercase-input"
                      maxLength={1000}
                      value={isUpdate ? editDimensoes : newDimensoes}
                      onChange={handleDimensoesChange}
                    />
                  </Form.Group>
                </Col>
              </Row>


              {/* <Row className="mt-2">
                <Form.Group as={Col} controlId="observacao">
                  <Form.Label className="bold-label font-label-laudo">
                    Observação:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="OBSERVAÇÃO"
                    className="uppercase-input"
                    maxLength={1100}
                    value={
                      isUpdate
                        ? editDemaisInformacoesAcessorio
                        : newDemaisInformacoesAcessorio
                    }
                    onChange={handleObservacaoChange}
                  />
                </Form.Group>
              </Row> */}

              <Row className="mt-2">
                <Form.Group as={Col} controlId="contato" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Contato:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="CONTATO"
                    className="uppercase-input"
                    maxLength={26}
                    value={isUpdate ? editContato : newContato}
                    onChange={handleContatoChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="setor" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Setor:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="SETOR"
                    className="uppercase-input"
                    maxLength={20}
                    value={isUpdate ? editSetor : newSetor}
                    onChange={handleSetorChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="ddd" xs={12} sm={1} md={1} lg={1}>
                  <Form.Label className="bold-label font-label-laudo">
                    DDD:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="DDD"
                    className="uppercase-input"
                    maxLength={2}
                    value={isUpdate ? editDDD : newDDD}
                    onChange={handleDDDChange}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="telefone"
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                >
                  <Form.Label className="bold-label font-label-laudo">
                    Tel:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="TELEFONE"
                    className="uppercase-input"
                    maxLength={9}
                    value={isUpdate ? editTelefone : newTelefone}
                    onChange={handleTelefoneChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mt-2">
                <Form.Group as={Col} controlId="subsetor" xs={12} sm={8} md={8} lg={8}            >
                  <Form.Label className="bold-label font-label-laudo">
                    Sub Setor:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="SUB SETOR"
                    className="uppercase-input"
                    maxLength={100}
                    value={isUpdate ? editSubsetor : newSubsetor}
                    onChange={handleSubSetorChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="datainspecao" xs={12} sm={4} md={4} lg={4}
                >
                  <Form.Label className="bold-label font-label-laudo">
                    *Data da Inspeção:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="date"
                    placeholder="DATA INSP."
                    value={
                      isUpdate
                        ? editDataMetodologiaInspecao
                        : newDataMetodologiaInspecao
                    }
                    onChange={handleDataMetodologiaInspecaoChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mt-2">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Form.Group controlId="metodologiainspecao">
                    <Form.Label className="bold-label font-label-laudo">
                      *Metodologia da inspeção executada:
                    </Form.Label>
                    <Form.Select
                      size={"sm"}
                      className="uppercase-input"
                      value={isUpdate ? editMetodologiaInspecao : newMetodologiaInspecao}
                      onChange={handleMetodologiaInspecaoChange}
                    >
                      {metodologiasInspecaoList.map((item) => (
                        <option key={item.idMetodologiaInspecao} value={item.tipoMetodologia}>
                          {item.tipoMetodologia}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-2">
                <Form.Group as={Col} controlId="certificadofabricante" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Certificado do Fabricante:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="CERTIFICADO DO FABRICANTE"
                    className="uppercase-input"
                    maxLength={10}
                    value={
                      isUpdate
                        ? editCertificadoFabricante
                        : newCertificadoFabricante
                    }
                    onChange={handleCertificadoFabricanteChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="registroinspecao" xs={12} sm={4} md={4} lg={4}>
                  <Form.Label className="bold-label font-label-laudo">
                    *Registro de Inspeção:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="REGISTRO DE INSPEÇÃO"
                    className="uppercase-input"
                    maxLength={9}
                    value={isUpdate ? editRegistroInspecao : newRegistroInspecao}
                    onChange={handleRegistroInspecaoChange}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="registroreparo"
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                >
                  <Form.Label className="bold-label font-label-laudo">
                    *Registro de Reparo:
                  </Form.Label>
                  <Form.Control
                    size={"sm"}
                    type="text"
                    placeholder="REGISTRO DE REPARO"
                    className="uppercase-input"
                    maxLength={9}
                    value={isUpdate ? editRegistroReparo : newRegistroReparo}
                    onChange={handleRegistroReparoChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mt-2">
                <Form.Group as={Col} controlId="conclusaotecncia">
                  <Form.Label className="bold-label font-label-laudo">
                    *Conclusão Técnica:
                  </Form.Label>
                  <Form.Select
                    size={"sm"}
                    className="uppercase-input"
                    value={isUpdate ? editConclusaoTecnica : newConclusaoTecnica}
                    onChange={handleConclusaoTecnicaChange}
                  >
                    <option key={-1} value={"-1"}>
                      SELECIONE
                    </option>
                    <option key={1} value={"APROVADO 3 MESES"}>
                      APROVADO 3 MESES
                    </option>
                    <option key={2} value={"APROVADO 6 MESES"}>
                      APROVADO 6 MESES
                    </option>
                    <option key={3} value={"APROVADO 12 MESES"}>
                      APROVADO 12 MESES
                    </option>
                    <option key={4} value={"APROVADO/RECOMENDAÇÕES"}>
                      APROVADO/RECOMENDAÇÕES
                    </option>
                    <option key={5} value={"REPROVADO"}>
                      REPROVADO
                    </option>
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row className="mt-2">
                <Col>
                  <Form.Group controlId="recomendacoes">
                    <Form.Label className="bold-label font-label-laudo">
                      Recomendações:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="RECOMENDAÇÕES"
                      rows={5}
                      className="uppercase-input"
                      maxLength={1100}
                      value={isUpdate ? editRecomendacoes : newRecomendacoes}
                      onChange={handleRecomendacoesChange}
                    />
                  </Form.Group>
                </Col>
              </Row>


              {isUpdate ? (
                <>
                  <Row className="mt-3 w-100">
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
                      {/* Evidência 1 */}
                      <div className="w-50" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Evidência fotográfica 01:
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                setPreviewImage1,
                                setFile1,
                                setUploadResult1
                              )
                            }
                            size="sm"
                          />
                          <Tooltip title="Enviar envidência fotográfica" arrow>
                            <Button
                              variant="outline-secondary"
                              onClick={() =>
                                handleFileUpload(
                                  file1,
                                  "EvidenciaFotografica01",
                                  setIsUploading1,
                                  setUploadResult1,
                                  setFileName1
                                )
                              }
                              className="custom-laudo-send-button"
                              size="sm"
                            >
                              {isUploading1 ? <Spinner animation="border" /> : "Enviar"}
                            </Button>
                          </Tooltip>
                        </InputGroup>
                        <div>
                          {previewImage1 && (
                            <div style={{ height: "160px", width: "160px" }}>
                              <img
                                src={previewImage1}
                                alt="Preview da imagem"
                                style={{ maxWidth: "150px", maxHeight: "150px" }}
                              />
                            </div>
                          )}
                          {uploadResult1 === "success" && (
                            <>
                              <FaCheckCircle
                                style={{ color: "green", marginLeft: "5px" }}
                              />{" "}
                              <span>Imagem salva com sucesso.</span>
                            </>
                          )}
                          {uploadResult1 === "error" && (
                            <>
                              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                              <span>Não foi possível salvar essa imagem</span>
                            </>
                          )}
                        </div>
                      </div>

                      {/* Evidência 2 */}
                      <div className="w-50" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Evidência fotográfica 02:
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                setPreviewImage2,
                                setFile2,
                                setUploadResult2
                              )
                            }
                            size="sm"
                          />
                          <Tooltip title="Enviar envidência fotográfica" arrow>
                            <Button
                              variant="outline-secondary"
                              onClick={() =>
                                handleFileUpload(
                                  file2,
                                  "EvidenciaFotografica02",
                                  setIsUploading2,
                                  setUploadResult2,
                                  setFileName2
                                )
                              }
                              className="custom-laudo-send-button"
                              size="sm"
                            >
                              {isUploading2 ? <Spinner animation="border" /> : "Enviar"}
                            </Button>
                          </Tooltip>
                        </InputGroup>
                        <div>
                          {previewImage2 && (
                            <div style={{ height: "160px", width: "160px" }}>
                              <img
                                src={previewImage2}
                                alt="Preview da imagem"
                                style={{ maxWidth: "150px", maxHeight: "150px" }}
                              />
                            </div>
                          )}
                          {uploadResult2 === "success" && (
                            <>
                              <FaCheckCircle
                                style={{ color: "green", marginLeft: "5px" }}
                              />{" "}
                              <span>Imagem salva com sucesso.</span>
                            </>
                          )}
                          {uploadResult2 === "error" && (
                            <>
                              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                              <span>Não foi possível salvar essa imagem</span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="mt-3 w-100">
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
                      {/* Evidência 3 */}
                      <div className="w-50" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Evidência fotográfica 03:
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                setPreviewImage3,
                                setFile3,
                                setUploadResult3
                              )
                            }
                            size="sm"
                          />
                          <Tooltip title="Enviar envidência fotográfica" arrow>
                            <Button
                              variant="outline-secondary"
                              onClick={() =>
                                handleFileUpload(
                                  file3,
                                  "EvidenciaFotografica03",
                                  setIsUploading3,
                                  setUploadResult3,
                                  setFileName3
                                )
                              }
                              className="custom-laudo-send-button"
                              size="sm"
                            >
                              {isUploading3 ? <Spinner animation="border" /> : "Enviar"}
                            </Button>
                          </Tooltip>
                        </InputGroup>
                        <div>
                          {previewImage3 && (
                            <div style={{ height: "160px", width: "160px" }}>
                              <img
                                src={previewImage3}
                                alt="Preview da imagem"
                                style={{ maxWidth: "150px", maxHeight: "150px" }}
                              />
                            </div>
                          )}
                          {uploadResult3 === "success" && (
                            <>
                              <FaCheckCircle
                                style={{ color: "green", marginLeft: "5px" }}
                              />{" "}
                              <span>Imagem salva com sucesso.</span>
                            </>
                          )}
                          {uploadResult3 === "error" && (
                            <>
                              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                              <span>Não foi possível salvar essa imagem</span>
                            </>
                          )}
                        </div>
                      </div>

                      {/* Evidência 4 */}
                      <div className="w-50" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Evidência fotográfica 04:
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              handleImageChange(
                                e,
                                setPreviewImage4,
                                setFile4,
                                setUploadResult4
                              )
                            }
                            size="sm"
                          />
                          <Tooltip title="Enviar envidência fotográfica" arrow>
                            <Button
                              variant="outline-secondary"
                              onClick={() =>
                                handleFileUpload(
                                  file4,
                                  "EvidenciaFotografica04",
                                  setIsUploading4,
                                  setUploadResult4,
                                  setFileName4
                                )
                              }
                              className="custom-laudo-send-button"
                              size="sm"
                            >
                              {isUploading4 ? <Spinner animation="border" /> : "Enviar"}
                            </Button>
                          </Tooltip>
                        </InputGroup>
                        <div>
                          {previewImage4 && (
                            <div style={{ height: "160px", width: "160px" }}>
                              <img
                                src={previewImage4}
                                alt="Preview da imagem"
                                style={{ maxWidth: "150px", maxHeight: "150px" }}
                              />
                            </div>
                          )}
                          {uploadResult4 === "success" && (
                            <>
                              <FaCheckCircle
                                style={{ color: "green", marginLeft: "5px" }}
                              />{" "}
                              <span>Imagem salva com sucesso.</span>
                            </>
                          )}
                          {uploadResult4 === "error" && (
                            <>
                              <FaTimesCircle style={{ color: "red", marginLeft: "5px" }} />{" "}
                              <span>Não foi possível salvar essa imagem</span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "6vh",
                      marginTop: "5vh",
                    }}
                  >
                    <Button
                      variant="outline-secondary"
                      className="custom-laudo-incluir-button"
                      onClick={() =>
                        handleUpdate(
                          idLaudo,
                          fileName1,
                          fileName2,
                          fileName3,
                          fileName4,
                          editAcessorio,
                          editTagInguanti,
                          editTagCliente,
                          editFabricante,
                          editModelo,
                          editCapacidade,
                          editDimensoes,
                          editDemaisInformacoesAcessorio,
                          editContato,
                          editSetor,
                          editTelefone,
                          editDDD,
                          editSubsetor,
                          editMetodologiaInspecao,
                          editDataMetodologiaInspecao,
                          editCertificadoFabricante,
                          editRegistroInspecao,
                          editRegistroReparo,
                          editConclusaoTecnica,
                          editRecomendacoes
                        )
                      }
                    >
                      Atualizar Registro
                    </Button>
                  </div>
                </>

              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "6vh",
                    marginTop: "5vh",
                  }}
                >
                  <Button
                    variant="outline-secondary"
                    className="custom-laudo-incluir-button"
                    onClick={() =>
                      handleSave(
                        fileName1,
                        fileName2,
                        fileName3,
                        fileName4,
                        newAcessorio,
                        newTagInguanti,
                        newTagCliente,
                        newFabricante,
                        newModelo,
                        newCapacidade,
                        newDimensoes,
                        newDemaisInformacoesAcessorio,
                        newContato,
                        newSetor,
                        newTelefone,
                        newDDD,
                        newSubsetor,
                        newMetodologiaInspecao,
                        newDataMetodologiaInspecao,
                        newCertificadoFabricante,
                        newRegistroInspecao,
                        newRegistroReparo,
                        newConclusaoTecnica,
                        newRecomendacoes
                      )
                    }
                  >
                    Incluir Registro
                  </Button>
                </div>
              )}
            </>
          )}
          {loadingPDF && <Loading />}
          {responseData && <ExportToPDF response={responseData.data} onPdfUploadComplete={handlePdfUploadComplete} />}

        </Card.Body>
      </Card>

    </Container>
  );
};

export default Laudos;
