import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";


const NossosContatos = ({
    textNossosContatos,
    textFoneContatos,
    textEmailContatos,
    textSaibaMaisContatos
}) => (
    <>
        <View style={[styles.rectangleNossosContatos]}>
            <Text style={[styles.textNossosContatos, { marginTop: 2 }]}>
                {textNossosContatos}
            </Text>
            <Text style={[styles.textNossosContatos]}>
                {textFoneContatos}
            </Text>
            <Text style={[styles.textNossosContatos]}>
                {textEmailContatos}
            </Text>
            <Text style={[styles.textNossosContatos, { marginBottom: 5 }]}>
                {textSaibaMaisContatos}
            </Text>
        </View>

    </>

);

export default NossosContatos;
