import { Navigate } from 'react-router-dom';
import { useAuth } from './Auth';

function PrivateRoute({ children, requiredPermission }) {
  const { currentUser, checkUserPermission } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  } else if (!checkUserPermission(requiredPermission)) {  
    return <Navigate to="/acesso-negado" replace />;
  } else {   
    return children;
  }
}


export default PrivateRoute;
