import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import './../sharedStyles.css'

const InspetorChart = ({ dataInspecoes }) => {

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const currentDate = new Date().toLocaleDateString('pt-BR'); // Formato de data brasileiro

  const labels = dataInspecoes?.map(item => item.inspetor);
  const dataValues2 = dataInspecoes?.map(item => item.quantidadeInspecoes);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Inspeções',
        data: dataValues2,
        backgroundColor: '#CCE5FF',
        borderColor: 'rgba(3, 2, 103, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Opções do gráfico
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      title: {
        display: false,
        text: 'Horizontal Bar Chart',
      },
    },
  };

  return (
    <>
      <Row className='mt-3 g-3'>
        <Col>
          <Card>
            <Card.Header className='card-chart-title'>Total inspeções por Inspetor - 01/01/{previousYear} à {currentDate}</Card.Header>
            <Card.Body>
              <Bar data={data} options={options} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InspetorChart;
