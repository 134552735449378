import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';

//Compartilhados
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora } from '../Shared/Utils/helper';
import TableOs from '../Shared/CustomJs/TableOs';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/OrdemServico`;

const OsLaudos = () => {

    const listOptions = [
        { value: "os", label: "N° O.S." },
        { value: "cliente", label: "Cliente" },
        { value: "art", label: "N° Art" },
    ];

    const [loading, setLoading] = useState(true);

    const itemsPerPage = 50; // Número de itens por página

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [constListData, setConstListData] = useState([]);

    const [selectedOption, setSelectedOption] = useState("os");

    const navigate = useNavigate();

    // No react 18 é necessário para não chamar 2 vezes a API
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            fetchInitialData();
        }
    }, []);

    const fetchInitialData = async () => {
        fetchOrdemServicoData();
    };

    const fetchOrdemServicoData = async () => {
        setLoading(true);
        try {
            setSearchTerm('');
            const response = await axios.get(`${baseApi}/get-ordemservicos`);
            const formattedData = formatarDataComHora(response.data);
            setConstListData(formattedData);
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
        setLoading(false);
    };

    const handleChangeSearchTerm = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSearchClick = () => {
        const searchInput = document.getElementById("pesquisarId");
        setSearchTerm(searchInput.value);
    };

    const handleAddLaudo = (idOrdemServico) => {
        navigate(`/laudos/${idOrdemServico}`);
    };
    const handleDetalharOs = (idOrdemServico) => {
        navigate(`/detalhar-os/${idOrdemServico}`);
    };
    const handleDocOs = (idOrdemServico) => {
        navigate(`/doc-os/${idOrdemServico}`);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredData = constListData.filter((item) =>
        item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const totalRecords = filteredData.length;

    if (loading) {
        return <Loading />;
    }
    if (constListData.length <= 0) {
        return (
            <Container fluid>
                <style>{styles}</style>
                <Row>
                    <Col>
                        <Form>
                            <Row className='mt-3'>
                                <Card>
                                    <Card.Header className='title-card-custom'><h4>OS - Laudos</h4></Card.Header>
                                </Card>
                            </Row>
                            <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        )
    }
    return (
        <Container fluid>
            <style>{styles}</style>
            <Row className='mt-3'>
                <Col>
                    <Form>
                        <Card>
                            <Card.Header className='title-card-custom'><h4>OS - Laudos</h4></Card.Header>
                            <Card.Body>

                                <SearchBar
                                    listOptions={listOptions}
                                    handleChangeSearchTerm={handleChangeSearchTerm}
                                    handleSelectChange={handleSelectChange}
                                    handleSearchClick={handleSearchClick}
                                    fetchInitialData={fetchInitialData}
                                />
                                <TableOs
                                    data={paginatedData}
                                    handleAddLaudo={handleAddLaudo}
                                    handleDetalharOs={handleDetalharOs}
                                    handleDocOs={handleDocOs}
                                />

                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
};

export default OsLaudos;
