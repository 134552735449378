import React from "react";
import { Table, Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { BsTrash, BsPencil } from 'react-icons/bs';
import './CustomTable.css';

const TableLaudo = ({ data, handleDownloadPdf, handleEditLaudo, handleDeleteLaudo }) => {
    return (
        <div className="table-responsive custom-div-table">
            <Table responsive striped bordered hover className="table-sm custom-table">
                <thead >
                    <tr style={{ color: "#236CB3" }}>
                        <th>PDF</th>
                        <th></th>
                        <th></th>
                        <th>Resultado</th>
                        <th>Laudo</th>
                        <th>N° ART</th>
                        <th>Acessórios</th>
                        <th>Tag Ing.</th>
                        <th>Tag</th>
                        <th>Fabricante</th>
                        <th>Modelo</th>
                        <th>Capacidade</th>
                        <th>Dimensões</th>
                        <th>Obs. Acess.</th>
                        <th>Empresa</th>
                        <th>CNPJ</th>
                        <th>Contato</th>
                        <th>Setor</th>
                        <th>Telefone</th>
                        <th>Data Metodologia Inspeção</th>
                        <th>Certificado Fabr.</th>
                        <th>Registro Inspeção</th>
                        <th>Registro reparo</th>
                        <th>Recomendações</th>
                        <th>Inspetor</th>
                        <th>CPF Inspetor</th>
                        <th>Respons. Téc.</th>
                        <th>Crea</th>
                        <th>Cadastrado por</th>
                        <th>Data Cadastro</th>
                        <th>Alterado por</th>
                        <th>Data Alteração</th>
                    </tr>
                </thead>
                <tbody >
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Abrir Pdf" arrow>
                                    <Button
                                        size="sm"
                                        className="custom-pdf-button"
                                        variant="link"
                                        onClick={() => handleDownloadPdf(item.laudo)}
                                    >
                                        <img src={process.env.PUBLIC_URL + '/icons/PdfIco.ico'} className="custom-icon-pdf" />
                                    </Button>


                                </Tooltip>

                            </td>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Editar" arrow>
                                    <Button
                                        size="sm"
                                        onClick={() => handleEditLaudo(item)}
                                        className="custom-edit-button"
                                    >
                                        <BsPencil className="custom-icon-trash-pencil" />
                                    </Button>
                                </Tooltip>
                            </td>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Deletar" arrow>
                                    <Button
                                        size="sm"
                                        onClick={() => handleDeleteLaudo(item)}
                                        className="custom-delete-button"
                                    >
                                        <BsTrash className="custom-icon-trash-pencil" />
                                    </Button>
                                </Tooltip>
                            </td>
                            <td style={{
                                backgroundColor:
                                    item.conclusaoTecnica === "APROVADO 12 MESES" ||
                                        item.conclusaoTecnica === "APROVADO 6 MESES" ||
                                        item.conclusaoTecnica === "APROVADO 3 MESES"
                                        ? "#00FF7F" // Verde
                                        : item.conclusaoTecnica.includes("RECOMENDAÇÕES")
                                            ? "#FFFF00" // Amarelo
                                            : item.conclusaoTecnica === "REPROVADO"
                                                ? "#FF0000" // Vermelho
                                                : "transparent" // Caso não seja nenhum dos valores acima
                            }}>
                                {item.conclusaoTecnica}
                            </td>


                            <td>{item.laudo}</td>
                            <td>{item.art}</td>
                            <td>{item.acessorio}</td>
                            <td>{item.tagInguanti}</td>
                            <td>{item.tagCliente}</td>
                            <td>{item.fabricante}</td>
                            <td>{item.modelo}</td>
                            <td>{item.capacidade}</td>
                            <td>{item.dimensoes}</td>
                            <td>{item.demaisInformacoesAcessorio}</td>
                            <td>{item.empresa}</td>
                            <td>{item.cnpj}</td>
                            <td>{item.contato}</td>
                            <td>{item.setor}</td>
                            <td>{item.telefone}</td>
                            <td>{item.dataMetodologiaInspecao}</td>
                            <td>{item.certificadoFabricante}</td>
                            <td>{item.registroInspecao}</td>
                            <td>{item.registroReparo}</td>
                            <td>{item.recomendacoes}</td>
                            <td>{item.nomeInspetor}</td>
                            <td>{item.numeroDocumentoInspetor}</td>
                            <td>{item.nomeResponsavelTecnico}</td>
                            <td>{item.crea}</td>
                            <td>{item.cadastradoPor}</td>
                            <td>{item.dataCadastro}</td>
                            <td>{item.alteradoPor}</td>
                            <td>{item.dataAlteracao}</td>
                        </tr>
                    ))}
                </tbody>


            </Table>
        </div>
    );
};

export default TableLaudo;
