import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './AccessDenied.css';

const AccessDenied = () => {
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        setInProp(true);
    }, []);

    return (
        <CSSTransition in={inProp} timeout={2000} classNames="my-node">

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', flexDirection: 'column' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10%', 
                    maxWidth: '100%'
                }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/img/jpeg/Inguanti_Inspecoes_new.jpg`}
                        alt="Inguanti Inspeções"
                        style={{
                            maxWidth: '60%',
                            height: 'auto'
                        }}
                    />
                </div>
                <p style={{ marginTop: '1vh', color: 'red', fontSize: '60px', fontWeight: 'bold' }}>Acesso Negado</p>
                <p style={{ color: 'red', fontSize: '23px' }}>Você não tem permissão para acessar esta página.</p>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1%', 
                    maxWidth: '100%'
                }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/img/jpeg/access_denied.jpg`}
                        alt="Inguanti Inspeções"
                        style={{
                            maxWidth: '40%',
                            height: 'auto'
                        }}
                    />

                </div>

            </div>
        </CSSTransition>


    );
}

export default AccessDenied;
