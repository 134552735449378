import React from 'react';
import { Pagination } from 'react-bootstrap';

function CustomPagination({ currentPage, totalPages, onPageChange }) {
    const maxButtons = 7; // número máximo de botões que você quer exibir
    const sideButtons = Math.floor(maxButtons / 2);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const calculateRange = (currentPage, totalPages) => {
        if (totalPages <= maxButtons) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        } else if (currentPage <= sideButtons) {
            return Array.from({ length: maxButtons - 1 }, (_, i) => i + 1).concat('...');
        } else if (currentPage > totalPages - sideButtons) {
            return ['...'].concat(Array.from({ length: maxButtons - 1 }, (_, i) => totalPages - (maxButtons - 2) + i));
        } else {
            return ['...'].concat(Array.from({ length: maxButtons - 2 }, (_, i) => currentPage - sideButtons + i + 1)).concat('...');
        }
    }

    const pagesToShow = calculateRange(currentPage, totalPages);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
            <Pagination>
                <Pagination.First
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(1)}
                />
                <Pagination.Prev
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                />
                {pagesToShow.map((page, index) => (
                    page === '...'
                        ? <Pagination.Ellipsis key={index} />
                        : <Pagination.Item
                            key={index}
                            active={page === currentPage}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </Pagination.Item>
                ))}
                <Pagination.Next
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                />
                <Pagination.Last
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(totalPages)}
                />
            </Pagination>
        </div>
    );
}

export default CustomPagination;

