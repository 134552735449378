import React from "react";
import { Table, Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import './CustomTable.css';
import LoadingTable from "../Loading/LoadingTable";
import { exportToExcel } from "../Utils/exportToExcel";
import { FaFileExcel, FaPrint } from 'react-icons/fa';


export const TableAreaCliente = ({ data, handleDownloadPdf, handlePrintLaudos, isLoading, applyMaxHeight = false, maxHeight = '200px' }) => {

    const handleExport = () => {
        const columns = ["idCliente", "idLaudo", "idOrdemServico", "dataInicio", "dataFim"];  // Por exemplo, se você quiser remover "coluna2" e "coluna4"
        exportToExcel(data, "Laudo", "Laudo.xlsx", columns);
    }

    if (isLoading) {
        return (
            <LoadingTable />
        );
    }

    if (data.length <= 0) {
        return (
            <div className="table-responsive custom-div-table mt-3">
                <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                </div>
            </div>
        )
    }

    return (
        <div className="table-responsive mt-3" style={{height: applyMaxHeight ? maxHeight : 'auto'}}>

            <Tooltip title="Imprimir relatório dos Laudos" arrow>
                <Button
                    size="sm"
                    className="custom-laudos-button"
                    variant="link"
                    onClick={handlePrintLaudos}
                >
                    <FaPrint color="gray" style={{ marginBottom: '3px' }} />
                </Button>
            </Tooltip>

            <Tooltip title="Exportar tabela para Excel" arrow>
                <Button
                    size="sm"
                    className="custom-laudos-button"
                    variant="link"
                    onClick={handleExport}
                >
                    <FaFileExcel color="green" style={{ marginBottom: '3px' }} />
                </Button>
            </Tooltip>


            <Table striped bordered hover className="table-sm custom-table">
                <thead className="thead-fix">
                    <tr style={{ color: "#236CB3" }}>
                        <th className="no-print">PDF</th>
                        <th>Resultado</th>
                        <th>Empresa</th>
                        <th>Laudo</th>
                        <th>Setor</th>
                        <th>Sub Setor</th>
                        <th>Acessório</th>
                        <th>Tag Inguanti</th>
                        <th>Tag Cliente</th>
                        <th>Respons. Téc.</th>
                        <th>Metodologia</th>
                        <th>Recomendações</th>
                        <th>Data Cadastro</th>
                    </tr>
                </thead>
                <tbody >
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td style={{ width: '1vh' }} className="no-print">
                                <Tooltip title="Abrir Pdf" arrow>
                                    <Button
                                        size="sm"
                                        className="custom-pdf-button"
                                        variant="link"
                                        onClick={() => handleDownloadPdf(item.laudo, item.idLaudo)}
                                    >
                                        <img src={process.env.PUBLIC_URL + '/icons/PdfIco.ico'} className="custom-icon-pdf" />
                                    </Button>
                                </Tooltip>
                            </td>

                            <td style={{
                                backgroundColor:
                                    item.conclusaoTecnica === "APROVADO 12 MESES" ||
                                        item.conclusaoTecnica === "APROVADO 6 MESES" ||
                                        item.conclusaoTecnica === "APROVADO 3 MESES"
                                        ? "#00FF7F" // Verde
                                        : item.conclusaoTecnica.includes("RECOMENDAÇÕES")
                                            ? "#FFFF00" // Amarelo
                                            : item.conclusaoTecnica === "REPROVADO"
                                                ? "#FF0000" // Vermelho
                                                : "transparent" // Caso não seja nenhum dos valores acima
                            }}>
                                {item.conclusaoTecnica}
                            </td>

                            <td>{item.empresa}</td>
                            <td>{item.laudo}</td>
                            <td>{item.setor}</td>
                            <td>{item.subSetor}</td>
                            <td>{item.acessorio}</td>
                            <td>{item.tagInguanti}</td>
                            <td>{item.tagCliente}</td>
                            <td>{item.responsavelTecnico}</td>
                            <td>{item.metodologiaInspecao}</td>
                            <td>{item.recomendacoes}</td>
                            <td>{item.dataCadastro}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export const SimpleTable = ({ data }) => {
    return (
        <Table style={{ width: '100%' }} id="printableAreaCliente">
            <thead>
                <tr style={{ color: "#236CB3" }}>
                    <th style={{ width: '5%' }}>Resultado</th>
                    <th>Empresa</th>
                    <th>Laudo</th>
                    <th>Setor</th>
                    <th>Sub Setor</th>
                    <th>Acessório</th>
                    <th>Tag Inguanti</th>
                    <th>Tag Cliente</th>
                    <th>Respons. Téc.</th>
                    <th>Metodologia</th>
                    <th>Recomendações</th>
                    <th>Data Cadastro</th>
                </tr>
            </thead>
            <tbody >
                {data.map((item, index) => (
                    <tr key={index}>
                        <td style={{
                            backgroundColor:
                                item.conclusaoTecnica === "APROVADO 12 MESES" ||
                                    item.conclusaoTecnica === "APROVADO 6 MESES" ||
                                    item.conclusaoTecnica === "APROVADO 3 MESES"
                                    ? "#00FF7F" // Verde
                                    : item.conclusaoTecnica.includes("RECOMENDAÇÕES")
                                        ? "#FFFF00" // Amarelo
                                        : item.conclusaoTecnica === "REPROVADO"
                                            ? "#FF0000" // Vermelho
                                            : "transparent"
                        }}>
                            {item.conclusaoTecnica}
                        </td>

                        <td>{item.empresa}</td>
                        <td>{item.laudo}</td>
                        <td>{item.setor}</td>
                        <td>{item.subSetor}</td>
                        <td>{item.acessorio}</td>
                        <td>{item.tagInguanti}</td>
                        <td>{item.tagCliente}</td>
                        <td>{item.responsavelTecnico}</td>
                        <td>{item.metodologiaInspecao}</td>
                        <td>{item.recomendacoes}</td>
                        <td>{item.dataCadastro}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
