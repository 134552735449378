function TermosDeUso() {

    return (
        <div style={{ marginTop: '100px' }}>
            <h2>TermosDeUso</h2>
        </div>
    );
}

export default TermosDeUso;
