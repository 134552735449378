import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import DashboardTable from '../Components/DashboardClienteTable';
import DashboardTipoAcessSetorTable from '../Components/DashboardClienteTipoAcessorioSetorTable';
import DashboardAccessoryBarChart from '../Components/DashboardClienteAccessoryBarChart';
import DashboardAccessoryPieChart from '../Components/DashboardClienteAccessoryPieChart';
import DashboardComparisonPieCharts from '../Components/DashboardComparisonPieCharts';
import axios from '../Config/axiosConfig';
import "./DashboardCliente.css";

//Compartilhados
import Loading from '../Shared/Loading/Loading';
import './../Shared/sharedStyles.css';

const DashboardCliente = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({ aprovado: 0, aprovadoRecomendacoes: 0, reprovado: 0, total: 0 });
  const [totalsByAcessorio, setTotalsByAcessorio] = useState([]);
  const [accessoryTotals, setAccessoryTotals] = useState([]);

  const baseApiUrl = process.env.REACT_APP_API_URL;
  const baseApi = `${baseApiUrl}/api/DashboardCliente`;

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchInitialData();
    }
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const responseGetSubSetorStatistics = await axios.get(`${baseApi}/get-sub-setor-statistics`);
      const allData = responseGetSubSetorStatistics.data;

      const filteredData = allData.filter(item => item.acessorio !== null);

      const totalData = allData.find(item => item.acessorio === null);

      setData(filteredData);

      if (totalData) {
        setTotals({
          aprovado: totalData.aprovado,
          aprovadoRecomendacoes: totalData.aprovadoRecomendacoes,
          reprovado: totalData.reprovado,
          total: totalData.totalPorAcessorio
        });

        const totalsByAcessorio = filteredData.reduce((acc, item) => {
          if (!acc[item.acessorio]) {
            acc[item.acessorio] = {
              acessorio: item.acessorio,
              total: 0,
              porcentagem: 0
            };
          }
          acc[item.acessorio].total += item.totalPorAcessorio;
          return acc;
        }, {});

        for (let acessorio in totalsByAcessorio) {
          totalsByAcessorio[acessorio].porcentagem = ((totalsByAcessorio[acessorio].total / totalData.totalPorAcessorio) * 100).toFixed(2);
        }

        setTotalsByAcessorio(Object.values(totalsByAcessorio));
      }

      const accessoryTotals = groupByAcessorio(filteredData);
      setAccessoryTotals(accessoryTotals);   

    } catch (error) {
      console.log('Erro ao buscar dados:', error);
    }
    setLoading(false);
  };

  const groupBySubSetor = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.subSetor]) {
        acc[item.subSetor] = {
          aprovado: 0,
          aprovadoRecomendacoes: 0,
          reprovado: 0
        };
      }
      acc[item.subSetor].aprovado += item.aprovado;
      acc[item.subSetor].aprovadoRecomendacoes += item.aprovadoRecomendacoes;
      acc[item.subSetor].reprovado += item.reprovado;
      return acc;
    }, {});
  };

  const groupByAcessorio = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.acessorio]) {
        acc[item.acessorio] = {
          aprovado: 0,
          aprovadoRecomendacoes: 0,
          reprovado: 0
        };
      }
      acc[item.acessorio].aprovado += item.aprovado;
      acc[item.acessorio].aprovadoRecomendacoes += item.aprovadoRecomendacoes;
      acc[item.acessorio].reprovado += item.reprovado;
      return acc;
    }, {});
  };

  const getUniqueAcessorios = (data) => {
    const allAcessorios = data.map(item => item.acessorio);
    return [...new Set(allAcessorios)].sort();  // Sort accessories alphabetically
  };

  const groupedData = groupBySubSetor(data);
  const uniqueAcessorios = getUniqueAcessorios(data);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container fluid>
      <DashboardTable groupedData={groupedData} uniqueAcessorios={uniqueAcessorios} totals={totals} data={data} accessoryTotals={accessoryTotals} />
      <DashboardTipoAcessSetorTable totalsByAcessorio={totalsByAcessorio} />
      <DashboardAccessoryBarChart totalsByAcessorio={totalsByAcessorio} />

      {/* Renderizar os gráficos de pizza dinamicamente */}
      <Card>
        <Card.Body>
          <Row>
            {Object.keys(accessoryTotals).map((acessorio, index) => (
              <Col key={index} md={4}>
                <DashboardAccessoryPieChart acessorio={acessorio} data={accessoryTotals[acessorio]} />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>


      <DashboardComparisonPieCharts totals={totals} />

    </Container>
  );
};

export default DashboardCliente;
