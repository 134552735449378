import React from "react";
import { Table, Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import './CustomTable.css';

const TableOs = ({ data, handleAddLaudo, handleDetalharOs, handleDocOs }) => {
    return (
        <div className="table-responsive custom-div-table">
            <Table responsive striped bordered hover className="table-sm custom-table">
                <thead>
                    <tr style={{ fontSize: "14px", color: "#236CB3" }}>
                        <th>O.S.</th>
                        <th>Novo Laudo</th>
                        <th>Detalhar</th>
                        <th>Doc. Complementar</th>
                        <th>Cliente</th>
                        <th>Inspetor</th>
                        <th>Finalizado</th>
                    </tr>
                </thead>
                <tbody >
                    {data.map((item, index) => (
                        <tr key={index} >
                            <td style={{ fontSize: "13px" }}>{item.os}</td>
                            <td style={{ width: '13vh' }}>
                                <Tooltip title="Adicionar novo laudo" arrow>
                                    <Button variant="link" onClick={() => handleAddLaudo(item.idOrdemServico)}>
                                        <img src={process.env.PUBLIC_URL + '/icons/adicionar.ico'} style={{ height: '22PX', width: '22PX' }} />
                                    </Button>
                                </Tooltip>
                            </td>
                            <td style={{ width: '13vh' }}>
                                <Tooltip title="Visualizar e editar todos laudos dessa O.S." arrow>
                                    <Button variant="link" onClick={() => handleDetalharOs(item.idOrdemServico)}>
                                        <img src={process.env.PUBLIC_URL + '/icons/informacaoIco.ico'} style={{ height: '22PX', width: '22PX' }} />
                                    </Button>
                                </Tooltip>
                            </td>
                            <td style={{ width: '13vh' }}>
                                <Tooltip title="Incluir documentos na O.S." arrow>
                                    <Button variant="link" onClick={() => handleDocOs(item.idOrdemServico)}>
                                        <img src={process.env.PUBLIC_URL + '/icons/doc_compl.ico'} style={{ height: '22PX', width: '22PX' }} />
                                    </Button>
                                </Tooltip>
                            </td>
                            <td>{item.cliente}</td>
                            <td>{item.inspetor}</td>
                            <td>{item.finalizado ? 'SIM' : 'NÃO'}</td>
                        </tr>
                    ))}
                </tbody>


            </Table>
        </div>
    );
};

export default TableOs;
