import React from "react";
import {
    Text,
    View,
    Image
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";


const ResponsaveisLaudo = ({
    textCabecalhoInspetor,
    textCabecalhoResponsavelTecnico,
    textCabecalhoResponsavelLaudo,
    textNomeInspetor,
    nomeInspetor,
    textNomeResponsavelLaudo,
    nomeResponsavelLaudo,
    textNomeResponsavelTecnico,
    nomeResponsavelTecnico,
    textNumeroDocumento,
    numeroDocumentoInspetor,
    numeroDocumentoRespLaudo,
    textCrea,
    crea,
    assinaturaInspetorImage,
    assinaturaResponsavelImage,
    assinaturaResponsavelLaudoImage,
}) => (
    <>
        <View style={[styles.rectangleTitle, { marginTop: 8 }]}>
            <Text style={styles.numberText}>7.</Text>
            <View style={[styles.verticalLine]}></View>

            <Text style={{ fontSize: 9, fontFamily: "Arial", fontWeight: "bold", width: '33%', textAlign: "center" }}>
                {textCabecalhoInspetor}
            </Text>
            <View style={[styles.verticalLine, {marginRight: 13.9}]}></View>
            <Text style={{ fontSize: 9, fontFamily: "Arial", fontWeight: "bold", width: '33%', textAlign: "center" }}>
                {textCabecalhoResponsavelLaudo}
            </Text>
            <View style={[styles.verticalLine, {marginRight: 13.9}]}></View>
            <Text style={{ fontSize: 9, fontFamily: "Arial", fontWeight: "bold", width: '33%', textAlign: "center" }}>
                {textCabecalhoResponsavelTecnico}
            </Text>
        </View>

        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '33.333%' }]}>
                {textNomeInspetor}
                {nomeInspetor}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '33.333%' }]}>
                {textNomeResponsavelLaudo}
                {nomeResponsavelLaudo}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '33.333%' }]}>
                {textNomeResponsavelTecnico}
                {nomeResponsavelTecnico}
            </Text>
        </View>

        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '33.333%' }]}>
                {textNumeroDocumento}
                {numeroDocumentoInspetor}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '33.333%' }]}>
                {textNumeroDocumento}
                {numeroDocumentoRespLaudo}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '33.333%' }]}>
                {textCrea}
                {crea}
            </Text>

        </View>

        <View style={[styles.rectangle, { minHeight: 47, borderTop: 0 }]}>
            <View style={{
                borderWidth: 1.5,
                borderColor: '#000',
                borderTop: 0,
                borderLeft: 0,
                borderBottom: 0,
                borderRight: 0,
                width: '33.333%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 1
            }}>
                <Image
                    source={assinaturaInspetorImage}
                    resizeMode="contain"
                    style={{ width: '37%', height: 'auto' }}
                />
            </View>
            <View style={[styles.verticalLine]}></View>
            <View style={{
                borderWidth: 1.5,
                borderColor: '#000',
                borderTop: 0,
                borderLeft: 0,
                borderBottom: 0,
                borderRight: 0,
                width: '33.333%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 1
            }}>
                <Image
                    source={assinaturaResponsavelLaudoImage}
                    resizeMode="contain"
                    style={{ width: '45%', height: 'auto' }}
                />
            </View>

            <View style={[styles.verticalLine]}></View>
            <View style={{
                borderWidth: 1.5,
                borderColor: '#000',
                borderTop: 0,
                borderLeft: 0,
                borderBottom: 0,
                borderRight: 0,
                width: '33.333%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 1
            }}>
                <Image
                    source={assinaturaResponsavelImage}
                    resizeMode="contain"
                    style={{ width: '45%', height: 'auto' }}
                />
            </View>
        </View>
    </>
);
export default ResponsaveisLaudo;
