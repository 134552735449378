// SessionCheckWrapper2.js
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { BsXCircle } from 'react-icons/bs';

let timerInterval;
//const TOTAL_EXPIRATION_TIME_IN_SECONDS = 900; // 15 minutos
const TOTAL_EXPIRATION_TIME_IN_SECONDS = 1200; // 20 minutos
const WARNING_TIME_IN_SECONDS = 60; // 1 minuto

const SessionCheckWrapper2 = ({ children }) => {

  const location = useLocation(); // Pegue a localização atual

  useEffect(() => {
    let warningTimeoutId;
    let expirationTimeoutId;

    if (location.pathname !== "/login") {
      const handleUserActivity = () => {
        clearTimeout(warningTimeoutId);
        clearTimeout(expirationTimeoutId);
        
        // Configurar aviso de expiração
        warningTimeoutId = setTimeout(notifyExpirationWarning, (TOTAL_EXPIRATION_TIME_IN_SECONDS - WARNING_TIME_IN_SECONDS) * 1000);
        // Configurar notificação de expiração
        expirationTimeoutId = setTimeout(sessionExpiredNotification, TOTAL_EXPIRATION_TIME_IN_SECONDS * 1000);
      };

      // Adicionar eventos de escuta para detectar atividade do usuário
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keypress', handleUserActivity);

      // Configuração inicial dos temporizadores
      handleUserActivity();

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keypress', handleUserActivity);
        clearTimeout(warningTimeoutId);
        clearTimeout(expirationTimeoutId);
      };
    }
  }, [location.pathname]);

  const notifyExpirationWarning = () => {
    toast.info(
      <div>
        <p>Prezado usuário, notamos um período de inatividade em sua sessão.</p>
        <p>Por favor, clique aqui para manter sua sessão ativa.</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 40000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        closeButton: <BsXCircle />,
        theme: "dark",
      }
    );
  };
  
  const sessionExpiredNotification = () => {
    Swal.fire({
      icon: "error",
      title: "Sessão Expirada!",
      html: `Sua sessão expirou devido a inatividade. Por favor, faça login novamente. <br><br> Redirecionando em <b id="countdown">10</b> segundos...`,
      timer: 60000,
      timerProgressBar: true,
      willOpen: () => {
        timerInterval = setInterval(() => {
          const b = document.getElementById("countdown");
          if (b) {
            b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/login");
    });
  };

  return children;
};

export default SessionCheckWrapper2;
