import axios from "axios";
import Swal from "sweetalert2";


// Interceptor de Requisição
axios.interceptors.request.use(function (config) {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        const user = JSON.parse(storedUser);
        const token = user.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Interceptor de Resposta
axios.interceptors.response.use(function (response) {    
    return response;  // se tudo estiver bem, apenas continue
}, function (error) {
    let timerInterval;

    // Verifique a presença do cabeçalho 'Token-Expired'
    if (error.response && error.response?.headers['token-expired'] === 'true') {
        console.error("Token expirou");

        // Limpa localStorage e sessionStorage
        localStorage.clear();
        sessionStorage.clear();

        Swal.fire({
            icon: "error",
            title: "Sessão Expirada!",
            html: `Sua sessão expirou. Por favor, faça login novamente. <br><br> Redirecionando em <b id="countdown">10</b> segundos...`,
            timer: 10000,
            timerProgressBar: true,
            willOpen: () => {
                timerInterval = setInterval(() => {
                    const b = document.getElementById("countdown");
                    if (b) {
                        b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
                    }
                }, 1000);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace("/login");
        });


        // window.location.href = "/login";  // ajuste para sua rota de login
    }
    return Promise.reject(error);
});

export default axios;
