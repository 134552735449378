import React, { useRef } from 'react';
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { FaSearch, FaPlus, FaList, FaCopy  } from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';

const SearchBar = ({
    listOptions,
    handleChangeSearchTerm,
    handleSelectChange,
    handleSearchClick,
    openModal = null,
    fetchInitialData,
    copyLaudos = null,
}) => {

    // Cria uma ref para o campo pesquisarId
    const inputRef = useRef();

    // Função para lidar com a mudança no select
    const handleSelect = (event) => {
        // Chama a função original, se existir
        if (handleSelectChange) {
            handleSelectChange(event);
        }

        // Aplica o foco no campo pesquisarId
        inputRef.current.focus();
    };

    return (
        <>
            <Row>
                <Col md={3}>
                    <Form.Label htmlFor="listId" style={{ fontWeight: 'bold' }}>
                        Pesquisar por:
                    </Form.Label>
                    <Form.Control
                        id="listId"
                        as="select"
                        className="mb-2 custom-select"
                        size="sm"
                        onChange={handleSelect}
                    >
                        {listOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Control>
                </Col>
                <Col md={9}>
                    <Form.Label htmlFor="pesquisarId" style={{ fontWeight: 'bold' }}>
                        Pesquisar:
                    </Form.Label>
                    <InputGroup size="sm" className="mb-2">
                        <Form.Control
                            ref={inputRef}
                            id="pesquisarId"
                            type="text"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            placeholder="Digite para fazer uma pesquisa"
                            onChange={handleChangeSearchTerm}
                        />
                        <Tooltip title="Pesquisar" arrow>
                            <Button
                                variant="outline-secondary"
                                onClick={handleSearchClick}
                            >
                                <FaSearch />
                            </Button>
                        </Tooltip>

                        {openModal != null
                            ? (
                                <Tooltip title="Adicionar" arrow>
                                    <Button variant="outline-secondary" onClick={openModal}>
                                        <span style={{ backgroundColor: 'lightgreen' }}>
                                            <FaPlus color="green" />
                                        </span>
                                    </Button>
                                </Tooltip>
                            ) : null}

                        <Tooltip title="Listar" arrow>
                            <Button variant="outline-secondary" onClick={fetchInitialData}>
                                <span style={{ backgroundColor: 'lightblue', borderRadius: '20%' }}>
                                    <FaList color="blue" />
                                </span>
                            </Button>
                        </Tooltip>

                        {copyLaudos != null
                            ? (
                                <Tooltip title="Copiar Laudos" arrow>
                                    <Button variant="outline-secondary" onClick={copyLaudos}>
                                        <span style={{ borderRadius: '20%' }}>
                                            <FaCopy />
                                        </span>
                                    </Button>
                                </Tooltip>
                            ) : null}

                    </InputGroup>
                </Col>
            </Row>
        </>
    );

};

export default SearchBar;
