import React from "react";
import { Table, Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import './CustomTable.css';

const TableDocumentosComplementares = ({ data, handleDownloadPdf, handleDownloadMergePdfDocCompl, handleDownloadLotePdfsIndividualDocCompl }) => {
    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                    <Tooltip title="Mescla todos os PDFs em 1." arrow>
                        <Button className='btn-gerar-pdf' onClick={handleDownloadMergePdfDocCompl}  >
                            Gerar PDF
                        </Button>
                    </Tooltip>
                </div>
                <div style={{ marginLeft: '5px' }}>
                    <Tooltip title="Gera um PDF em lote de forma individual" arrow>
                        <Button className='btn-gerar-pdf' onClick={handleDownloadLotePdfsIndividualDocCompl}>
                            Gerar PDF lote individual
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div className="table-responsive mt-3" style={{ overflow: 'auto', flexGrow: 1, maxHeight: '200px' }}>
                    <Table responsive striped bordered hover className="table-sm custom-table" id="printableDocComplementar">
                        <thead >
                            <tr style={{ color: "#236CB3" }}>
                                <th className="no-print">PDF</th>
                                <th>Tipo de Documento</th>
                                <th>Descrição</th>
                                <th>Observação</th>
                            </tr>
                        </thead>
                        <tbody >
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ width: '1vh' }} className="no-print">
                                        <Tooltip title="Abrir Pdf" arrow>
                                            <Button
                                                size="sm"
                                                className="custom-pdf-button"
                                                variant="link"
                                                onClick={() => handleDownloadPdf(item.caminhoDocumento, item.idDocOs)}
                                            >
                                                <img src={process.env.PUBLIC_URL + '/icons/PdfIco.ico'} className="custom-icon-pdf" />
                                            </Button>
                                        </Tooltip>
                                    </td>
                                    <td>{item.tipoDocumento}</td>
                                    <td>{item.descricao}</td>
                                    <td>{item.observacao}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>               
            </div>
        </div>
    );
};

export default TableDocumentosComplementares;



