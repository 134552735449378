import React from "react";
import {
    Text,
    View,
    Image,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";

const EvidenciaFotograficas = ({
    textEvidenciaFotografica,
    image1Data,
    image2Data,
    image3Data,
    image4Data
}) => (
    <>
        {/* Evidência Fotográfica */}
        <View style={[styles.rectangleTitle, { marginTop: 8 }]}>
            <Text style={styles.numberText}>5.</Text>
            <View style={styles.verticalLine}></View>
            <Text style={{ paddingLeft: 197, fontSize: 9, fontFamily: "Arial", fontWeight: "bold" }}>
                {textEvidenciaFotografica}
            </Text>
        </View>

        <View style={styles.containerEvidenciaFotografica}>
            <View style={[styles.rectangleEvidenciaFotografica, { marginRight: 24 }]}>
                <View style={styles.innerRectangle}>
                    <Image src={image1Data} />
                </View>
            </View>
            <View style={styles.rectangleEvidenciaFotografica}>
                <View style={styles.innerRectangle}>
                    <Image src={image2Data} />
                </View>
            </View>
        </View>

        <View style={styles.containerEvidenciaFotografica}>
            <View style={[styles.rectangleEvidenciaFotografica, { marginRight: 24 }]}>
                <View style={styles.innerRectangle}>
                    <Image src={image3Data} />
                </View>
            </View>
            <View style={styles.rectangleEvidenciaFotografica}>
                <View style={styles.innerRectangle}>
                    <Image src={image4Data} />
                </View>
            </View>
        </View>
    </>
);
export default EvidenciaFotograficas;
