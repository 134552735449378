import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from '../Config/axiosConfig';
import { useParams } from "react-router-dom";

//Compartilhados
import CustomModal from '../Shared/CustomJs/CustomModal';
import SearchBar from '../Shared/CustomJs/SearchBar';
import CustomTable from '../Shared/CustomJs/CustomTable';
import CustomPagination from '../Shared/CustomJs/CustomPagination';
import Loading from '../Shared/Loading/Loading';
import CustomFooter from '../Shared/CustomJs/CustomFooter';

//
import './../Shared/sharedStyles.css';
import { formatarDataComHora } from './../Shared/Utils/helper';

// Definindo as classes CSS
const styles = ` 
  .custom-select {
    appearance: auto; // Adicione esta linha para restaurar a aparência padrão do <select>
  }
`;
const baseApiUrl = process.env.REACT_APP_API_URL;
const baseApi = `${baseApiUrl}/api/OsAcessorios`;
const baseApiOrdemServico = `${baseApiUrl}/api/OrdemServico`;

const OsAcessorios = () => {

        const { idOrdemServico } = useParams();

        const listOptions = [

                { value: "acessorio", label: "Acessório" },
                { value: "cadastradoPor", label: "Cadastrado Por" },
                { value: "dataCadastro", label: "Data Cadastro" },
                { value: "alteradoPor", label: "Alterado Por" },
                { value: "dataAlteracao", label: "Data Alteração" },
        ];

        const excludeColumns = ["idOsAcessorios", "idAcessorios", "idOrdemServico", "ativo", "os"]; // Colunas que você quer excluir da tabela

        const renderTableHeaders = () => {
                const emptyColumns = [<th key="empty1"></th>, <th key="empty2"></th>];

                const optionColumns = listOptions.map((option, index) => (
                        <th key={index}>{option.label}</th>
                ));

                return [...emptyColumns, ...optionColumns];
        };

        const [loading, setLoading] = useState(true);

        const [listAcessoriosData, setListAcessoriosData] = useState([]);

        const itemsPerPage = 50; // Número de itens por página

        const [os, setOs] = useState(null);

        const [searchTerm, setSearchTerm] = useState('');
        const [currentPage, setCurrentPage] = useState(1);
        const [showModal, setShowModal] = useState(false);
        const [isUpdate, setIsUpdate] = useState(false);

        const [listOsAcessoriosData, setListOsAcessoriosData] = useState([]);
        const [constListData, setConstListData] = useState([]);

        //Add
        const [newSelectedAcessorio, setNewSelectedAcessorio] = useState(null);

        //Edit  
        const [editSelectedAcessorio, setEditSelectedAcessorio] = useState(null);

        const [idOsAcessorios, setIdOsAcessorios] = useState(null);

        const [modalMessage, setModalMessage] = useState('');
        const itemInputRef = useRef(null);

        const [selectedOption, setSelectedOption] = useState("os");

        // No react 18 é necessário para não chamar 2 vezes a API
        const initialized = useRef(false);

        useEffect(() => {
                if (!initialized.current) {
                        initialized.current = true;

                        fetchInitialData();
                }
        }, []);

        const fetchInitialData = async () => {
                try {
                        setLoading(true);
                        fetchInitialOsAcessorios();
                        fetchInitialDataOrdemServico();
                        fetchInitialDataAcessorios();
                } catch (error) {
                        handleLoadingFalse();
                        console.log('Erro ao buscar dados:', error);
                }
        };

        const fetchInitialOsAcessorios = async () => {
                try {
                        setSearchTerm('');
                        const response = await axios.get(`${baseApi}/get-os-acessorios-by-id-os/${idOrdemServico}`);
                        const formattedData = formatarDataComHora(response.data);
                        setListOsAcessoriosData(formattedData);
                        setConstListData(formattedData);
                        handleNoRecordsFoundAndLoadingFalse(response?.status); 
                } catch (error) {
                        handleLoadingFalse();
                        console.log('Erro ao buscar dados:', error);
                }
        };

        const fetchInitialDataOrdemServico = async () => {
                try {
                        const response = await axios.get(`${baseApiOrdemServico}/get-ordemservico/${idOrdemServico}`);
                        setOs(response?.data?.os);
                } catch (error) {
                        handleLoadingFalse();
                        console.error("Erro ao buscar dados:", error);
                }

        };

        const fetchInitialDataAcessorios = async () => {
                try {
                        setSearchTerm('');
                        const response = await axios.get(`${baseApi}/get-acessorios`);
                        const formattedData = formatarDataComHora(response.data);
                        formattedData.sort((a, b) => a.acessorio.localeCompare(b.acessorio));
                        setListAcessoriosData(formattedData);
                } catch (error) {
                        handleLoadingFalse();
                        console.log('Erro ao buscar dados:', error);
                }
        };

        const handleSave = async (idAcessorio) => {
                if (
                        !idAcessorio
                ) {
                        setModalMessage('Erro ao adicionar o registro: É obrigatório preecher todos os campos com *');
                        return;
                }

                let acessorio;
                const numericIdAcessorio = Number(idAcessorio);

                const itemExists = listOsAcessoriosData.some((item) => {
                        acessorio = item.acessorio;
                        return item.idAcessorios === numericIdAcessorio;
                });

                if (itemExists) {
                        setModalMessage(`Registro ${acessorio} já existe`);
                } else {
                        setLoading(true);
                        try {
                                const newItem = {
                                        IdAcessorios: idAcessorio,
                                        IdOrdemServico: idOrdemServico
                                };

                                const response = await axios.post(`${baseApi}/add-os-acessorios`, newItem);

                                if (response?.status === 200) {
                                        setModalMessage(`${response?.data?.message}`);

                                        if (!(response?.data?.message.toLowerCase().includes('erro')
                                                || response?.data?.message.toLowerCase().includes('existe'))
                                        ) {
                                                setNewSelectedAcessorio(-1);
                                                setEditSelectedAcessorio(-1);
                                                fetchInitialData();
                                        }

                                } else {
                                        handleLoadingFalse();
                                        console.error('Erro ao adicionar o acessório:', response?.data?.message);
                                        setModalMessage(`Erro ao adicionar o registro: ${response?.data?.message}`);
                                }
                        } catch (error) {
                                console.error('Erro ao adicionar o acessório:', error?.response?.data?.errors[0]);
                                setModalMessage(`Erro ao adicionar o registro: ${error?.response?.data?.errors[0]}`);
                                //setModalMessage(`Erro ao adicionar o registro: ${error.response.data.errors[0]}`);
                        }
                        handleLoadingFalse();
                }
        };

        const handleUpdate = async (idAcessorio) => {

                if (
                        !idAcessorio?.trim()
                ) {
                        setModalMessage('Erro ao editar o registro: É obrigatório preecher todos os campos com *');
                        return;
                }

                let acessorio;
                const numericIdAcessorio = Number(idAcessorio);

                const itemExists = listOsAcessoriosData.some((item) => {
                        acessorio = item.acessorio;
                        return item.idAcessorios === numericIdAcessorio;
                });

                if (itemExists) {
                        setModalMessage(`Registro ${acessorio} já existe`);
                } else {
                        setLoading(true);

                        try {
                                const newItem = {
                                        IdAcessorios: idAcessorio,
                                        IdOrdemServico: idOrdemServico
                                };

                                const response = await axios.put(`${baseApi}/update-os-acessorios/${idOsAcessorios}`, newItem);

                                if (response?.status === 200) {
                                        setModalMessage(`${response.data.message}`);

                                        if (!(response?.data?.message.toLowerCase().includes('erro')
                                                || response?.data?.message.toLowerCase().includes('existe'))
                                        ) {
                                                setNewSelectedAcessorio(-1);
                                                setEditSelectedAcessorio(-1);
                                                setShowModal(false);
                                                Swal.fire('Atualizado!', `${response?.data?.message}`, 'success');
                                                setTimeout(() => {
                                                        fetchInitialData();
                                                }, 50);
                                        }
                                        handleLoadingFalse();
                                } else {
                                        handleLoadingFalse();
                                        console.error('Erro ao editar o acessório:', response?.data?.message);
                                        setModalMessage(`Erro ao editar o registro: ${response?.data?.message}`);
                                }
                        } catch (error) {
                                handleLoadingFalse();
                                console.error('Erro ao editar o acessório:', error?.response?.data?.errors[0]);
                                setModalMessage(`Erro ao editar o registro: ${error?.response?.data?.errors[0]}`);
                        }
                        handleLoadingFalse();
                }
        };

        const handleDelete = async (item) => {
                Swal.fire({
                        title: 'Tem certeza?',
                        text: 'Você tem certeza que deseja excluir esse registro?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sim, deletar!',
                        cancelButtonText: 'Não, cancelar!',
                        reverseButtons: true
                }).then(async (result) => {
                        if (result.isConfirmed) {
                                try {
                                        const response = await axios.delete(`${baseApi}/delete-os-acessorios/${item.idOsAcessorios}`);
                                        if (response?.status === 200) {
                                                Swal.fire('Deletado!', `${response?.data?.message}`, 'success').then(async () => {
                                                        fetchInitialData();
                                                });

                                        } else {
                                                console.error('Erro ao deletar o registro:', response?.data?.message);
                                        }
                                } catch (error) {
                                        console.error('Erro ao deletar o registro:', error?.response?.data?.errors[0]);
                                }
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                                Swal.fire('Cancelado', 'Seu registro está seguro :)', 'error');
                        }
                });
        };

        const handleChangeSearchTerm = (event) => {
                setSearchTerm(event.target.value);
        };

        const handleSelectChange = (event) => {
                setSelectedOption(event.target.value);
        };

        const handleSearchClick = () => {
                const searchInput = document.getElementById("pesquisarId");
                setSearchTerm(searchInput.value);
        };

        const handleAcessorioChange = (event) => {
                isUpdate
                        ? setEditSelectedAcessorio(event.target.value)
                        : setNewSelectedAcessorio(event.target.value);
        };

        const handlePageChange = (pageNumber) => {
                setCurrentPage(pageNumber);
        };
        const handleLoadingFalse = () => {
                setTimeout(() => {
                        setLoading(false);
                }, 100);
        };
        const handleNoRecordsFoundAndLoadingFalse = (status) => {
                if (status === 200) {
                    handleLoadingFalse();
                }
            };
        const filteredData = constListData.filter((item) =>
                item[selectedOption].toString().toLowerCase().includes(searchTerm.toLowerCase())
        );

        const paginatedData = filteredData.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
        );

        const totalPages = Math.ceil(filteredData.length / itemsPerPage);
        const totalRecords = filteredData.length;

        const openModalAdd = () => {
                setModalMessage('');
                setNewSelectedAcessorio(-1);
                setIdOsAcessorios(null);
                setEditSelectedAcessorio(-1);
                setIsUpdate(false);
                setShowModal(true);
        };
        const openModalEdit = (item) => {
                setModalMessage('');
                setEditSelectedAcessorio(item.acessorio);
                setIdOsAcessorios(item.idOsAcessorios);
                setEditSelectedAcessorio(item.idAcessorios);
                setNewSelectedAcessorio(-1);
                setIsUpdate(true);
                setShowModal(true);
        };
        const closeModal = () => {
                setModalMessage('');
                setNewSelectedAcessorio(-1);
                setEditSelectedAcessorio(-1);
                setIdOsAcessorios(null);
                setIsUpdate(false);
                setShowModal(false);
        };

        if (loading) {
                return <Loading />;
        }
        return (
                <Container fluid>
                        <style>{styles}</style>
                        <Row className='mt-3'>
                                <Col>
                                        <Form>
                                                <Card>
                                                        <Card.Header className='title-card-custom'><h4>Incluir Acessórios na O.S. N° {os}</h4></Card.Header>
                                                        <Card.Body>
                                                                <SearchBar
                                                                        listOptions={listOptions}
                                                                        handleChangeSearchTerm={handleChangeSearchTerm}
                                                                        handleSelectChange={handleSelectChange}
                                                                        handleSearchClick={handleSearchClick}
                                                                        openModal={openModalAdd}
                                                                        fetchInitialData={fetchInitialData}
                                                                />
                                                                {constListData.length <= 0 && loading === false ? (
                                                                        <div style={{ display: 'flex', height: 'calc(100vh - 200px)', justifyContent: 'center', alignItems: 'center' }}>
                                                                                <h1 style={{ fontSize: '3em' }}>Nenhum registro encontrado</h1>
                                                                        </div>
                                                                ) : (
                                                                        <>

                                                                                <CustomTable
                                                                                        renderTableHeaders={renderTableHeaders}
                                                                                        data={paginatedData}
                                                                                        handleEdit={openModalEdit}
                                                                                        handleDelete={handleDelete}
                                                                                        excludeColumns={excludeColumns}
                                                                                />

                                                                                <CustomPagination
                                                                                        currentPage={currentPage}
                                                                                        totalPages={totalPages}
                                                                                        onPageChange={handlePageChange}
                                                                                />
                                                                                <CustomFooter currentPage={currentPage} totalPages={totalPages} totalRecords={totalRecords} />
                                                                        </>
                                                                )}

                                                        </Card.Body>
                                                </Card>
                                        </Form>
                                </Col>
                        </Row>


                        <CustomModal
                                show={showModal}
                                onHide={closeModal}
                                title={isUpdate ? 'O.S Acessórios - Editar' : 'O.S Acessórios - Incluir'}
                                onSave={isUpdate
                                        ? () => handleUpdate(editSelectedAcessorio)
                                        : () => handleSave(newSelectedAcessorio)}
                                saveButtonText={isUpdate ? 'Atualizar' : 'Incluir'}
                        >

                                <Form.Group as={Col} controlId="acessorio">
                                        <Form.Label className="bold-label">*Acessório:</Form.Label>
                                        <Form.Select
                                                value={isUpdate ? editSelectedAcessorio : newSelectedAcessorio}
                                                autoFocus
                                                onChange={handleAcessorioChange}
                                        >
                                                <option key={-1} value={"-1"}>SELECIONE</option>

                                                {listAcessoriosData.map((item, index) => (
                                                        <option
                                                                key={index}
                                                                value={item.idAcessorios}
                                                        >
                                                                {item.acessorio}
                                                        </option>
                                                ))}
                                        </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="os">
                                        <Form.Label className="bold-label">O.S.:</Form.Label>
                                        <Form.Control style={{ backgroundColor: '#ECECEC' }}
                                                type="text"
                                                readOnly
                                                value={os}
                                        />
                                </Form.Group>

                                {modalMessage && (
                                        <p style={{ color: modalMessage.startsWith(`Erro`) ? 'red' : 'green' }}>
                                                {modalMessage}
                                        </p>
                                )}
                        </CustomModal>
                </Container>
        )
};

export default OsAcessorios;