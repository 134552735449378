import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";
import { formatarDataIsoString } from './../../Shared/Utils/helper';

const DadosInspecaoExecutada = ({
    textDadosDaInspecaoExecutada,
    textMetodologiaInspecao,
    metodologiaInspecao,
    textDataMetodologiaInspecao,
    dataMetodologiaInspecao
}) => (
    <>
        {/* Dados da inspeção Executada */}
        <View style={[styles.rectangleTitle, { marginTop: 8 }]}>
            <Text style={styles.numberText}>3.</Text>
            <View style={styles.verticalLine}></View>
            <Text style={{ paddingLeft: 176, fontSize: 9, fontFamily: "Arial", fontWeight: "bold" }}>
                {textDadosDaInspecaoExecutada}
            </Text>
        </View>

        {/* Metodologia e Data */}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '73%' }]}>
                {textMetodologiaInspecao}
                {metodologiaInspecao}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '27%' }]}>
                {textDataMetodologiaInspecao}
                {formatarDataIsoString(dataMetodologiaInspecao)}
            </Text>
        </View>

    </>

);

export default DadosInspecaoExecutada;
