import React from "react";
import { Table, Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import DownloadImages from '../Downloads/DownloadImages';
import './CustomTable.css';

const StatusBadge = ({ status }) => {
    const style = {
        display: 'inline-block',
        padding: '0.25em 0.5em',
        color: status === true ? 'white' : 'black',
        backgroundColor: status === true ? 'green' : 'red',
        borderRadius: '5px',
    };

    return <span style={style}>{status === true ? 'Pago' : 'Pendente'}</span>;
};

const isDateBeforeTenDays = (dateString) => {
    const dateParts = dateString.split("/"); // Dividir a data em partes
    // Converter "dd/MM/yyyy" para "MM/dd/yyyy"
    const targetDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

    // Subtrair 10 dias da data de vencimento
    targetDate.setDate(targetDate.getDate() - 10);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Ajustar para meia-noite para comparação justa

    // Verificar se a data atual é menor ou igual a 10 dias antes da data de vencimento
    return currentDate <= targetDate;
};

const TableBoletos = ({ data, handleDownloadPdf, api, updateBoletoStatus, handleCheckTypeUse }) => {

    const isUserMaster = handleCheckTypeUse();

    const handleCheckboxChange = (item) => {
        updateBoletoStatus(item.nome, !item.status);
    };

    return (
        <div className="table-responsive custom-div-table">
            <Table responsive striped bordered hover className="table-sm custom-table">
                <thead>
                    <tr style={{ fontSize: "14px", color: "#236CB3" }}>
                        <th>PDF</th>
                        <th>Vencimento</th>
                        <th>Valor</th>
                        <th>Cód. Barras</th>
                        <th>Status</th>
                        <th>QrCode</th>
                        {isUserMaster && <th>Pago ?</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => {
                        const isBeforeTenDays = isDateBeforeTenDays(item.vencimento);

                        return (
                            <tr key={index} style={isBeforeTenDays ? { backgroundColor: '#eee' } : {}}>
                                <td style={{ width: '1vh' }}>
                                    {isBeforeTenDays ? 'Indisponível' : (
                                        <Tooltip title="Abrir Documento" arrow>
                                            <Button
                                                size="sm"
                                                className="custom-pdf-button"
                                                variant="link"
                                                onClick={() => handleDownloadPdf(item.nome)}
                                            >
                                                <img src={process.env.PUBLIC_URL + '/icons/PdfIco.ico'} className="custom-icon-pdf" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </td>
                                <td>{isBeforeTenDays ? 'Indisponível' : item.vencimento}</td>
                                <td>{isBeforeTenDays ? 'Indisponível' : item.valor}</td>
                                <td>{isBeforeTenDays ? 'Indisponível' : item.codigoBarras}</td>
                                <td>{isBeforeTenDays ? 'Indisponível' : <StatusBadge status={item.status} />}</td>
                                <td>
                                    {isBeforeTenDays ? 'Indisponível' : <DownloadImages caminhoImage={item.qrcode + '.jpg'} style={{ width: '100px', height: '50px', objectFit: 'contain', cursor: 'pointer' }} api={api} value={item.pixCopiaCola} />}
                                </td>

                                {/* <td style={{ width: '1vh' }}>
                                    {
                                        <Tooltip title="Abrir Documento" arrow>
                                            <Button
                                                size="sm"
                                                className="custom-pdf-button"
                                                variant="link"
                                                onClick={() => handleDownloadPdf(item.nome)}
                                            >
                                                <img src={process.env.PUBLIC_URL + '/icons/PdfIco.ico'} className="custom-icon-pdf" />
                                            </Button>
                                        </Tooltip>
                                    }
                                </td>
                                <td>{item.vencimento}</td>
                                <td>{item.valor}</td>
                                <td>{item.codigoBarras}</td>
                                <td>{<StatusBadge status={item.status} />}</td>
                                <td>
                                    {<DownloadImages caminhoImage={item.qrcode + '.jpg'} style={{ width: '100px', height: '50px', objectFit: 'contain', cursor: 'pointer' }} api={api} value={item.pixCopiaCola} />}
                                </td> */}

                                {isUserMaster && (
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={item.status && !isBeforeTenDays}
                                            onChange={() => handleCheckboxChange(item)}
                                            disabled={isBeforeTenDays}
                                        />
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default TableBoletos;
