import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";


const ConclusaoTecnica = ({
    textCabecalhoConclusaoTecnica,
    textConclusaoTecnica,
    conclusaoTecnica,
    textRecomendacoes,
    recomendacoes
}) => (
    <>
        {/* Conclusão Técnica*/}
        <View style={[styles.rectangleTitle, { marginTop: 8 }]}>
            <Text style={styles.numberText}>6.</Text>
            <View style={styles.verticalLine}></View>
            <Text style={{ paddingLeft: 197, fontSize: 9, fontFamily: "Arial", fontWeight: "bold" }}>
                {textCabecalhoConclusaoTecnica}
            </Text>
        </View>

        {/* Resultado*/}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '100%' }]}>
                {textConclusaoTecnica}
                {conclusaoTecnica}
            </Text>
        </View>

        {/* Observações*/}
        <View style={[styles.rectangleTopAlign]}>
            <Text style={[styles.textSection, { width: '100%' }]}>
                {textRecomendacoes}
                {recomendacoes}
            </Text>
        </View>
    </>
);

export default ConclusaoTecnica;
