import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  
  const storedUser = localStorage.getItem('user'); 
 
  const [currentUser, setCurrentUser] = useState(storedUser ? JSON.parse(storedUser) : null);
  const [isClient, setIsClient] = useState(currentUser?.isClient);

  const logout = async () => {   
    localStorage.clear();
    sessionStorage.clear();
    setCurrentUser(null);
    setIsClient(null);
    return Promise.resolve();
  };

  const checkUserPermission = (requiredRole) => {
    if (!currentUser) {
      return false;
    }

    if (!requiredRole)
      return true;

    if ((currentUser.roles || []).includes('Master')) {
      return true;
    }

    return (currentUser.roles || []).includes(requiredRole);
  }

  const value = { currentUser, setCurrentUser, logout, checkUserPermission, isClient };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

