import React from 'react';
import { Row } from 'react-bootstrap';
import './StatisticsCards.css';
import './../sharedStyles.css'

const StatisticsCards = ({ data }) => {

    return (
        <Row className='mt-3 g-3'>
            <div className="col-sm-4">
                <div className="card text-white bg-primary mb-3 card-custom">
                    <h5 className="card-title card-title-custom">{data.totalLaudos}</h5>
                    <p className="card-text card-text-custom">Inspeções</p>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="card text-white bg-success mb-3 card-custom">
                    <h5 className="card-title card-title-custom">{data.totalClientes}</h5>
                    <p className="card-text card-text-custom">Clientes</p>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="card text-white bg-danger mb-3 card-custom">
                    <h5 className="card-title card-title-custom">{data.totalOs}</h5>
                    <p className="card-text card-text-custom">O.S.</p>
                </div>
            </div>
        </Row>
    );
};

export default StatisticsCards;
