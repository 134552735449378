import React from "react";
import { Table, Button } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import { BsTrash, BsPencil } from 'react-icons/bs';
import './CustomTable.css';

const TableDocOs = ({ data, handleDownloadPdf, handleEdit, handleDelete }) => {
    return (
        <div className="table-responsive custom-div-table">
            <Table responsive striped bordered hover className="table-sm custom-table">
                <thead >
                    <tr style={{fontSize: "14px", color: "#236CB3" }}>
                        <th>PDF</th>
                        <th></th>
                        <th></th>
                        <th>Tipo de Documento</th>
                        <th>Descrição</th>
                        <th>Ordem de Serviço</th>
                        <th>Observação</th>                      
                        <th>Cadastrado por</th>
                        <th>Data Cadastro</th>
                        <th>Alterado por</th>
                        <th>Data Alteração</th>
                    </tr>
                </thead>
                <tbody >
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Abrir Documento" arrow>
                                    <Button
                                        size="sm"
                                        className="custom-pdf-button"
                                        variant="link"
                                        onClick={() => handleDownloadPdf(item.caminhoDocumento)}
                                    >
                                        <img src={process.env.PUBLIC_URL + '/icons/PdfIco.ico'} className="custom-icon-pdf" />
                                    </Button>


                                </Tooltip>

                            </td>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Editar" arrow>
                                    <Button
                                        size="sm"
                                        onClick={() => handleEdit(item)}
                                        className="custom-edit-button"
                                    >
                                        <BsPencil className="custom-icon-trash-pencil" />
                                    </Button>
                                </Tooltip>
                            </td>
                            <td style={{ width: '1vh' }}>
                                <Tooltip title="Deletar" arrow>
                                    <Button
                                        size="sm"
                                        onClick={() => handleDelete(item)}
                                        className="custom-delete-button"
                                    >
                                        <BsTrash className="custom-icon-trash-pencil" />
                                    </Button>
                                </Tooltip>
                            </td> 

                            <td>{item.tipoDocumento}</td>
                            <td>{item.descricao}</td>
                            <td>{item.ordemServico}</td>
                            <td>{item.observacao}</td> 
                            <td>{item.cadastradoPor}</td>
                            <td>{item.dataCadastro}</td>
                            <td>{item.alteradoPor}</td>
                            <td>{item.dataAlteracao}</td>
                        </tr>
                    ))}
                </tbody>


            </Table>
        </div>
    );
};

export default TableDocOs;
