import React from "react";
import {
    Text,
    View,
} from "@react-pdf/renderer";
import { styles } from "./../ExportToPDFStyles";


const IdentificacaoAcessorio = ({
    textIdentificacaoAcessorio,
    textTipo,
    tipo,
    textTagIng,
    tagIng,
    textTagCliente,
    tagCliente,
    textFabricante,
    fabricante,
    textModelo,
    modelo,
    textCapacidade,
    capacidade,
    textDimensoes,
    dimensoes
}) => (
    <>

        {/* Cabeçalho Identificação do acessório */}
        <View style={styles.rectangleTitle}>
            <Text style={styles.numberText}>1.</Text>
            <View style={styles.verticalLine}></View>
            <Text style={{ paddingLeft: 153, fontSize: 9, fontFamily: "Arial", fontWeight: "bold" }}>
                {textIdentificacaoAcessorio}
            </Text>
        </View>

        {/* Tipo e Tags */}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '50%' }]}>
                {textTipo}
                {tipo}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '25%' }]}>
                {textTagIng}
                {tagIng}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '25%' }]}>
                {textTagCliente}
                {tagCliente}
            </Text>
        </View>

        {/* Fabricante, Modelo e Capacidade */}
        <View style={styles.rectangle}>
            <Text style={[styles.textSection, { width: '33.33%' }]}>
                {textFabricante}
                {fabricante}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '33.33%' }]}>
                {textModelo}
                {modelo}
            </Text>
            <View style={[styles.verticalLine]}></View>
            <Text style={[styles.textSection, { width: '33.33%' }]}>
                {textCapacidade}
                {capacidade}
            </Text>
        </View>       

        {/* Dimensões */}
        <View style={[styles.rectangleTopAlign]}>
            <Text style={[styles.textSection, { width: '100%' }]}>
            {textDimensoes}
                {dimensoes}
            </Text>
        </View>
    </>

);

export default IdentificacaoAcessorio;
